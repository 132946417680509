export * from 'gl-commonui/lib/antd-icons';
// export what you need
export { default as MenuFoldOutline } from '@ant-design/icons/lib/outline/MenuFoldOutline';
export { default as MenuUnfoldOutline } from '@ant-design/icons/lib/outline/MenuUnfoldOutline';
export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill';
export { default as ArrowLeftOutline } from '@ant-design/icons/lib/outline/ArrowLeftOutline';
export { default as StepBackwardOutline } from '@ant-design/icons/lib/outline/StepBackwardOutline';
export { default as StepForwardOutline } from '@ant-design/icons/lib/outline/StepForwardOutline';
export { default as DownloadOutline } from '@ant-design/icons/lib/outline/DownloadOutline';
export { default as SyncOutline } from '@ant-design/icons/lib/outline/SyncOutline';
export { default as FormOutline } from '@ant-design/icons/lib/outline/FormOutline';
export { default as QuestionCircleOutline } from '@ant-design/icons/lib/outline/QuestionCircleOutline';
export { default as QuestionOutline } from '@ant-design/icons/lib/outline/QuestionOutline';
