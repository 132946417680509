var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { GLGlobal } from "gl-commonui";
import { lazyInject, TYPES, Mode } from "@app/util";
import ContentPlayer from "@app/components/media/contentplayer";
import SliderControlContainer from "@app/components/media/slider-controlcontainer";
import { SequenceMode } from "@app/components/media";
import UploadLogs from "@app/service/logs/uploadlogs";
var CDplayer = /** @class */ (function (_super) {
    __extends(CDplayer, _super);
    function CDplayer(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.onCurrentStarted = function (context) {
            _this.setState({ startedItem: context.content.id });
            _this.props.onItemStarted && _this.props.onItemStarted(context.content.id);
        };
        _this.onPlay = function () {
            if (_this.state.startedItem) {
                _this.props.onPlay && _this.props.onPlay(_this.state.startedItem);
            }
        };
        _this.onCurrentEnded = function (content) {
            //upload log.
            _this.props.onItemEnded && _this.props.onItemEnded(content.id);
            UploadLogs({ id: _this.state.userId, role: GLGlobal.store.getState().theme.leftData.role }, _this.state.unit, content, Mode.Listen);
        };
        _this.onEnded = function () {
            //upload log.
        };
        _this.state = {
            userId: null,
            versionId: null,
            unit: 0,
            sequence: null,
            signature: {},
            contentresource: {},
            startedItem: null,
        };
        return _this;
    }
    CDplayer.prototype.componentDidMount = function () {
        var _a = this.props, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, sequence = _a.sequence;
        this.setState({ userId: userId, versionId: versionId, unit: unit, sequence: sequence });
    };
    CDplayer.prototype.componentWillReceiveProps = function (_a) {
        var unitcontents = _a.unitcontents, contents = _a.contents;
        if (this.props.unitcontents !== unitcontents) {
            var unitcontent = unitcontents.shift();
            var playlistscontents = [{ contents: contents.map(function (id) { return ({ id: id }); }) }];
            var contentresource = { playlists: playlistscontents, contents: unitcontent.contents };
            this.setState({ contentresource: contentresource });
        }
    };
    CDplayer.prototype.play = function (userId, versionId, unit, sequence, contents) {
        var _this = this;
        this.setState({ userId: userId, versionId: versionId, unit: unit, sequence: sequence });
        if (contents && contents.length > 0) {
            Promise.all([
                this.contentsignatureappservice.getSignatures(null, { versionId: versionId }),
                this.unitsContentsService.getUnitsContents({ units: [Math.abs(unit)] }, { versionId: versionId })
            ]).then(function (_a) {
                var _b = __read(_a, 2), signature = _b[0], unitcontents = _b[1];
                var unitcontent = unitcontents.shift();
                var playlistscontents = [{ contents: contents.map(function (id) { return ({ id: id }); }) }];
                var resources = { playlists: playlistscontents, contents: unitcontent.contents };
                _this.setState({
                    contentResource: contentResource, signature: signature, sequence: sequence
                });
            });
        }
    };
    CDplayer.prototype.getResources = function (_a) {
        var unitcontents = _a.unitcontents, contents = _a.contents;
        if (!unitcontents || unitcontents.length === 0)
            return {};
        var unitcontent = unitcontents[0];
        var playlistscontents = [{ contents: contents.map(function (id) { return ({ id: id }); }) }];
        return { playlists: playlistscontents, contents: unitcontent.contents };
    };
    CDplayer.prototype.render = function () {
        var _this = this;
        this.contentResource || (this.contentResource = this.getResources(this.props));
        return React.createElement("div", { className: "playlistplayer media-page" },
            React.createElement(ContentPlayer, { autoplay: false, stream: true, streamPreload: false, visibleMedia: false, playAction: this.props.playAction, sequenceMode: SequenceMode.singleCycle, onCurrentStarted: this.onCurrentStarted, onPlay: this.onPlay, onCurrentEnded: this.onCurrentEnded, onEnded: this.onEnded, resources: this.contentResource, signature: this.props.signature, startIndex: this.props.sequence, playbackRate: this.props.playbackRate ? this.props.playbackRate : 1, visibleBar: true },
                React.createElement(SliderControlContainer, { showTitle: true, mode: this.props.playMode, isCD: true, onModeChanged: function (mode) { _this.props.onModeChanged && _this.props.onModeChanged(mode); } })));
    };
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], CDplayer.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsContentsService)
    ], CDplayer.prototype, "unitsContentsService", void 0);
    return CDplayer;
}(Component));
export default CDplayer;
