var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React from 'react';
import { Icon } from 'antd-min';
import NoneFinSvg from '@app/style/images/puzzle-badge/none-fin.svg';
import PieSvg from "@app/style/images/pie.svg";
import UnitBoxSvg from "@app/style/images/unit-box.svg";
import PlayLoopAllSvg from "@app/style/images/icon-play-loop-all.svg";
import PlayLoopSingleSvg from "@app/style/images/icon-play-loop-single.svg";
import PlayOrderSvg from "@app/style/images/icon-play-order.svg";
import PlayShuffleSvg from "@app/style/images/icon-play-shuffle.svg";
import PlayPlayerSvg from "@app/style/images/icon-player-play.svg";
import PlayWaitSvg from "@app/style/images/back_wait.svg";
import LibrarySvg from "@app/style/images/icon-library.svg";
import PlaylistSvg from "@app/style/images/icon-playlist.svg";
import RewindSvg from "@app/style/images/icon-rewind-back.svg";
import PhotoSvg from "@app/style/images/photo.svg";
import SpeakerSvg from "@app/style/images/speaker-wave.svg";
import SpellBookSvg from "@app/style/images/spell-book.svg";
export var NoneFin = function (props) { return (React.createElement(Icon, __assign({ component: NoneFinSvg, className: 'svg-icon' }, props))); };
export var Pie = function (props) { return (React.createElement(Icon, __assign({ component: PieSvg, className: 'svg-icon' }, props))); };
export var UnitBox = function (_a) {
    var className = _a.className, rest = __rest(_a, ["className"]);
    return (React.createElement(Icon, __assign({ component: UnitBoxSvg, className: "svg-icon unit-box " + className }, rest)));
};
export var PlayLoopAll = function (props) { return (React.createElement(Icon, __assign({ component: PlayLoopAllSvg, className: "svg-icon play-mode" }, props))); };
export var PlayLoopSingle = function (props) { return (React.createElement(Icon, __assign({ component: PlayLoopSingleSvg, className: "svg-icon play-mode" }, props))); };
export var PlayOrder = function (props) { return (React.createElement(Icon, __assign({ component: PlayOrderSvg, className: "svg-icon play-mode" }, props))); };
export var PlayShuffle = function (props) { return (React.createElement(Icon, __assign({ component: PlayShuffleSvg, className: "svg-icon play-mode" }, props))); };
export var PlayPlayer = function (props) { return (React.createElement(Icon, __assign({ component: PlayPlayerSvg, className: "svg-icon play-btn" }, props))); };
export var PlayWait = function (props) { return (React.createElement(Icon, __assign({ component: PlayWaitSvg, className: "svg-icon play-btn" }, props))); };
export var LibraryIcon = function (props) { return (React.createElement(Icon, __assign({ component: LibrarySvg, className: "svg-icon" }, props))); };
export var PlaylistIcon = function (props) { return (React.createElement(Icon, __assign({ component: PlaylistSvg, className: "svg-icon" }, props))); };
export var RewindIcon = function (props) { return (React.createElement(Icon, __assign({ component: RewindSvg, className: "svg-icon play-btn" }, props))); };
export var SpeakerIcon = function (props) { return (React.createElement(Icon, __assign({ component: SpeakerSvg, className: "svg-icon" }, props))); };
export var SpellBookIcon = function (props) { return (React.createElement(Icon, __assign({ component: SpellBookSvg, className: "svg-icon" }, props))); };
export var PhotoIcon = function (props) { return (React.createElement(Icon, __assign({ component: PhotoSvg, className: "svg-icon" }, props))); };
