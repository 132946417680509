var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { lazyInject, TYPES } from "@app/util";
import { Badge } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Notifications } from "./components";
import "./style.less";
var HeaderNotification = /** @class */ (function (_super) {
    __extends(HeaderNotification, _super);
    function HeaderNotification(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            visible: false,
            numUnread: 0
        };
        return _this;
    }
    HeaderNotification.prototype.componentDidMount = function () {
        if (this.props.actionLoaded) {
            this.getNumUnread();
        }
    };
    HeaderNotification.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        if (this.props.actionLoaded !== prevProps.actionLoaded &&
            this.props.actionLoaded) {
            this.getNumUnread();
        }
    };
    HeaderNotification.prototype.getNumUnread = function () {
        var _this = this;
        this.service
            .countAllNotificationsUnRead("")
            .then(function (d) { return _this.setState({ numUnread: d }); });
    };
    HeaderNotification.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "gl-header-right-item-dropdown", style: {
                position: "relative"
            } },
            React.createElement(Badge, { count: this.state.numUnread },
                React.createElement("div", { className: "gl-header-right-item", onClick: function () {
                        return _this.setState({
                            visible: !_this.state.visible
                        });
                    } },
                    React.createElement("i", { id: "notification-icon-header", className: "material-icons" }, "circle_notifications"))),
            this.state.visible && (React.createElement(Notifications, { onHide: function () {
                    return _this.setState({
                        visible: false
                    });
                }, onGetNumUnread: function () { return _this.getNumUnread(); } }))));
    };
    __decorate([
        lazyInject(TYPES.INotificationService)
    ], HeaderNotification.prototype, "service", void 0);
    HeaderNotification = __decorate([
        connect(function (_a) {
            var loginInfo = _a.oidc.loginInfo;
            return ({
                actionLoaded: loginInfo &&
                    loginInfo.loggedin &&
                    loginInfo.profile &&
                    loginInfo.profile.actions &&
                    true
            });
        }, {})
    ], HeaderNotification);
    return HeaderNotification;
}(Component));
export { HeaderNotification };
