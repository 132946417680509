var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '../../util/di';
import VerificationServiceBase from '../app/servicebase/verificationservicebase';
var ResourceService = /** @class */ (function (_super) {
    __extends(ResourceService, _super);
    function ResourceService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'content/v1', suffix: '{versionId}' };
        return _this;
    }
    ResourceService.prototype.getActivityPage = function (versionId, units) {
        return this.get('versions/{versionId}/units/activitypage', { units: units }, { versionId: versionId });
    };
    ResourceService.prototype.getNewsletter = function (params) {
        return this.get('versions/getunitnewsletters', params);
    };
    ResourceService.prototype.getPreviewAudio = function (contentId) {
        return this.get('contents/{contentId}/previewaudio', { contentId: contentId });
    };
    ResourceService.prototype.getContentsignature = function (versionId) {
        return this.get('versions/{versionId}/contents/contentsignature', null, { versionId: versionId });
    };
    ResourceService.prototype.getContentStreamingUrl = function (data) {
        return this.get('resource/getcontentstreamingurl', data);
    };
    ResourceService.prototype.getAudioStreaming = function (data) {
        return this.get('resource/getaudiostreaming', data);
    };
    ResourceService.prototype.getVideoStreaming = function (data) {
        return this.get('resource/getvideostreaming', data);
    };
    ResourceService.prototype.getVideoStreams = function (data) {
        return this.create('resource/videos/streams', data.contentIds);
    };
    ResourceService.prototype.getThumbnails = function (data) {
        return this.get('versions/Thumbnails', data);
    };
    ResourceService.prototype.getUnitBadges = function (data) {
        return this.createWithoutLoader('versions/UnitBadges', data);
    };
    ResourceService.prototype.getStudentAppIntroDoc = function (params) {
        return this.get("versions/getstudentappintro", params);
    };
    ResourceService.prototype.getContentSubtitle = function (params) {
        return this.get("versions/getcontentlinesv3/{contentId}", null, params);
    };
    ResourceService.prototype.getContentSubtitles = function (params) {
        return this.get("versions/getcontentlinesv3", params);
    };
    ResourceService = __decorate([
        injectable()
    ], ResourceService);
    return ResourceService;
}(VerificationServiceBase));
export { ResourceService };
