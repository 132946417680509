var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GLServiceBase, GLUtil, GLGlobal, RoleName } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { userHasRole } from "@app/util";
var VerificationServiceBase = /** @class */ (function (_super) {
    __extends(VerificationServiceBase, _super);
    function VerificationServiceBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VerificationServiceBase.prototype.validate = function () {
        var userId = GLUtil.pathParse(PathConfig.CurrentUser).userId;
        var loginInfo = GLGlobal.loginInfo();
        if (!loginInfo
            || !loginInfo.loggedin
            || (userId && GLGlobal.store.getState().theme.leftData.id
                && (GLGlobal.store.getState().theme.leftData.id !== userId
                    || (GLGlobal.store.getState().theme.leftData.parentId !== loginInfo.profile.sub && !userHasRole(RoleName.student, loginInfo.profile))))) {
            window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
        }
    };
    VerificationServiceBase.prototype.getItemsBy = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.getItemsBy.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.get = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.get.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.delete = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.delete.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.create = function (suffix, data, routeData, requestOptions) {
        this.validate();
        return _super.prototype.create.call(this, suffix, data, routeData, requestOptions);
    };
    VerificationServiceBase.prototype.createMultipart = function (suffix, data, routeData, requestOptions) {
        this.validate();
        return _super.prototype.createMultipart.call(this, suffix, data, routeData, requestOptions);
    };
    VerificationServiceBase.prototype.createWithoutLoader = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.createWithoutLoader.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.update = function (suffix, data, routeData, options) {
        this.validate();
        return _super.prototype.update.call(this, suffix, data, routeData, options);
    };
    VerificationServiceBase.prototype.merge = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.merge.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.getBlob = function (suffix, data, routeData) {
        this.validate();
        return _super.prototype.getBlob.call(this, suffix, data, routeData);
    };
    VerificationServiceBase.prototype.download = function (_a) {
        var suffix = _a.suffix, data = _a.data, routeData = _a.routeData, method = _a.method;
        this.validate();
        return _super.prototype.download.call(this, { suffix: suffix, data: data, routeData: routeData, method: method });
    };
    return VerificationServiceBase;
}(GLServiceBase));
export default VerificationServiceBase;
