var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConversationService } from "@app/service/conversation";
import { lazyInject, nonParentAndStudentUsers, TYPES } from "@app/util";
import { ConversationVoiceGenerationStatus } from "@app/util/conversation-api";
import { OidcProxy, PathConfig as GLCommonPathConfig } from "gl-commonui";
import { toNumber } from "lodash";
import { PathConfig } from "./pathconfig";
var AllowTestingAccessibleRouteHandler = /** @class */ (function () {
    function AllowTestingAccessibleRouteHandler() {
    }
    AllowTestingAccessibleRouteHandler.prototype.invoke = function (context) {
        AllowTestingAccessibleRouteGuard(this.next, context, this.userService);
    };
    __decorate([
        lazyInject(TYPES.IUserService)
    ], AllowTestingAccessibleRouteHandler.prototype, "userService", void 0);
    return AllowTestingAccessibleRouteHandler;
}());
export { AllowTestingAccessibleRouteHandler };
function AllowTestingAccessibleRouteGuard(next, context, userService) {
    return __awaiter(this, void 0, void 0, function () {
        var loginInfo, isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loginInfo = OidcProxy.getLoginInfo();
                    if (!loginInfo.loggedin) return [3 /*break*/, 2];
                    if (nonParentAndStudentUsers()) {
                        next.invoke(context);
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, userService.isTestingAllowed({
                            id: loginInfo.profile.sub
                        })];
                case 1:
                    isValid = _a.sent();
                    if (isValid) {
                        next.invoke(context);
                    }
                    else {
                        context.history.replace({
                            pathname: GLCommonPathConfig.AccessDenied
                        });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    context.history.replace({ pathname: GLCommonPathConfig.AccessDenied });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
var ConversationVoiceUrlGeneratedRouteHandler = /** @class */ (function () {
    function ConversationVoiceUrlGeneratedRouteHandler() {
    }
    ConversationVoiceUrlGeneratedRouteHandler.prototype.invoke = function (context) {
        ConversationVoiceUrlGeneratedRouteGuard(this.next, context);
    };
    return ConversationVoiceUrlGeneratedRouteHandler;
}());
export { ConversationVoiceUrlGeneratedRouteHandler };
function ConversationVoiceUrlGeneratedRouteGuard(next, context) {
    return __awaiter(this, void 0, void 0, function () {
        var status, isValid;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ConversationService.getVoiceUrlGeneratedStatus(toNumber(context.match.params.conversationId))];
                case 1:
                    status = _a.sent();
                    isValid = status && status === ConversationVoiceGenerationStatus.Success
                        ? true
                        : false;
                    if (isValid) {
                        next.invoke(context);
                    }
                    else {
                        context.history.replace({
                            pathname: PathConfig.ConversationVoiceUrlGenerationAccessDenied
                        });
                    }
                    return [2 /*return*/];
            }
        });
    });
}
var StudentPuzzleAccess = /** @class */ (function () {
    function StudentPuzzleAccess() {
    }
    StudentPuzzleAccess.prototype.invoke = function (context) {
        StudentPuzzleRouteGuard(this.next, context, this.puzzleService);
    };
    __decorate([
        lazyInject(TYPES.IPuzzleService)
    ], StudentPuzzleAccess.prototype, "puzzleService", void 0);
    return StudentPuzzleAccess;
}());
export { StudentPuzzleAccess };
function StudentPuzzleRouteGuard(next, context, puzzleService) {
    return __awaiter(this, void 0, void 0, function () {
        var studentId, puzzleId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    studentId = context.match.params.userId;
                    puzzleId = context.match.params.puzzleId;
                    return [4 /*yield*/, puzzleService.isStudentPuzzleUnlocked({ puzzleId: puzzleId, studentId: studentId })
                            .then(function (isPuzzleUnlocked) {
                            if (isPuzzleUnlocked) {
                                next.invoke(context);
                            }
                            else {
                                context.history.replace({ pathname: GLCommonPathConfig.AccessDenied });
                            }
                        })
                            .catch(function () {
                            context.history.replace({ pathname: PathConfig.PuzzlePlayerDenied });
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
