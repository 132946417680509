var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { DropDownHelper, OidcProxy, RoleName } from "gl-commonui";
import { fmtMsg, userHasRole } from ".";
import { CommonUI } from "@app/locales/localeid";
export var PortalHelpDropDown = function () {
    var _a = __read(useState(false), 2), isStudent = _a[0], setIsStudent = _a[1];
    useEffect(function () {
        OidcProxy.getUser().then(function (user) {
            var hasStudentRole = userHasRole(RoleName.student, user.profile);
            setIsStudent(hasStudentRole);
        });
    }, []);
    return isStudent ? React.createElement(React.Fragment, null) : React.createElement(DropDownHelper, { documentLabel: fmtMsg(CommonUI.helpMenuDocumentLabel) });
};
