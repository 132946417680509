var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useRef } from "react";
import { UnitPathCoordinates, BadgeCoordinates } from "./extra/coordinates";
import PlaylistCard from "./components/playlistcard";
import { mergeClasses } from "@app/util";
import AnimatedPuzzlePiece from "./components/animated-puzzle-piece";
import PlaylistDot from "./components/playlist-dot";
import PlaylistBadge from "./components/playlist-badge";
export default function PlaylistPath(_a) {
    var unitPlaylist = _a.unitPlaylist, activeBlink = _a.activeBlink, blinkActivedItem = _a.blinkActivedItem, completedRoundOne = _a.completedRoundOne, maxPlayableIndex = _a.maxPlayableIndex, maxUnit = _a.maxUnit, stopActiveBlink = _a.stopActiveBlink, unit = _a.unit, userId = _a.userId, versionId = _a.versionId, history = _a.history, showPuzzlePiece = _a.showPuzzlePiece, stopShowPuzzlePiece = _a.stopShowPuzzlePiece, completeUnitPlaylist = _a.completeUnitPlaylist;
    var playlists = unitPlaylist.playlists, round = unitPlaylist.round;
    var _b = __read(useState([]), 2), playlistCoordinates = _b[0], setPlaylistCoordinates = _b[1];
    var _c = __read(useState([]), 2), badgeCoordinates = _c[0], setBadgeCoordinates = _c[1];
    var scrollRef = useRef();
    var startIndex = (Math.min(round, 4) - 1) * playlists.length;
    useEffect(function () {
        translateCoordinatesOnLoad();
    }, []);
    useEffect(function () {
        if (round > 1) {
            scrollRef.current &&
                scrollRef.current.scrollBy({
                    left: (imageWidth * (round - 1)) / 4,
                    behaviour: "smooth"
                });
        }
    }, [round]);
    var imageHeight = 500;
    var imageWidth = 2000;
    var renderedHeight = 483;
    var renderedWidth = (imageWidth / imageHeight) * renderedHeight;
    var getTranslatedCoordinates = function (coordinates) {
        var translated = coordinates.map(function (_a, index) {
            var x = _a.x, y = _a.y;
            var tuple = {
                x: Math.floor((x / imageWidth) * renderedWidth),
                y: Math.floor((y / imageHeight) * renderedHeight)
            };
            return tuple;
        });
        return translated;
    };
    var translateCoordinatesOnLoad = function () {
        var playlistCoordinates = getTranslatedCoordinates(UnitPathCoordinates);
        var badgeCoordinates = getTranslatedCoordinates(BadgeCoordinates);
        setPlaylistCoordinates(playlistCoordinates);
        setBadgeCoordinates(badgeCoordinates);
    };
    var renderPointsOnPath = function (item, index) {
        return (React.createElement("div", { style: {
                position: "absolute",
                top: item.y - 9,
                left: item.x - 7
            } },
            React.createElement(PlaylistDot, { index: item.x, className: index < startIndex ? "playlist-dot--enabled" : "", onClick: function () {
                } })));
    };
    var renderPlaylistCell = function (item, index) {
        return (React.createElement("div", { key: "base_" + index, className: "card-base" },
            React.createElement(PlaylistCard, { key: index, playlistNumber: index + 1, history: history, content: item, params: { userId: userId, unit: unit, versionId: versionId, maxUnit: maxUnit, maxPlayableIndex: maxPlayableIndex, index: index }, needWait: unit == maxUnit &&
                    unitPlaylist.isFinishedMaxNewPlaylistsTask &&
                    item.index == unitPlaylist.round * 10 + unitPlaylist.playingIndex, enableBlink: item.isEnable &&
                    index == maxPlayableIndex &&
                    !unitPlaylist.isFinishedMaxNewPlaylistsTask, activeBlink: activeBlink, activedBlinkCallback: stopActiveBlink, isFinishedDailyTask: unitPlaylist.isFinishedDailyTask, callActivedItem: blinkActivedItem, coordinates: playlistCoordinates[index] })));
    };
    var renderBadges = function (item, index) {
        var awardedBadge = Math.min(round - 1, 4);
        return awardedBadge >= index + 1 ? (React.createElement(PlaylistBadge, { coordinates: item, index: index })) : null;
    };
    return (React.createElement("div", { id: "scroller", className: "scroll-container", ref: scrollRef },
        React.createElement("div", { className: mergeClasses("playlist-background", "playlist-background__image_" + unit % 5) },
            completeUnitPlaylist && completeUnitPlaylist.map(function (item, index) {
                return renderPlaylistCell(item, index);
            }),
            badgeCoordinates.map(function (item, index) {
                return renderBadges(item, index);
            })),
        showPuzzlePiece && (React.createElement(AnimatedPuzzlePiece, { round: round, stopShowPuzzlePiece: stopShowPuzzlePiece }))));
}
