var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { notification } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import { AlwaysBlink } from "@app/page/components/blink";
import PlaylistDot from "./playlist-dot";
var PlaylistCard = /** @class */ (function (_super) {
    __extends(PlaylistCard, _super);
    function PlaylistCard(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.onClicked = function () {
            var _a = _this.props, history = _a.history, params = _a.params, content = _a.content, playlistNumber = _a.playlistNumber, isFinishedDailyTask = _a.isFinishedDailyTask;
            if (params.unit == params.maxUnit) {
                if (isFinishedDailyTask) {
                    if (content.isEnable) {
                        navigateBy(params, history, content.pid, playlistNumber, 'Your account does not have any unit.');
                    }
                    else {
                        _this.props.callActivedItem && _this.props.callActivedItem();
                    }
                }
                else if (playlistNumber == params.maxPlayableIndex + 1) {
                    if (content.isEnable) {
                        navigateBy(params, history, content.pid, playlistNumber, 'Your account does not have any unit.');
                    }
                }
            }
            // for previous units
            else {
                if (content.isEnable) {
                    navigateBy(params, history, content.pid, playlistNumber, 'Your account does not have any unit.');
                }
            }
        };
        _this.state = {
            thumbnail: null,
            activeBlink: false,
        };
        return _this;
    }
    PlaylistCard.prototype.componentDidMount = function () {
        var content = this.props.content;
        var thumbnail = {};
        if (content.thumbnailUrl) {
            thumbnail = {
                // border: "1px solid #e8e8e8",
                backgroundImage: 'url("' + content.thumbnailUrl + '")'
            };
        }
        this.setState({ thumbnail: thumbnail });
    };
    PlaylistCard.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.activeBlink != this.props.activeBlink) {
            this.setState({ activeBlink: nextProps.activeBlink });
        }
    };
    PlaylistCard.prototype.render = function () {
        var _a = this.props, history = _a.history, content = _a.content, enableBlink = _a.enableBlink, needWait = _a.needWait, coordinates = _a.coordinates, params = _a.params, playlistNumber = _a.playlistNumber;
        var thumbnail = this.state.thumbnail;
        var isLock = !content.isEnable;
        var locked = isLock && 'back-lock';
        var isCovered = !thumbnail || !thumbnail.backgroundImage;
        var covered = isCovered ? 'back-covered' : 'back-uncovered';
        var disabled = isLock && isCovered ? 'back-disabled' : '';
        var index = params.index, maxPlayableIndex = params.maxPlayableIndex;
        var dotPositionStyle = {
            position: "absolute",
            top: coordinates ? coordinates.y - 15 : 0,
            left: coordinates ? coordinates.x - 13 : 0,
        };
        if (enableBlink) {
            return (React.createElement(AlwaysBlink, { className: "blinkable-card", style: dotPositionStyle },
                React.createElement(PlaylistDot, { index: content.index, className: content.isEnable
                        ? "playlist-dot--enabled playlist-dot--hoverable"
                        : "", onClick: this.onClicked })));
        }
        else {
            return (React.createElement("div", { className: "blinkable", style: dotPositionStyle },
                React.createElement(PlaylistDot, { index: content.index, className: index <= maxPlayableIndex
                        ? "playlist-dot--enabled"
                        : "", onClick: this.onClicked })));
        }
    };
    return PlaylistCard;
}(Component));
export default PlaylistCard;
function navigateBy(params, history, sequence, playlistNumber, message) {
    if (params.unit) {
        var userId = params.userId, unit = params.unit, versionId = params.versionId;
        history.push({ pathname: GLUtil.pathStringify(PathConfig.PlaylistPlayer, { userId: userId, unit: unit, versionId: versionId, sequence: sequence }), state: { playlistNumber: playlistNumber } });
    }
    else {
        notification.warn({ message: message });
    }
}
