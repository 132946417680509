var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Card, notification } from "antd-min";
import { ContentGroupEnum, ContentPlayMode, RoleHelper } from "@app/util";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import { allowedProps } from "@app/components/subtitle/subtitle";
var images = {
    back_image: 'back-image',
};
var icons = {
    back: 'back',
    song_icon: 'song-icon',
    movie_icon: 'movie-icon',
    story_icon: 'story-icon',
};
var iconClassNames = {
    "Song": "song-icon",
    "Movie": "movie-icon",
    "Story": "story-icon",
    "VPC": "vpc-icon"
};
export var PlayTarget;
(function (PlayTarget) {
    PlayTarget[PlayTarget["NewPage"] = 0] = "NewPage";
    PlayTarget[PlayTarget["CurrentPage"] = 1] = "CurrentPage";
})(PlayTarget || (PlayTarget = {}));
export default function LibraryCard(_a) {
    var state = _a.state, history = _a.history, content = _a.content, type = _a.type, getSelection = _a.getSelection, role = _a.role, playcount = _a.playcount, playTarget = _a.playTarget, beginPlay = _a.beginPlay;
    var thumbnail = {};
    var iconClass = iconClassNames[type];
    var mode = type === ContentGroupEnum.Movie ? ContentPlayMode.Video : ContentPlayMode.Audio;
    if (content.thumbnailUrl) {
        thumbnail = {
            backgroundImage: 'url("' + content.thumbnailUrl + '")'
        };
    }
    var iconborderClass = RoleHelper.isStudent(role) ? "icontextborder" : "iconborder";
    var enable = true; //role == PlayerRole.Other || playcount;
    var onCardClick = function () {
        switch (playTarget) {
            case PlayTarget.CurrentPage:
                typeof beginPlay == "function" && beginPlay(content);
                break;
            case PlayTarget.NewPage:
            default:
                enable && navigateBy(state, history, content, getSelection, 'Your account does not have any unit.', mode);
                break;
        }
    };
    return (React.createElement("div", { className: "blinkable" },
        React.createElement(Card, { hoverable: enable, onClick: onCardClick, cover: React.createElement("div", null,
                React.createElement("div", { className: "back " + images.back_image, style: thumbnail }),
                enable ?
                    React.createElement("div", { className: iconborderClass },
                        React.createElement("div", { className: "icon " + iconClass }),
                        React.createElement("span", { className: "icontext" }, playcount))
                    :
                        React.createElement("div", { className: "disablemask" })) },
            React.createElement(Card.Meta, { title: React.createElement("span", __assign({}, allowedProps, { title: content.name }), content.name) }))));
}
function navigateBy(state, history, content, getSelection, message, mode) {
    if (state.unit || state.versionId && content.id) {
        var userId = state.userId, unit = state.unit, versionId = state.versionId;
        var selection = getSelection();
        history.push({ pathname: GLUtil.pathStringify(PathConfig.LibraryPlayer, { userId: userId, unit: unit, versionId: versionId, sequence: content.id, mode: mode }), search: GLUtil.queryStringify({ selection: selection }) });
    }
    else {
        notification.warn({ message: message });
    }
}
