var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
var AudioPlayer = React.forwardRef(function (props, ref) {
    var audioUrl = props.audioUrl, onAudioEnded = props.onAudioEnded;
    var audioRef = useRef(null);
    var _a = __read(useState(0), 2), currentTime = _a[0], setCurrentTime = _a[1];
    useImperativeHandle(ref, function () { return ({
        play: function () {
            audioRef.current.play();
        },
        pause: function () {
            audioRef.current.pause();
        },
        stop: function () {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setCurrentTime(0);
        }
    }); });
    useEffect(function () {
        var audio = audioRef.current;
        if (audioUrl) {
            audio.src = audioUrl;
            audio.currentTime = currentTime;
            audio.play();
        }
        else {
            audio.pause();
        }
    }, [audioUrl]);
    useEffect(function () {
        var audio = audioRef.current;
        var handleTimeUpdate = function () {
            setCurrentTime(audio.currentTime);
        };
        audio.addEventListener("timeupdate", handleTimeUpdate);
        return function () {
            setCurrentTime(0);
            audio.removeEventListener("timeupdate", handleTimeUpdate);
        };
    }, []);
    useEffect(function () {
        var audio = audioRef.current;
        var handleEnded = function () {
            if (onAudioEnded) {
                onAudioEnded();
            }
        };
        audio.addEventListener("ended", handleEnded);
        return function () {
            setCurrentTime(0);
            audio.removeEventListener("ended", handleEnded);
        };
    }, [onAudioEnded]);
    return React.createElement("audio", { ref: audioRef, style: { display: "hidden" } });
});
export default AudioPlayer;
