var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PathConfig } from "@app/config/pathconfig";
import { Constants, ConversationSourceEnum, getChildrenRegion } from "@app/util";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React, { useRef, useState } from "react";
import { PlaylistConversation } from "../media/playlist-conversation/playlist-conversation";
import "./conversation.less";
import { updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
var getRegionId = function (studentId) {
    var allChildren = getChildrenRegion();
    var currentChild = allChildren.filter(function (c) { return c.id == studentId; })[0];
    if (currentChild) {
        return currentChild.regionId;
    }
    return null;
};
export var ConversationPlayerComponent = function (props) {
    var history = props.history, params = props.match.params;
    var studentId = params.userId, unit = params.unit, playlistId = params.playlistId, conversationId = params.conversationId, versionId = params.versionId;
    var _a = __read(useState(false), 2), isBarVisible = _a[0], setBarVisible = _a[1];
    var _b = __read(useState(0), 2), speed = _b[0], setSpeed = _b[1];
    var conversationSpeed = Constants.DefaultPlaybackRate;
    var regionId = useRef(getRegionId(studentId));
    var onCancelOrComplete = function () {
        var url = GLUtil.pathStringify(PathConfig.Playlist, { userId: studentId, versionId: versionId, unit: unit });
        history.push(url);
    };
    var onDropdownVisibleChange = function (visible) {
        setBarVisible(visible);
    };
    var onPlaybackRateChange = function (value) {
        updateAppSettings(studentId, GLGlobal.store.getState().theme.leftData.parentId, parseInt(unit), { slowSpeed: value != 1 });
        setSpeed(value);
    };
    return (React.createElement("div", { className: "conversation-page mediaplayer-wrapper" },
        React.createElement(PlaylistConversation, { studentId: studentId, regionId: regionId.current, unit: unit, playlistId: playlistId, content: { conversationId: conversationId }, playSequence: onCancelOrComplete, conversationSpeed: speed, versionId: versionId, source: ConversationSourceEnum.header, onDropdownVisibleChange: onDropdownVisibleChange, onPlaybackRateChange: onPlaybackRateChange })));
};
export var ConversationPlayerPage = withRouter(ConversationPlayerComponent);
