import { GLGlobal, GLUtil } from 'gl-commonui';
import { PlayerRole } from '@app/util';
import { PathConfig } from '@app/config/pathconfig';
export function PagePermission(userId, unit) {
    var role = GLGlobal.store.getState().theme.leftData.role;
    if (!GLGlobal.store.getState().theme.leftData.isLoaded) {
        return false;
    }
    else if (!unit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (userId != GLGlobal.store.getState().theme.leftData.id) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if ((unit > 0 && GLGlobal.store.getState().theme.leftData.unit > 0 && unit > GLGlobal.store.getState().theme.leftData.unit) ||
        (unit < 0 && GLGlobal.store.getState().theme.leftData.unit < 0 && unit < GLGlobal.store.getState().theme.leftData.unit) ||
        (unit < 0 && GLGlobal.store.getState().theme.leftData.lsUnit && unit < GLGlobal.store.getState().theme.leftData.lsUnit) ||
        (!GLGlobal.store.getState().theme.leftData.lsUnit && !GLGlobal.store.getState().theme.leftData.unit)) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    // hiden navigation bar on ls mode
    GLGlobal.store.getState().theme.rightChanged = (role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other) && unit > 0;
    return true;
}
