var PathConfig = /** @class */ (function () {
    function PathConfig() {
    }
    PathConfig.Home = "/";
    PathConfig.CodeLogin = "/code";
    PathConfig.Children = "/children";
    PathConfig.CurrentUser = "/users/:userId";
    PathConfig.Unit = "/users/:userId/:versionId/units/:unit";
    PathConfig.Playlist = "/users/:userId/:versionId/units/:unit/playlists";
    PathConfig.PlaylistPlayer = "/users/:userId/:versionId/units/:unit/playlists/:sequence";
    PathConfig.ConversationPlayer = "/users/:userId/:versionId/units/:unit/:playlistId/conversation/:conversationId";
    PathConfig.PlayContents = "/playcontents";
    PathConfig.Library = "/users/:userId/:versionId/units/:unit/library";
    PathConfig.LibraryPlayer = "/users/:userId/:versionId/units/:unit/library/:sequence/:mode?";
    PathConfig.Units = "/users/:userId/units";
    PathConfig.Listen = "/users/:userId/:versionId/units/:unit/listen";
    PathConfig.DVDPlayer = "/users/:userId/:versionId/units/:unit/listen/:sequence";
    PathConfig.Videoplayer = "/videoplayer";
    PathConfig.Welcome = "/users/:userId/:versionId/units/:unit/:path/welcome/player";
    PathConfig.PuzzlePlayer = "/users/:userId/:versionId/puzzle/:puzzleId";
    PathConfig.PuzzlePlayerDenied = "/warning/puzzle/accessdenied";
    PathConfig.TestConversationPlayer = "/testconversation/conversation/:conversationId";
    PathConfig.ConversationVoiceUrlGenerationAccessDenied = "/warning/conversation/voiceurl/generation/accessdenied";
    PathConfig.ConversationsList = "/conversations";
    return PathConfig;
}());
export { PathConfig };
