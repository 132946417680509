import { ConversationFeedbackLocale } from "@app/locales/localeid";
import { ComputerSpokenFeedbackType, UserSpokenFeedbackType } from "./conversation-api/utils/conversation-feedback-model";
var Constants = /** @class */ (function () {
    function Constants() {
    }
    Constants.WINDOW_NAME = "WINDOW_NAME";
    Constants.TraceLogFileName = "traceLog";
    Constants.ConversationCompleteMessage = "Conversation has been completed.";
    Constants.ConversationErrorMessage = "Error occured.";
    Constants.ConversationCancelMessage = "Conversation cancelled.";
    Constants.DefaultPlaybackRate = 1;
    Constants.AllowedPlaybackRate = [0.6, 0.7, 0.8, 0.9, 1];
    Constants.ConversationSlowModePlaybackRate = 0.8;
    Constants.SlowModeFrameRate = 8;
    Constants.NormalModeFrameRate = 10;
    Constants.RewindTimeInSeconds = 10;
    return Constants;
}());
export { Constants };
var ComputerSpokenFeedbackType4Display = new Map([
    [ComputerSpokenFeedbackType.NotFitTheFlow, ConversationFeedbackLocale.ConversationFeedbackComputerSpokenNotWithFlowText],
    [ComputerSpokenFeedbackType.StrangeVoice, ConversationFeedbackLocale.ConversationFeedbackComputerSpokenStrangeVoiceText],
    [ComputerSpokenFeedbackType.Mispronunciation, ConversationFeedbackLocale.ConversationFeedbackComputerSpokenMispronunciationText],
    [ComputerSpokenFeedbackType.StrangeAnimation, ConversationFeedbackLocale.ConversationFeedbackComputerSpokenStrangeAnimationText],
    [ComputerSpokenFeedbackType.Other, ConversationFeedbackLocale.ConversationFeedbackComputerSpokenOtherText]
]);
var UserSpokenFeedbackType4Display = new Map([
    [UserSpokenFeedbackType.InaccurateSpeechToText, ConversationFeedbackLocale.ConversationFeedbackUserSpokenInaccurateSpeechText],
    [UserSpokenFeedbackType.IntentionMisunderstood, ConversationFeedbackLocale.ConversationFeedbackUserSpokenIntentionText],
    [UserSpokenFeedbackType.TechnicalProblem, ConversationFeedbackLocale.ConversationFeedbackUserSpokenTechnicalText],
    [UserSpokenFeedbackType.Other, ConversationFeedbackLocale.ConversationFeedbackUserSpokenOtherText]
]);
export var PlaylistSequenceInRound = [
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [2, 1, 8, 9, 10, 7, 6, 3, 5, 4],
    [8, 3, 2, 6, 5, 1, 9, 4, 10, 7],
    [1, 5, 8, 6, 9, 7, 10, 2, 3, 4],
];
