import { random } from "lodash";
var GestureBase = /** @class */ (function () {
    function GestureBase() {
    }
    GestureBase.prototype.pickRandom = function () {
        var index = random(0, this.animations.length - 1);
        var duration = this.getDuration(index);
        return {
            gesture: this.animations[index],
            duration: duration,
        };
    };
    GestureBase.prototype.playRandom = function (jbExecutorFn) {
        var _this = this;
        if (!jbExecutorFn) {
            return Promise.resolve();
        }
        return new Promise(function (resolve) {
            var _a = _this.pickRandom(), _b = _a.gesture, animations = _b.animations, id = _b.id, duration = _a.duration;
            jbExecutorFn(animations);
            setTimeout(function () {
                resolve();
            }, duration);
        });
    };
    GestureBase.prototype.play = function (jbExecutorFn, index) {
        var _this = this;
        if (index === void 0) { index = 0; }
        var duration = this.getDuration(index);
        return new Promise(function (resolve) {
            jbExecutorFn(_this.animations[index].animations);
            setTimeout(function () {
                resolve();
            }, duration);
        });
    };
    GestureBase.prototype.getDuration = function (index) {
        var totalDuration = 0;
        this.animations[index].animations.forEach(function (animation) {
            if (animation.duration) {
                totalDuration += animation.duration;
            }
            else {
                totalDuration += animation.frames.length * 100;
            }
        });
        return totalDuration;
    };
    return GestureBase;
}());
export { GestureBase };
