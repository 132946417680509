var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { mergeClasses } from "gl-commonui";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { JbAnimations } from ".";
import { Sprite } from "./sprite";
import "./jonny-bear.less";
import JonnyBearSprite0 from "./sprites/jonnyBear_sprite_HQ-0.png";
import JonnyBearSprite1 from "./sprites/jonnyBear_sprite_HQ-1.png";
import JonnyBearSprite2 from "./sprites/jonnyBear_sprite_HQ-2.png";
import JonnyBearSprite3 from "./sprites/jonnyBear_sprite_HQ-3.png";
import JonnyBearSprite4 from "./sprites/jonnyBear_sprite_HQ-4.png";
import JonnyBearSprite5 from "./sprites/jonnyBear_sprite_HQ-5.png";
import JonnyBearSprite6 from "./sprites/jonnyBear_sprite_HQ-6.png";
import JonnyBearSprite7 from "./sprites/jonnyBear_sprite_HQ-7.png";
import JonnyBearSprite8 from "./sprites/jonnyBear_sprite_HQ-8.png";
import { Constants } from "@app/util";
export var JonneyBear = forwardRef(function (props, ref) {
    var className = props.className;
    var _a = __read(useState(1), 2), currentSprite = _a[0], setCurrentSprite = _a[1];
    var _b = __read(useState({
        left: 0,
        top: 0,
        imageIndex: 0,
    }), 2), _c = _b[0], left = _c.left, top = _c.top, imageIndex = _c.imageIndex, setCoordinates = _b[1];
    var sprites = useRef([
        JonnyBearSprite0,
        JonnyBearSprite1,
        JonnyBearSprite2,
        JonnyBearSprite3,
        JonnyBearSprite4,
        JonnyBearSprite5,
        JonnyBearSprite6,
        JonnyBearSprite7,
        JonnyBearSprite8,
    ]);
    var frameRate = useRef(10);
    var animationTrackRef = useRef([new JbAnimations.Neutral()]);
    var isTrackStopped = useRef(true);
    var interval = useRef();
    var timeout = useRef();
    var mounted = useRef(false);
    useEffect(function () {
        start();
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    useEffect(function () {
        updateCoordinates();
    }, [currentSprite]);
    useEffect(function () {
        if (props.playbackRate) {
            updateFrameRate(props.playbackRate);
        }
    }, [props.playbackRate]);
    useImperativeHandle(ref, function () { return ({
        executor: executor,
        clear: clear,
    }); });
    var executor = function (executorContext) {
        var _a;
        (_a = animationTrackRef.current).push.apply(_a, __spread(executorContext));
        if (isTrackStopped.current) {
            play();
        }
    };
    var updateFrameRate = function (speed) {
        if (speed === Constants.ConversationSlowModePlaybackRate || speed === 0.7) {
            frameRate.current = Constants.SlowModeFrameRate;
        }
        else {
            frameRate.current = Constants.NormalModeFrameRate;
        }
    };
    var start = function () {
        play();
    };
    var clear = function () {
        animationTrackRef.current = [];
        if (interval.current) {
            clearInterval(interval.current);
        }
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        isTrackStopped.current = true;
    };
    var play = function () {
        var animation = animationTrackRef.current[0];
        if (!animation) {
            isTrackStopped.current = true;
            return null;
        }
        isTrackStopped.current = false;
        var frames = animation.frames, duration = animation.duration;
        var spriteIndex = 0;
        interval.current = setInterval(function () {
            mounted.current && setCurrentSprite(frames[spriteIndex]);
            var isCurrentCycleCompleted = spriteIndex >= frames.length - 1;
            spriteIndex++;
            if (isCurrentCycleCompleted) {
                spriteIndex = 0;
                if (!duration) {
                    stop();
                }
            }
        }, 1000 / frameRate.current);
        var stop = function () {
            clearInterval(interval.current);
            interval.current = null;
            // Play next
            play();
        };
        if (duration) {
            timeout.current = setTimeout(stop, duration);
        }
        removeAnimationFromTrack();
    };
    var removeAnimationFromTrack = function () {
        animationTrackRef.current.shift();
    };
    var updateCoordinates = function () {
        var imageCountInOneSprite = 16;
        var spriteWidth = 452;
        var spriteHeight = 442;
        var backgroundImageIndex = Math.floor((currentSprite - 1) / imageCountInOneSprite);
        var spritePosition = currentSprite - backgroundImageIndex * imageCountInOneSprite;
        var row = Math.floor((spritePosition - 1) / 4);
        var col = (spritePosition / 4 - row) * 4 - 1;
        var top = row * spriteHeight;
        var left = col * spriteWidth;
        setCoordinates({ left: -left, top: -top, imageIndex: backgroundImageIndex });
    };
    return (React.createElement("div", { className: mergeClasses("jonney", className) }, sprites.current.map(function (sprite, index) {
        return (React.createElement(Sprite, { key: "sprite" + index, imageIndex: imageIndex, uri: sprite, top: top, left: left, index: index }));
    })));
});
