export var JbInteractionStyles;
(function (JbInteractionStyles) {
    JbInteractionStyles[JbInteractionStyles["Positive"] = 1] = "Positive";
    JbInteractionStyles[JbInteractionStyles["Negative"] = 2] = "Negative";
    JbInteractionStyles[JbInteractionStyles["Neutral"] = 3] = "Neutral";
    JbInteractionStyles[JbInteractionStyles["Indicative"] = 4] = "Indicative";
    JbInteractionStyles[JbInteractionStyles["Encouraging"] = 5] = "Encouraging";
    // Idle and Fallback styles are managed by the client itself, they'd not be coming from database
    JbInteractionStyles[JbInteractionStyles["Idle"] = 6] = "Idle";
    JbInteractionStyles[JbInteractionStyles["Fallback"] = 7] = "Fallback";
})(JbInteractionStyles || (JbInteractionStyles = {}));
