import { MediaSlotEnum } from "@app/util";
import { useContext, useMemo, useRef } from "react";
import { FocusWordsContext } from "../components/playlist-reader-focuswords/context/reader-focus-word-context";
import { setIsReaderAction } from "@app/states/playlistplayer";
export var useReaderFocusWord = function (focusWordContents) {
    var _a = useContext(FocusWordsContext), selectedIndex = _a.selectedIndex, setSelectedIndex = _a.setSelectedIndex, isPlaying = _a.isPlaying, setIsPlaying = _a.setIsPlaying, showDefinition = _a.showDefinition, setShowDefinition = _a.setShowDefinition, showImage = _a.showImage, setShowImage = _a.setShowImage, wordAudio = _a.wordAudio, setWordAudio = _a.setWordAudio, definitionAudio = _a.definitionAudio, setDefinitionAudio = _a.setDefinitionAudio;
    var textAudioPlayerRef = useRef(null);
    var definitionAudioPlayerRef = useRef(null);
    var selectedFocusWord = useMemo(function () { return focusWordContents[selectedIndex]; }, [
        selectedIndex
    ]);
    var resetToggleStates = function () {
        setShowImage(false);
        setShowDefinition(false);
        setIsPlaying(false);
        onAudioEnded();
    };
    var onSelectWord = function (event, index) {
        if (selectedIndex !== index) {
            setSelectedIndex(index);
            setWordAudio("");
            setDefinitionAudio("");
            resetToggleStates();
        }
        event.stopPropagation();
    };
    var handleTextAudioPlayPause = function (event) {
        if (!wordAudioUrl)
            return;
        if (wordAudio) {
            setWordAudio("");
        }
        else {
            setDefinitionAudio("");
            definitionAudioPlayerRef.current.stop();
            setWordAudio(wordAudioUrl);
        }
        setIsReaderAction({ isReaderAction: true });
        event.stopPropagation();
    };
    var handleDefinitionAudioPlayPause = function (event) {
        if (!definitionAudioUrl)
            return;
        if (definitionAudio) {
            setDefinitionAudio("");
        }
        else {
            setWordAudio("");
            textAudioPlayerRef.current.stop();
            setDefinitionAudio(definitionAudioUrl);
        }
        setIsReaderAction({ isReaderAction: true });
        event.stopPropagation();
    };
    var onToggleImage = function (event) {
        if (imageUrl) {
            setShowImage(function (state) { return !state; });
        }
        event.stopPropagation();
    };
    var onToggleDefinition = function (event) {
        setShowDefinition(function (state) { return !state; });
        event.stopPropagation();
    };
    var getMediaUrl = function (mediaSlot, focusWord) {
        var media = focusWord.medias.find(function (m) { return m.mediaSlotId === mediaSlot; });
        return media ? media.url : null;
    };
    var wordAudioUrl = useMemo(function () { return getMediaUrl(MediaSlotEnum.Audio, selectedFocusWord); }, [selectedFocusWord]);
    var definitionAudioUrl = useMemo(function () { return getMediaUrl(MediaSlotEnum.IndividualAudio, selectedFocusWord); }, [selectedFocusWord]);
    var imageUrl = useMemo(function () { return getMediaUrl(MediaSlotEnum.Image, selectedFocusWord); }, [selectedFocusWord]);
    var onAudioEnded = function () {
        setWordAudio("");
        setDefinitionAudio("");
        setIsPlaying(false);
    };
    return {
        selectedIndex: selectedIndex,
        setSelectedIndex: setSelectedIndex,
        isPlaying: isPlaying,
        setIsPlaying: setIsPlaying,
        showImage: showImage,
        setShowImage: setShowImage,
        showDefinition: showDefinition,
        setShowDefinition: setShowDefinition,
        resetToggleStates: resetToggleStates,
        onSelectWord: onSelectWord,
        onToggleImage: onToggleImage,
        onToggleDefinition: onToggleDefinition,
        getMediaUrl: getMediaUrl,
        selectedFocusWord: selectedFocusWord,
        wordAudioUrl: wordAudioUrl,
        definitionAudioUrl: definitionAudioUrl,
        imageUrl: imageUrl,
        onAudioEnded: onAudioEnded,
        wordAudio: wordAudio,
        definitionAudio: definitionAudio,
        textAudioPlayerRef: textAudioPlayerRef,
        definitionAudioPlayerRef: definitionAudioPlayerRef,
        handleTextAudioPlayPause: handleTextAudioPlayPause,
        handleDefinitionAudioPlayPause: handleDefinitionAudioPlayPause
    };
};
