import { mergeClasses } from "@app/util";
import React from "react";
import { useReaderFocusWord } from "../../hooks/useReaderFocusWords";
import "./focusword-list.less";
var FocusWordList = function (_a) {
    var focusWordContents = _a.focusWordContents;
    var _b = useReaderFocusWord(focusWordContents), selectedIndex = _b.selectedIndex, onSelectWord = _b.onSelectWord;
    return (React.createElement("div", { className: "fwlist" }, focusWordContents.map(function (focusWord, index) { return (React.createElement("div", { key: focusWord.sequence, className: mergeClasses("fwlist__fwlistItem", index === selectedIndex && "selected-focus-word"), onClick: function (event) { return onSelectWord(event, index); } }, focusWord.focusWordText)); })));
};
export default FocusWordList;
