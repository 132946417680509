var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLGlobal, connect } from "gl-commonui";
import { lazyInject, TYPES, getChildrenRegion, fmtMsg, PlaylistSequenceInRound } from "@app/util";
import PermissionParse, { PagePermission, buildUnitPlaylist } from "./extra/permission";
import UnitButton from "../components/unitbutton";
import { showHeaderFooter } from "../components/hidenheaderfooter";
import HeartBeat from "../components/heartbeat";
import UnitReward from "./components/unit-reward/unit-reward";
import { getCompletedPlaylist, setCompletedPlaylist, getStudentPuzzle, setStudentPuzzle } from "@app/states/playlistplayer";
import { setSystemCheckPopupVisible } from "@app/states/system-check";
import { StudentLocale } from "@app/locales/localeid";
import { Alert } from "antd";
import PlaylistPath from "./playlistPath";
import moment from "moment";
import _ from "lodash";
var PlaylistPage = /** @class */ (function (_super) {
    __extends(PlaylistPage, _super);
    function PlaylistPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        // to generate the playlists for all the 4 round based on the playlist sequence
        _this.generateCompletePlaylist = function (round, roundLogs) {
            function getPlaylistData(seq, logs) {
                return logs.find(function (log) { return log.seq === seq; });
            }
            function generateLogsForRound(roundIndex, playlistSequences, baseLogs) {
                return playlistSequences.map(function (seq, pos) {
                    var baseLog = getPlaylistData(seq, baseLogs) || {};
                    var isEnable = roundIndex < round - 1 ? true : (roundIndex === round - 1 ? baseLog.isEnable : false);
                    return {
                        index: pos,
                        pos: pos,
                        seq: seq,
                        img: pos,
                        pid: baseLog.pid,
                        thumbnailUrl: baseLog.thumbnailUrl || '',
                        isEnable: isEnable
                    };
                });
            }
            var allLogs = [];
            PlaylistSequenceInRound.forEach(function (sequences, roundIndex) {
                allLogs = allLogs.concat(generateLogsForRound(roundIndex, sequences, roundLogs));
            });
            return allLogs;
        };
        _this.setLocalStorageData = function (data, studentId, unit) {
            var countFromDB = data[0].playlistPlayedCount;
            var dataFromLocalStorage = window.localStorage.getItem(studentId + '-Unit-' + unit);
            if (dataFromLocalStorage && Number(dataFromLocalStorage) < countFromDB) {
                _this.setState({
                    countFromLocalStorage: countFromDB,
                });
                window.localStorage.setItem(studentId + '-Unit-' + unit, String(countFromDB));
            }
            else if ((dataFromLocalStorage) && Number(dataFromLocalStorage) > countFromDB) {
                _this.setState({ countFromLocalStorage: Number(dataFromLocalStorage) });
            }
            else {
                _this.setState({ countFromLocalStorage: countFromDB });
                window.localStorage.setItem(studentId + '-Unit-' + unit, String(countFromDB));
            }
        };
        _this.blinkActivedItem = function () {
            _this.setState({ activedBlink: true });
        };
        _this.state = {
            unitplaylist: {
                round: 1,
                playingIndex: 0,
                playlists: []
            },
            completeUnitPlaylist: [],
            badgePercentage: 0,
            activedBlink: false,
            isRoundCompleted: false,
            puzzleId: 0,
            rewardData: null,
            currentUnit: 0,
            countFromDB: 0,
            countFromLocalStorage: 0,
            isExtendedSubscription: false,
            isPlayListRegionHasRep: true,
            showPuzzlePiece: false,
        };
        return _this;
    }
    PlaylistPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userId, versionId, unit, unitplaylist, allChildren, currentChild, isStudentAndCodeBasedLogin, getStudentPlaylistCountData, completedPlayList, unlockedPuzzleInfo, _b, signature, logs, unitplaylists, childInfoExt, _c, unitPlaylist, badgePercentage, log, hasPlayed, puzzlePieceInfo, getThumbnails, getPuzzleFullImage, _d, _e, _f, _g, old_logs, isNextRound, maxPlaylistPerUnit, maxUnlockableBadge, enabledPlaylists, isUnlockedNewRound, _h, _j, _k, _l, todaysDate, completeUnitPlaylist;
            var _this = this;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        _a = this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
                        unitplaylist = this.state.unitplaylist;
                        allChildren = getChildrenRegion();
                        currentChild = allChildren.filter(function (c) { return c.id == userId; })[0];
                        if (currentChild.repMethodologyVersionId == null) {
                            this.setState({ isPlayListRegionHasRep: false });
                            return [2 /*return*/];
                        }
                        if (!PagePermission(userId, unit)) {
                            return [2 /*return*/];
                        }
                        isStudentAndCodeBasedLogin = function () {
                            if (GLGlobal.store.getState().oidc.loginInfo.profile.from == "Code" && GLGlobal.store.getState().oidc.loginInfo.profile.role == "Student") {
                                return true;
                            }
                            return false;
                        };
                        getStudentPlaylistCountData = function () {
                            return _this.studentService.getChildInfoByIdExt({ studentId: userId });
                        };
                        completedPlayList = getCompletedPlaylist();
                        unlockedPuzzleInfo = getStudentPuzzle();
                        return [4 /*yield*/, Promise.all([
                                this.puzzleService.getSAS(),
                                this.playlistlogAppService.getPlaylistlog({ unit: unit, studentId: userId }),
                                this.unitsPlaylistsService.getplaylists({ units: [unit], lessonplanVersionId: currentChild && currentChild.repMethodologyVersionId }, { versionId: versionId }),
                                isStudentAndCodeBasedLogin() ? getStudentPlaylistCountData() : userId == GLGlobal.store.getState().theme.leftData.parentId ? null : getStudentPlaylistCountData()
                            ])];
                    case 1:
                        _b = __read.apply(void 0, [_m.sent(), 4]), signature = _b[0], logs = _b[1], unitplaylists = _b[2], childInfoExt = _b[3];
                        _c = buildUnitPlaylist(unitplaylists, logs, unit), unitPlaylist = _c.unitPlaylist, badgePercentage = _c.badgePercentage, log = _c.log, hasPlayed = _c.hasPlayed;
                        unitplaylist.unit = unit;
                        unitplaylist.round = unitPlaylist.round;
                        unitplaylist.playingIndex = unitPlaylist.playingIndex;
                        unitplaylist.playlists = unitPlaylist.playlists;
                        return [4 /*yield*/, this.puzzleService.getStudentPuzzlePieceInfo({ studentId: userId, unitId: unit, round: unitPlaylist.round >= 4 ? 4 : unitPlaylist.round })];
                    case 2:
                        puzzlePieceInfo = _m.sent();
                        getThumbnails = function (sequence) {
                            var endPoint = puzzlePieceInfo.endPoint;
                            var currentPieceThumbnail = puzzlePieceInfo
                                .puzzlePiece
                                .find(function (puzzlePiece) { return puzzlePiece.sequence === sequence; });
                            return currentPieceThumbnail ? "" + endPoint + currentPieceThumbnail.path + signature : "";
                        };
                        getPuzzleFullImage = function (puzzleId) { return __awaiter(_this, void 0, void 0, function () {
                            var finalPath;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!puzzleId) {
                                            return [2 /*return*/, null];
                                        }
                                        finalPath = "";
                                        return [4 /*yield*/, this.puzzleService.getPuzzles([puzzleId], userId).then(function (puzzleData) {
                                                var endPoint = puzzleData.endPoint;
                                                var path = puzzleData.puzzle[0].path;
                                                finalPath = endPoint + path;
                                                _this.setState({ puzzleId: puzzleId });
                                            })];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/, finalPath + signature];
                                }
                            });
                        }); };
                        if (hasPlayed) {
                            unitplaylist.playlists
                                .slice(0, log.round > 4 ? 10 : log.logs.length)
                                .forEach(function (p, index) {
                                p.thumbnailUrl = getThumbnails(index + 1);
                            });
                        }
                        if (!(unlockedPuzzleInfo && unlockedPuzzleInfo.puzzleId && completedPlayList)) return [3 /*break*/, 4];
                        _d = this.setState;
                        _e = {};
                        _f = {
                            unit: parseInt("" + completedPlayList.unit),
                            round: completedPlayList.round
                        };
                        return [4 /*yield*/, getPuzzleFullImage(unlockedPuzzleInfo.puzzleId)];
                    case 3:
                        _d.apply(this, [(_e.rewardData = (_f.puzzleImage = _m.sent(),
                                _f),
                                _e)]);
                        _m.label = 4;
                    case 4:
                        _g = userId && GLGlobal.store.getState().theme.leftData.role;
                        if (!_g) return [3 /*break*/, 6];
                        return [4 /*yield*/, PermissionParse(userId, GLGlobal.store.getState().theme.leftData.role, unitplaylist)];
                    case 5:
                        _g = (_m.sent());
                        _m.label = 6;
                    case 6:
                        _g;
                        unitplaylist.maxPlayableIndex = unitplaylist.playlists.filter(function (pl) { return pl.isEnable; }).map(function (pl) { return pl.index; }).reduce(function (max, index) { return Math.max(max, index); }, -1);
                        old_logs = window.localStorage.getItem(userId + "_oldlogs");
                        if (completedPlayList) {
                            if (!_.isEqual(logs.logs, JSON.parse(old_logs)) && completedPlayList.playlistId === unitPlaylist.playlists[Math.max(0, unitPlaylist.playingIndex - 1)].pid) {
                                this.setState({ showPuzzlePiece: true });
                            }
                        }
                        isNextRound = completedPlayList &&
                            completedPlayList.unit === unitPlaylist.unit &&
                            unitPlaylist.round - 1 === completedPlayList.round &&
                            unitPlaylist.playingIndex === 0;
                        if (!isNextRound) return [3 /*break*/, 10];
                        maxPlaylistPerUnit = 10;
                        maxUnlockableBadge = 4;
                        enabledPlaylists = unitPlaylist.playlists.filter(function (p) { return p.isEnable; });
                        isUnlockedNewRound = (completedPlayList.round === maxUnlockableBadge &&
                            enabledPlaylists.length === maxPlaylistPerUnit) ||
                            (completedPlayList.round < maxUnlockableBadge &&
                                enabledPlaylists.length === 1 &&
                                enabledPlaylists[0].index === 0);
                        if (!isUnlockedNewRound) return [3 /*break*/, 10];
                        this.setState({ isRoundCompleted: true });
                        _h = this.setState;
                        _j = {};
                        _k = {
                            unit: parseInt("" + completedPlayList.unit),
                            round: completedPlayList.round
                        };
                        if (!unlockedPuzzleInfo) return [3 /*break*/, 8];
                        return [4 /*yield*/, getPuzzleFullImage(unlockedPuzzleInfo.puzzleId)];
                    case 7:
                        _l = _m.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        _l = null;
                        _m.label = 9;
                    case 9:
                        _h.apply(this, [(_j.rewardData = (_k.puzzleImage = _l,
                                _k),
                                _j)]);
                        _m.label = 10;
                    case 10:
                        if (completedPlayList) {
                            setCompletedPlaylist(null);
                            setStudentPuzzle(null);
                        }
                        this.setState({
                            unitplaylist: unitplaylist,
                            badgePercentage: badgePercentage,
                        });
                        showHeaderFooter();
                        this.openSystemCheckPopup();
                        if (childInfoExt) {
                            this.setState({
                                currentUnit: childInfoExt.unit,
                                isExtendedSubscription: childInfoExt.isExtendedSubscription
                            });
                            childInfoExt.unit == unit && !childInfoExt.isExtendedSubscription
                                ? this.reportService
                                    .getPlaylistCount({ unit: unit, studentIds: [userId] })
                                    .then(function (res) {
                                    _this.setLocalStorageData(res, userId, unit);
                                })
                                : null;
                        }
                        todaysDate = moment.utc().format("YYYY-MM-DD");
                        completeUnitPlaylist = this.generateCompletePlaylist(unitPlaylist.round, unitPlaylist.playlists);
                        this.setState({
                            completeUnitPlaylist: completeUnitPlaylist
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PlaylistPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.leftData && (this.props.leftData.unit !== nextProps.leftData.unit)) {
            this.componentDidMount();
        }
    };
    PlaylistPage.prototype.openSystemCheckPopup = function () {
        var _this = this;
        if (this.props.microphoneDeviceId) {
            return;
        }
        var userId = this.props.match.params.userId;
        if (!userId) {
            return;
        }
        this.conversationAdminService.checkAccess(userId).then(function (hasAccess) {
            if (hasAccess) {
                _this.props.openSystemCheckPopup(true);
            }
        });
    };
    PlaylistPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, history = _a.history, _b = _a.match.params, userId = _b.userId, unit = _b.unit, versionId = _b.versionId;
        var _c = this.state, unitplaylist = _c.unitplaylist, badgePercentage = _c.badgePercentage, isPlayListRegionHasRep = _c.isPlayListRegionHasRep, completeUnitPlaylist = _c.completeUnitPlaylist;
        var completedRoundOne = unitplaylist.isFinishedDailyTask;
        var round = unitplaylist.round;
        var playlists = unitplaylist.playlists;
        var maxPlayableIndex = unitplaylist.maxPlayableIndex;
        var maxUnit = unitplaylist.maxUnit;
        return isPlayListRegionHasRep ? React.createElement("div", { className: "playlist" },
            React.createElement(HeartBeat, { userId: userId },
                React.createElement("div", { className: "palylisttoolbar" },
                    React.createElement(UnitButton, { history: this.props.history, unit: unit, userId: userId, from: "playlist", enableBlink: !completedRoundOne && maxPlayableIndex < 0, activeBlink: this.state.activedBlink, activedBlinkCallback: function () { _this.setState({ activedBlink: false }); }, pinnedUnitButton: unit == maxUnit && !unitplaylist.isFinishedDailyTask, callActivedItem: this.blinkActivedItem })),
                React.createElement("div", { className: "cards" },
                    React.createElement(PlaylistPath, { unitPlaylist: unitplaylist, completeUnitPlaylist: completeUnitPlaylist, activeBlink: this.state.activedBlink, blinkActivedItem: this.blinkActivedItem, completedRoundOne: completedRoundOne, maxPlayableIndex: (round - 1) * 10 + maxPlayableIndex, maxUnit: maxUnit, stopActiveBlink: function () { _this.setState({ activedBlink: false }); }, unit: unit, userId: userId, versionId: versionId, history: history, showPuzzlePiece: this.state.showPuzzlePiece, stopShowPuzzlePiece: function () {
                            _this.setState({ showPuzzlePiece: false });
                        } }))),
            this.state.rewardData && (React.createElement(UnitReward, { history: history, unit: this.state.rewardData.unit, round: this.state.rewardData.round, userId: userId, versionId: versionId, puzzleFullImage: this.state.rewardData.puzzleImage, duration: 5000, isRoundCompleted: this.state.isRoundCompleted, puzzleId: this.state.puzzleId, onClose: function () {
                    _this.setState({ rewardData: null });
                } }))) : React.createElement("div", null,
            React.createElement(Alert, { showIcon: false, message: (React.createElement("a", { target: "_blank" }, fmtMsg(StudentLocale.PlayListRegionHasNoRep))), banner: true }));
    };
    __decorate([
        lazyInject(TYPES.IPlaylistlogsAppService)
    ], PlaylistPage.prototype, "playlistlogAppService", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsPlaylistsService)
    ], PlaylistPage.prototype, "unitsPlaylistsService", void 0);
    __decorate([
        lazyInject(TYPES.IPuzzleService)
    ], PlaylistPage.prototype, "puzzleService", void 0);
    __decorate([
        lazyInject(TYPES.IConversationAdminService)
    ], PlaylistPage.prototype, "conversationAdminService", void 0);
    __decorate([
        lazyInject(TYPES.IReportService)
    ], PlaylistPage.prototype, "reportService", void 0);
    __decorate([
        lazyInject(TYPES.IStudentService)
    ], PlaylistPage.prototype, "studentService", void 0);
    PlaylistPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData, microphoneDeviceId = _a.systemCheck.microphoneDeviceId;
            return ({
                leftChanged: leftChanged,
                leftData: leftData,
                microphoneDeviceId: microphoneDeviceId
            });
        }, { openSystemCheckPopup: setSystemCheckPopupVisible })
    ], PlaylistPage);
    return PlaylistPage;
}(Component));
export default PlaylistPage;
