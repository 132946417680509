var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Card } from 'antd-min';
;
var Tabs = /** @class */ (function (_super) {
    __extends(Tabs, _super);
    function Tabs(props, tabsState) {
        var _this = _super.call(this, props, tabsState) || this;
        _this.onTabChange = function (keystr) {
            var tabs = _this.props.tabs;
            var getIndex = function () {
                for (var index_1 = 0; index_1 < tabs.length; index_1++) {
                    if (keystr == tabs[index_1].key)
                        return index_1;
                }
                return -1;
            };
            var index = getIndex();
            if (index >= 0) {
                _this.setState({ tabIndex: index });
                _this.props.onTabChanged && _this.props.onTabChanged(index, tabs[index].key);
            }
        };
        var selectedIndex = _this.props.selectedIndex;
        _this.state = {
            tabIndex: selectedIndex >= 0 ? selectedIndex : 0,
        };
        return _this;
    }
    Tabs.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.state.tabIndex !== nextProps.selectedIndex) {
            this.setState({ tabIndex: nextProps.selectedIndex });
        }
    };
    Tabs.prototype.render = function () {
        var _this = this;
        var _a = this.props, tabs = _a.tabs, contents = _a.contents;
        var index = this.state.tabIndex ? this.state.tabIndex : 0;
        index = index < tabs.length ? index : tabs.length - 1;
        var key = tabs[index].key;
        return (React.createElement("div", null,
            React.createElement(Card, { bordered: false, style: { width: '100%' }, tabList: tabs, activeTabKey: key, onTabChange: function (keystr) { _this.onTabChange(keystr); } }, contents[key])));
    };
    return Tabs;
}(Component));
export default Tabs;
