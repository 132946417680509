import { fmtMsg } from "@app/util";
import { ComparisonOperator, FormHelper } from "gl-commonui";
import { isDate, isNumber, toNumber } from "lodash";
var GridValidators = /** @class */ (function () {
    function GridValidators() {
    }
    GridValidators.required = function (localeId) {
        return function (value) {
            if (!value || !value.length) {
                return FormHelper.ruleForRequire(localeId).message;
            }
            return null;
        };
    };
    GridValidators.maxLength = function (length, localeId) {
        return function (value) {
            return FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, length).validator(null, value);
        };
    };
    GridValidators.minLength = function (length, localeId) {
        return function (value) {
            return FormHelper.ruleForCompareLength(localeId, ComparisonOperator.GreaterOrEqualsThan, length).validator(null, value);
        };
    };
    GridValidators.maxDate = function (dateOrField, localeId) {
        return function (value, dataItem) {
            var maxDate = isDate(dateOrField) ? dateOrField : dataItem[dateOrField] && new Date(dataItem[dateOrField]);
            if (value && maxDate && new Date(value) > maxDate) {
                return fmtMsg(localeId);
            }
            return null;
        };
    };
    GridValidators.minDate = function (dateOrField, localeId) {
        return function (value, dataItem) {
            var maxDate = isDate(dateOrField) ? dateOrField : new Date(dataItem[dateOrField]);
            if (value && maxDate && new Date(value) < maxDate) {
                return fmtMsg(localeId);
            }
            return null;
        };
    };
    GridValidators.max = function (valueOrField, localeId) {
        return function (value, dataItem) {
            var max = isNumber(valueOrField) ? valueOrField : toNumber(dataItem[valueOrField]);
            if (value > max) {
                return fmtMsg(localeId);
            }
            return null;
        };
    };
    GridValidators.min = function (valueOrField, localeId) {
        return function (value, dataItem) {
            var max = isNumber(valueOrField) ? valueOrField : toNumber(dataItem[valueOrField]);
            if (value < max) {
                return fmtMsg(localeId);
            }
            return null;
        };
    };
    return GridValidators;
}());
export { GridValidators };
