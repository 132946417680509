var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { connect, GLUtil, GLGlobal, RoleName } from "gl-commonui";
import { Link } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { Icon, Avatar } from "antd-min";
import { lazyInject, TYPES, PlayerRole, setParentChildrenCache, setChildrenRegion, getFormattedName } from "@app/util";
import { themeChangedDispatch } from "@app/states/theme";
import { StudentLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import GrapeSEEDLogoWhite from "../../style/images/logo-horizontal-white.png";
import { setAllChildren } from "@app/states/children";
var LeftHeader = /** @class */ (function (_super) {
    __extends(LeftHeader, _super);
    function LeftHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LeftHeader.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (!nextProps.leftData.name) {
            var userId_1 = GLUtil.pathParse(PathConfig.CurrentUser).userId;
            var loginInfo_1 = GLGlobal.loginInfo();
            if (loginInfo_1.loggedin && userId_1) {
                if (loginInfo_1.profile.sub === userId_1) {
                    if (loginInfo_1.profile.roles.indexOf(RoleName.student) >= 0) {
                        this.studentAppService.getStudentInfo({ id: userId_1 })
                            .then(function (child) {
                            var subscriptionType = child.subscriptionType, avatar = child.avatar, name = child.name, englishName = child.englishName, unit = child.unit, lsUnit = child.lsUnit, id = child.id, regionId = child.regionId, repMethodologyVersionId = child.repMethodologyVersionId, schoolId = child.schoolId, campusId = child.campusId, schoolClassId = child.schoolClassId;
                            setChildrenRegion([{ id: id, regionId: regionId, repMethodologyVersionId: repMethodologyVersionId, schoolId: schoolId, campusId: campusId, schoolClassId: schoolClassId }]);
                            themeChangedDispatch({
                                leftData: {
                                    id: userId_1,
                                    role: subscriptionType,
                                    src: avatar,
                                    name: getFormattedName(englishName, name),
                                    unit: +unit,
                                    lsUnit: lsUnit,
                                    parentId: null,
                                    isLoaded: true
                                }
                            });
                        });
                    }
                    else {
                        this.accessunitService.getAccessUnit({ parentId: userId_1 }).then(function (accessUnit) {
                            themeChangedDispatch({
                                leftData: {
                                    id: userId_1,
                                    role: PlayerRole.Other,
                                    src: loginInfo_1.profile.avatarUrl,
                                    name: loginInfo_1.profile.name,
                                    unit: accessUnit.unit,
                                    lsUnit: accessUnit.lsUnit,
                                    parentId: userId_1,
                                    isLoaded: true
                                }
                            });
                        });
                    }
                }
                else {
                    this.parentappservice.getChildren({ parentId: loginInfo_1.profile.sub }).then(function (resp) {
                        _this.props.setChildren(resp.children || []);
                        setParentChildrenCache(resp);
                        if (resp && resp.children && resp.children.length > 0) {
                            var user = resp.children.find(function (child) { return child.id == userId_1; });
                            if (user) {
                                themeChangedDispatch({
                                    leftData: {
                                        id: user.id, role: user.subscriptionType, src: user.avatar, name: user.name, unit: user.unit, lsUnit: user.lsUnit, parentId: loginInfo_1.profile.sub,
                                        isLoaded: true
                                    }
                                });
                            }
                            else {
                                themeChangedDispatch({
                                    leftData: {
                                        id: undefined, role: undefined, src: undefined, name: undefined, unit: undefined, lsUnit: undefined, parentId: undefined,
                                        isLoaded: true
                                    }
                                });
                                window.location.replace(PathConfig.Home);
                            }
                        }
                        else {
                            themeChangedDispatch({
                                leftData: {
                                    id: undefined, role: undefined, src: undefined, name: undefined, unit: undefined, lsUnit: undefined, parentId: undefined,
                                    isLoaded: true
                                }
                            });
                        }
                    });
                }
            }
        }
    };
    LeftHeader.prototype.render = function () {
        var _a = this.props, leftChanged = _a.leftChanged, _b = _a.leftData, src = _b.src, name = _b.name;
        return (React.createElement("div", { className: "header-left" }, leftChanged ?
            React.createElement("div", { className: "gl-header-img" }, React.createElement(Link, { to: PathConfig.Home },
                src ? React.createElement(Avatar, { className: "user-avatar", icon: "user", src: src }) : React.createElement(Icon, { type: 'user' }),
                React.createElement("span", { title: name }, name)))
            : React.createElement("div", { className: "gl-header-logo" },
                React.createElement("img", { className: "gl-logo-logo", src: GrapeSEEDLogoWhite }),
                React.createElement("div", { className: "divider" }),
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, { id: StudentLocale.CommonSiteTitle })))));
    };
    __decorate([
        lazyInject(TYPES.IStudentService)
    ], LeftHeader.prototype, "studentservice", void 0);
    __decorate([
        lazyInject(TYPES.IParentAppService)
    ], LeftHeader.prototype, "parentappservice", void 0);
    __decorate([
        lazyInject(TYPES.IAccessunitService)
    ], LeftHeader.prototype, "accessunitService", void 0);
    __decorate([
        lazyInject(TYPES.IStudentAppService)
    ], LeftHeader.prototype, "studentAppService", void 0);
    LeftHeader = __decorate([
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        }, { setChildren: setAllChildren })
    ], LeftHeader);
    return LeftHeader;
}(Component));
export default LeftHeader;
