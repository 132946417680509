var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Button, notification } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import Blink from "@app/page/components/blink";
var images = {
    back_image: 'back-image',
};
var icons = {
    back: 'back',
    song_icon: 'song-icon',
    movie_icon: 'movie-icon',
    story_icon: 'story-icon',
};
var iconClassNames = {
    "Song": "song-icon",
    "Movie": "movie-icon",
    "Story": "story-icon",
    "VPC": "vpc-icon"
};
var UnitButton = /** @class */ (function (_super) {
    __extends(UnitButton, _super);
    function UnitButton(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            activeBlink: false,
        };
        return _this;
    }
    UnitButton.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.activeBlink != this.props.activeBlink) {
            this.setState({ activeBlink: nextProps.activeBlink });
        }
    };
    UnitButton.prototype.render = function () {
        var _a = this.props, history = _a.history, unit = _a.unit, userId = _a.userId, from = _a.from;
        return this.renderBlink(history, unit, userId, from);
    };
    UnitButton.prototype.renderBlink = function (history, unit, userId, from) {
        var isGS = unit > 0;
        var _a = this.props, enableBlink = _a.enableBlink, pinnedUnitButton = _a.pinnedUnitButton, activedBlinkCallback = _a.activedBlinkCallback;
        if (enableBlink) {
            return (React.createElement(Blink, { className: "blinkable-button", activeBlink: this.state.activeBlink, activedBlinkCallback: function () { activedBlinkCallback && activedBlinkCallback(); } },
                React.createElement(Button, { className: pinnedUnitButton ? 'pinned-unit-button' : "unit-button " + (isGS ? '' : 'unit-button-ls'), type: "primary", onClick: function () { return navigateBy(history, userId, from, 'Your account does not have any unit.'); } }, isGS ? "Unit " + unit : "LittleSEED " + Math.abs(unit))));
        }
        else {
            return (React.createElement(Button, { className: pinnedUnitButton ? 'pinned-unit-button' : "unit-button " + (isGS ? '' : 'unit-button-ls') + "}", type: "primary", onClick: function () { return navigateBy(history, userId, from, 'Your account does not have any unit.'); } }, isGS ? "Unit " + unit : "LittleSEED " + Math.abs(unit)));
        }
    };
    return UnitButton;
}(Component));
export default UnitButton;
function navigateBy(history, userId, from, message) {
    if (userId) {
        history.push({ pathname: GLUtil.pathStringify(PathConfig.Units, { userId: userId }), search: GLUtil.queryStringify({ from: from }) });
    }
    else {
        notification.warn({ message: message });
    }
}
