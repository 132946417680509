import React, { memo } from 'react';
export var Sprite = memo(function (_a) {
    var imageIndex = _a.imageIndex, top = _a.top, left = _a.left, uri = _a.uri, index = _a.index;
    return (React.createElement("div", { className: "jonney__image", style: {
            backgroundImage: "url(" + uri + ")",
            backgroundPositionY: top,
            backgroundPositionX: left,
            backgroundRepeat: "no-repeat",
            opacity: index == imageIndex ? 1 : 0
        } }));
}, function (prevProps, nextProps) {
    if (nextProps.imageIndex === prevProps.index) {
        return false;
    }
    if ((prevProps.imageIndex === prevProps.index && nextProps.imageIndex !== prevProps.index) ||
        (prevProps.imageIndex !== prevProps.index && nextProps.imageIndex === prevProps.index)) {
        return false;
    }
    return true;
});
