var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { Menu } from 'antd-min';
import SpeedControlSwitch from "./speed-control-switch";
import SpeedIcon from "./icon/speed-icon";
import "./style/conversation-speed-controller.less";
import SettingDropdownIcon from "./icon/setting-dropdown-icon";
import { Constants } from "@app/util";
var ConversationPlaySetting = /** @class */ (function (_super) {
    __extends(ConversationPlaySetting, _super);
    function ConversationPlaySetting() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._menuVisible = false;
        _this.isConversation = true;
        _this.onClick = function (event) {
            event.stopPropagation();
            _this.setVisibleByTag(!_this._menuVisible);
        };
        _this.onSlowModeChange = function (checked) {
            _this.props.onPlaybackRateChange && _this.props.onPlaybackRateChange(checked ? Constants.ConversationSlowModePlaybackRate : 1);
        };
        _this.setVisibleByTag = function (tag) {
            _this._menuVisible = tag;
            _this.props.onDropdownVisibleChange && _this.props.onDropdownVisibleChange(tag);
            var base = document.querySelector(".base");
            base.style.setProperty("display", tag ? "block" : "none");
            document.addEventListener("click", _this.documentClickHandler);
        };
        _this.documentClickHandler = function (event) {
            if (!_this.isChildNodeOfDropdown(event.target)) {
                _this.setVisibleByTag(false);
                document.removeEventListener("click", _this.documentClickHandler);
            }
        };
        _this.isChildNodeOfDropdown = function (node) {
            var parent = node;
            while (parent) {
                if (parent.className && parent.className.indexOf && parent.className.indexOf("base") >= 0) {
                    return true;
                }
                parent = parent.parentElement;
            }
            return false;
        };
        return _this;
    }
    ConversationPlaySetting.prototype.componentWillUnmount = function () {
        document.removeEventListener("click", this.documentClickHandler);
    };
    ConversationPlaySetting.prototype.render = function () {
        return (React.createElement("div", { className: "wrapper" },
            React.createElement("div", { className: "base" },
                React.createElement(Menu, null,
                    React.createElement("div", { className: "container" },
                        React.createElement(SpeedIcon, null),
                        React.createElement("span", { className: "item-title" },
                            React.createElement("strong", null, "Speed:")),
                        React.createElement(SpeedControlSwitch, { enable: this.props.playbackRate !== 1, onEnableChanged: this.onSlowModeChange, isConversation: this.isConversation })))),
            React.createElement("div", { className: "flat-button", onClick: this.onClick },
                React.createElement(SettingDropdownIcon, null))));
    };
    return ConversationPlaySetting;
}(Component));
export default ConversationPlaySetting;
