var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default {
    namespace: 'drawer',
    state: {
        isDrawerVisible: false
    },
    reducers: {
        setIsDrawerVisible: function (state, action) {
            return __assign({}, state, { isDrawerVisible: action.payload });
        }
    }
};
export function setIsDrawerVisible(isVisible) {
    return { type: 'drawer/setIsDrawerVisible', payload: isVisible };
}
