var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import { Switch } from "react-router-dom";
import { PathConfig } from "./pathconfig";
import { GLDefaultRoutes, AuthRouteHandler, RoleRouteHandler, RoleName, GLRoute, GLGlobal, CallbackRouteHandler, unmaskThrottle, OidcProxy, withRouter, GLLocale } from 'gl-commonui';
import PageContainer from "@app/components/page-container";
import HomePage from '@app/page/index';
import ChildrenPage from '@app/page/children/children';
import PlayContentsPage from '@app/page/playcontents/playcontents';
import PlaylistPage from '@app/page/playlist/playlist';
import PlaylistPlayerPage from '@app/page/playlist/playlistplayer';
import LibraryPage from '@app/page/library/library';
import LibraryplayerPage from '@app/page/library/libraryplayer';
import UnitsPage from '@app/page/units/units';
import ListenPage from '@app/page/listen/listen';
import DVDplayerPage from '@app/page/listen/dvdplayer';
import VideoplayerPage from '@app/page/videoplayer/videoplayer';
import { themeChangedDispatch } from '@app/states/theme';
import { changeThemeColor, isGuid, getParentChildrenCache, userHasRole } from '@app/util/func';
import { watchSignature } from '@app/states/signature';
import { cacheVpcBkAudiosDispatch } from '@app/states/vpcbkaudios';
import WelcomeplayerPage from '@app/page/welcome/welcomeplayer';
import { pausePlaylistPlayer } from '@app/states/playlistplayer';
import PuzzlePlayerPage from '@app/page/puzzle/puzzleplayer';
import { StudentHomePage } from '@app/page/student-home/student-home';
import { StudentLogin } from '@app/page/student-login/student-login';
import { ConversationPlayerPage } from '@app/components/conversation/conversation';
import { Jonney } from '@app/page/jonney';
import { TestConversationPage } from '@app/page/test-conversation/test-conversation';
import { AllowTestingAccessibleRouteHandler, ConversationVoiceUrlGeneratedRouteHandler, StudentPuzzleAccess } from './route-handler';
import { ConversationTestLocale } from '@app/locales/localeid';
import { ConversationListPage } from '@app/page/conversations/conversationlist';
export var AccessDeniedPage = withRouter(function AccessDeniedPage(props) {
    var message = GLGlobal.intl.formatMessage({ id: GLLocale.AccessDeniedDesc });
    if (props.location.pathname === PathConfig.ConversationVoiceUrlGenerationAccessDenied) {
        message = GLGlobal.intl.formatMessage({ id: ConversationTestLocale.ConversationTestVoiceUrlNotGenerated });
    }
    return React.createElement("div", { className: 'gl-access-denied' },
        React.createElement("h1", null, GLGlobal.intl.formatMessage({ id: GLLocale.AccessDenied })),
        React.createElement("p", null, message));
});
var ChangeThemeHandler = /** @class */ (function () {
    function ChangeThemeHandler(changed) {
        if (changed === void 0) { changed = {}; }
        this.changed = changed;
    }
    ChangeThemeHandler.prototype.invoke = function (context) {
        changeTheme(true, this.changed);
        this.changed.ignoreThemeColorChange ? changeThemeColor(undefined) : (context.match.params.unit && changeThemeColor(context.match.params.unit));
        this.next.invoke(context);
    };
    return ChangeThemeHandler;
}());
var UnchangeThemeHandler = /** @class */ (function () {
    function UnchangeThemeHandler(unChanged) {
        if (unChanged === void 0) { unChanged = {}; }
        this.unChanged = unChanged;
    }
    UnchangeThemeHandler.prototype.invoke = function (context) {
        changeTheme(false, this.unChanged.override);
        this.unChanged.color && changeThemeColor();
        this.next.invoke(context);
    };
    return UnchangeThemeHandler;
}());
function changeTheme(changed, changes) {
    themeChangedDispatch(__assign({ leftChanged: changed, midChanged: changed, rightChanged: changed }, changes));
}
var permissonGuardHandler = new CallbackRouteHandler(function (context) {
    var userId = context.match.params.userId;
    var parentChildren = getParentChildrenCache();
    if (parentChildren.length > 0 && isGuid(userId) && !parentChildren.some(function (e) { return e === userId; })) {
        window.location.replace(PathConfig.Home);
    }
}, function () { });
var watchSignatureHandler = new CallbackRouteHandler(function () { return watchSignature(true); }, function () { return watchSignature(false); });
var cacheVpcBkAudiosHandler = new CallbackRouteHandler(function () { return cacheVpcBkAudiosDispatch(); }, function () { });
var clearPlaylistPlayerStateHandler = new CallbackRouteHandler(function () { return pausePlaylistPlayer(false); }, function () { return pausePlaylistPlayer(false); });
var basicHandlers = [new AuthRouteHandler(), new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher,
        RoleName.parent,
        RoleName.student,
        RoleName.trainingManager
    ])];
var testConversationHandlers = [new AuthRouteHandler(), new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher,
        RoleName.parent,
        RoleName.contentAdmin,
        RoleName.trainingManager
    ]), new AllowTestingAccessibleRouteHandler(), new ConversationVoiceUrlGeneratedRouteHandler()];
var puzzleAccessHandlers = [new AuthRouteHandler(), new RoleRouteHandler([
        RoleName.systemAdmin,
        RoleName.globalHead,
        RoleName.trainingAdmin,
        RoleName.regionAdmin,
        RoleName.accountManager,
        RoleName.trainer,
        RoleName.schoolAdmin,
        RoleName.campusAdmin,
        RoleName.teacher,
        RoleName.parent,
        RoleName.student,
        RoleName.trainingManager
    ]), new StudentPuzzleAccess()];
var StudentBasicHandlers = [new AuthRouteHandler(), new RoleRouteHandler([RoleName.student])];
var handlers = __spread(basicHandlers, [new CallbackRouteHandler(function () { }, function () { unmaskThrottle(); })]);
var studentHandlers = __spread(StudentBasicHandlers, [new CallbackRouteHandler(function () { }, function () { unmaskThrottle(); })]);
var routerPromise = OidcProxy.getUser().then(function (loginInfo) {
    var isStudent = loginInfo.loggedin && userHasRole(RoleName.student, loginInfo.profile);
    return (isStudent
        ? React.createElement(PageContainer, null,
            React.createElement(Switch, null,
                React.createElement(GLRoute, { exact: true, path: PathConfig.Home, component: StudentHomePage, handlers: studentHandlers }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.CodeLogin, component: StudentLogin, handlers: [] }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Welcome, component: WelcomeplayerPage, handlers: handlers.concat([new UnchangeThemeHandler({ override: { leftChanged: true } })]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Playlist, component: PlaylistPage, handlers: handlers.concat([new ChangeThemeHandler(), cacheVpcBkAudiosHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PlaylistPlayer, component: PlaylistPlayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler, clearPlaylistPlayerStateHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.ConversationPlayer, component: ConversationPlayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler, clearPlaylistPlayerStateHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PlayContents, component: PlayContentsPage, handlers: handlers.concat([new ChangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Library, component: LibraryPage, handlers: handlers.concat([new ChangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.LibraryPlayer, component: LibraryplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Units, component: UnitsPage, handlers: handlers.concat([new UnchangeThemeHandler({ override: { leftChanged: true } })]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PuzzlePlayer, component: PuzzlePlayerPage, handlers: puzzleAccessHandlers.concat([new ChangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Listen, component: ListenPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.DVDPlayer, component: DVDplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Videoplayer, component: VideoplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: "/jonny", component: Jonney, handlers: handlers.concat([new ChangeThemeHandler()]) }),
                GLDefaultRoutes))
        : React.createElement(PageContainer, null,
            React.createElement(Switch, null,
                React.createElement(GLRoute, { exact: true, path: PathConfig.Home, component: HomePage, handlers: handlers.concat([new UnchangeThemeHandler({ color: true })]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Children, component: ChildrenPage, handlers: handlers.concat([new UnchangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Welcome, component: WelcomeplayerPage, handlers: handlers.concat([new UnchangeThemeHandler({ override: { leftChanged: true } })]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.CodeLogin, component: StudentLogin, handlers: [] }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Playlist, component: PlaylistPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, cacheVpcBkAudiosHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PlaylistPlayer, component: PlaylistPlayerPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, watchSignatureHandler, clearPlaylistPlayerStateHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.ConversationPlayer, component: ConversationPlayerPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, watchSignatureHandler, clearPlaylistPlayerStateHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PlayContents, component: PlayContentsPage, handlers: handlers.concat([new ChangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Library, component: LibraryPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.LibraryPlayer, component: LibraryplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Units, component: UnitsPage, handlers: handlers.concat([new UnchangeThemeHandler({ override: { leftChanged: true } }), permissonGuardHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.PuzzlePlayer, component: PuzzlePlayerPage, handlers: puzzleAccessHandlers.concat([new ChangeThemeHandler(), permissonGuardHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Listen, component: ListenPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.DVDPlayer, component: DVDplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), permissonGuardHandler, watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.Videoplayer, component: VideoplayerPage, handlers: handlers.concat([new ChangeThemeHandler(), watchSignatureHandler]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.TestConversationPlayer, component: TestConversationPage, handlers: testConversationHandlers.concat([new UnchangeThemeHandler({ color: true })]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.ConversationVoiceUrlGenerationAccessDenied, component: AccessDeniedPage, handlers: [] }),
                React.createElement(GLRoute, { exact: true, path: "/jonny", component: Jonney, handlers: handlers.concat([new ChangeThemeHandler()]) }),
                React.createElement(GLRoute, { exact: true, path: PathConfig.ConversationsList, component: ConversationListPage, handlers: handlers.concat([new AllowTestingAccessibleRouteHandler()]) }),
                GLDefaultRoutes)));
});
export default routerPromise;
