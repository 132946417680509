var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { GLGlobal } from "gl-commonui";
import { useEffect, useRef, useState } from "react";
export var useStore = function () {
    var _a = GLGlobal.store, getState = _a.getState, subscribe = _a.subscribe;
    var _b = __read(useState(getState()), 2), state = _b[0], setState = _b[1];
    var isUnmounted = useRef(false);
    useEffect(function () {
        var unsubscribe = subscribe(function () {
            if (!isUnmounted.current) {
                setState(getState());
            }
        });
        return function () {
            isUnmounted.current = true;
            unsubscribe();
        };
    }, []);
    return state;
};
