var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import { withRouter, GLUtil, GLGlobal, maskThrottle, unmaskThrottle, connect } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { lazyInject, TYPES, changeThemeColor, getMedias } from "@app/util";
import { hidenHeaderFooter, showHeaderFooter } from "../components/hidenheaderfooter";
import { Button, notification } from "antd";
import { updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
import { StudentLocale } from "@app/locales/localeid";
var WelcomeplayerPage = /** @class */ (function (_super) {
    __extends(WelcomeplayerPage, _super);
    function WelcomeplayerPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        var _a = _this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, path = _a.path;
        var userName = GLUtil.queryParse().userName;
        _this.state = {
            userId: userId,
            userName: userName,
            versionId: versionId,
            unit: unit,
            path: path,
        };
        return _this;
    }
    WelcomeplayerPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // to track device login
                this.trackDevice();
                hidenHeaderFooter();
                return [2 /*return*/];
            });
        });
    };
    WelcomeplayerPage.prototype.trackDevice = function () {
        var _a = GLGlobal.loginInfo().profile, parentId = _a.sub, sid = _a.sid;
        var previoussid = localStorage.getItem("dsid");
        if (previoussid != sid) {
            this.deviceService.trackDeviceLogin({ userId: parentId }).then(function (d) {
                localStorage.setItem("dsid", sid);
            });
        }
    };
    WelcomeplayerPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.leftData && (this.state.userName !== nextProps.leftData.name)) {
            this.setState({ userName: nextProps.leftData.name });
        }
    };
    WelcomeplayerPage.prototype.componentWillUnmount = function () {
        showHeaderFooter();
    };
    WelcomeplayerPage.prototype.render = function () {
        var _this = this;
        var history = this.props.history;
        return React.createElement("div", { className: "playlistplayer media-page welcome-media-page" },
            React.createElement("div", { className: "welcome-text" },
                React.createElement("h1", null, GLGlobal.intl.formatMessage({ id: StudentLocale.ChildrenWelcomeTitle }, { user: this.state.userName }))),
            React.createElement("div", { className: "welcome-video" },
                React.createElement("video", { autoPlay: true, controls: true, controlsList: "nodownload" },
                    React.createElement("source", { src: getMedias()['welcome_video'].uri, type: "video/mp4" }))),
            React.createElement(Button, { className: "unit-button", type: "primary", onClick: function () { return navigateBy(history, _this.state, 'Your account does not have any active student.'); } }, GLGlobal.intl.formatMessage({ id: StudentLocale.WelcomeBegin })));
    };
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], WelcomeplayerPage.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsContentsService)
    ], WelcomeplayerPage.prototype, "unitsContentsService", void 0);
    __decorate([
        lazyInject(TYPES.IDeviceService)
    ], WelcomeplayerPage.prototype, "deviceService", void 0);
    WelcomeplayerPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], WelcomeplayerPage);
    return WelcomeplayerPage;
}(Component));
export default WelcomeplayerPage;
function navigateBy(history, state, message) {
    var userId = state.userId, versionId = state.versionId, unit = state.unit, path = state.path;
    if (unit) {
        maskThrottle();
        updateAppSettings(userId, GLGlobal.store.getState().theme.leftData.parentId, GLGlobal.store.getState().theme.leftData.unit, { hasViewedWelcome: true });
        unmaskThrottle();
        var page = path == "playlist" ? PathConfig.Playlist : PathConfig.Library;
        history.push({ pathname: GLUtil.pathStringify(page, { userId: userId, unit: unit, versionId: versionId }) });
        changeThemeColor(unit);
    }
    else {
        notification.warn({ message: message });
    }
}
