var JbRaiseHandFromNeutral = /** @class */ (function () {
    function JbRaiseHandFromNeutral() {
        this.frames = [
            1,
            112,
            113,
            114,
            115,
            116,
            117,
            118,
            119
        ];
    }
    return JbRaiseHandFromNeutral;
}());
export { JbRaiseHandFromNeutral };
