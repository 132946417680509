import { OutputFormat, ServicePropertyChannel, SpeechConfig, SpeechRecognizer } from "microsoft-cognitiveservices-speech-sdk";
var AzureSpeechRecognizerCognitiveServiceClass = /** @class */ (function () {
    function AzureSpeechRecognizerCognitiveServiceClass(speechKey, speechRegion) {
        this.speechConfig = SpeechConfig.fromSubscription(speechKey, speechRegion);
        this.speechConfig.speechRecognitionLanguage = "en-US";
        this.speechConfig.outputFormat = OutputFormat.Detailed;
        this.speechConfig.enableDictation();
        this.speechConfig.setServiceProperty("punctuation", "explicit", ServicePropertyChannel.UriQueryParameter); // done to remove punctuation
    }
    AzureSpeechRecognizerCognitiveServiceClass.prototype.getSpeechRecognizer = function (audioConfig) {
        return new SpeechRecognizer(this.speechConfig, audioConfig);
    };
    AzureSpeechRecognizerCognitiveServiceClass.prototype.getSpeechConfig = function () {
        return this.speechConfig;
    };
    return AzureSpeechRecognizerCognitiveServiceClass;
}());
export var AzureSpeechRecognizerCognitiveService = AzureSpeechRecognizerCognitiveServiceClass;
