import * as React from "react";
import LeftHeader from "@app/components/header/leftheader";
import RightHeader from "@app/components/header/rightheader";
import { StudentLocale } from "@app/locales/localeid";
import { LanguageIcon, DrawerHelper } from "gl-commonui";
import { PortalHelpDropDown } from "./PortalHelpDropDown";
import { PortalUserAvatar } from "./PortalUserAvatar";
import { HeaderNotification } from "@app/components/header-notification";
function contentHeaderMobile() {
    return (React.createElement("div", { className: "header-mobile" },
        React.createElement(RightHeader, null,
            React.createElement(HeaderNotification, null),
            React.createElement(PortalHelpDropDown, null),
            React.createElement("div", { className: "header-mobile-top" },
                React.createElement(PortalUserAvatar, null),
                React.createElement(LanguageIcon, null)))));
}
var masterPagesDefined = {
    appName: StudentLocale.CommonSiteTitle,
    back: {
        props: {
            headerComponents: {
                left: React.createElement(LeftHeader, null),
                right: [
                    React.createElement(DrawerHelper, { componentItems: contentHeaderMobile() }),
                    React.createElement(RightHeader, null,
                        React.createElement(PortalHelpDropDown, null),
                        React.createElement(HeaderNotification, null),
                        React.createElement(LanguageIcon, null),
                        React.createElement(PortalUserAvatar, null))
                ]
            },
            styleClass: {
                entirety: {
                    class: "portal-theme"
                }
            }
        }
    }
};
export { masterPagesDefined };
