var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbEncouragingGestures = /** @class */ (function (_super) {
    __extends(JbEncouragingGestures, _super);
    function JbEncouragingGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            {
                id: "EncouragingGesture#1",
                styleId: JbInteractionStyles.Encouraging,
                animations: [
                    new JbAnimations.Neutral(200),
                    new JbAnimations.Neutral(200),
                    new JbAnimations.ClapStart(),
                    new JbAnimations.ClapCycle(),
                    new JbAnimations.ClapReturn(),
                    new JbAnimations.Neutral(200),
                    new JbAnimations.Neutral(200)
                ],
            },
            {
                id: "EncouragingGesture#2",
                styleId: JbInteractionStyles.Encouraging,
                animations: [
                    new JbAnimations.NeutralToHappy(),
                    new JbAnimations.Happy(200),
                    new JbAnimations.Happy(200),
                    new JbAnimations.HappyToNeutral(),
                    new JbAnimations.Neutral(),
                ],
            },
            {
                id: "EncouragingGesture#3",
                styleId: JbInteractionStyles.Encouraging,
                animations: [
                    new JbAnimations.Neutral(100),
                    new JbAnimations.ThumbsUp(),
                    new JbAnimations.ThumbsUpReturn(),
                    new JbAnimations.Neutral(100),
                ],
            },
        ];
        return _this;
    }
    return JbEncouragingGestures;
}(GestureBase));
export { JbEncouragingGestures };
