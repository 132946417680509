import { useRef, useEffect } from "react";
export function useEffectAfterFirst(effect, deps) {
    var ref = useRef(true);
    useEffect(function () {
        if (ref.current) {
            ref.current = false;
        }
        else {
            return effect();
        }
    }, deps);
}
