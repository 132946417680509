var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var ExitFullScreenIcon = /** @class */ (function (_super) {
    __extends(ExitFullScreenIcon, _super);
    function ExitFullScreenIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExitFullScreenIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" },
            React.createElement("polygon", { className: "path", points: "5 0 5 5 0 5 0 7 5 7 7 7 7 5 7 0 5 0" }),
            React.createElement("polygon", { className: "path", points: "13 5 13 0 11 0 11 5 11 7 13 7 18 7 18 5 13 5" }),
            React.createElement("polygon", { className: "path", points: "5 11 0 11 0 13 5 13 5 18 7 18 7 13 7 11 5 11" }),
            React.createElement("polygon", { className: "path", points: "18 11 13 11 11 11 11 13 11 18 13 18 13 13 18 13 18 11" })));
    };
    return ExitFullScreenIcon;
}(Component));
export default ExitFullScreenIcon;
