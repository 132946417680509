var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import moment from "moment";
import { withRouter, GLGlobal, maskThrottle, unmaskThrottle, GLUtil, LanguageDateFormat, connect, OidcProxy } from "gl-commonui";
import { lazyInject, TYPES, changeThemeColor, PlayerRole, RoleHelper, setParentChildrenCache, fmtMsg, getFormattedName, ProgressControlColors, nonParentAndStudentUsers } from "@app/util";
import { Card, notification, Progress, Spin, Avatar as UserAvatar } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { themeChangedDispatch } from "@app/states/theme";
import { getMySetting } from "@app/service/logs/uploadplaylistlogs";
import { SettingService } from "@app/service/app/setting";
import { heartbeat_unregister_state_change } from "@app/app";
import { ConversationTestLocale, StudentLocale } from "@app/locales/localeid";
import { Pie } from "@app/components/svg-icon";
import { fetchConversationAccessPermission } from "@app/states/system-check";
import './children.less';
import { setAllChildren } from "@app/states/children";
import { SubscriptionExpired, ExtendedSubscriptionExpiration } from "@app/components/extended-subscription-notice";
var ChildrenPage = /** @class */ (function (_super) {
    __extends(ChildrenPage, _super);
    function ChildrenPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.state = {
            currentUser: { name: '' },
            isParent: false,
            children: [],
            self: {},
            loadFinished: false,
            isTestingAllowed: false,
            childrenData: [{ studentId: '', playlistPlayedCount: 0 }],
        };
        return _this;
    }
    ChildrenPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, parentId, name, roles, avatarUrl, loginInfo, testingAllowed;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = GLGlobal.loginInfo().profile, parentId = _a.sub, name = _a.name, roles = _a.roles, avatarUrl = _a.avatarUrl;
                        this.setState({ currentUser: { name: name, avatarUrl: avatarUrl }, isParent: roles.some(function (r) { return r === 'Parent'; }) });
                        // to track device login
                        this.trackDevice();
                        this.props.fetchConversationAccessPermission(parentId);
                        this.parentappservice.getChildren({ parentId: parentId }).then(function (d) {
                            _this.props.setChildren(d.children || []);
                            _this.setState({ children: d.children.sort(function (a, b) { return a.name > b.name ? 1 : -1; }), self: d.self ? __assign({}, d.self, { id: parentId, englishName: name }) : null, loadFinished: true });
                            var todaysDate = moment.utc().format('YYYY-MM-DD');
                            var studentIds = d.children.map(function (obj) { return obj.id; });
                            var playlistData = {
                                startDate: _this.getStartDateOfPreviousMonth(todaysDate),
                                endDate: _this.getEndDateOfPreviousMonth(todaysDate),
                                unit: null,
                                studentIds: studentIds
                            };
                            _this.reportService.getStudentsPlaylistData(playlistData).then(function (res) {
                                _this.setState({ childrenData: res });
                            });
                            setParentChildrenCache(d);
                        });
                        loginInfo = OidcProxy.getLoginInfo();
                        return [4 /*yield*/, this.userService.isTestingAllowed({ id: loginInfo.profile.sub })];
                    case 1:
                        testingAllowed = _b.sent();
                        this.setState({ isTestingAllowed: testingAllowed });
                        heartbeat_unregister_state_change();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChildrenPage.prototype.getStudentPlaylistCount = function (studentId) {
        if (this.state.childrenData.length) {
            var childData = this.state.childrenData.find(function (x) { return x.studentId === studentId; });
            if (childData) {
                return childData.playlistPlayedCount;
            }
            ;
        }
        return 0;
    };
    ChildrenPage.prototype.trackDevice = function () {
        var _a = GLGlobal.loginInfo().profile, parentId = _a.sub, sid = _a.sid;
        var previoussid = localStorage.getItem("dsid");
        if (previoussid != sid) {
            this.deviceService.trackDeviceLogin({ userId: parentId }).then(function (d) {
                localStorage.setItem("dsid", sid);
            });
        }
    };
    ChildrenPage.prototype.getEndDateOfPreviousMonth = function (todaysDate) {
        return moment(todaysDate).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    };
    ChildrenPage.prototype.getStartDateOfPreviousMonth = function (todaysDate) {
        return moment(todaysDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    };
    ChildrenPage.prototype.goToConversationsList = function (history) {
        history && history.push({ pathname: GLUtil.pathStringify(PathConfig.ConversationsList, {}) });
    };
    ChildrenPage.prototype.getTalkTimeTestingLink = function (history) {
        var _this = this;
        return (React.createElement("div", { className: "welcome__tttesting" },
            React.createElement("a", { onClick: function () { return _this.goToConversationsList(history); } }, GLGlobal.intl.formatMessage({
                id: ConversationTestLocale.ConversationTestingText
            }))));
    };
    ChildrenPage.prototype.getProgressColor = function (id) {
        var percentage = this.getProgressPercent(id);
        if (percentage <= 20) {
            return ProgressControlColors.Red;
        }
        else if (percentage > 20 && percentage <= 40) {
            return ProgressControlColors.Yellow;
        }
        else if (percentage > 40) {
            return ProgressControlColors.Green;
        }
    };
    ChildrenPage.prototype.getProgressPercent = function (id) {
        var count = this.getStudentPlaylistCount(id);
        var maxCountLimit = 50;
        var percentage = Math.round((count / maxCountLimit) * 100);
        if (percentage > 100) {
            return 100;
        }
        return percentage;
    };
    ChildrenPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, children = _a.children, self = _a.self, currentUser = _a.currentUser, isParent = _a.isParent, loadFinished = _a.loadFinished;
        var history = this.props.history;
        return (React.createElement(React.Fragment, null,
            React.createElement(ExtendedSubscriptionExpiration, null),
            React.createElement(SubscriptionExpired, null),
            React.createElement("div", { className: "children" },
                React.createElement("div", { className: "welcome" },
                    React.createElement("h1", null, GLGlobal.intl.formatMessage({ id: StudentLocale.ChildrenWelcomeTitle }, { user: currentUser.name })),
                    React.createElement("h3", null, GLGlobal.intl.formatMessage({
                        id: StudentLocale.ChildrenSelectTitle,
                    })),
                    nonParentAndStudentUsers() || this.state.isTestingAllowed
                        ? this.getTalkTimeTestingLink(history)
                        : null,
                    (this.state.isTestingAllowed || children.length > 0) && React.createElement("hr", null)),
                React.createElement(Spin, { spinning: !loadFinished, delay: 0 },
                    React.createElement("div", { className: "cards" },
                        self ? (React.createElement(Card, { key: "0", className: "not-child", hoverable: true, onClick: function () {
                                return navigateBy(history, self, PlayerRole.Other, self, StudentLocale.ChildrenNoneUnit, true, currentUser.avatarUrl);
                            } },
                            React.createElement(Card.Meta, { avatar: React.createElement(UserAvatar, { className: "default-avatar", icon: "user", src: currentUser.avatarUrl }), title: React.createElement("span", { title: currentUser.name }, currentUser.name) }))) : null,
                        children &&
                            children
                                .map(function (child) { return (__assign({}, child, { avatar: child.avatar })); })
                                .map(function (child) {
                                var id = child.id, subscriptionType = child.subscriptionType, avatar = child.avatar, name = child.name, englishName = child.englishName, schoolName = child.schoolName, previousSchoolName = child.previousSchoolName, schoolClassName = child.schoolClassName, previousClassName = child.previousClassName, extendedSubscriptionEndDate = child.extendedSubscriptionEndDate;
                                var mergedSchoolName = schoolName ||
                                    (extendedSubscriptionEndDate ? previousSchoolName : "");
                                var mergedSchoolClassName = schoolClassName ||
                                    (extendedSubscriptionEndDate ? previousClassName : "");
                                return (React.createElement(Card, { key: id, className: isParent ? "" : "not-child", hoverable: true, onClick: function () {
                                        return navigateBy(history, child, subscriptionType, self, StudentLocale.ChildrenNoneUnit, true, child.avatar);
                                    } },
                                    React.createElement(Card.Meta, { avatar: React.createElement(React.Fragment, null,
                                            React.createElement(Progress, { type: "circle", percent: _this.getProgressPercent(child.id), className: "progress-display", showInfo: false, width: 100, strokeColor: _this.getProgressColor(child.id) }),
                                            React.createElement(UserAvatar, { className: "default-avatar", icon: "user", src: avatar })), title: React.createElement("span", { title: name }, getFormattedName(englishName, name)), description: mergedSchoolName &&
                                            mergedSchoolClassName && (React.createElement("div", { className: "user-info" },
                                            React.createElement("div", null,
                                                React.createElement("span", null,
                                                    fmtMsg(StudentLocale.ChildrenSchoolTitle),
                                                    " "),
                                                React.createElement("div", { title: mergedSchoolName }, mergedSchoolName)),
                                            React.createElement("div", null,
                                                React.createElement("span", null,
                                                    fmtMsg(StudentLocale.ChildrenClassTitle),
                                                    " "),
                                                React.createElement("div", { title: mergedSchoolClassName }, mergedSchoolClassName)))) }),
                                    extendedSubscriptionEndDate && (React.createElement("div", { className: "gs-graceperiod" },
                                        React.createElement(Pie, null),
                                        React.createElement("div", { className: "gs-graceperiod-text" },
                                            React.createElement("span", null, fmtMsg(StudentLocale.ChildrenExtendedSubscription)),
                                            React.createElement("span", null, moment(extendedSubscriptionEndDate).format(LanguageDateFormat[GLGlobal.intl.locale])))))));
                            }))))));
    };
    __decorate([
        lazyInject(TYPES.IParentAppService)
    ], ChildrenPage.prototype, "parentappservice", void 0);
    __decorate([
        lazyInject(TYPES.IStudentService)
    ], ChildrenPage.prototype, "studentService", void 0);
    __decorate([
        lazyInject(TYPES.IDeviceService)
    ], ChildrenPage.prototype, "deviceService", void 0);
    __decorate([
        lazyInject(TYPES.IUserService)
    ], ChildrenPage.prototype, "userService", void 0);
    __decorate([
        lazyInject(TYPES.IPlaylistlogsAppService)
    ], ChildrenPage.prototype, "playlistlogAppService", void 0);
    __decorate([
        lazyInject(TYPES.IReportService)
    ], ChildrenPage.prototype, "reportService", void 0);
    ChildrenPage = __decorate([
        withRouter,
        connect(function () { }, { fetchConversationAccessPermission: fetchConversationAccessPermission, setChildren: setAllChildren })
    ], ChildrenPage);
    return ChildrenPage;
}(Component));
export default ChildrenPage;
export function navigateBy(history, user, role, parent, message, showNotification, avatarUrl) {
    return __awaiter(this, void 0, Promise, function () {
        var userId, name, englishName, unit, lsUnit, versionGroupId, lsVersionGroupId, maxUnit, settingService, mySetting, hasViewedWelcome, lastOpenUnit, targetUnit, unitVersionGroudId, path, path;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userId = user.id, name = user.name, englishName = user.englishName, unit = user.unit, lsUnit = user.lsUnit, versionGroupId = user.versionGroupId, lsVersionGroupId = user.lsVersionGroupId;
                    parent = parent || { id: GLGlobal.loginInfo().profile.sub };
                    maxUnit = unit ? unit : lsUnit;
                    message = fmtMsg(message);
                    if (!Number.isInteger(maxUnit)) {
                        if (showNotification) {
                            notification.warn({ message: message });
                        }
                        return [2 /*return*/, message];
                    }
                    maskThrottle();
                    settingService = new SettingService();
                    return [4 /*yield*/, getMySetting(settingService, userId, maxUnit)];
                case 1:
                    mySetting = (_a.sent()).mySetting;
                    hasViewedWelcome = mySetting.appSettings.hasViewedWelcome;
                    lastOpenUnit = (mySetting.appSettings.lastOpenUnit && mySetting.appSettings.lastOpenUnit >= lsUnit && mySetting.appSettings.lastOpenUnit <= unit) ?
                        mySetting.appSettings.lastOpenUnit : undefined;
                    targetUnit = maxUnit;
                    unitVersionGroudId = maxUnit > 0 ? versionGroupId : lsVersionGroupId;
                    if (!RoleHelper.isStudent(role)) {
                        targetUnit = lastOpenUnit && maxUnit ? (maxUnit > 0 ? Math.min(maxUnit, lastOpenUnit) : Math.max(maxUnit, lastOpenUnit)) : maxUnit;
                        unitVersionGroudId = targetUnit > 0 ? versionGroupId : lsVersionGroupId;
                    }
                    unmaskThrottle();
                    if (targetUnit && unitVersionGroudId) {
                        if (hasViewedWelcome) {
                            path = targetUnit > 0 && (role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other) ? PathConfig.Playlist : PathConfig.Library;
                            history.push({ pathname: GLUtil.pathStringify(path, { userId: userId, unit: targetUnit, versionId: unitVersionGroudId }) });
                        }
                        else {
                            path = targetUnit > 0 && (role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other) ? "playlist" : "library";
                            history.push({ pathname: GLUtil.pathStringify(PathConfig.Welcome, { userId: userId, versionId: unitVersionGroudId, unit: targetUnit, path: path }), search: GLUtil.queryStringify({ userName: getFormattedName(englishName, name) }) });
                        }
                        themeChangedDispatch({
                            leftData: {
                                id: userId,
                                role: role, src: avatarUrl,
                                name: getFormattedName(englishName, name),
                                unit: unit,
                                lsUnit: lsUnit,
                                parentId: parent.id,
                                isLoaded: true
                            }
                        });
                        hasViewedWelcome && changeThemeColor(targetUnit);
                    }
                    else {
                        if (showNotification) {
                            notification.warn({ message: message });
                        }
                        return [2 /*return*/, message];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
