var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { withRouter } from "gl-commonui";
var VideoplayerPage = /** @class */ (function (_super) {
    __extends(VideoplayerPage, _super);
    function VideoplayerPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoplayerPage.prototype.componentDidMount = function () {
        // this.props.match.params.lsUnit
        var myOptions = {
            "nativeControlsForTouch": false,
            controls: true,
            autoplay: true,
            width: "640",
            height: "400",
        };
        this.player = window.amp("azuremediaplayer", myOptions);
        this.player.src([
            {
                "src": "//amssamples.streaming.mediaservices.windows.net/3b970ae0-39d5-44bd-b3a3-3136143d6435/AzureMediaServicesPromo.ism/manifest",
                "type": "application/vnd.ms-sstr+xml"
            }
        ]);
    };
    VideoplayerPage.prototype.componentWillUnmount = function () {
        this.player.dispose();
    };
    VideoplayerPage.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement("video", { id: "azuremediaplayer", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered", tabIndex: 0 }));
    };
    VideoplayerPage = __decorate([
        withRouter
    ], VideoplayerPage);
    return VideoplayerPage;
}(Component));
export default VideoplayerPage;
