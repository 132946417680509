var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from "react";
import { MatIcon } from "gl-commonui";
import AudioPlayer from "../audio-player";
import "./playlist-reader-chapter.less";
import { setIsReaderAction } from "@app/states/playlistplayer";
export var ReaderChapter = function (props) {
    var content = props.content, onGoToNextReaderContent = props.onGoToNextReaderContent, onGoToPreviousReaderContent = props.onGoToPreviousReaderContent, isMovedForward = props.isMovedForward, isFirstContent = props.isFirstContent;
    var pages = content.pages, medias = content.medias;
    var _a = __read(useState([]), 2), pagesToView = _a[0], setPagesToView = _a[1];
    var _b = __read(useState(), 2), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = __read(useState(""), 2), audioUrl = _c[0], setAudioUrl = _c[1];
    var _d = __read(useState(true), 2), isFirstPage = _d[0], setIsFirstPage = _d[1];
    var audioPlayerRef = useRef(null);
    useEffect(function () {
        var pagesToView = pages.map(function (page) {
            var audioMedia = medias.find(function (m) { return m.id === page.audioMediaId; });
            if (audioMedia) {
                return __assign({}, page, { audioMediaUrl: audioMedia.url });
            }
            else {
                return __assign({}, page, { audioMediaUrl: null });
            }
        });
        setPagesToView(pagesToView);
        if (isMovedForward) {
            setCurrentPage(pagesToView[0]);
            setIsFirstPage(true);
        }
        else {
            setCurrentPage(pagesToView[pagesToView.length - 1]);
            setIsFirstPage(false);
        }
    }, [content]);
    var handleAudioPlayPause = function (event) {
        if (audioUrl) {
            setAudioUrl("");
        }
        else {
            setAudioUrl(currentPage.audioMediaUrl);
        }
        setIsReaderAction({ isReaderAction: true });
        event.stopPropagation();
    };
    var goToNextPage = function () {
        var currentPageIndex = pagesToView.findIndex(function (page) { return page.id === currentPage.id; });
        if (pagesToView.length === 1 && currentPageIndex === 0) {
            // only one page -- go to the next content
            onGoToNextReaderContent && onGoToNextReaderContent();
        }
        else if (pagesToView.length > 1 &&
            currentPageIndex === pagesToView.length - 1) {
            // this was the last item -- got to the next content
            onGoToNextReaderContent && onGoToNextReaderContent();
        }
        else {
            var nextPage = pagesToView.find(function (page) { return page.sequence === currentPage.sequence + 1; });
            setCurrentPage(nextPage);
            onAudioEnded();
            audioPlayerRef.current.stop();
            setIsFirstPage(false);
        }
        setIsReaderAction({ isReaderAction: true });
    };
    var goToPreviousPage = function () {
        var currentPageIndex = pagesToView.findIndex(function (page) { return page.id === currentPage.id; });
        if (currentPageIndex - 1 <= 0) {
            setIsFirstPage(true);
        }
        if (pagesToView.length === 1 && currentPageIndex === 0) {
            // only one page -- go to the previous content
            onGoToPreviousReaderContent && onGoToPreviousReaderContent();
        }
        else if (pagesToView.length > 1 && currentPageIndex === 0) {
            // this was the last item -- got to the previous content
            onGoToPreviousReaderContent && onGoToPreviousReaderContent();
        }
        else {
            var previousPage = pagesToView.find(function (page) { return page.sequence === currentPage.sequence - 1; });
            setCurrentPage(previousPage);
            onAudioEnded();
            audioPlayerRef.current.stop();
        }
        setIsReaderAction({ isReaderAction: true });
    };
    var onAudioEnded = function () {
        setAudioUrl("");
    };
    return (React.createElement(React.Fragment, null,
        currentPage && (React.createElement("div", { className: "readerchapter" },
            React.createElement("div", { className: "readerchapter__pagebtn" },
                React.createElement("span", { onClick: goToPreviousPage, className: isFirstContent && isFirstPage
                        ? "readerchapter__pagebtn--leftdisabled"
                        : "readerchapter__pagebtn--left" },
                    React.createElement(MatIcon, { type: "navigate_before" }))),
            React.createElement("div", { className: "readerchapter__pageplay" },
                React.createElement("div", { className: "readerchapter-page", onClick: function () { return setIsReaderAction({ isReaderAction: false }); } },
                    React.createElement("img", { src: currentPage.url, width: "100%", height: "100%" })),
                React.createElement("div", { className: "readerchapter-play" }, !audioUrl ? (React.createElement("span", { className: currentPage.audioMediaUrl
                        ? "readerchapter-play--icon"
                        : "readerchapter-play--icondisabled", onClick: handleAudioPlayPause },
                    React.createElement(MatIcon, { type: "play_circle_outlined" }))) : (React.createElement("span", { className: currentPage.audioMediaUrl
                        ? "readerchapter-play--icon"
                        : "readerchapter-play--icondisabled", onClick: handleAudioPlayPause },
                    React.createElement(MatIcon, { type: "pause_circle_outlined" }))))),
            React.createElement("div", { className: "readerchapter__pagebtn" },
                React.createElement("span", { onClick: goToNextPage, className: "readerchapter__pagebtn--right" },
                    React.createElement(MatIcon, { type: "chevron_right" }))))),
        React.createElement(AudioPlayer, { audioUrl: audioUrl, onAudioEnded: onAudioEnded, ref: audioPlayerRef })));
};
