var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { SubContentPos } from "./playlistmediaplayer";
import { Row, Col } from "antd-min";
import once from "lodash/once";
import cry from "@app/style/images/question/cry.png";
import great from "@app/style/images/question/great.png";
import { polyfillNodeList } from "@app/util";
var PlaylistQuestionContainer = /** @class */ (function (_super) {
    __extends(PlaylistQuestionContainer, _super);
    function PlaylistQuestionContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            candidators: [],
            selectIndex: [],
            loaded: false,
            isCurrentQuestionCompleted: false
        };
        return _this;
    }
    PlaylistQuestionContainer.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, content = _a.content, playSequence = _a.playSequence;
        if (hasNextContents(content)) {
            this.initQuestions(content, playSequence);
        }
        var timer;
        this.resize = function () {
            if (!timer) {
                timer = setTimeout(function () {
                    clearTimeout(timer);
                    timer = null;
                    _this.resetDimensions();
                }, 50);
            }
        };
        window.addEventListener("resize", this.resize);
    };
    PlaylistQuestionContainer.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.resize);
    };
    PlaylistQuestionContainer.prototype.resetDimensions = function () {
        var dom = document.querySelector('.question-control .ant-row .ant-col > div');
        if (dom) {
            var margin = dom.offsetTop - 5;
            document.querySelector('.repeat-question').style.marginTop = margin + "px";
        }
    };
    PlaylistQuestionContainer.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.content.description.exts !== nextProps.content.description.exts && nextProps.content.description.exts) {
            var content = nextProps.content, playSequence = nextProps.playSequence;
            this.initQuestions(content, playSequence);
            //If previous question is completed then change the currentQuestion state to false for next question
            if (this.state.isCurrentQuestionCompleted === true) {
                this.setState({ isCurrentQuestionCompleted: false });
            }
        }
        else if (!nextProps.content.description.exts) {
            this.setState({ candidators: [], selectIndex: [] });
        }
    };
    PlaylistQuestionContainer.prototype.initQuestions = function (content, playSequence) {
        var _this = this;
        this.setState({ candidators: this.spliceCandidators(content, playSequence), selectIndex: [], loaded: true }, function () {
            _this.createQuestionUploadLogs();
            var allImgLoaded = [];
            var doms = polyfillNodeList(document.querySelectorAll('img.candidator'));
            doms.forEach(function (dom) { return allImgLoaded.push(new Promise(function (resolve) { return dom.addEventListener('load', function () { return resolve(); }, { once: true }); })); });
            Promise.all(allImgLoaded).then(function () { return _this.setState({ loaded: true }, function () { return _this.resetDimensions(); }); });
        });
    };
    PlaylistQuestionContainer.prototype.createQuestionUploadLogs = function () {
        var _this = this;
        this.selectCandidator = once(function (d) {
            _this.props.onSelectCandidator(d);
        });
    };
    PlaylistQuestionContainer.prototype.getCandidators = function (content, playSequence) {
        var clickCandidator = function (c) { return ({
            id: c.id,
            src: c.thumbnailUrl,
            img: cry,
            type: 'candidator',
            onClick: function () { return playSequence(__spread(getCandidator(content, c.id), getQuestion(content))); }
        }); };
        return getCandidators(content).map(clickCandidator);
    };
    PlaylistQuestionContainer.prototype.getAnswerCandidator = function (content, playSequence) {
        var answers = getAnswer(content);
        return ({
            id: answers[answers.length - 1].id,
            src: answers[answers.length - 1].thumbnailUrl,
            img: great,
            type: 'answer',
            onClick: function () { return playSequence(answers); }
        });
    };
    PlaylistQuestionContainer.prototype.spliceCandidators = function (content, playSequence) {
        var candidators = this.getCandidators(content, playSequence);
        var start = Math.floor(Math.random() * 4);
        candidators.splice(start, 0, this.getAnswerCandidator(content, playSequence));
        return candidators;
    };
    PlaylistQuestionContainer.prototype.render = function () {
        var _this = this;
        var _a = this.props, content = _a.content, playSequence = _a.playSequence, isPaused = _a.isPaused;
        var _b = this.state, selectIndex = _b.selectIndex, loaded = _b.loaded, candidators = _b.candidators, isCurrentQuestionCompleted = _b.isCurrentQuestionCompleted;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { style: { display: candidators.length && loaded ? 'flex' : 'none' }, className: "playlist-questioncontainer media-screen-dimensions" },
                React.createElement("div", { className: "question-control" },
                    React.createElement(Row, null, this.state.candidators.map(function (c, index) {
                        return React.createElement(Col, { span: 12, key: index },
                            React.createElement("div", { className: "" + (selectIndex.some(function (i) { return i == index; }) && c.type === 'candidator' && 'candidator-event'), onClick: function (e) {
                                    if (isPaused && selectIndex.every(function (i) { return i !== index; }) && !isCurrentQuestionCompleted) {
                                        c.onClick(e);
                                        _this.setState(function (_a) {
                                            var selectIndex = _a.selectIndex;
                                            return ({ selectIndex: selectIndex.concat(index) });
                                        });
                                        _this.selectCandidator({ contentId: c.id, right: c.type == 'answer' });
                                        if (c.type == "answer") {
                                            _this.setState({ isCurrentQuestionCompleted: true });
                                        }
                                    }
                                    e.stopPropagation();
                                } },
                                React.createElement("img", { src: c.src, className: "candidator " + (selectIndex.some(function (i) { return i == index; }) && c.type === 'candidator' && 'wrong') })),
                            selectIndex.some(function (i) { return i == index; }) && React.createElement("img", { className: 'answer-status', src: c.img }));
                    }))),
                React.createElement("div", { className: "repeat-question", onClick: function (e) {
                        !isCurrentQuestionCompleted && isPaused && playSequence(getQuestion(content));
                        e.stopPropagation();
                    } })));
    };
    return PlaylistQuestionContainer;
}(Component));
export default PlaylistQuestionContainer;
function getQuestion(content) {
    return getNextContents(content, 'question');
}
function getAnswer(content) {
    var contents = getNextContents(content, 'answer');
    contents[contents.length - 1].description.subContentPos = SubContentPos.end;
    return contents;
}
function getCandidator(content, id) {
    return getNextContents(content, 'candidators').filter(function (c) { return c.id === id; });
}
function getCandidators(content) {
    return getNextContents(content, 'candidators');
}
function getNextContents(content, key) {
    var exts = content.description.exts;
    var next = exts.contents[key].map(function (c) { return (c.description.exts = exts, c); });
    return next;
}
function hasNextContents(content) {
    return !!content.description.exts;
}
