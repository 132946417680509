var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GLGlobal } from "gl-commonui";
export default {
    namespace: 'theme',
    state: {
        midChanged: false,
        leftChanged: false,
        rightChanged: false,
        leftData: { id: '', role: '', src: '', name: '', unit: 0, lsUnit: 0, parentId: '', isLoaded: false }
    },
    reducers: {
        changed: function (state, action) {
            return __assign({}, state, action.payload);
        },
    },
};
export function themeChanged(state) {
    return { type: 'theme/changed', payload: state };
}
export function themeChangedDispatch(state) {
    GLGlobal.store.dispatch(themeChanged(state));
}
