var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect, useState } from "react";
import { mergeClasses } from "gl-commonui";
import { MediaSlotEnum, SkillType, formatMedia, preload } from "@app/util";
import { ReaderChapter } from "./components/playlist-reader-chapter/playlist-reader-chapter";
import { ReaderFocusWords } from "./components/playlist-reader-focuswords/playlist-reader-focuswords";
import "./playlist-reader.less";
import { cloneDeep } from "lodash";
import { FocusWordsContextProvider } from "./components/playlist-reader-focuswords/context/reader-focus-word-context";
export var PlaylistReader = function (props) {
    var content = props.content, signature = props.signature, playSequence = props.playSequence, onReaderEnded = props.onReaderEnded, setManualSpinKeeper = props.setManualSpinKeeper;
    var id = content.id, skillTypeId = content.skillTypeId;
    var _a = __read(useState(false), 2), visible = _a[0], setVisible = _a[1];
    var _b = __read(useState([]), 2), readerContents = _b[0], setReaderContents = _b[1];
    var _c = __read(useState(null), 2), currentReaderContent = _c[0], setCurrentReaderContent = _c[1];
    var _d = __read(useState(true), 2), isMovedForward = _d[0], setIsMovedForward = _d[1];
    var _e = __read(useState(true), 2), isFirstContent = _e[0], setIsFirstContent = _e[1];
    useEffect(function () {
        if (skillTypeId) {
            setVisible(true);
            if (content.readerContents.length) {
                var formattedReaderContents = formatReaderContents(content.readerContents);
                setReaderContents(formattedReaderContents);
                setCurrentReaderContent(formattedReaderContents[0]);
            }
        }
        else {
            setVisible(false);
        }
    }, [id]);
    var groupFocusWordContents = function (readerItems) {
        var e_1, _a;
        var groupedReaderItems = [];
        var contents = readerItems.filter(function (content) { return content.skillTypeId !== SkillType.FocusWord; });
        var insertionIndex = 0;
        try {
            for (var readerItems_1 = __values(readerItems), readerItems_1_1 = readerItems_1.next(); !readerItems_1_1.done; readerItems_1_1 = readerItems_1.next()) {
                var item = readerItems_1_1.value;
                if (item.skillTypeId === SkillType.FocusWord) {
                    groupedReaderItems.push(item);
                }
                else {
                    if (groupedReaderItems.length) {
                        var itemToInsert = __assign({}, groupedReaderItems[0]);
                        itemToInsert.focusWords = __spread(groupedReaderItems);
                        itemToInsert.skillTypeId = 4;
                        contents.splice(insertionIndex, 0, __assign({}, itemToInsert));
                        insertionIndex += groupedReaderItems.length;
                        groupedReaderItems.length = 0;
                    }
                    else {
                        insertionIndex++;
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (readerItems_1_1 && !readerItems_1_1.done && (_a = readerItems_1.return)) _a.call(readerItems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (groupedReaderItems.length) {
            var itemToInsert = __assign({}, groupedReaderItems[0]);
            itemToInsert.focusWords = __spread(groupedReaderItems);
            itemToInsert.skillTypeId = 4;
            if (contents.length) {
                contents.splice(contents.length, 0, itemToInsert);
            }
            else {
                contents.splice(0, 0, itemToInsert);
            }
        }
        return contents;
    };
    var preloadReaderContentImages = function (readerContents) {
        var imageUrls = readerContents.reduce(function (imageUrls, content) {
            if (content.skillTypeId === SkillType.Content || content.skillTypeId === SkillType.ContentChapter) {
                var imageUrlOfPages = content.pages.map(function (page) { return page.url; });
                imageUrls.push.apply(imageUrls, __spread(imageUrlOfPages));
            }
            else {
                var imageUrlOfImages = content.medias.filter(function (m) { return m.mediaSlotId === MediaSlotEnum.Image; }).map(function (m) { return m.url; });
                imageUrls.push.apply(imageUrls, __spread(imageUrlOfImages));
            }
            return imageUrls;
        }, []);
        preload(imageUrls);
    };
    var formatReaderContents = function (readerContents) {
        var formattedContents = cloneDeep(readerContents);
        var newFormattedContents = formattedContents.map(function (content) {
            return __assign({}, content, { focusWords: [] });
        });
        // 1. put signature in url of medias, pages
        newFormattedContents.forEach(function (content) {
            var replaceSignatureUrl = formatMedia(content.updateTime);
            // i) for medias
            if (content.medias.length) {
                var audioMedias = content.medias
                    .filter(function (m) {
                    return m.mediaSlotId === MediaSlotEnum.Audio ||
                        m.mediaSlotId === MediaSlotEnum.IndividualAudio;
                })
                    .map(replaceSignatureUrl(signature.audioContainer));
                var imageMedias = content.medias
                    .filter(function (m) { return m.mediaSlotId === MediaSlotEnum.Image; })
                    .map(replaceSignatureUrl(signature.pageContainer));
                content.medias = audioMedias.concat(imageMedias);
            }
            if (content.pages.length) {
                content.pages = content.pages.map(replaceSignatureUrl(signature.pageContainer));
            }
        });
        preloadReaderContentImages(newFormattedContents);
        newFormattedContents = groupFocusWordContents(newFormattedContents)
            .map(function (content) {
            if (content.skillTypeId === 4) {
                content.skillTypeId = SkillType.FocusWord;
            }
            return content;
        });
        // 2. put sequence in all items
        var sequence = 1;
        newFormattedContents.forEach(function (content) {
            content.sequence = sequence;
            sequence++;
        });
        return newFormattedContents;
    };
    var renderContent = function () {
        if (currentReaderContent) {
            if (currentReaderContent.skillTypeId === SkillType.Content ||
                currentReaderContent.skillTypeId === SkillType.ContentChapter) {
                return (React.createElement(ReaderChapter, { content: currentReaderContent, onGoToNextReaderContent: onGoToNextReaderContent, onGoToPreviousReaderContent: onGoToPreviousReaderContent, isMovedForward: isMovedForward, isFirstContent: isFirstContent }));
            }
            else if (currentReaderContent.skillTypeId === SkillType.FocusWord) {
                var focusWordContents = currentReaderContent.focusWords;
                return (focusWordContents.length && (React.createElement(FocusWordsContextProvider, null,
                    React.createElement(ReaderFocusWords, { focusWordContents: focusWordContents, onGoToNextReaderContent: onGoToNextReaderContent, onGoToPreviousReaderContent: onGoToPreviousReaderContent, isMovedForward: isMovedForward, isFirstContent: isFirstContent }))));
            }
        }
        else {
            return null;
        }
    };
    var onGoToNextReaderContent = function () {
        var currentReaderContentIndex = readerContents.findIndex(function (readerContent) { return readerContent.id === currentReaderContent.id && readerContent.sequence === currentReaderContent.sequence; });
        if (readerContents[currentReaderContentIndex + 1]) {
            setIsMovedForward(true);
            setIsFirstContent(false);
            setCurrentReaderContent(readerContents[currentReaderContentIndex + 1]);
        }
        else {
            var context = {
                subContentPos: undefined,
                sequence: content.sequence,
                content: content,
            };
            setManualSpinKeeper(true);
            onReaderEnded && onReaderEnded(context);
            // no more items present... go to next playlist content
            playSequence && playSequence(undefined, true);
        }
    };
    var onGoToPreviousReaderContent = function () {
        var currentReaderContentIndex = readerContents.findIndex(function (readerContent) { return readerContent.id === currentReaderContent.id && readerContent.sequence === currentReaderContent.sequence; });
        if (readerContents[currentReaderContentIndex - 1]) {
            if (currentReaderContentIndex - 1 === 0) {
                setIsFirstContent(true);
            }
            setIsMovedForward(false);
            setCurrentReaderContent(readerContents[currentReaderContentIndex - 1]);
        }
    };
    return (React.createElement("div", { className: mergeClasses("playlist-reader-container media-screen-dimensions", !visible && "playlist-reader-container--hidden") }, visible && renderContent()));
};
