export var UnitPathCoordinates = [
    { x: 50.82396317, y: 242.7656708 },
    { x: 108.6765442, y: 235.7406921 },
    { x: 155.2854309, y: 202.8220673 },
    { x: 206.9689636, y: 180.7453918 },
    { x: 263.4338684, y: 166.8459778 },
    { x: 321.036377, y: 165.7671089 },
    { x: 366.3319702, y: 202.1183472 },
    { x: 421.8514709, y: 217.4652405 },
    { x: 463.1118469, y: 258.3164063 },
    { x: 516.6843262, y: 270.5184479 },
    { x: 553.8591919, y: 226.501709 },
    { x: 597.6184692, y: 191.6160583 },
    { x: 643.7103271, y: 222.340065 },
    { x: 679.5877075, y: 267.8067322 },
    { x: 737.0073242, y: 271.6375122 },
    { x: 795.2352295, y: 266.8334961 },
    { x: 846.8128052, y: 244.1874542 },
    { x: 895.6117554, y: 215.1285172 },
    { x: 949.5333252, y: 233.5182953 },
    { x: 1003.222656, y: 252.7416687 },
    { x: 1061.543945, y: 249.013443 },
    { x: 1119.274414, y: 243.0658264 },
    { x: 1157.170654, y: 199.3725967 },
    { x: 1205.484863, y: 172.8064461 },
    { x: 1242.158936, y: 215.5468979 },
    { x: 1265.411743, y: 269.1674957 },
    { x: 1311.817627, y: 295.6238708 },
    { x: 1357.706299, y: 261.2159119 },
    { x: 1410.559814, y: 241.1373901 },
    { x: 1456.182373, y: 206.6306915 },
    { x: 1507.95166, y: 184.9280319 },
    { x: 1565.483521, y: 175.29953 },
    { x: 1621.539795, y: 181.2662125 },
    { x: 1650.665527, y: 231.488266 },
    { x: 1681.021729, y: 280.7829437 },
    { x: 1737.404907, y: 281.7913513 },
    { x: 1793.398926, y: 266.4222107 },
    { x: 1851.105713, y: 273.7690582 },
    { x: 1908.84375, y: 279.7395477 },
    { x: 1950.517212, y: 241.1446381 },
];
export var BadgeCoordinates = [
    { x: 488, y: 135 },
    { x: 989, y: 135 },
    { x: 1429, y: 135 },
    { x: 1954, y: 135 },
];
