var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import "./style/speed-controller.less";
import OpenEyeIcon from "./icon/open-eye-icon";
import CloseEyeIcon from "./icon/close-eye-icon";
var CaptionControlSwitch = /** @class */ (function (_super) {
    __extends(CaptionControlSwitch, _super);
    function CaptionControlSwitch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onEnableChanged = function (enable) {
            _this.props.onEnableChanged && _this.props.onEnableChanged(enable);
        };
        return _this;
    }
    CaptionControlSwitch.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "switch-base" },
            React.createElement("div", { className: "menu-button " + (this.props.enable ? "" : "disable"), onClick: function () { _this.onEnableChanged(true); } },
                React.createElement(OpenEyeIcon, null)),
            React.createElement("div", { className: "menu-button " + (this.props.enable ? "disable" : ""), onClick: function () { _this.onEnableChanged(false); } },
                React.createElement(CloseEyeIcon, null))));
    };
    return CaptionControlSwitch;
}(Component));
export default CaptionControlSwitch;
