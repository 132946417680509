var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ConversationPlayer } from "@app/components/media/playlist-conversation/components/conversation-player/conversation-player";
import { useActionCreator, useEffectAfterFirst, useSelector, useService } from "@app/hooks";
import { ConversationTestLocale } from "@app/locales/localeid";
import { ConversationService } from "@app/service/conversation";
import { setSystemCheckPopupVisible, updateConversationAccess } from "@app/states/system-check";
import { Constants, fmtMsg, isSafari, preload, TYPES } from "@app/util";
import { ContainerType, ConversationAPI } from "@app/util/conversation-api";
import { ConversationTraceLogType } from "@app/util/conversation-api/utils/conversation-trace-log-model";
import { Col, Icon, Row } from "antd";
import { OidcProxy, withRouter } from "gl-commonui";
import { cloneDeep, toNumber, uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import "./test-conversation.less";
import audioFile from '@app/style/audios/90ef06f9-a47f-431a-9d00-24d4dbb81daf.wav';
import { ConversationIntroPlayer } from "@app/components/conversation-intro-player/conversation-intro-player";
import { getMySetting, updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
import ConversationPlaySetting from "../components/speedcontroller/conversation-speed-control-dropdown";
var isUnmounted = false;
export var TestConversationPageComponent = function (props) {
    var dummyAccessibleRegionData = {
        id: "",
        name: ""
    };
    var history = props.history, params = props.match.params;
    var conversationId = toNumber(params.conversationId);
    var conversationSpeed = Constants.DefaultPlaybackRate;
    var _a = __read(useState(false), 2), visible = _a[0], setVisible = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), isConversationStarted = _c[0], setIsConversationStarted = _c[1];
    var _d = __read(useState(false), 2), isListening = _d[0], setIsListening = _d[1];
    var _e = __read(useState(null), 2), dialog = _e[0], setDialog = _e[1];
    var conversationAPI = useRef(null);
    var conclusionNodeReached = useRef(false);
    var jonnyBearRef = useRef();
    var _f = __read(useState([]), 2), conversationTraceLogs = _f[0], setConversationTraceLogs = _f[1];
    var traceLogCopy = useRef([]); // using this as function are warpped as closure
    var microphoneSystemCheck = useActionCreator(setSystemCheckPopupVisible);
    var microphoneDeviceId = useSelector(function (state) { return state.systemCheck.microphoneDeviceId; });
    var setConversationAccess = useActionCreator(updateConversationAccess);
    var _g = __read(useState(false), 2), playToggle = _g[0], setPlayToggle = _g[1];
    var _h = __read(useState(null), 2), message = _h[0], setMessage = _h[1];
    var _j = __read(useState(false), 2), isConversationCompleted = _j[0], setIsConversationCompleted = _j[1];
    var _k = __read(useState(dummyAccessibleRegionData), 2), accessibleRegion = _k[0], setAccessibleRegion = _k[1];
    var regionService = useService(TYPES.IRegionService);
    var _l = __read(useState(false), 2), isTestingAllowed = _l[0], setIsTestingAllowed = _l[1];
    var userService = useService(TYPES.IUserService);
    var _m = __read(useState(false), 2), showIntroVideo = _m[0], setShowIntroVideo = _m[1];
    var isServerDown = false;
    var settingService = useService(TYPES.ISettingService);
    var _o = __read(useState(false), 2), isBarVisible = _o[0], setBarVisible = _o[1];
    var _p = __read(useState(""), 2), userId = _p[0], setUserId = _p[1];
    var _q = __read(useState(Constants.DefaultPlaybackRate), 2), speed = _q[0], setSpeed = _q[1];
    var _r = __read(useState(false), 2), isBlinking = _r[0], setIsBlinking = _r[1];
    useEffect(function () {
        setConversationAccess(true);
        if (!microphoneDeviceId) {
            microphoneSystemCheck(true);
        }
        getIsTestingAllowed();
        // Commented as per GL-13101
        // const parsedSpeed = parseFloat(params.conversationSpeed.replace("-", "."));
        // if (isNumber(parsedSpeed) && Constants.AllowedPlaybackRate.indexOf(parsedSpeed) > -1) {
        //     conversationSpeed = parsedSpeed;
        // }
        // else {
        //     MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: ConversationTestLocale.ConversationTestInvalidSpeed }, { speeds: Constants.AllowedPlaybackRate.join(), defaultspeed: Constants.DefaultPlaybackRate }));
        // }
    }, []);
    useEffect(function () {
        var loginInfo = OidcProxy.getLoginInfo();
        setUserId(loginInfo.profile.sub);
    }, []);
    var getIsTestingAllowed = function () {
        var loginInfo = OidcProxy.getLoginInfo();
        userService
            .isTestingAllowed({ id: loginInfo.profile.sub })
            .then(function (data) { return setIsTestingAllowed(data); })
            .catch(function (e) { return console.error(e); });
    };
    useEffect(function () {
        isUnmounted = false;
        return function () {
            isUnmounted = true;
            reset(true);
            setConversationAccess(false);
        };
    }, []);
    useEffectAfterFirst(function () {
        setVisible(true);
        resetCompleteError();
        loadConversation();
    }, [playToggle]);
    useEffect(function () {
        if (conversationId) {
            // Check for permission again.
            if (microphoneDeviceId) {
                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then(function (stream) {
                    stream.getTracks().forEach(function (track) { return track.stop(); });
                    setVisible(true);
                    resetCompleteError();
                    loadConversation();
                })
                    .catch(function (e) {
                    reset(true);
                    setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestMicrophoneMessage }));
                });
            }
            else {
                reset(true);
                setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestMicrophoneMessage }));
            }
        }
        else {
            setVisible(false);
        }
    }, [conversationId]);
    useEffectAfterFirst(function () {
        if (conversationAPI.current) {
            conversationAPI.current.updateMicrophone(microphoneDeviceId);
        }
    }, [microphoneDeviceId]);
    useEffectAfterFirst(function () {
        if (conversationAPI.current) {
            var updatedSpeed = 0;
            if (conversationId) {
                if (speed === Constants.ConversationSlowModePlaybackRate) {
                    updatedSpeed = Constants.ConversationSlowModePlaybackRate;
                }
                else {
                    updatedSpeed = Constants.DefaultPlaybackRate;
                }
            }
            conversationAPI.current.updateSpeed(updatedSpeed);
        }
    }, [conversationId, speed]);
    var setConversationAPI = function (instance) {
        conversationAPI.current = instance;
    };
    var onConclusionNodeReached = function () {
        conclusionNodeReached.current = true;
    };
    var resetCompleteError = function () {
        setMessage(null);
    };
    var onError = function () {
        reset(true);
        setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestErrorMessage }));
    };
    var onInComplete = function () {
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.Error,
            dialog: null,
            attempt: 0,
            isMatched: false,
            matchedIntentName: null,
            topIntentName: null,
            utterenceText: null,
            pathName: null,
            matchingScore: 0,
            message: Constants.ConversationErrorMessage,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null
        };
        updateTraceLogs(traceLog);
        setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestErrorMessage }));
        // Reset the component to its default state
        reset(false);
    };
    var onServerDown = function () {
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.Error,
            dialog: null,
            attempt: 0,
            isMatched: false,
            matchedIntentName: null,
            topIntentName: null,
            utterenceText: null,
            pathName: null,
            matchingScore: 0,
            message: Constants.ConversationErrorMessage,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null
        };
        updateTraceLogs(traceLog);
        setMessage(fmtMsg({ id: ConversationTestLocale.ConversationServerDownMessage }));
        // Reset the component to its default state
        reset(false);
    };
    var onEnd = function () {
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.ConversationEnd,
            dialog: null,
            attempt: 0,
            isMatched: false,
            matchedIntentName: null,
            topIntentName: null,
            utterenceText: null,
            pathName: null,
            matchingScore: 0,
            message: Constants.ConversationCompleteMessage,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null
        };
        updateTraceLogs(traceLog);
        setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestSuccessMessage }));
        setIsConversationCompleted(true);
        // Reset the component to its default state
        reset(false);
    };
    var onListen = function (isListening) {
        if (isUnmounted) {
            return;
        }
        setIsListening(isListening);
    };
    var onUserResponse = function (dialogTrack) {
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.UserResponse,
            dialog: null,
            dialogId: dialogTrack.conversationDialogId,
            attempt: dialogTrack.attempt,
            isMatched: dialogTrack.isMatched,
            matchedIntentName: dialogTrack.matchedIntentName,
            topIntentName: dialogTrack.topIntentName,
            utterenceText: dialogTrack.utterenceText,
            pathName: dialogTrack.pathName,
            pathId: dialogTrack.pathId,
            matchingScore: dialogTrack.matchingScore,
            message: null,
            matchedIntentId: dialogTrack.matchedIntentId,
            matchedEntityId: dialogTrack.matchedEntityId,
            matchedEntityName: dialogTrack.matchedEntityName,
            matchedEntityValue: dialogTrack.matchedEntityValue
        };
        updateTraceLogs(traceLog);
    };
    var onDialogChange = function (dialog) {
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.MachineDialog,
            dialog: dialog.body,
            dialogId: dialog.data.id,
            attempt: 0,
            isMatched: false,
            matchedIntentName: null,
            topIntentName: null,
            utterenceText: null,
            pathName: null,
            matchingScore: 0,
            message: null,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null
        };
        updateTraceLogs(traceLog);
        setDialog(dialog);
    };
    var getTraceLogLength = function () {
        return traceLogCopy.current.length;
    };
    var updateTraceLogs = function (traceLog) {
        var existingTraceLogs = cloneDeep(traceLogCopy.current);
        existingTraceLogs.push(traceLog);
        traceLogCopy.current = existingTraceLogs;
        setConversationTraceLogs(existingTraceLogs);
    };
    var loadConversation = function () {
        setLoading(true);
        var loginInfo = OidcProxy.getLoginInfo();
        Promise.all([
            ConversationService.getSasURL(),
            ConversationService.getConversation(conversationId),
            regionService.getAccessibleRegions(),
            getMySetting(settingService, loginInfo.profile.sub, 1)
        ])
            .then(function (_a) {
            var _b = __read(_a, 4), signatures = _b[0], conversationInfo = _b[1], regionsInfo = _b[2], setting = _b[3];
            loadConversationFunction([signatures, conversationInfo, regionsInfo, isServerDown = false, setting]);
        })
            .catch(function () {
            var conversationInfo = {
                "conversationDialog": [
                    {
                        "isHead": true,
                        "statusId": 0,
                        "voiceUrl": audioFile,
                        "path": []
                    }
                ]
            };
            var signatures = [];
            var regionsInfo = [];
            loadConversationFunction([signatures, conversationInfo, regionsInfo, isServerDown = true, null]);
            setLoading(false);
        });
    };
    var loadConversationFunction = function (_a) {
        var _b = __read(_a, 5), signatures = _b[0], conversationInfo = _b[1], regionsInfo = _b[2], isServerDown = _b[3], setting = _b[4];
        var speedSetting = setting.mySetting.appSettings.slowSpeed;
        var currentSpeed = speedSetting === true ? Constants.ConversationSlowModePlaybackRate : Constants.DefaultPlaybackRate;
        setSpeed(currentSpeed);
        var accessibleRegions = regionsInfo.filter(function (r) { return r.disabled === false; });
        var accessibleRegionData = dummyAccessibleRegionData;
        if (accessibleRegions.length > 0) {
            accessibleRegionData.id = accessibleRegions[0].id;
            accessibleRegionData.name = accessibleRegions[0].name;
        }
        setAccessibleRegion(accessibleRegionData);
        var imageSignature = signatures.filter(function (f) { return f.type === ContainerType.Page; });
        var audioSignature = signatures.filter(function (f) { return f.type === ContainerType.File; });
        var api = new ConversationAPI(conversationInfo, imageSignature.length ? imageSignature[0] : null, audioSignature.length ? audioSignature[0] : null, {
            microphoneDeviceId: microphoneDeviceId,
            onComplete: onConclusionNodeReached,
            onEnd: onEnd,
            onIncomplete: onInComplete,
            //onListen,
            onDialogChange: onDialogChange,
            onUserResponse: onUserResponse,
            jbRef: jonnyBearRef.current,
            playbackRate: currentSpeed,
            onListenStart: function () { return onListen(true); },
            onListenStop: function () { return onListen(false); },
            onServerDown: onServerDown
        }, true, isServerDown);
        setConversationAPI(api);
        // preload dialog images
        var imageUrls = uniq(conversationInfo.conversationDialog.filter(function (cd) { return cd.imageUrl; }).map(function (cd) { return api.getImageUrl(cd.imageUrl); }));
        preload(imageUrls);
        setLoading(false);
    };
    var startConversation = function (audioElement, audioContext) {
        setIsConversationStarted(true);
        isSafari() && conversationAPI.current.setAudioContext(audioElement, audioContext);
        conversationAPI.current.start();
    };
    var cancelConversation = function (triggerPendingCall) {
        if (triggerPendingCall === void 0) { triggerPendingCall = true; }
        var traceLog = {
            index: getTraceLogLength(),
            traceLogType: ConversationTraceLogType.Cancel,
            dialog: null,
            attempt: 0,
            isMatched: false,
            matchedIntentName: null,
            topIntentName: null,
            utterenceText: null,
            pathName: null,
            matchingScore: 0,
            message: Constants.ConversationCancelMessage,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null
        };
        updateTraceLogs(traceLog);
        setMessage(fmtMsg({ id: ConversationTestLocale.ConversationTestCancelMessage }));
        setIsConversationCompleted(true);
        // Reset the component to its default state
        reset(false);
    };
    var resetConversationAndShowIntroVideo = function () {
        restartConversation();
        setShowIntroVideo(true);
    };
    var reset = function (resetLog) {
        if (conversationAPI.current) {
            conversationAPI.current.dispose();
        }
        if (isUnmounted) {
            // We can't update state if the component is already unmounted
            return;
        }
        setVisible(false);
        setIsConversationStarted(false);
        setIsListening(false);
        setDialog(null);
        setConversationAPI(null);
        conclusionNodeReached.current = false;
        if (resetLog) {
            clearTraceLogs();
        }
    };
    var clearTraceLogs = function () {
        setIsConversationCompleted(false);
        setConversationTraceLogs([]);
        traceLogCopy.current = [];
    };
    var restartConversation = function () {
        reset(true);
        isUnmounted = false;
        setPlayToggle(!playToggle);
    };
    var onDropdownVisibleChange = function (visible) {
        setBarVisible(visible);
    };
    var onPlaybackRateChange = function (value) {
        updateAppSettings(userId, userId, 1, { slowSpeed: value != 1 });
        setSpeed(value);
    };
    var startAndStopListening = function () {
        if (!isBlinking) {
            conversationAPI.current.startListening();
            setTimeout(function () {
                setIsBlinking(true);
            }, 1000);
        }
        else {
            conversationAPI.current.stopListening();
            setTimeout(function () {
                setIsBlinking(false);
            }, 500);
        }
    };
    return (React.createElement("div", { className: "test-conversation-page" },
        React.createElement(Row, { className: "test-conversation-page-row" },
            React.createElement(Col, { className: "conversation-col" },
                React.createElement("div", { className: "conversation-col-container" },
                    React.createElement("div", { className: "play-button-section" },
                        " ",
                        React.createElement("a", { onClick: restartConversation },
                            React.createElement(Icon, { type: "sync" }),
                            fmtMsg({ id: ConversationTestLocale.ConversationTestPlayBeginningButton }))),
                    visible && (React.createElement("div", { className: "conversation-player" },
                        React.createElement(React.Fragment, null,
                            React.createElement(ConversationPlayer, { isConversationStarted: isConversationStarted, isListening: isListening, dialog: dialog, loading: loading, startConversation: startConversation, cancelConversation: cancelConversation, resetConversationAndShowIntroVideo: resetConversationAndShowIntroVideo, ref: jonnyBearRef, playbackRate: speed, startAndStopListening: startAndStopListening, isBlinking: isBlinking }),
                            React.createElement(ConversationPlaySetting, { playbackRate: speed, onDropdownVisibleChange: onDropdownVisibleChange, onPlaybackRateChange: onPlaybackRateChange })))),
                    !visible && message && (React.createElement("div", { className: "message-section" },
                        React.createElement("span", null, message)))))),
        showIntroVideo && React.createElement(ConversationIntroPlayer, { playbackRate: speed, onConversationIntroClose: function () { setShowIntroVideo(false); } })));
};
export var TestConversationPage = withRouter(TestConversationPageComponent);
