var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbFallbackGestures = /** @class */ (function (_super) {
    __extends(JbFallbackGestures, _super);
    function JbFallbackGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            {
                id: "FallbackGesture#1",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(600),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(900),
                ],
            },
            {
                id: "FallbackGesture#2",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltBlink(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                ],
            },
            {
                id: "FallbackGesture#3",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltBlink(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                ],
            },
            {
                id: "FallbackGesture#4",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(400),
                ],
            },
            {
                id: "FallbackGesture#5",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                ],
            },
            {
                id: "FallbackGesture#6",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(400),
                ],
            },
            {
                id: "FallbackGesture#7",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                ],
            },
            {
                id: "FallbackGesture#8",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                ],
            },
            {
                id: "FallbackGesture#9",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                ],
            },
            {
                id: "FallbackGesture#10",
                styleId: JbInteractionStyles.Fallback,
                animations: [
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.DownTiltFromNeutral(),
                    new JbAnimations.DownTiltToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                ],
            },
        ];
        return _this;
    }
    return JbFallbackGestures;
}(GestureBase));
export { JbFallbackGestures };
