var JbWaveCycle = /** @class */ (function () {
    function JbWaveCycle(
    /**
     * @duration in milliseconds
     */
    duration) {
        this.duration = duration;
        this.frames = [53, 54, 55, 56, 57, 58, 59, 60];
    }
    return JbWaveCycle;
}());
export { JbWaveCycle };
