var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '../../util/di';
import VerificationServiceBase from '../app/servicebase/verificationservicebase';
var DeviceService = /** @class */ (function (_super) {
    __extends(DeviceService, _super);
    function DeviceService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'account/v1/devices', suffix: '{id}' };
        return _this;
    }
    DeviceService.prototype.deactivate = function (params, route) {
        return this.create("{id}/deactivationRequests", params, route);
    };
    DeviceService.prototype.getDeactivationCount = function (params) {
        return this.get("{userId}/deactivationRequests", params);
    };
    DeviceService.prototype.trackDeviceLogin = function (params) {
        return this.createWithoutLoader("{userId}/trackdevicelogin", params);
    };
    DeviceService = __decorate([
        injectable()
    ], DeviceService);
    return DeviceService;
}(VerificationServiceBase));
export { DeviceService };
