var JbTiltRightFromNeutral = /** @class */ (function () {
    function JbTiltRightFromNeutral() {
        this.frames = [
            1,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107
        ];
    }
    return JbTiltRightFromNeutral;
}());
export { JbTiltRightFromNeutral };
