import { isMobileOrTablet, IEVersion, addMediaAttributesForIOS, polyfillNodeList } from "@app/util";
import { MediaDomDisplay } from ".";
export function resetDimensions() {
    var posterImg = document.querySelector('#videojs-audio-player .vjs-poster img');
    posterImg && setDimensions(posterImg.width, posterImg.height);
    var video = document.querySelector('#videojs-video-player video');
    if (video) {
        var _a = flexVideo(video), width = _a.width, height = _a.height;
        setDimensions(width, height);
    }
}
export function setDimensions(width, height) {
    if (width === 0 || height === 0) {
        return;
    }
    width = width + "px";
    var mediaplayerController = document.querySelector('.mediaplayer-controlbar');
    if (mediaplayerController) {
        mediaplayerController.style.width = width;
        mediaplayerController.style.top = height - mediaplayerController.clientHeight + "px";
    }
    var mediaScreens = document.querySelectorAll('.media-screen-dimensions');
    for (var i = 0; mediaScreens && i < mediaScreens.length; i++) {
        var mediaScreen = mediaScreens[i];
        mediaScreen.style.width = width;
        mediaScreen.style.height = height + "px";
    }
    if (isMobileOrTablet()) {
        var mediaplayers = document.querySelectorAll('.mediaplayer');
        for (var i = 0; mediaplayers && i < mediaplayers.length; i++) {
            var mediaplayer = mediaplayers[i];
            mediaplayer.style.height = height + "px";
        }
    }
    else {
        var mediaplayers = document.querySelectorAll('.mediaplayer');
        for (var i = 0; mediaplayers && i < mediaplayers.length; i++) {
            var mediaplayer = mediaplayers[i];
            mediaplayer.style.removeProperty("height");
        }
    }
    var wrapper = document.querySelector('.mediaplayer-wrapper');
    var setCenter = function (val) {
        wrapper.style.paddingTop = val;
        for (var i = 0; i < mediaScreens.length; i++) {
            var mediaScreen = mediaScreens[i];
            mediaScreen.style.top = val;
        }
    };
    var formatCenterVal = function (val) { return wrapper.clientHeight > val ? (wrapper.clientHeight - val) / 2 + "px" : '0'; };
    setCenter(formatCenterVal(window.innerWidth >= window.innerHeight ? 575 : height));
}
export function flexVideo(video) {
    if (window.innerWidth >= window.innerHeight) {
        var wrapper = document.querySelector('.mediaplayer-wrapper');
        video.style.height = '100%';
        video.style.width = '100%';
        var calcDimensions = function (height) {
            video.style.height = height + "px";
            return { width: calcDomWidth(height), height: height };
        };
        return calcDimensions(wrapper.clientHeight >= 575 ? 575 : wrapper.clientHeight);
    }
    else {
        video.style.width = '100%';
        video.style.height = calcVideoHeight(video);
        return { width: video.clientWidth, height: video.clientHeight };
    }
}
export function calcVideoWidth(video) {
    return calcDomWidth(video.clientHeight) + "px";
}
export function calcDomWidth(height) {
    return height * 1.78;
}
export function calcDomHeight(width) {
    return width / 1.78;
}
export function calcVideoHeight(video) {
    return calcDomHeight(video.clientWidth) + "px";
}
export function controlMediaDom(type, player) {
    var videoMediaDom = document.getElementById('videojs-video-player');
    var audioMediaDom = document.getElementById('videojs-audio-player');
    var audioBackgroundMediaDom = document.getElementById('background-audio');
    videoMediaDom.style.display = 'none';
    audioMediaDom.style.display = 'none';
    audioBackgroundMediaDom.style.display = 'none';
    switch (type) {
        case MediaDomDisplay.visible:
            var videoDom = videoMediaDom.querySelector('video');
            var audioDom = audioMediaDom.querySelector('video');
            videoDom && (videoDom.style.display = 'block');
            audioDom && (audioDom.style.display = 'block');
            break;
        case MediaDomDisplay.audioShow:
            var posterImg_1 = document.querySelector('#videojs-audio-player .vjs-poster img');
            var imgLoad_1 = function () {
                // posterImg.naturalHeight && (audioMediaDom.style.maxHeight = `${posterImg.naturalHeight}px`);
                audioMediaDom.style.maxHeight = '575px';
                audioMediaDom.style.maxWidth = '1024px';
                setDimensions(posterImg_1.width, posterImg_1.height);
                //fix controller size changed when img loaded. --start
                var video = document.querySelector('#videojs-video-player video');
                var _a = flexVideo(video), width = _a.width, height = _a.height;
                setDimensions(width, height);
                //fix controller size changed when img loaded. --end
                posterImg_1.removeEventListener('load', imgLoad_1);
            };
            posterImg_1.addEventListener('load', imgLoad_1);
            audioMediaDom.style.display = 'block';
            break;
        case MediaDomDisplay.videoShow:
            var resetDimensions_1 = function () {
                var video = document.querySelector('#videojs-video-player video');
                video.style.maxHeight = videoMediaDom.style.maxHeight = '575px';
                video.style.maxWidth = videoMediaDom.style.maxWidth = '1024px';
                var _a = flexVideo(video), width = _a.width, height = _a.height;
                setDimensions(width, height);
            };
            var loadstart_1 = function () {
                resetDimensions_1();
                player.off('loadstart', loadstart_1);
            };
            player.on('loadstart', loadstart_1);
            var loadeddata_1 = function () {
                resetDimensions_1();
                player.off('loadeddata', loadeddata_1);
            };
            player.on('loadeddata', loadeddata_1);
            videoMediaDom.style.display = 'block';
            break;
    }
}
export function clearAudioPoster(callback) {
    var posterImg = document.querySelector('#videojs-audio-player .vjs-poster img');
    callback && callback();
}
export function random() {
    return Math.random() > 0.5 ? -1 : 1;
}
function twoDigits(d) {
    if (d <= 0) {
        return '00';
    }
    ;
    d = Math.floor(d);
    return d < 10 ? "0" + d : d;
}
export function getTime(time) {
    return twoDigits(time / 3600) + ":" + twoDigits(time % 3600 / 60) + ":" + twoDigits(time % 60);
}
export function setMediaInlineForIOS() {
    if (IEVersion() == -1) {
        setAttribuiteForIOS('video');
        setAttribuiteForIOS('audio');
    }
}
export function setAttribuiteForIOS(query) {
    var doms = polyfillNodeList(document.querySelectorAll(query));
    doms.forEach(function (dom) {
        addMediaAttributesForIOS(dom.id);
        dom.setAttribute("playsinline", "true");
    });
}
