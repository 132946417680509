var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import classNames from 'classnames';
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, connect, GLGlobal } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { Button, Icon } from "antd-min";
import { lazyInject, TYPES, ContentGroupEnum, PlayerRole, RoleHelper, contentGroupAdapter } from "@app/util";
import LibraryCard, { PlayTarget } from "./components/librarycard";
import Tabs from "../../components/tabs";
import Toolbar from "@app/components/toolbar/toolbar";
import UnitButton from "../components/unitbutton";
import { PagePermission } from "./extra/permission";
import { showHeaderFooter } from "../components/hidenheaderfooter";
import HeartBeat from "../components/heartbeat";
import { getMySetting } from "@app/service/logs/uploadplaylistlogs";
import { PlayerControlBar } from "./components/player-control-bar";
import { PlayAction } from "@app/components/media/playlistmediaplayer";
import IPodImage from "@app/style/images/mp3.svg";
var tabHeaderClassNames = {
    "library": "allcontent-icon",
    "song": "song-icon",
    "movie": "movie-icon",
    "story": "story-icon",
    "songStory": "song-icon",
};
var tabSelection;
(function (tabSelection) {
    tabSelection[tabSelection["songStory"] = 0] = "songStory";
    tabSelection[tabSelection["movie"] = 1] = "movie";
})(tabSelection || (tabSelection = {}));
var LibraryPage = /** @class */ (function (_super) {
    __extends(LibraryPage, _super);
    function LibraryPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.getContents = function () {
            var _a = _this.state, songStory = _a.songStory, movie = _a.movie;
            var songStories = songStory && songStory.length > 0 ? { songStory: _this.getSpecialContent("songStory") } : {};
            var movies = movie && movie.length > 0 ? { movie: _this.getSpecialContent("movie") } : {};
            var contents = __assign({}, songStories, movies);
            return contents;
        };
        _this.getSpecialContent = function (key) {
            var content = _this.state[key];
            return React.createElement("div", { className: key + "Tab" },
                React.createElement("div", { className: "cards" }, content && content.length > 0 && content.map(function (content) { return (React.createElement(LibraryCard, { key: content.id, state: _this.state, history: _this.props.history, content: content, type: content.contentGroup, getSelection: _this.getSelection, role: _this.state.role, playcount: content.playCount, playTarget: content.contentGroup == ContentGroupEnum.Movie ?
                        PlayTarget.NewPage :
                        _this.state.collapseControlBar ? PlayTarget.NewPage : PlayTarget.CurrentPage, beginPlay: _this.beginPlay.bind(_this) })); })));
        };
        _this.getPlayerContents = function (selectIndex) {
            var songStory = _this.state.songStory;
            switch (selectIndex) {
                case tabSelection.movie:
                    return null;
                case tabSelection.songStory:
                default:
                    return songStory;
            }
        };
        _this.getSelection = function () {
            return _this.state.selection;
        };
        _this.onTabChanged = function (selectIndex, selectKey) {
            _this.setState({
                selection: selectIndex,
                currentPlayerContent: _this.getPlayerContents(selectIndex),
                stopPlayer: true,
            });
        };
        _this.playIntroduction = function () {
            var _a = _this.props, history = _a.history, _b = _a.match.params, userId = _b.userId, versionId = _b.versionId, unit = _b.unit, leftData = _a.leftData;
            var path = "library";
            var userName = leftData ? leftData.name : "";
            history.push({ pathname: GLUtil.pathStringify(PathConfig.Welcome, { userId: userId, versionId: versionId, unit: unit, path: path }), search: GLUtil.queryStringify({ userName: userName }) });
        };
        var _a = _this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
        var selection = GLUtil.queryParse().selection;
        _this.state = {
            userId: userId,
            role: PlayerRole.Digital,
            versionId: versionId,
            unit: unit,
            signature: null,
            unitcontents: null,
            playerContent: null,
            currentPlayerContent: null,
            library: null,
            songStory: null,
            movie: null,
            selection: selection,
            playbackRate: 1,
            pausePlayer: false,
            stopPlayer: false,
            collapseControlBar: true,
            nextPlayingItem: null,
            hideTabBar: false,
            badgePercentage: 0
        };
        return _this;
    }
    LibraryPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, userId, versionId, unit, selection, role, signature, contents, log, _b, rest, maxUnit, settings, unitcontents, libraryContents, library, songStory, movie, logs, badgePercentage;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        maskThrottle();
                        _a = this.state, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, selection = _a.selection;
                        role = GLGlobal.store.getState().theme.leftData.role;
                        this.setState({ role: role });
                        if (!PagePermission(userId, unit)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.contentsignatureappservice.getSignatures(null, { versionId: versionId })];
                    case 1:
                        signature = _c.sent();
                        return [4 /*yield*/, this.unitsContentsService.getUnitsContents({ units: [Math.abs(unit)] }, { versionId: versionId })];
                    case 2:
                        contents = _c.sent();
                        if (!(unit > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.playlistlogAppService.getPlaylistlog({ unit: unit, studentId: userId })];
                    case 3:
                        _b = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        _b = null;
                        _c.label = 5;
                    case 5:
                        log = _b;
                        rest = GLGlobal.store.getState().theme.leftData;
                        maxUnit = rest.unit || rest.lsUnit;
                        return [4 /*yield*/, getMySetting(this.settingService, GLGlobal.store.getState().theme.leftData.id, maxUnit)];
                    case 6:
                        settings = _c.sent();
                        unitcontents = { unit: contents[0].unit, size: contents[0].size, contents: contents[0].contents, hasLogs: false };
                        unitcontents.contents.map(function (content) {
                            content.thumbnailUrl += signature.pageContainer;
                            content.contentGroup = ContentGroupEnum[content.contentGroup];
                        });
                        return [4 /*yield*/, this.addLogs()];
                    case 7:
                        _c.sent();
                        libraryContents = unitcontents.contents
                            .filter(function (content) { return content.contentGroup != ContentGroupEnum.VPC; })
                            .reduce(contentGroupAdapter, []);
                        library = libraryContents;
                        songStory = libraryContents
                            .filter(function (content) { return content.contentGroup == ContentGroupEnum.Song || content.contentGroup == ContentGroupEnum.Story; })
                            .sort(function (contentA, contentB) { return contentA.sequence - contentB.sequence; });
                        movie = libraryContents
                            .filter(function (content) { return content.contentGroup == ContentGroupEnum.Movie; })
                            .sort(function (contentA, contentB) { return contentA.sequence - contentB.sequence; });
                        logs = log && log.logs ? JSON.parse(log.logs) : [];
                        badgePercentage = log ?
                            Math.min(1, logs.length / 10 / 2 + (log.round - 1) * 0.5)
                            : 0;
                        this.setState({
                            library: library,
                            songStory: songStory,
                            movie: movie,
                            badgePercentage: badgePercentage,
                            collapseControlBar: role != PlayerRole.Text,
                            unitcontents: unitcontents,
                            signature: signature,
                            playerContent: contents,
                            currentPlayerContent: songStory,
                            playbackRate: settings.mySetting.appSettings.slowSpeed ? 0.7 : 1,
                            hideTabBar: songStory.length == 0 || movie.length == 0
                        });
                        unmaskThrottle();
                        showHeaderFooter();
                        return [2 /*return*/];
                }
            });
        });
    };
    LibraryPage.prototype.componentWillReceiveProps = function (nextProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.props.leftData && (this.props.leftData.role !== nextProps.leftData.role))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.addLogs()];
                    case 1:
                        _a.sent();
                        this.setState({ role: nextProps.leftData.role });
                        this.componentDidMount();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LibraryPage.prototype.addLogs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, unit, unitcontents, contentplaylogs, contentPlayCounts_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.state, unit = _a.unit, unitcontents = _a.unitcontents;
                        if (!(RoleHelper.isStudent(this.state.role) && unitcontents && !unitcontents.hasLogs)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contentplaylogsAppService.getLogs({ unit: unit }, { studentId: this.state.userId })];
                    case 1:
                        contentplaylogs = _b.sent();
                        contentPlayCounts_1 = contentplaylogs ? contentplaylogs.contents : null;
                        unitcontents.contents.map(function (content) {
                            for (var index = 0; contentPlayCounts_1 && index < contentPlayCounts_1.length; index++) {
                                var countlog = contentPlayCounts_1[index];
                                if (content.id == countlog.contentId) {
                                    content.playCount = countlog.playCount;
                                    break;
                                }
                            }
                        });
                        unitcontents.hasLogs = true;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LibraryPage.prototype.getTabHeaderFromKey = function (key) {
        return React.createElement("div", { className: "tabheader " + tabHeaderClassNames[key] });
    };
    LibraryPage.prototype.onPlayControlBarCollapse = function () {
        this.setState({
            collapseControlBar: !this.state.collapseControlBar,
            pausePlayer: !this.state.collapseControlBar,
        });
    };
    LibraryPage.prototype.afterPlayeActionChanged = function (action) {
        switch (action) {
            case PlayAction.pause:
                this.setState({
                    pausePlayer: false
                });
                break;
            case PlayAction.stop:
            default:
                this.setState({
                    stopPlayer: false
                });
                break;
        }
    };
    LibraryPage.prototype.afterPlayed = function () {
        this.setState({
            nextPlayingItem: null
        });
    };
    LibraryPage.prototype.beginPlay = function (content) {
        this.setState({
            nextPlayingItem: content
        });
    };
    LibraryPage.prototype.render_left = function () {
        var _a = this.state, userId = _a.userId, unit = _a.unit, badgePercentage = _a.badgePercentage;
        return (React.createElement(UnitButton, { history: this.props.history, unit: unit, userId: userId, from: "library", isShowBadge: true, percentage: badgePercentage }));
    };
    LibraryPage.prototype.render_right = function (tabs) {
        var className = this.state.hideTabBar ? "ant-card-no-body hide-tab-bar" : "ant-card-no-body";
        return (React.createElement("div", { className: "tabcontainer" },
            React.createElement("div", { className: className },
                React.createElement(Tabs, { tabs: tabs, contents: {}, selectedIndex: this.state.selection, onTabChanged: this.onTabChanged }))));
    };
    LibraryPage.prototype.renderIpodIcon = function () {
        return React.createElement(Icon, { component: IPodImage, className: "ipod" });
    };
    LibraryPage.prototype.render = function () {
        var _this = this;
        var _a = this.props, history = _a.history, location = _a.location, match = _a.match;
        var _b = this.state, userId = _b.userId, selection = _b.selection, versionId = _b.versionId, unit = _b.unit, signature = _b.signature, role = _b.role, unitcontents = _b.unitcontents, playerContent = _b.playerContent, currentPlayerContent = _b.currentPlayerContent, playbackRate = _b.playbackRate, pausePlayer = _b.pausePlayer, stopPlayer = _b.stopPlayer, collapseControlBar = _b.collapseControlBar, nextPlayingItem = _b.nextPlayingItem;
        var contents = this.getContents();
        var tabs = [];
        contents && Object.keys(contents).map(function (objk) {
            tabs.push({ key: objk, tab: _this.getTabHeaderFromKey(objk) });
        });
        var playerControlBarClassName = {
            playerControlBar: true,
            collapseToolbar: collapseControlBar
        };
        var showAudioPlayBar = selection != tabSelection.movie && unitcontents && currentPlayerContent && currentPlayerContent.length > 0;
        return tabs.length > 0 && React.createElement("div", { className: "library" },
            React.createElement(HeartBeat, { userId: userId },
                React.createElement("div", { className: "palylisttoolbar" },
                    React.createElement(Toolbar, { leftContent: this.render_left(), midContent: this.render_right(tabs), rightContent: showAudioPlayBar && role != PlayerRole.Text ?
                            React.createElement("div", { className: "right-content" },
                                React.createElement("div", { className: "intro-img", onClick: this.playIntroduction }),
                                React.createElement(Button, { type: "primary", className: 'player-btn collapse-btn player-btn--wrap', onClick: this.onPlayControlBarCollapse.bind(this) }, collapseControlBar
                                    ? React.createElement(React.Fragment, null,
                                        React.createElement(Icon, { type: "down" }),
                                        " ",
                                        this.renderIpodIcon())
                                    : React.createElement(React.Fragment, null,
                                        React.createElement(Icon, { type: "up" }),
                                        " ",
                                        this.renderIpodIcon()))) :
                            React.createElement("div", { className: "intro-img", onClick: this.playIntroduction }) })),
                showAudioPlayBar && React.createElement("div", { className: classNames({
                        'player-toolbar': true,
                        collapseToolbar: collapseControlBar
                    }) },
                    React.createElement(Toolbar, { key: selection, leftContent: null, rightContent: null, midContent: React.createElement(PlayerControlBar, __assign({ className: classNames(playerControlBarClassName), userId: userId, versionId: versionId, unit: unit, signature: signature, unitcontents: playerContent, cds: currentPlayerContent, playbackRate: playbackRate, pausePlayer: pausePlayer, stopPlayer: stopPlayer, nextPlayingItem: nextPlayingItem, afterPlayeActionChanged: this.afterPlayeActionChanged.bind(this), afterPlayed: this.afterPlayed.bind(this) }, { history: history, location: location, match: match })) })),
                React.createElement("div", { className: "tabcontainer" },
                    React.createElement("div", { className: "ant-card-no-head" },
                        React.createElement(Tabs, { tabs: tabs, contents: contents, selectedIndex: selection, onTabChanged: this.onTabChanged })))));
    };
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], LibraryPage.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsContentsService)
    ], LibraryPage.prototype, "unitsContentsService", void 0);
    __decorate([
        lazyInject(TYPES.IContentplaylogsAppService)
    ], LibraryPage.prototype, "contentplaylogsAppService", void 0);
    __decorate([
        lazyInject(TYPES.IPlaylistlogsAppService)
    ], LibraryPage.prototype, "playlistlogAppService", void 0);
    __decorate([
        lazyInject(TYPES.ISettingService)
    ], LibraryPage.prototype, "settingService", void 0);
    LibraryPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], LibraryPage);
    return LibraryPage;
}(Component));
export default LibraryPage;
