import { AzureAppSlot } from ".";
import { LUISRuntimeClient } from "@azure/cognitiveservices-luis-runtime";
import { ApiKeyCredentials } from "@azure/ms-rest-js";
import { isNull, isUndefined } from "lodash";
var AzureLuisCognitiveService = /** @class */ (function () {
    function AzureLuisCognitiveService(predKey, predictionEndpoint) {
        var cred = new ApiKeyCredentials({ inHeader: { "Ocp-Apim-Subscription-Key": predKey } });
        this.runtimeClient = new LUISRuntimeClient(cred, predictionEndpoint);
    }
    AzureLuisCognitiveService.prototype.getData = function (appId, queryText, predictionSlot) {
        var predictionRequest = {
            query: queryText
        };
        var verbose = true;
        var showAllIntents = true;
        if (isUndefined(predictionSlot) || isNull(predictionSlot)) {
            predictionSlot = AzureAppSlot.Production;
        }
        return this.runtimeClient.prediction.getSlotPrediction(appId, predictionSlot, predictionRequest, {
            verbose: verbose,
            showAllIntents: showAllIntents
        });
    };
    return AzureLuisCognitiveService;
}());
export { AzureLuisCognitiveService };
