import { PathConfig } from "@app/config/pathconfig";
import { useActionCreator, useSelector } from "@app/hooks";
import { fetchUnitPendingConversationAction } from "@app/states/conversation";
import JonnybearIcon from "@app/style/images/jonnybear-icon.png";
import { Avatar, List, Popover } from "antd";
import { GLUtil, mergeClasses } from "gl-commonui";
import React, { useEffect } from "react";
import { getChildrenRegion } from "@app/util";
import { PlayPlayer } from "@app/components/svg-icon";
import "./conversation-header-icon.less";
export var ConversationHeaderIcon = function (props) {
    var studentId = props.studentId, versionId = props.versionId, insideDropdown = props.insideDropdown, currentUnit = props.currentUnit;
    var fetchUnitPendingConversation = useActionCreator(fetchUnitPendingConversationAction);
    var unitPendingConversation = useSelector(function (state) { return state.conversation.unitPendingConversations; });
    useEffect(function () {
        var allChildren = getChildrenRegion();
        var currentChild = allChildren.filter(function (c) { return c.id == studentId; })[0];
        if (currentUnit &&
            studentId &&
            currentChild &&
            currentChild.repMethodologyVersionId) {
            fetchUnitPendingConversation({ unit: currentUnit, studentId: studentId, repMethodologyVersionId: currentChild.repMethodologyVersionId });
        }
    }, [currentUnit, studentId]);
    return (React.createElement("span", { className: mergeClasses("cnv-pending", insideDropdown && "cnv-pending--inside-dropdown"), style: {
            marginLeft: '15px'
        } }, unitPendingConversation.length ? (React.createElement(Popover, { trigger: "click", placement: "bottom", content: React.createElement(List, { itemLayout: "horizontal", dataSource: unitPendingConversation, renderItem: function (item) { return (React.createElement(List.Item, { className: "cnv-pending__list" },
                React.createElement(List.Item.Meta, { className: "cnv-pending__list--item", avatar: React.createElement(Avatar, { src: item.imageUrl, className: "cnv-pending__list--itemicon" }), title: React.createElement("a", { href: GLUtil.pathStringify(PathConfig.ConversationPlayer, {
                            versionId: versionId,
                            userId: studentId,
                            unit: currentUnit,
                            playlistId: item.playListId,
                            conversationId: item.conversationId
                        }) },
                        React.createElement(PlayPlayer, { className: "cnv-pending__list--playericon" })) }))); } }) },
        React.createElement("span", { style: { cursor: "pointer" } },
            React.createElement("img", { className: "cnv-pending__icon", src: JonnybearIcon })))) : null));
};
