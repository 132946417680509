var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
var Portal = function (_a) {
    var id = _a.id, children = _a.children;
    var el = useRef(document.getElementById(id) || document.createElement('div'));
    var _b = __read(useState(!el.current.parentElement), 1), dynamic = _b[0];
    useEffect(function () {
        if (dynamic) {
            el.current.id = id;
            document.body.appendChild(el.current);
        }
        return function () {
            if (dynamic && el.current.parentElement) {
                el.current.parentElement.removeChild(el.current);
            }
        };
    }, [id]);
    return createPortal(children, el.current);
};
var PortalContainer = memo(Portal);
export default PortalContainer;
