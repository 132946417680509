import React, { useEffect } from "react";
import { GLGlobal, withRouter } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
export var StudentLogin = withRouter(function (props) {
    useEffect(function () {
        var loginInfo = GLGlobal.loginInfo();
        if (loginInfo && loginInfo.loggedin) {
            // redirect to home
            props.history.replace(PathConfig.Home);
        }
        else {
            localStorage.setItem("codeloginstart", "true");
            props.history.replace(PathConfig.Home);
        }
    }, []);
    return React.createElement(React.Fragment, null);
});
