var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { Progress } from "antd-min";
import { PlayAction } from "./playlistmediaplayer";
import PlayerButton from "./player-button";
import { getPausePlayer, getStopPlayer, ContentGroupEnum, IEVersion, polyfillComp, PolyfillCompKey, rewind } from "@app/util";
import { isAutoPlayAlertShow, hideAutoplayAlert } from "@app/states/autoplayalert";
import { pausePlaylistPlayer, isPlaylistPlayerPaused } from "@app/states/playlistplayer";
import FullScreenButton from "@app/page/components/fullscreen/full-screen-button";
import PlaySetting from "@app/page/components/speedcontroller/speed-control-dropdown";
import { connect } from "gl-commonui";
var PlaylistControlContainer = /** @class */ (function (_super) {
    __extends(PlaylistControlContainer, _super);
    function PlaylistControlContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.showSpeedControl = function () {
            return _this.props.content && _this.props.content.contentGroup === ContentGroupEnum.ConversationIntroVideo;
        };
        _this.onDropdownVisibleChange = function (visible) {
            _this.props.onDropdownVisibleChange && _this.props.onDropdownVisibleChange(visible);
        };
        _this.onPlaybackRateChange = function (value) {
            _this.props.onPlaybackRateChange && _this.props.onPlaybackRateChange(value);
        };
        _this.onCaptionShowChange = function (visible) {
            _this.props.onCaptionShowChange && _this.props.onCaptionShowChange(visible);
        };
        _this.showControlBarOnReaderContent = function () {
            var _a = _this.props, content = _a.content, isReaderAction = _a.isReaderAction;
            return content.skillTypeId && !isReaderAction.isReaderAction;
        };
        // hide rewind button if content is Question, VPC, Conversation or Reader
        _this.hideRewindButton = function () {
            var contentGroups = [
                ContentGroupEnum.Conversation,
                ContentGroupEnum.Question,
                ContentGroupEnum.VPC
            ];
            var content = _this.props.content;
            // return contentGroups.indexOf(content.contentGroup) !== -1 || content.skillTypeId !== null ;
            return content === undefined || contentGroups.indexOf(content.contentGroup) !== -1 || content.skillTypeId !== null;
            // return content === undefined || contentGroups.includes(content.contentGroup) || content.skillTypeId !== null
        };
        _this.onRewind = function (seek, time) {
            event.stopPropagation();
            return rewind(seek, time);
        };
        _this.state = {
            pausePressed: isPlaylistPlayerPaused(),
        };
        return _this;
    }
    PlaylistControlContainer.prototype.componentDidMount = function () {
        polyfillComp(PolyfillCompKey.ScreenControl);
    };
    PlaylistControlContainer.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.paused !== nextProps.paused && nextProps.paused) {
            this.setState({ pausePressed: true });
        }
    };
    PlaylistControlContainer.prototype.renderControlButton = function () {
        var _this = this;
        var _a = this.props, paused = _a.paused, isPaused = _a.isPaused, play = _a.play, pause = _a.pause, stop = _a.stop, content = _a.content, playSequence = _a.playSequence;
        var pausePressed = this.state.pausePressed;
        var playBtn = function () { return (React.createElement(PlayerButton, { iconType: pausePressed ? PlayAction.play : PlayAction.pause, onClick: function () {
                if (pausePressed) {
                    if (!content.description.exts || !playSequence()) {
                        play();
                    }
                    _this.setState({ pausePressed: false });
                    isAutoPlayAlertShow() && hideAutoplayAlert();
                    pausePlaylistPlayer(false);
                }
                else {
                    pause();
                    setAudio(getPausePlayer());
                    _this.setState({ pausePressed: true });
                    pausePlaylistPlayer(true);
                }
            } })); };
        // const playing = content.description.contentPrefixPos && !pausePressed;
        var playing = false;
        return (React.createElement(React.Fragment, null,
            playing ? null : playBtn(),
            React.createElement(PlayerButton, { iconType: PlayAction.stop, onClick: function () { return stopAudio(pause, stop); } })));
    };
    PlaylistControlContainer.prototype.render = function () {
        var _a = this.props, remainingCount = _a.remainingCount, total = _a.total, controlOnScreen = _a.controlOnScreen, content = _a.content, seek = _a.seek, time = _a.times.time;
        var fixRemainingCount = function () { return content.description.contentPrefixPos ? 1 : 0; };
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "playlist-controlcontainer control-bar-container media-screen-dimensions" },
                controlOnScreen && !content.skillTypeId && React.createElement("div", { className: "screen-control " + (IEVersion() != -1 ? "IE_EDGE_FIX_STYLE" : "") }, this.renderControlButton()),
                (!content.skillTypeId || this.showControlBarOnReaderContent()) && React.createElement("div", { className: "controlbar mediaplayer-controlbar" },
                    React.createElement(Progress, { percent: percent(total - remainingCount + fixRemainingCount(), total), format: function () { return total - remainingCount + fixRemainingCount() + "/" + total; } }),
                    !controlOnScreen && this.renderControlButton(),
                    React.createElement(PlaySetting, { hasCaption: true, skillTypeId: content.skillTypeId, captionShow: this.props.captionShow, playbackRate: this.props.playbackRate, onDropdownVisibleChange: this.onDropdownVisibleChange, onCaptionShowChange: this.onCaptionShowChange, onPlaybackRateChange: this.onPlaybackRateChange, showSpeedControls: this.showSpeedControl() }),
                    React.createElement(FullScreenButton, null))),
            React.createElement("audio", { id: 'playlist-audio', style: { display: 'none' }, src: getStopPlayer() }));
    };
    PlaylistControlContainer.displayName = 'PlaylistControlContainer';
    PlaylistControlContainer = __decorate([
        connect(function (_a) {
            var _b = _a.playlistplayer, paused = _b.paused, isReaderAction = _b.isReaderAction;
            return ({
                paused: paused,
                isReaderAction: isReaderAction
            });
        })
    ], PlaylistControlContainer);
    return PlaylistControlContainer;
}(Component));
export default PlaylistControlContainer;
function percent(progress, total) {
    if (progress === void 0) { progress = 0; }
    if (total === void 0) { total = 1; }
    return Math.floor((progress / total) * 100);
}
function setAudio(src) {
    var audio = document.getElementById('playlist-audio');
    audio.src = src;
    audio.play();
}
function stopAudio(pause, stop) {
    pause();
    setAudio(getStopPlayer());
    setTimeout(stop, 500);
}
