var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { withRouter } from "gl-commonui";
import ContentPlayer from "@app/components/media/contentplayer";
import SliderControlContainer from "@app/components/media/slider-controlcontainer";
var PlayContentsPage = /** @class */ (function (_super) {
    __extends(PlayContentsPage, _super);
    function PlayContentsPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            resources: {},
            signature: {}
        };
        return _this;
    }
    PlayContentsPage.prototype.componentDidMount = function () {
        var data = {}; //getData()[0];
        var signature = {
            "pageContainer": "?sv=2017-04-17&sr=c&sig=NXjG0pS4OFss0uwCfsSdqNo%2BCUDVDKfMn2P7gG2pMQE%3D&st=2019-04-18T06%3A41%3A34Z&se=2019-04-18T08%3A41%3A34Z&sp=r",
            "audioContainer": "?sv=2017-04-17&sr=c&sig=%2BjNEMnMB2Lvio1HKiAQsUPD0nOGv5MwHlFNE0FL%2Bchw%3D&st=2019-04-18T06%3A41%3A34Z&se=2019-04-18T08%3A41%3A34Z&sp=r",
            "videoContainer": "?sv=2017-04-17&sr=c&sig=R79gpS3dYZ6JLOlobxBXhT2FV%2F1OYOI4RgnFmd4b4u4%3D&st=2019-04-18T06%3A41%3A34Z&se=2019-04-18T08%3A41%3A34Z&sp=r"
        };
        this.setState({
            resources: data, signature: signature
        });
    };
    PlayContentsPage.prototype.componentWillUnmount = function () {
    };
    PlayContentsPage.prototype.render = function () {
        return React.createElement("div", null,
            React.createElement(ContentPlayer, { visibleBar: true, visibleMedia: true, resources: this.state.resources, signature: this.state.signature, playlistId: '54a98cc1-ade2-47d8-b0bd-70610ce7fe52' },
                React.createElement(SliderControlContainer, { showTitle: true })));
    };
    PlayContentsPage = __decorate([
        withRouter
    ], PlayContentsPage);
    return PlayContentsPage;
}(Component));
export default PlayContentsPage;
