var TYPES = {
    IPlaylistConnectService: Symbol("IPlaylistConnectService"),
    IHeartBeatService: Symbol("IHeartBeatService"),
    ICdDvdsService: Symbol("ICdDvdsService"),
    IStudentaccessunitService: Symbol("IStudentaccessunitService"),
    IAccessunitService: Symbol("IAccessunitService"),
    ISettingService: Symbol("ISettingService"),
    IContentplaylogsAppService: Symbol("IContentplaylogsAppService"),
    IVpcsthumbnailAppService: Symbol("IVpcsthumbnailAppService"),
    IUnitsPlaylistsService: Symbol("IUnitsPlaylistsService"),
    IUnitsContentsService: Symbol("IUnitsContentsService"),
    IPlaylistlogsAppService: Symbol("IPlaylistlogsAppService"),
    IContentsignatureAppService: Symbol("IContentsignatureAppService"),
    IParentAppService: Symbol("IParentAppService"),
    IParentService: Symbol("IParentService"),
    IStudentService: Symbol("IStudentService"),
    IDeviceService: Symbol("IDeviceService"),
    IResourceService: Symbol("IResourceService"),
    IUserService: Symbol("IUserService"),
    IContentService: Symbol("IContentService"),
    ICaptionService: Symbol("ICaptionService"),
    IAnswerService: Symbol("IAnswerService"),
    IPuzzleService: Symbol("IPuzzleService"),
    IStudentAppService: Symbol("IStudentAppService"),
    IConversationAdminService: Symbol("IConversationAdminService"),
    IReportService: Symbol("IReportService"),
    IRegionService: Symbol("IRegionService"),
    IRemoteService: Symbol("IRemoteService"),
    IActiveLearnService: Symbol("IActiveLearnService"),
    IConversationIntroService: Symbol("IConversationIntroService"),
    INotificationService: Symbol("INotificationService")
};
export { TYPES };
