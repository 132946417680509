var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '@app/util/di';
import { GLServiceBase } from 'gl-commonui';
var NotificationService = /** @class */ (function (_super) {
    __extends(NotificationService, _super);
    function NotificationService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'admin/v1/notifications', itemSuffix: '{id}' };
        return _this;
    }
    NotificationService.prototype.createArchived = function (id) {
        return this.create('{id}/archived', { id: id });
    };
    NotificationService.prototype.countAllNotificationsUnRead = function (params) {
        return this.get('CountNotifications', params);
    };
    NotificationService.prototype.markAsReadForAll = function () {
        return this.create('MarkAsRead');
    };
    NotificationService.prototype.markAsReadForOne = function (notificationId) {
        return this.create('MarkAsRead', [notificationId]);
    };
    NotificationService.prototype.markAsArchived = function () {
        return this.create('MarkNotificationsAsArchived');
    };
    NotificationService = __decorate([
        injectable()
    ], NotificationService);
    return NotificationService;
}(GLServiceBase));
export { NotificationService };
