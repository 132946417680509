import React, { useEffect } from "react";
import { Button, Card, Modal } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import { Link } from "react-router-dom";
import { AudioWrapper } from "@app/components/puzzle/puzzle-audios";
import { getUnitReward } from "@app/util";
import "./index.less";
var UnitReward = function (_a) {
    var history = _a.history, versionId = _a.versionId, userId = _a.userId, unit = _a.unit, round = _a.round, puzzleFullImage = _a.puzzleFullImage, duration = _a.duration, isRoundCompleted = _a.isRoundCompleted, puzzleId = _a.puzzleId, onClose = _a.onClose;
    useEffect(function () {
        var timerId = setTimeout(function () {
            if (onClose)
                onClose();
        }, duration);
        new AudioWrapper(getUnitReward()).play();
        return function () { return clearTimeout(timerId); };
    }, []);
    var onClickRewardBadge = function () {
        history.push({
            pathname: GLUtil.pathStringify(PathConfig.Units, { userId: userId }),
            search: GLUtil.queryStringify({
                from: "playlist"
            })
        });
    };
    var puzzlePlayerScreen = {
        pathname: GLUtil.pathStringify(PathConfig.PuzzlePlayer, {
            userId: userId,
            versionId: versionId,
            puzzleId: puzzleId
        })
    };
    return (React.createElement(Modal, { bodyStyle: { padding: 0 }, visible: true, centered: true, footer: null, closable: false, onCancel: onClose, destroyOnClose: true },
        React.createElement(Card, { className: "playlist-reward-card" },
            React.createElement("div", { className: "content-wrapper" },
                isRoundCompleted ? React.createElement("div", { onClick: onClickRewardBadge, className: "reward-badge student-badge student-badge-round" + round + "-reward" }) : null,
                puzzleFullImage ? React.createElement("div", { className: "puzzle-wrapper" },
                    React.createElement(Link, { to: puzzlePlayerScreen },
                        React.createElement("img", { src: puzzleFullImage, className: "puzzle-image", alt: "rewarded puzzle image" }))) : null),
            React.createElement(Button, { className: "btn-close", shape: "circle", size: "large", icon: "close", type: "danger", onClick: onClose }))));
};
export default UnitReward;
