var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import { Tooltip } from "wijmo/wijmo";
import { _NewRowTemplate } from "wijmo/wijmo.grid";
import { toggleClass } from "wijmo/wijmo";
import { last } from "lodash";
import { useWijmoGridValidCheck } from "./useWijmoGridValidCheck";
function flatGridColumns(columns) {
    return Array.isArray(columns)
        ? columns.reduce(function (flated, columns) { return flated.concat(flatGridColumns(columns)); }, [])
        : [columns];
}
export function useWijmoValidators(grid, children) {
    var _a = __read(useState({}), 2), validators = _a[0], setValidators = _a[1];
    var tooltip = new Tooltip({ showDelay: 0, cssClass: "wj-error-tip" });
    var validCheck = useWijmoGridValidCheck(grid);
    useEffect(function () {
        var validators = {};
        flatGridColumns(children)
            .filter(function (child) { return child && child.props && child.props.binding && child.props.validators && child.props.validators.length; })
            .forEach(function (child) {
            validators[child.props.binding] = child.props.validators;
        });
        setValidators(validators);
    }, [children]);
    useEffect(function () {
        if (grid) {
            grid.cellEditEnded.addHandler(cellEditEnded);
            grid.pastedCell.addHandler(pastedCell);
            grid.formatItem.addHandler(formatItem);
            grid.updatingView.addHandler(updatingView);
            grid.loadedRows.addHandler(loadedRows);
        }
    }, [grid]);
    // Validate when a cell is being edited
    var cellEditEnded = function (grid, event) {
        setError(grid, event.row, event.col);
        grid.invalidate();
    };
    // Validate when a cell being pasted
    var pastedCell = function (grid, event) {
        setError(grid, event.row, event.col);
        grid.invalidate();
    };
    // Vilidate a last row when new row has been created
    var loadedRows = function (grid, event) {
        var lastRow = getLastRow(grid);
        if (lastRow) {
            setError(grid, lastRow.index, 0);
            grid.invalidate();
        }
    };
    var getLastRow = function (grid) {
        if (grid.rows.length) {
            var lastRow = last(grid.rows);
            if (!(lastRow instanceof _NewRowTemplate)) {
                return lastRow;
            }
            if (grid.rows.length > 1) {
                return grid.rows[grid.rows.length - 2];
            }
        }
        return null;
    };
    // Sets errors to grid based on the validators passed in <Column /> components.
    var setError = function (flex, row, col) {
        var dataItem = grid.collectionView.items[row];
        flex.columns.forEach(function (column, columnIndex) {
            var _a;
            var binding = grid.columns[columnIndex].binding;
            var columnValidators = validators[binding];
            if (columnValidators && columnValidators.length) {
                var errorMessage_1 = null;
                columnValidators.every(function (validator) {
                    var message = validator(dataItem[binding], dataItem, grid);
                    if (message) {
                        errorMessage_1 = message;
                        return false;
                    }
                    return true;
                });
                if (!dataItem.errors) {
                    dataItem.errors = {};
                }
                if (errorMessage_1) {
                    dataItem.errors = __assign({}, dataItem.errors, (_a = {}, _a[binding] = errorMessage_1, _a));
                }
                else if (dataItem.errors && dataItem.errors[binding]) {
                    delete dataItem.errors[binding];
                }
            }
        });
    };
    // Shows error tooltips
    var formatItem = function (flex, event) {
        if (!Object.keys(validators).length) {
            return false;
        }
        if (event.panel === flex.cells) {
            var dataItem = flex.rows[event.row].dataItem;
            var binding = flex.columns[event.col].binding;
            var error = dataItem && dataItem.errors && dataItem.errors[binding];
            toggleClass(event.cell, "wj-state-invalid", !!error);
            if (error) {
                tooltip.setTooltip(event.cell, error);
            }
        }
        if (event.panel === flex.rowHeaders) {
            var dataItem = flex.rows[event.row].dataItem;
            var hasErrors = dataItem && dataItem.errors && !!Object.keys(dataItem.errors).length;
            toggleClass(event.cell, "wj-state-invalid", !!hasErrors);
        }
    };
    // Disposing error tooltips
    var updatingView = function () {
        tooltip.dispose();
    };
    // A public method top trigger validation manually on all of rows.
    var validate = function () {
        grid.rows.forEach(function (row, index) {
            if (!(row instanceof _NewRowTemplate)) {
                setError(grid, index, 0);
            }
        });
        grid.invalidate();
        validCheck.manualTrigger();
    };
    var validateRow = function (rowIndex) {
        setError(grid, rowIndex, 0);
    };
    return { validate: validate, isValid: validCheck.isValid, validateRow: validateRow };
}
