var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React, { Component } from "react";
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, GLGlobal, connect } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { List, notification } from "antd-min";
import { lazyInject, TYPES } from "@app/util";
import Tabs from "../../components/tabs";
import CDplayer from "./cdplayer";
import Toolbar from "@app/components/toolbar/toolbar";
import DVDControlContainer from "./components/dvdpalyercontroller";
import UnitButton from "../components/unitbutton";
import { PagePermission } from "./extra/permission";
import { PlayAction, PlayMode } from "@app/components/media";
import { showHeaderFooter } from "../components/hidenheaderfooter";
import HeartBeat from "../components/heartbeat";
import { getMySetting } from "@app/service/logs/uploadplaylistlogs";
var tabHeaderClassNames = {
    "cds": "song-icon",
    "dvds": "movie-icon",
};
var ListenPage = /** @class */ (function (_super) {
    __extends(ListenPage, _super);
    function ListenPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.getContents = function () {
            var _a = _this.state, cds = _a.cds, dvds = _a.dvds;
            var cdscontent = cds && cds.length > 0 ? { cds: _this.getCDsContent() } : {};
            var dvdscontent = dvds && dvds.length > 0 ? { dvds: _this.getDVDsContent() } : {};
            var contents = __assign({}, cdscontent, dvdscontent);
            return contents;
        };
        _this.getCDsContent = function () {
            var key = "cds";
            var content = _this.state[key];
            var size = Math.ceil(content.length / 2);
            var isPlayingState = _this.state.cdPlayAction == PlayAction.play;
            return React.createElement("div", { className: key + "_tab" },
                React.createElement("div", { className: "cards" },
                    React.createElement("ol", { key: key + "_list_left", className: "list" }, content.slice(0, size).map(function (item) {
                        return React.createElement("li", { key: item.id, onClick: function () { _this.playCD(item); } },
                            React.createElement("a", null,
                                item.name,
                                React.createElement("div", { className: "playing-icon-base " + (isPlayingState && item.isPlaying ? "isPlaying" : "") })));
                    })),
                    React.createElement("ol", { key: key + "_list_right", className: "list", start: size + 1 }, content.slice(size).map(function (item) {
                        return React.createElement("li", { key: item.id, onClick: function () { _this.playCD(item); } },
                            React.createElement("a", null,
                                item.name,
                                React.createElement("div", { className: "playing-icon-base " + (isPlayingState && item.isPlaying ? "isPlaying" : "") })));
                    }))));
        };
        _this.getDVDsContent = function () {
            var key = "dvds";
            var content = _this.state[key];
            var size = Math.ceil(content.length / 2);
            return React.createElement("div", { className: key + "_tab" },
                React.createElement("div", { className: "cards" },
                    React.createElement("ul", { key: key + "_list_left", className: "list" }, content.slice(0, size).map(function (item) {
                        return React.createElement("li", { key: item.id, onClick: function () { _this.playDVD(content, item); } },
                            React.createElement("a", null, item.name));
                    })),
                    React.createElement("ul", { key: key + "_list_right", className: "list" }, content.slice(size).map(function (item) {
                        return React.createElement("li", { key: item.id, onClick: function () { _this.playDVD(content, item); } },
                            React.createElement("a", null, item.name));
                    }))));
        };
        _this.onTabChanged = function (selectIndex, selectKey) {
            _this.setState({ selection: { index: selectIndex, key: selectKey } });
            _this.setState({ cdPlayAction: PlayAction.stop });
            _this.setCDItemStatus(null, false, true);
        };
        _this.onCDItemStarted = function (id) {
            _this.state.cdPlayAction == PlayAction.play && _this.setCDItemStatus(id, true);
        };
        _this.onCDItemEnded = function (id) {
            _this.setCDItemStatus(id, false);
        };
        _this.onCDPlay = function (id) {
            _this.setState({ cdPlayAction: PlayAction.play });
            _this.setCDItemStatus(id, true);
        };
        _this.setCDItemStatus = function (id, isPlaying, isclean) {
            if (_this.state && _this.state.cds) {
                var cds = _this.state.cds;
                if (isPlaying || isclean) {
                    cds.forEach(function (item) { return item.isPlaying = false; });
                }
                var cd = cds.find(function (cd) { return cd.id == id; });
                if (cd) {
                    cd.isPlaying = isPlaying;
                    _this.setState({ cds: cds });
                }
            }
        };
        _this.onCDModeChanged = function (mode) {
            _this.setState({ cdMode: mode });
        };
        _this.changeMode = function (mode) {
            _this.setState({ dvdMode: mode });
        };
        var _a = _this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
        var dvdMode = GLUtil.queryParse().mode;
        _this.state = {
            userId: userId,
            versionId: versionId,
            unit: unit,
            cds: null,
            dvds: null,
            selection: { index: 0, key: null },
            unitcontents: null,
            signature: null,
            selectedsequence: null,
            cdMode: PlayMode.order,
            dvdMode: dvdMode,
            cdPlayAction: PlayAction.stop,
            playbackRate: 1,
        };
        return _this;
    }
    ListenPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var selectionIndex, _a, userId, versionId, unit, cddvds, signature, unitcontents, contents, selectedIndex, keys, selectedKey, _b, id, rest, maxUnit, settings;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        maskThrottle();
                        selectionIndex = GLUtil.queryParse().selection;
                        _a = this.state, userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
                        if (!PagePermission(userId, unit)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.cdDvdsService.getCdDvds({ units: [Math.abs(unit)] }, { versionId: versionId })];
                    case 1:
                        cddvds = _c.sent();
                        return [4 /*yield*/, this.contentsignatureappservice.getSignatures(null, { versionId: versionId })];
                    case 2:
                        signature = _c.sent();
                        return [4 /*yield*/, this.unitsContentsService.getUnitsContents({ units: [Math.abs(unit)] }, { versionId: versionId })];
                    case 3:
                        unitcontents = _c.sent();
                        contents = { cds: [], dvds: [] };
                        contents.cds = cddvds.cds.map(function (cd) {
                            return unitcontents[0].contents.find(function (content) { return content.id == cd; });
                        });
                        contents.dvds = cddvds.dvds.map(function (dvd) {
                            return unitcontents[0].contents.find(function (content) { return content.id == dvd; });
                        });
                        this.setState({ cds: contents.cds, dvds: contents.dvds, unitcontents: unitcontents, signature: signature });
                        selectionIndex = selectionIndex ? selectionIndex : 0;
                        selectedIndex = Math.min(selectionIndex, (contents.cds.length > 0 ? 1 : 0) + (contents.dvds.length > 0 ? 1 : 0) - 1);
                        keys = Object.keys(tabHeaderClassNames);
                        selectedKey = selectedIndex < 1 ? (contents.cds.length > 0 ? keys[0] : keys[1]) : keys[selectedIndex];
                        this.setState({ selection: { index: selectedIndex, key: selectedKey } });
                        _b = GLGlobal.store.getState().theme.leftData, id = _b.id, rest = __rest(_b, ["id"]);
                        maxUnit = rest.unit || rest.lsUnit;
                        return [4 /*yield*/, getMySetting(this.settingService, id, maxUnit)];
                    case 4:
                        settings = _c.sent();
                        this.setState({
                            playbackRate: settings.mySetting.appSettings.slowSpeed ? 0.7 : 1
                        });
                        unmaskThrottle();
                        showHeaderFooter();
                        return [2 /*return*/];
                }
            });
        });
    };
    ListenPage.prototype.componentWillReceiveProps = function (nextProps) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.props.leftData && (this.props.leftData.role !== nextProps.leftData.role)) {
                    this.componentDidMount();
                }
                return [2 /*return*/];
            });
        });
    };
    ListenPage.prototype.renderListItem = function (item) {
        return (React.createElement(List.Item, { key: item.id },
            React.createElement("ol", null, item.name)));
    };
    ListenPage.prototype.playCD = function (item) {
        var _this = this;
        if (item && item.id) {
            this.setState({ selectedsequence: item.id, cdPlayAction: PlayAction.play });
            window.setTimeout(function () {
                _this.setState({ selectedsequence: null });
            }, 100);
        }
        else {
            notification.warn({ message: 'There are nothing to paly' });
        }
    };
    ListenPage.prototype.playDVD = function (contents, item) {
        if (contents && contents.length > 0) {
            var _a = this.state, userId = _a.userId, unit = _a.unit, versionId = _a.versionId, selection = _a.selection, dvdMode = _a.dvdMode;
            var ids = contents.map(function (content) { return content.id; });
            this.props.history.push({ pathname: GLUtil.pathStringify(PathConfig.DVDPlayer, { userId: userId, unit: unit, versionId: versionId, sequence: item.id }), search: GLUtil.queryStringify({ contents: ids, selection: selection.index, mode: dvdMode }) });
        }
        else {
            notification.warn({ message: 'There are nothing to paly' });
        }
    };
    ListenPage.prototype.getTabHeaderFromKey = function (key) {
        return React.createElement("div", { className: "tabheader " + tabHeaderClassNames[key] });
    };
    ListenPage.prototype.render_left = function () {
        return (React.createElement(UnitButton, { history: this.props.history, unit: this.state.unit, userId: this.state.userId, from: "listen", isShowBadge: false }));
    };
    // render_mid() {
    //     const { selection, cdPlayAction } = this.state;
    //     const display_none = { display: "none" };
    //     const display_flex = { display: "flex" };
    //     const display_block = { display: "block" };
    //     const key = "dvds";
    //     const content = this.state[key];
    //     const show_dvd = selection.key == "dvds";
    //     return (
    //         <div style={{ width: "100%" }}>
    //             <div className="mediaplayer-wrapper dvd_control_panel" style={show_dvd ? display_flex : display_none}>
    //                 <DVDControlContainer
    //                     mode={this.state.dvdMode}
    //                     play={() => { this.playDVD(content, content[0]) }}
    //                     onModeChanged={(mode) => { this.changeMode(mode); }} />
    //             </div>
    //             <div className="control_panel cd_control_panel" style={show_dvd ? display_none : display_block}>
    //                 <CDplayer
    //                     userId={this.state.userId}
    //                     versionId={this.state.versionId}
    //                     unit={this.state.unit}
    //                     contents={this.state.cds.map(cd => cd.id) as any}
    //                     unitcontents={this.state.unitcontents as any}
    //                     signature={this.state.signature}
    //                     sequence={this.state.selectedsequence}
    //                     playAction={cdPlayAction}
    //                     onItemStarted={this.onCDItemStarted}
    //                     onItemEnded={this.onCDItemEnded}
    //                     onPlay={this.onCDPlay}
    //                 />
    //             </div>
    //         </div>
    //     );
    // }
    ListenPage.prototype.render_mid = function () {
        var _this = this;
        var _a = this.state, selection = _a.selection, cdPlayAction = _a.cdPlayAction;
        var key = "dvds";
        var content = this.state[key];
        var show_dvd = selection.key == "dvds";
        if (show_dvd) {
            return (React.createElement("div", { style: { width: "100%" } },
                React.createElement("div", { className: "mediaplayer-wrapper dvd_control_panel" },
                    React.createElement(DVDControlContainer, { mode: this.state.dvdMode, play: function () { _this.playDVD(content, content[0]); }, onModeChanged: function (mode) { _this.changeMode(mode); } }))));
        }
        else {
            return (React.createElement("div", { style: { width: "100%" } },
                React.createElement("div", { className: "control_panel cd_control_panel" },
                    React.createElement(CDplayer, { userId: this.state.userId, versionId: this.state.versionId, unit: this.state.unit, contents: this.state.cds.map(function (cd) { return cd.id; }), unitcontents: this.state.unitcontents, signature: this.state.signature, sequence: this.state.selectedsequence, playMode: this.state.cdMode, onModeChanged: this.onCDModeChanged, playAction: cdPlayAction, onItemStarted: this.onCDItemStarted, onItemEnded: this.onCDItemEnded, onPlay: this.onCDPlay, playbackRate: this.state.playbackRate }))));
        }
    };
    ListenPage.prototype.render_right = function (tabs) {
        return (React.createElement("div", { className: "tabcontainer" },
            React.createElement("div", { className: "ant-card-no-body" },
                React.createElement(Tabs, { tabs: tabs, contents: {}, selectedIndex: this.state.selection.index, onTabChanged: this.onTabChanged }))));
    };
    ListenPage.prototype.render = function () {
        var _this = this;
        var selection = this.state.selection;
        var contents = this.getContents();
        var tabs = [];
        contents && Object.keys(contents).map(function (objk) {
            tabs.push({ key: objk, tab: _this.getTabHeaderFromKey(objk) });
        });
        return tabs.length > 0 && React.createElement("div", { className: "listen" },
            React.createElement(HeartBeat, { userId: this.state.userId },
                React.createElement("div", { className: "palylisttoolbar" },
                    React.createElement(Toolbar, { leftContent: this.render_left(), midContent: this.render_mid(), rightContent: this.render_right(tabs) })),
                React.createElement("div", { className: "tabcontainer" },
                    React.createElement("div", { className: "ant-card-no-head" },
                        React.createElement(Tabs, { tabs: tabs, contents: contents, selectedIndex: selection.index, onTabChanged: this.onTabChanged })))));
    };
    __decorate([
        lazyInject(TYPES.ICdDvdsService)
    ], ListenPage.prototype, "cdDvdsService", void 0);
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], ListenPage.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsContentsService)
    ], ListenPage.prototype, "unitsContentsService", void 0);
    __decorate([
        lazyInject(TYPES.ISettingService)
    ], ListenPage.prototype, "settingService", void 0);
    ListenPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], ListenPage);
    return ListenPage;
}(Component));
export default ListenPage;
