var JbSadToNeutral = /** @class */ (function () {
    function JbSadToNeutral() {
        this.frames = [
            24,
            23,
            22,
            21,
            20,
            19,
            18,
            17,
            16,
            15,
            1
        ];
    }
    return JbSadToNeutral;
}());
export { JbSadToNeutral };
