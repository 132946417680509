var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbIdleGestures = /** @class */ (function (_super) {
    __extends(JbIdleGestures, _super);
    function JbIdleGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            {
                id: "IdleGesture#1",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                ],
            },
            {
                id: "IdleGesture#2",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                ],
            },
            {
                id: "IdleGesture#3",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(500),
                ],
            },
            {
                id: "IdleGesture#4",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                ],
            },
            {
                id: "IdleGesture#5",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                ],
            },
            {
                id: "IdleGesture#6",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(700),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                ],
            },
            {
                id: "IdleGesture#7",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                ],
            },
            {
                id: "IdleGesture#8",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                ],
            },
            {
                id: "IdleGesture#9",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(400),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(600),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(800),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1000),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(900),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(400),
                ],
            },
            {
                id: "IdleGesture#10",
                styleId: JbInteractionStyles.Idle,
                animations: [
                    new JbAnimations.Neutral(900),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(1100),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(700),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(500),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(400),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(400),
                ],
            },
        ];
        return _this;
    }
    return JbIdleGestures;
}(GestureBase));
export { JbIdleGestures };
