import { mergeClasses } from "@app/util";
import React from "react";
import { useReaderFocusWord } from "../../hooks/useReaderFocusWords";
import AudioPlayer from "../audio-player";
import "./focusword-content-card.less";
import { SpellBookIcon, PhotoIcon, SpeakerIcon } from "@app/components/svg-icon";
export var FocusWordContentCard = function (_a) {
    var focusWordContents = _a.focusWordContents;
    var _b = useReaderFocusWord(focusWordContents), showDefinition = _b.showDefinition, showImage = _b.showImage, onToggleDefinition = _b.onToggleDefinition, onToggleImage = _b.onToggleImage, selectedFocusWord = _b.selectedFocusWord, imageUrl = _b.imageUrl, onAudioEnded = _b.onAudioEnded, wordAudio = _b.wordAudio, definitionAudio = _b.definitionAudio, definitionAudioPlayerRef = _b.definitionAudioPlayerRef, textAudioPlayerRef = _b.textAudioPlayerRef, wordAudioUrl = _b.wordAudioUrl, definitionAudioUrl = _b.definitionAudioUrl, handleTextAudioPlayPause = _b.handleTextAudioPlayPause, handleDefinitionAudioPlayPause = _b.handleDefinitionAudioPlayPause;
    return (React.createElement("div", { className: mergeClasses("focuswordcontent", "reader-bg") },
        React.createElement("div", { className: "focuswordcontent__header" }, selectedFocusWord.name),
        React.createElement("div", { className: "focuswordcontent__maincontent" },
            React.createElement("div", { className: "focuswordcontent__definition" },
                showDefinition
                    ? selectedFocusWord.focusWordDefinition
                    : null,
                showDefinition && selectedFocusWord.focusWordDefinition ? (React.createElement("span", { className: mergeClasses("focuswordcontent__controls__btn", "btnImage__definition", !definitionAudioUrl
                        ? "focuswordcontent__controls__btn--disabled"
                        : ""), onClick: handleDefinitionAudioPlayPause },
                    React.createElement(SpeakerIcon, null))) : null),
            React.createElement("div", { className: "focuswordcontent__image" }, showImage ? React.createElement("img", { src: imageUrl }) : null)),
        React.createElement("div", { className: "focuswordcontent__controls" },
            React.createElement("span", { className: mergeClasses("focuswordcontent__controls__btn", !wordAudioUrl
                    ? "focuswordcontent__controls__btn--disabled"
                    : ""), onClick: handleTextAudioPlayPause },
                React.createElement(SpeakerIcon, null)),
            React.createElement("span", { className: mergeClasses("focuswordcontent__controls__btn", !imageUrl
                    ? "focuswordcontent__controls__btn--disabled"
                    : ""), onClick: onToggleImage },
                React.createElement(PhotoIcon, null)),
            React.createElement("span", { className: mergeClasses("focuswordcontent__controls__btn", !selectedFocusWord.focusWordDefinition
                    ? "focuswordcontent__controls__btn--disabled"
                    : ""), onClick: onToggleDefinition },
                React.createElement(SpellBookIcon, null))),
        React.createElement(AudioPlayer, { audioUrl: wordAudio, onAudioEnded: onAudioEnded, ref: textAudioPlayerRef }),
        React.createElement(AudioPlayer, { audioUrl: definitionAudio, onAudioEnded: onAudioEnded, ref: definitionAudioPlayerRef })));
};
