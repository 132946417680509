var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React, { Component } from "react";
import { getTick } from "./func";
import { Icon } from "antd-min";
import debounce from "lodash/debounce";
import "./style/subtitle.less";
import { ContentGroupEnum, polyfillNodeList } from "@app/util";
import { MediaExtType } from "../media/playlistmediaplayer";
export var allowedProps = { translate: "no" };
var MultiSpeakerLineNumber = 3;
var SingleSpeakerLineNumber = 1;
var SubtitleLineMarginBottom = 10;
var MultiSpeakerGentleShowIndex = [0, 1, 2];
var SingleSpeakerGentleShowIndex = [0];
var Subtitle = /** @class */ (function (_super) {
    __extends(Subtitle, _super);
    function Subtitle(props) {
        var _this = _super.call(this, props) || this;
        _this.onResize = null;
        _this.resetSubtitle = null;
        _this.speakers = {};
        _this.speakLine = {};
        _this.visibleLines = [];
        _this.subtitleDom = null;
        _this.subtitleLineDoms = null;
        _this.subtitleContainerDom = null;
        _this.speakerLineNumber = SingleSpeakerLineNumber;
        _this.isMultiSpeaker = false;
        _this.resizeThrottle = 0;
        _this.state = {
            lines: [],
        };
        var resize = debounce(_this.resizeSubtitle.bind(_this), 100);
        _this.onResize = function () {
            _this.transformSubtitle();
            resize();
        };
        _this.resetSubtitle = function () {
            _this.initSubtitle(_this.props);
        };
        return _this;
    }
    Subtitle.prototype.componentDidMount = function () {
        this.initSubtitle(this.props);
        this.props.events.addEventListener('currentEnded', this.resetSubtitle);
        window.addEventListener('resize', this.onResize);
    };
    Subtitle.prototype.componentWillUnmount = function () {
        this.props.events.removeEventListener('currentEnded', this.resetSubtitle);
        window.removeEventListener('resize', this.onResize);
    };
    Subtitle.prototype.componentWillReceiveProps = function (nextProps) {
        var time = nextProps.times.time, subtitle = nextProps.subtitle;
        var lines = this.state.lines;
        var tick = Math.floor(time * 1000);
        if (subtitle && subtitle != this.props.subtitle && subtitle.lines) {
            this.initSubtitle(nextProps);
        }
        if (this.speakLine && this.speakLine.startTick <= tick) {
            var speakIndex_1 = this.speakLine.index;
            var nextIndex = speakIndex_1 + 1;
            this.subtitleLineDoms.forEach(function (dom) {
                var speakerVoice = dom.querySelector('.speaker-voice');
                speakerVoice.className = speakerVoice.className.replace(/\s?\bspeaker-voice-show\b\s?/g, '');
            });
            var subtitleLine = this.subtitleLineDoms[speakIndex_1];
            var speakerVoice = subtitleLine.querySelector('.speaker-voice');
            speakerVoice.className = speakerVoice.className + " speaker-voice-show";
            var gentleShowLineIndexex = this.isMultiSpeaker ? MultiSpeakerGentleShowIndex : SingleSpeakerGentleShowIndex;
            if (gentleShowLineIndexex.some(function (index) { return speakIndex_1 === index; })) {
                subtitleLine.className = subtitleLine.className + " show-gentle";
            }
            else {
                subtitleLine.className = subtitleLine.className + " show";
                this.moveOutSubtitle();
                this.setVisibleLines(speakIndex_1);
            }
            this.setContainerHeight();
            this.speakLine = lines[nextIndex];
        }
        var endLine = lines[lines.length - 1];
        if (this.isMultiSpeaker && endLine.enableHidden && endLine.endTick <= tick && !this.subtitleDom.className.includes('hidden-ends')) {
            this.subtitleDom.className = this.subtitleDom.className + " hidden-ends";
        }
        var showedLine = this.speakLine ? lines[this.speakLine.index - 1] : endLine;
        if (!this.isMultiSpeaker && showedLine && showedLine.enableHidden && showedLine.endTick <= tick && !this.subtitleLineDoms[showedLine.index].className.includes('hidden-gentle')) {
            var dom = this.subtitleLineDoms[showedLine.index];
            dom.className = dom.className + " hidden-gentle";
        }
    };
    Subtitle.prototype.initSubtitle = function (props) {
        var _this = this;
        this.formatSubtitle(props, function () {
            _this.subtitleDom = document.querySelector(".subtitle");
            _this.subtitleLineDoms = polyfillNodeList(document.querySelectorAll(".subtitle .line"));
            _this.subtitleContainerDom = document.querySelector(".subtitle-container");
            _this.resetDoms();
            _this.setStartEndLine();
            _this.setSpeakers();
            _this.setVisibleLines();
            _this.setContainerHeight();
        });
    };
    Subtitle.prototype.resetDoms = function () {
        this.subtitleDom.className = this.subtitleDom.className.replace(/\s?\bhidden-ends\b\s?/g, '');
        this.subtitleDom.style.transform = "translateY(0px)";
        this.subtitleLineDoms.forEach(function (dom) {
            dom.className = dom.className
                .replace(/\s?\bhidden-gentle\b\s?/g, '')
                .replace(/\s?\bshow(-gentle)?\b\s?/g, '');
        });
    };
    Subtitle.prototype.formatSubtitle = function (props, callback) {
        var subtitle = props.subtitle, pageTimings = props.pageTimings;
        if (!subtitle || !subtitle.lines) {
            return;
        }
        var lines = JSON.parse(subtitle.lines)
            .map(function (line) {
            return Object.getOwnPropertyNames(line).reduce(function (newLine, key) {
                var newKey = key.substring(0, 1).toLowerCase() + key.substring(1);
                newLine[newKey] = line[key];
                return newLine;
            }, {});
        })
            .sort(function (left, right) { return left.sequence - right.sequence; })
            .map(function (line) {
            line.startTick = getTick(line.startTime);
            line.endTick = getTick(line.endTime);
            line.speaker = line.speaker ? line.speaker : 'none_speeak';
            return line;
        })
            .map(function (line, index, lines) {
            line.index = index;
            if (index < lines.length - 1) {
                var preEndTick_1 = line.endTick;
                var nextStartTick_1 = lines[index + 1].startTick;
                var hasFlipPage = pageTimings && pageTimings.some(function (pageTiming) {
                    return preEndTick_1 <= pageTiming
                        && pageTiming <= nextStartTick_1
                        && Math.abs(pageTiming - nextStartTick_1) > 1000;
                });
                var hasLongSpace = (nextStartTick_1 - preEndTick_1) >= 6000;
                line.enableHidden = hasFlipPage || hasLongSpace;
            }
            return line;
        });
        this.setState({ lines: lines }, callback);
    };
    Subtitle.prototype.setStartEndLine = function () {
        var lines = this.state.lines;
        this.speakLine = lines[0];
        lines[lines.length - 1].enableHidden = true;
    };
    Subtitle.prototype.resetVisibleSubtitle = function () {
        var moveOutLineIndex = this.visibleLines[0].index - 1;
        var translateHeight = this.getMoveOutSubtitleLineHeight(moveOutLineIndex);
        this.subtitleDom.style.transform = "translateY(" + -translateHeight + "px)";
    };
    Subtitle.prototype.moveOutSubtitle = function () {
        var lastVisibleLineIndex = this.visibleLines[0].index;
        var translateHeight = this.getMoveOutSubtitleLineHeight(lastVisibleLineIndex);
        this.subtitleDom.style.transform = "translateY(" + -translateHeight + "px)";
    };
    Subtitle.prototype.getMoveOutSubtitleLineHeight = function (lastMoveOutLineIndex) {
        var moveOutLineHeight = 0;
        while (lastMoveOutLineIndex >= 0) {
            moveOutLineHeight += (this.subtitleLineDoms[lastMoveOutLineIndex].clientHeight + SubtitleLineMarginBottom);
            lastMoveOutLineIndex--;
        }
        return moveOutLineHeight;
    };
    Subtitle.prototype.setVisibleLines = function (moveInIndex) {
        var lines = this.state.lines;
        if (moveInIndex && lines[moveInIndex]) {
            this.visibleLines.shift();
            this.visibleLines.push(lines[moveInIndex]);
        }
        else if (!moveInIndex) {
            this.visibleLines = lines.slice(0, this.speakerLineNumber);
        }
    };
    Subtitle.prototype.setContainerHeight = function () {
        var _this = this;
        var containerHeight = this.visibleLines.reduce(function (height, line) { return height + _this.subtitleLineDoms[line.index].clientHeight + SubtitleLineMarginBottom; }, 0);
        this.subtitleContainerDom.style.height = containerHeight + "px";
    };
    Subtitle.prototype.setSpeakers = function () {
        var lines = this.state.lines;
        this.speakers = lines
            .reduce(function (speakers, line) {
            !speakers[line.speaker] && (speakers[line.speaker] = "speak" + (Object.keys(speakers).length + 1));
            return speakers;
        }, {});
        this.isMultiSpeaker = Object.keys(this.speakers).length > 1;
        this.speakerLineNumber = this.isMultiSpeaker ? MultiSpeakerLineNumber : SingleSpeakerLineNumber;
    };
    Subtitle.prototype.transformSubtitle = function () {
        this.setContainerHeight();
        this.resetVisibleSubtitle();
    };
    Subtitle.prototype.resizeSubtitle = function () {
        var _this = this;
        var resize = function () {
            if (_this.resizeThrottle-- > 0) {
                _this.transformSubtitle();
                requestAnimationFrame(resize);
            }
        };
        this.resizeThrottle = 20;
        resize();
    };
    Subtitle.prototype.render = function () {
        var _this = this;
        var visible = this.props.visible;
        var lines = this.state.lines;
        return (React.createElement("div", { className: "subtitle-layer media-screen-dimensions " + (visible ? '' : 'hidden') },
            React.createElement("div", { className: "subtitle-container" },
                React.createElement("div", __assign({}, allowedProps, { className: "subtitle" }), lines.map(function (line) { return (React.createElement("div", { key: line.sequence, className: "line " + _this.speakers[line.speaker] },
                    React.createElement(SubtitleVoice, { className: "speaker-voice" }),
                    line.lineText)); })))));
    };
    Subtitle.displayName = 'Subtitle';
    return Subtitle;
}(Component));
export default Subtitle;
var Subtitles = /** @class */ (function (_super) {
    __extends(Subtitles, _super);
    function Subtitles() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Subtitles.prototype.render = function () {
        var _a = this.props, visible = _a.visible, subtitles = _a.subtitles, contentPageTimings = _a.contentPageTimings, contentId = _a.contentId, resources = _a.resources, rest = __rest(_a, ["visible", "subtitles", "contentPageTimings", "contentId", "resources"]);
        if (resources && resources.contents) {
            var content = resources.contents.find(function (c) { return c.id === contentId; });
            if (content && content.medias) {
                var mediaTypePlaying_1 = content.contentGroup === ContentGroupEnum.Movie ? MediaExtType.MP4 : MediaExtType.MP3;
                var media_1 = content.medias.find(function (f) { return (f.mediaType === MediaExtType.MP3 && mediaTypePlaying_1 === MediaExtType.MP3)
                    || (f.mediaType === MediaExtType.MP4 && mediaTypePlaying_1 === MediaExtType.MP4); });
                if (media_1) {
                    var subtitle = subtitles.find(function (e) { return e.contentId == contentId && e.mediaId === media_1.id; });
                    var pageTimings = (contentPageTimings.find(function (e) { return e.contentId == contentId; }) || { pageTimings: [] }).pageTimings;
                    return subtitle ? (React.createElement(Subtitle, __assign({ visible: visible, subtitle: subtitle, pageTimings: pageTimings }, rest)))
                        : null;
                }
            }
        }
        return null;
    };
    Subtitles.displayName = 'Subtitles';
    return Subtitles;
}(Component));
export { Subtitles };
function SubtitleVoiceSvg() {
    return React.createElement("svg", { viewBox: "0 0 1280 1024", version: "1.1", xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em" },
        React.createElement("path", { d: "M807.83854165 1030.95568849C1077.88798012 738.98852541 1077.78910317 284.89611818 807.83854165-6.95568846c-10.15136719-10.9753418-27.33947754-11.1895752-37.86987305-0.57678222l-55.98083496 56.42578124c-10.05249023 10.1184082-10.26672363 26.40014648-0.6262207 36.91406251 220.29785156 240.46875 220.34729004 611.88354491-1e-8 852.40173339-9.64050293 10.51391602-9.40979004 26.7956543 0.62622071 36.91406251l55.98083496 56.42578125c10.53039551 10.59631348 27.71850586 10.38208008 37.86987305-0.59326173zM482.99479165 512.00000002c0-58.2550049-47.21374512-105.46875-105.46875-105.46875s-105.46875 47.21374512-105.46875 105.46875 47.21374512 105.46875 105.46875 105.46874999 105.46875-47.21374512 105.46875-105.46874999z m137.75207519 333.98986816c167.97546387-189.94262695 167.79418945-478.25134279 1e-8-667.97973633-10.05249023-11.37084961-27.50427247-11.73339844-38.15002442-0.93933105l-56.01379395 56.75537109c-9.75585938 9.8876953-10.41503906 25.80688477-1.31835937 36.33728027 119.59167481 138.34533692 119.32800293 345.64086914 0 483.65661622-9.09667969 10.53039551-8.4539795 26.44958497 1.31835937 36.33728027l56.01379395 56.75537108c10.64575195 10.81054688 28.11401366 10.43151856 38.15002441-0.92285155z", "p-id": "4225" }));
}
function SubtitleVoice(props) {
    return React.createElement(Icon, __assign({ component: SubtitleVoiceSvg }, props));
}
