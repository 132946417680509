var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { connect } from "react-redux";
import { GLGlobal, GLUtil, setSplash, OidcProxy } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { changeThemeColor } from "@app/util/func";
import { lazyInject, TYPES } from "@app/util";
import { TermOfPolicy } from "./term-policy";
import { MicrophonePermissionDialog } from "./microphone-permission-dialog";
var PageContainer = /** @class */ (function (_super) {
    __extends(PageContainer, _super);
    function PageContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            policyShown: false,
            childrenShown: false,
            policyContent: "",
            loading: false
        };
        _this.shouldShowPolicy = window.location.pathname !== '/oidc/signin';
        _this.handlePolicySubmit = function (accept) {
            _this.setState({ loading: true });
            _this.userService
                .policySubmit(accept)
                .then(function (_) {
                if (accept) {
                    _this.setState({
                        policyShown: false,
                        childrenShown: true,
                        policyContent: "",
                        loading: false
                    });
                    return;
                }
                OidcProxy.signoutRedirect();
            })
                .catch(function (_) {
                _this.setState({ loading: false });
            });
        };
        return _this;
    }
    PageContainer.prototype.componentDidMount = function () {
        var unit = GLUtil.pathParse(PathConfig.Unit).unit;
        changeThemeColor(unit);
    };
    PageContainer.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        if (prevProps.isLogged !== this.props.isLogged && this.props.isLogged) {
            this.userService
                .getPolicyStatus(GLGlobal.intl.locale)
                .then(function (resp) {
                var accepted = resp.accepted, isTermAgreementRequired = resp.isTermAgreementRequired, policyContent = resp.policy;
                var displayPolicy = false;
                if (isTermAgreementRequired && !accepted) {
                    displayPolicy = true;
                }
                if (displayPolicy) {
                    GLGlobal.store.dispatch(setSplash(false));
                    _this.setState({ policyShown: true, policyContent: policyContent });
                }
                else {
                    _this.setState({ childrenShown: true });
                }
            });
        }
    };
    PageContainer.prototype.render = function () {
        var _a = this.state, childrenShown = _a.childrenShown, policyShown = _a.policyShown, policyContent = _a.policyContent, loading = _a.loading;
        var isLogged = this.props.isLogged;
        return (React.createElement(React.Fragment, null, !isLogged || childrenShown ? (React.createElement(React.Fragment, null,
            React.createElement(MicrophonePermissionDialog, null),
            React.createElement("div", { className: "page-container" }, this.props.children))) : (React.createElement(TermOfPolicy, { policyShown: this.shouldShowPolicy && policyShown, policyContent: policyContent, handlePolicySubmit: this.handlePolicySubmit, loading: loading }))));
    };
    __decorate([
        lazyInject(TYPES.IUserService)
    ], PageContainer.prototype, "userService", void 0);
    PageContainer = __decorate([
        connect(function (_a) {
            var loginInfo = _a.oidc.loginInfo;
            return ({
                isLogged: loginInfo && loginInfo.loggedin
            });
        })
    ], PageContainer);
    return PageContainer;
}(Component));
export default PageContainer;
