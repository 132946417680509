var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { GLGlobal, GLUtil, getCountryCode, CountryCode, hasCountryCode, browser, RoleName } from "gl-commonui";
import MobileDetect from "mobile-detect";
import enableInlineVideo from 'iphone-inline-video';
import * as bowser from 'bowser';
import canAutoPlay from 'can-autoplay';
import { MediaExtType } from '@app/components/media';
import { cloneDeep, difference, orderBy, random } from 'lodash';
import { ContentGroupEnum } from '.';
import { Icon } from 'antd-min';
import { Constants } from './constant';
export function couldAutoPlay() {
    return Promise.all([
        canAutoPlay.audio({ inline: true, timeout: 1000 }),
        canAutoPlay.video({ inline: true, timeout: 1000 })
    ]).then(function (_a) {
        var _b = __read(_a, 2), audio = _b[0], video = _b[1];
        if (audio.result && video.result) {
            return Promise.resolve([audio.result, video.result]);
        }
        return Promise.reject([audio.error, video.error]);
    });
}
export function addMediaAttributesForIOS(mediaId) {
    var media = document.getElementById(mediaId);
    if (media) {
        enableInlineVideo(media);
        enableInlineVideo(media, { iPad: true });
    }
}
export function guidEmpty() {
    return '00000000-0000-0000-0000-000000000000';
}
export function newGuid() {
    var u = (new Date()).getTime().toString(16) +
        Math.random().toString(16).substring(2) + "0".repeat(16);
    var guid = u.substr(0, 8) + '-' + u.substr(8, 4) + '-4000-8' +
        u.substr(12, 3) + '-' + u.substr(15, 12);
    return guid;
}
export function isInChina() {
    return getCountryCode() === CountryCode.China;
}
export function getMediaPrefix() {
    return getMediaStorage().mediaprefix;
}
function changeMediaPrefix(src) {
    return isInChina() ? "" + getMediaPrefix() + src.split('/').pop() : src;
}
export function getMediaStorage() {
    return process.env.mediaStorage;
}
export function getVpcBackgroundUrls() {
    return getMediaStorage().vpcbackground.uris.map(function (uri) { return changeMediaPrefix(uri); });
}
export function getVpcBackgrounds() {
    return getVpcBackgroundUrls().map(function (src) { return ({ src: src, id: src, updateTime: '2019-05-10 00:00:00' }); });
}
export function getSwykUrls() {
    return getMediaStorage().swyk.uris.map(function (uri) { return changeMediaPrefix(uri); });
}
export function getSwyk() {
    return getSwykUrls().map(function (src) { return ({ src: src, id: src, updateTime: '2019-05-10 00:00:00' }); });
}
export function getMedias() {
    var media = getMediaStorage().media;
    Object.getOwnPropertyNames(media).forEach(function (key) {
        media[key].uri = changeMediaPrefix(media[key].uri);
    });
    return media;
}
export function getUnit() {
    var unit = getMediaStorage().unit;
    Object.getOwnPropertyNames(unit).forEach(function (key) {
        unit[key].uri = changeMediaPrefix(unit[key].uri);
    });
    return unit;
}
export function getCorrectAnswer() {
    return getMedias()['correct_answer'].uri;
}
export function getPausePlayer() {
    return getMedias()['pause_player'].uri;
}
export function getStopPlayer() {
    return getMedias()['stop_player'].uri;
}
export function getStartPlaylist() {
    return getMedias()['start_playlist'].uri;
}
export function getUnitReward() {
    return getUnit()['BadgeReward'].uri;
}
export function getPuzzleMedias() {
    var puzzle = getMediaStorage().puzzle;
    Object.getOwnPropertyNames(puzzle).forEach(function (key) {
        puzzle[key].uri = changeMediaPrefix(puzzle[key].uri);
    });
    return puzzle;
}
export function isGuid(str) {
    return str && str.match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/) !== null;
}
export function fmtMsg(messageDescriptor, values) {
    return GLGlobal.intl.formatMessage(typeof messageDescriptor === 'string' ? { id: messageDescriptor } : messageDescriptor, values);
}
export function changeThemeColor(unit) {
    var portalTheme = document.querySelector('.portal-theme');
    if (!unit) {
        portalTheme.className = portalTheme.className.replace(/theme-(ls)?\d+/, '');
        return;
    }
    ;
    var themeClass = "theme-" + (unit > 0 ? unit : "ls" + Math.abs(unit));
    if (portalTheme.className.indexOf('theme-') > -1) {
        portalTheme.className = portalTheme.className.replace(/theme-(ls)?\d+/, themeClass);
    }
    else {
        portalTheme.className += " " + themeClass;
    }
}
export function isMobileOrTablet() {
    return (function (md) { return md.mobile() || md.tablet(); })(new MobileDetect(window.navigator.userAgent));
}
export function isMobile() {
    return bowser.mobile || bowser.tablet || (function (md) { return md.mobile(); })(new MobileDetect(window.navigator.userAgent));
}
export function isiPhone() {
    return bowser.iphone;
}
export function isIE() {
    return bowser.msie;
}
export function isEdge() {
    return bowser.msedge;
}
export function isSafari() {
    return bowser.safari;
}
export function isChrome() {
    return bowser.chrome;
}
export function isFirefox() {
    return bowser.firefox;
}
export function isChromeFirefox() {
    return isChrome() || isFirefox();
}
export function isIOS() {
    return bowser.ios;
}
var isIOS9 = isIOS() && bowser.compareVersions(['10.0', bowser.osversion.toString()]) == 1;
var isSafari9 = isSafari() && browser().satisfies({
    macos: {
        safari: "<10.0"
    },
    ios: {
        safari: "<10.0"
    }
});
export var PolyfillCompKey;
(function (PolyfillCompKey) {
    PolyfillCompKey[PolyfillCompKey["BtnCicle"] = 1] = "BtnCicle";
    PolyfillCompKey[PolyfillCompKey["ScreenControl"] = 2] = "ScreenControl";
    PolyfillCompKey[PolyfillCompKey["ContentPlayer"] = 4] = "ContentPlayer";
    PolyfillCompKey[PolyfillCompKey["PlaylistAudio"] = 8] = "PlaylistAudio";
})(PolyfillCompKey || (PolyfillCompKey = {}));
export function isIOSSafari9() {
    return isIOS9 || isSafari9;
}
export function polyfillComp(key) {
    var queryAll = function (q) { return polyfillNodeList(document.querySelectorAll(q)); };
    var queryOne = function (q) { return document.querySelector(q); };
    var polyfill = function (k, cb) { return (k & key) && cb(); };
    if (isIOS()) {
        polyfill(PolyfillCompKey.BtnCicle, function () {
            var circleBtns = queryAll('.countdown-controlbar .controlbar.mediaplayer-controlbar .ant-btn-circle');
            circleBtns.forEach(function (el) {
                el.style.width = '32px';
                el.style.height = '32px';
            });
            var flatBtns = queryAll('.countdown-controlbar .controlbar.mediaplayer-controlbar .flat-button');
            flatBtns.forEach(function (el) {
                el.style.width = '26px';
                el.style.height = '20px';
            });
        });
    }
    if (isIOSSafari9()) {
        polyfill(PolyfillCompKey.ScreenControl, function () {
            queryOne('.screen-control').style.justifyContent = 'space-around';
        });
        polyfill(PolyfillCompKey.ContentPlayer, function () {
            var dom = queryOne('.contentplayer');
            dom.className = dom.className + " ios9-center";
        });
        polyfill(PolyfillCompKey.PlaylistAudio, function () {
            var dom = queryOne('#videojs-audio-player');
            dom.className = dom.className + " ios9-audio";
        });
    }
}
export function IEVersion() {
    var userAgent = navigator.userAgent;
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE;
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        }
        else if (fIEVersion == 8) {
            return 8;
        }
        else if (fIEVersion == 9) {
            return 9;
        }
        else if (fIEVersion == 10) {
            return 10;
        }
        else {
            return 6;
        }
    }
    else if (isEdge) {
        return 'edge';
    }
    else if (isIE11) {
        return 11;
    }
    else {
        return -1;
    }
}
export function encodeSig(url) {
    return url ? url.replace(/sig=(.+?)&/, function (m, p1) { return "sig=" + decodeURIComponent(p1).replace(/\+/gi, '%2B') + "&"; }) : url;
}
export function setParentChildrenCache(d) {
    var _a, _b;
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        sessionStorage.setItem("parent-children", JSON.stringify((_a = {},
            _a[loginUserId] = (d.self ? [d.self.id] : []).concat((d.children || []).map(function (c) { return c.id; })),
            _a["parentConversationdata"] = getParentConversationData(d),
            _a)));
        sessionStorage.setItem("child-regions", JSON.stringify((_b = {},
            _b[loginUserId] = d.children.map(function (c) { return ({ id: c.id, regionId: c.regionId, repMethodologyVersionId: c.repMethodologyVersionId, schoolId: c.schoolId, schoolClassId: c.schoolClassId, campusId: c.campusId }); })
                .concat([{
                    id: loginUserId,
                    regionId: d.self ? d.self.regionId : null,
                    repMethodologyVersionId: d.self ? d.self.repMethodologyVersionId : null,
                    schoolId: d.children ? d.children.schoolId : null,
                    schoolClassId: d.children ? d.children.schoolClassId : null,
                    campusId: d.children ? d.children.campusId : null
                }]),
            _b)));
    }
}
export function getParentConversationData(userData) {
    if (userData.self === null) {
        if (userData.children && userData.children.length) {
            var maxUnit = Math.max.apply(Math, __spread(userData.children.filter(function (c) { return c.unit != null; }).map(function (c) { return c.unit; })));
            return { maxunit: maxUnit };
        }
    }
    else {
        return { maxunit: userData.self.unit };
    }
}
export function getParentChildrenCache() {
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        return JSON.parse(sessionStorage.getItem("parent-children") || '{}')[loginUserId] || [];
    }
    return [];
}
export function setChildrenRegion(data) {
    var _a;
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        sessionStorage.setItem("child-regions", JSON.stringify((_a = {}, _a[loginUserId] = data, _a)));
    }
    ;
}
export function getChildrenRegion() {
    var loginInfo = GLGlobal.loginInfo();
    if (loginInfo.loggedin) {
        var loginUserId = loginInfo.profile.sub;
        return JSON.parse(sessionStorage.getItem("child-regions") || '{}')[loginUserId] || [];
    }
    return [];
}
function getBrowserVersion(agent, browserRegex) {
    var bData = agent.match(browserRegex);
    if (Array.isArray(bData) && bData.length) {
        return bData[0].match('[0-9\.]+')[0];
    }
    return null;
}
export function getPlatformInfo() {
    var detectedData = {
        client: 1,
        browser: null,
        os: null,
        bVersion: null,
        oVersion: null
    };
    var browsers = {
        edge: "edge",
        firefox: "firefox",
        chrome: "chrome",
        safari: "safari",
        opera: "opera",
        brave: "brave",
        ie: "ie",
        other: "other"
    };
    var browserCodes = {
        edge: 1,
        firefox: 2,
        chrome: 3,
        safari: 4,
        opera: 5,
        brave: 6,
        ie: 7,
        other: 8
    };
    var edgeLegacy = 'Edge\/[0-9\.]+';
    var edgeChromium = 'Edg\/[0-9\.]+';
    var chromeRegex = 'Chrome\/[0-9\.]+';
    var firefox = 'Firefox\/[0-9\.]+';
    var brave = 'Brave\/[0-9\.]+';
    var opera = 'OPR\/[0-9\.]+';
    var safari = 'Safari\/[0-9\.]+';
    var ie11 = 'rv:[0-9\.]+';
    var ie = 'MSIE\\s[0-9\.]+';
    var windows = 'Windows';
    var linux = 'Linux'; // !android
    var mac = 'Mac'; // !ios
    var android = 'Android';
    var ios = 'iPhone';
    var windowsVer = 'NT\\s[0-9\.]+';
    var androidVer = 'Android\\s[0-9\.]+';
    var iosVer = '[0-9]+_[0-9_]+';
    var browser = navigator.userAgent;
    if (RegExp(edgeLegacy).test(browser)) {
        detectedData.browser = browserCodes[browsers.edge];
        detectedData.bVersion = getBrowserVersion(browser, edgeLegacy);
    }
    else if (RegExp(edgeChromium).test(browser)) {
        detectedData.browser = browserCodes[browsers.edge];
        detectedData.bVersion = getBrowserVersion(browser, edgeChromium);
    }
    else if (RegExp(firefox).test(browser)) {
        detectedData.browser = browserCodes[browsers.firefox];
        detectedData.bVersion = getBrowserVersion(browser, firefox);
    }
    else if (navigator.brave) {
        detectedData.browser = browserCodes[browsers.brave];
    }
    else if (RegExp(opera).test(browser)) {
        detectedData.browser = browserCodes[browsers.opera];
        detectedData.bVersion = getBrowserVersion(browser, opera);
    }
    else if (RegExp(chromeRegex).test(browser)) {
        detectedData.browser = browserCodes[browsers.chrome];
        detectedData.bVersion = getBrowserVersion(browser, chromeRegex);
    }
    else if (RegExp(safari).test(browser)) {
        detectedData.browser = browserCodes[browsers.safari];
        detectedData.bVersion = getBrowserVersion(browser, safari);
    }
    else if (RegExp(ie11).test(browser)) {
        detectedData.browser = browserCodes[browsers.ie];
        detectedData.bVersion = 11;
    }
    else if (RegExp(ie).test(browser)) {
        detectedData.browser = browserCodes[browsers.ie];
        detectedData.bVersion = getBrowserVersion(browser, ie);
    }
    else {
        detectedData.browser = browserCodes[browsers.other];
    }
    if (RegExp(windows).test(browser)) {
        detectedData.os = 2;
        var version = browser.match(windowsVer);
        if (Array.isArray(version) && version.length) {
            detectedData.oVersion = version[0].match('[0-9\.]+')[0];
        }
    }
    else if (RegExp(android).test(browser)) {
        detectedData.os = 1;
        var version = browser.match(androidVer);
        if (Array.isArray(version) && version.length) {
            detectedData.oVersion = version[0].match('[0-9\.]+')[0];
        }
    }
    else if (RegExp(ios).test(browser)) {
        detectedData.os = 4;
        var version = browser.match(iosVer);
        if (Array.isArray(version) && version.length) {
            detectedData.oVersion = version[0].replace('_', '.');
        }
    }
    else if (RegExp(mac).test(browser)) {
        detectedData.os = 5;
    }
    else if (RegExp(linux).test(browser)) {
        detectedData.os = 3;
    }
    else {
        detectedData.os = 6;
    }
    return detectedData;
}
export function isSASExpiry(sas) {
    var now = new Date();
    now.setMinutes(now.getMinutes() + 5);
    return now >= new Date(decodeURIComponent(sas.match(/se=(.*Z)&/)[1]));
}
function getUserKey() {
    var loginInfo = GLGlobal.loginInfo();
    return ((loginInfo.loggedin ? loginInfo.profile.sub : 'unknown') + "-" + (hasCountryCode() ? getCountryCode() : 'unknown')).toLowerCase();
}
export function setSASCache(key, sas) {
    if (GLUtil.isNullOrUndefined(sas) || isSASExpiry(sas)) {
        return false;
    }
    key = key + "-" + getUserKey();
    sessionStorage.setItem(key, sas);
    return true;
}
export function getSASCache(key) {
    key = key + "-" + getUserKey();
    var sas = sessionStorage.getItem(key);
    if (GLUtil.isNullOrUndefined(sas) || isSASExpiry(sas)) {
        return null;
    }
    return sas;
}
export function polyfillNodeList(nodeList) {
    if (nodeList && GLUtil.isNullOrUndefined(nodeList.forEach)) {
        nodeList.forEach = function (callback) {
            for (var index = 0; index < nodeList.length; index++) {
                callback(nodeList[index], index, nodeList);
            }
        };
    }
    return nodeList;
}
function getTransitionEndEventName() {
    var transitions = {
        "transition": "transitionend",
        "OTransition": "oTransitionEnd",
        "MozTransition": "transitionend",
        "WebkitTransition": "webkitTransitionEnd"
    };
    var bodyStyle = document.body.style;
    for (var transition in transitions) {
        if (bodyStyle[transition] != undefined) {
            return transitions[transition];
        }
    }
}
export function transitionEnd(eventDom, callback) {
    if (!eventDom || !callback) {
        return;
    }
    var eventName = getTransitionEndEventName();
    var eventCallback = function (ev) {
        eventDom.removeEventListener(eventName, eventCallback);
        callback(ev);
    };
    eventDom.addEventListener(eventName, eventCallback);
}
var EventBus = /** @class */ (function () {
    function EventBus() {
        this.listeners = {};
    }
    EventBus.prototype.addEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = [];
        }
        this.listeners[type].push(callback);
    };
    EventBus.prototype.removeEventListener = function (type, callback) {
        if (!(type in this.listeners)) {
            return;
        }
        var stack = this.listeners[type];
        for (var i = 0, l = stack.length; i < l; i++) {
            if (stack[i] === callback) {
                stack.splice(i, 1);
                return;
            }
        }
    };
    EventBus.prototype.dispatchEvent = function (event) {
        if (!(event.type in this.listeners)) {
            return true;
        }
        var stack = this.listeners[event.type].slice();
        for (var i = 0, l = stack.length; i < l; i++) {
            stack[i].call(this, event);
        }
        return !event.defaultPrevented;
    };
    return EventBus;
}());
export { EventBus };
export var userHasRole = function (role, profile) {
    return (profile || GLGlobal.loginInfo().profile || { roles: [] }).roles.some(function (r) { return r == role; });
};
export var getFormattedName = function (englishName, nativeName) {
    englishName = englishName ? englishName.trim() : englishName;
    nativeName = nativeName ? nativeName.trim() : nativeName;
    if (englishName &&
        englishName.length &&
        nativeName &&
        nativeName.length &&
        englishName.toLowerCase() !== nativeName.toLowerCase()) {
        return englishName + " (" + nativeName + ")";
    }
    else {
        return englishName && englishName.length ? englishName : nativeName;
    }
};
export var contentGroupAdapter = function (contents, currentContent) {
    // Case 1: When both MP3 and MP4 are present.
    if (currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP3; }) &&
        currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP4; })) {
        var currentContentCopy = cloneDeep(currentContent);
        currentContentCopy.medias = currentContentCopy.medias.filter(function (m) { return m.mediaType === MediaExtType.MP4; });
        currentContentCopy.contentGroup = ContentGroupEnum.Movie;
        currentContent.medias = currentContent.medias.filter(function (m) { return m.mediaType === MediaExtType.MP3; });
        currentContent.contentGroup = ContentGroupEnum.Song;
        contents = contents
            .concat(currentContent)
            .concat(currentContentCopy);
    }
    // Case 2: When Mp4 is present but group is Song
    else if (currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP4; }) && (currentContent.contentGroup === ContentGroupEnum.Song || currentContent.contentGroup === ContentGroupEnum.Story)) {
        currentContent.contentGroup = ContentGroupEnum.Movie;
        contents = contents.concat(currentContent);
    }
    // Case 3: When Mp3 is present but group is Movie
    else if (currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP3; }) && (currentContent.contentGroup === ContentGroupEnum.Movie)) {
        currentContent.contentGroup = ContentGroupEnum.Song;
        contents = contents.concat(currentContent);
    }
    // Case 4: When any one of mp3 or movie is present
    else if (currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP3; }) ||
        currentContent.medias.some(function (s) { return s.mediaType === MediaExtType.MP4; })) {
        contents = contents.concat(currentContent);
    }
    else {
        // Don't include the content, since it does not contain audio or video
    }
    return contents;
};
export var downloadJsonFile = function (myData, fileName) {
    var json = JSON.stringify(myData);
    var blob = new Blob([json], { type: "application/json" });
    var href = URL.createObjectURL(blob);
    var link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export var downloadTextFile = function (text, fileName) {
    var link = document.createElement("a");
    link.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    link.download = fileName + ".txt";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export var isOldConversationImage = function (url) {
    if (!url || url.length === 0) {
        return false;
    }
    var splitUrl = url.split("/");
    if (splitUrl.length < 2) {
        return false;
    }
    var conversationOldImagePattern = /^(Conversation|Dialog|Path|Connector|External|Others)\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/;
    var name = splitUrl[splitUrl.length - 2] + "/" + splitUrl[splitUrl.length - 1].slice(0, 36);
    var result = conversationOldImagePattern.test(name);
    return result;
};
export var mergeClasses = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(function (c) { return !!c; }).join(" ");
};
export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export var IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2002837_xq1oue870ki.js',
});
export var isInternalUser = function () {
    return (userHasRole(RoleName.regionAdmin) ||
        userHasRole(RoleName.trainingAdmin) ||
        userHasRole(RoleName.trainer) ||
        userHasRole(RoleName.globalHead) ||
        userHasRole(RoleName.systemAdmin) ||
        userHasRole(RoleName.accountManager) ||
        userHasRole(RoleName.trainingManager) ||
        userHasRole(RoleName.contentAdmin));
};
export var nonParentAndStudentUsers = function () {
    return (userHasRole(RoleName.regionAdmin) ||
        userHasRole(RoleName.trainingAdmin) ||
        userHasRole(RoleName.trainer) ||
        userHasRole(RoleName.globalHead) ||
        userHasRole(RoleName.systemAdmin) ||
        userHasRole(RoleName.accountManager) ||
        userHasRole(RoleName.trainingManager) ||
        userHasRole(RoleName.contentAdmin) ||
        userHasRole(RoleName.schoolAdmin) ||
        userHasRole(RoleName.campusAdmin) ||
        userHasRole(RoleName.teacher));
};
export function selectContentForFailedObjectives(filteredResponse) {
    var sortedContentObjectiveMap = orderBy(filteredResponse, function (o) { return o.objectiveIds.length; }, ["desc"]);
    var selectedContent = [];
    var _loop_1 = function () {
        /**
         * randomize, if continous contents have same objectives
         *  */
        // find contents with exactly the same objectives.
        var groupForContentSelection = sortedContentObjectiveMap.filter(function (comno) {
            var firstElementObj = sortedContentObjectiveMap[0].objectiveIds;
            var currentElementObj = comno.objectiveIds;
            return (!difference(firstElementObj, currentElementObj).length &&
                !difference(currentElementObj, firstElementObj).length);
        });
        var selectedContentFromGroup = groupForContentSelection[random(groupForContentSelection.length - 1)];
        selectedContent.push(selectedContentFromGroup);
        // remove group content for next group
        sortedContentObjectiveMap = sortedContentObjectiveMap.filter(function (f) {
            return !groupForContentSelection.some(function (s) { return s.content.id === f.content.id; });
        });
    };
    while (sortedContentObjectiveMap.length) {
        _loop_1();
    }
    return selectedContent;
}
export function isHtml(text) {
    var htmlRegex = new RegExp([
        "<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>",
        "|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center",
        "|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset",
        "|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|",
        "ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output",
        "|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary",
        "|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\\/\\2>"
    ].join(""), "i");
    return htmlRegex.test(text);
}
export function parseUrlToAnchor(text) {
    if (/Your notification \(<\i>[\s\S]*?<\/\i>\) has been \b(denied|published)\b/gi.test(text))
        return text;
    if (!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi.test(text))
        return escapeHtml(text);
    if (/#\[.+?\]\(.+?\)/gi.test(text))
        return convertMarkUrlToAnchor(text);
    if (/\{url: ?.+?, ?text: ?.+?\}/gi.test(text))
        return convertUrlToAnchor(text);
    return convertTextToAnchor(text);
}
export function convertTextToAnchor(text) {
    if (!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi.test(text))
        return escapeHtml(text);
    var regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*/gi;
    var html = "";
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var href = match[0];
        html +=
            "<span>" + escapeHtml(text.substring(lastindex, index)) + "</span>";
        html += buildAnchor(href, href);
        lastindex = index + href.length;
    }
    text.substring(lastindex).length > 0 &&
        (html += convertTextToAnchor(text.substring(lastindex)));
    return html;
}
export function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/\n+/g, "<br />");
}
export function convertUrlToAnchor(text) {
    var regex = /\{url: ?.+?, ?text: ?.+?\}/gi;
    var formated = [];
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var matchAnchor = /\{url: ?(.+), ?text: ?(.+)\}/gi.exec(match[0]);
        var href = matchAnchor[1];
        formated.push(convertTextToAnchor(text.substring(lastindex, index)));
        formated.push(buildAnchor(href, matchAnchor[2]));
        lastindex = index + match[0].length;
    }
    text.substring(lastindex).length > 0 &&
        formated.push(convertTextToAnchor(text.substring(lastindex)));
    return formated.join("");
}
export function convertMarkUrlToAnchor(text) {
    var regex = /#\[.+?\]\(.+?\)/gi;
    var formated = [];
    var lastindex = 0;
    var match;
    while ((match = regex.exec(text))) {
        var index = match.index;
        var matchAnchor = /#\[(.+?)\]\((.+?)\)/gi.exec(match[0]);
        var href = matchAnchor[2];
        formated.push(convertTextToAnchor(text.substring(lastindex, index)));
        formated.push(buildAnchor(href, matchAnchor[1]));
        lastindex = index + match[0].length;
    }
    text.substring(lastindex).length > 0 &&
        formated.push(convertTextToAnchor(text.substring(lastindex)));
    return formated.join("");
}
function buildAnchor(href, text) {
    return "<a onclick='\n    (function(e){\n        if (e && e.stopPropagation){\n            e.stopPropagation()\n        }else{\n            e.cancelBubble = true\n        }\n    })(event)'\n    href='" + href + "'>\n    " + text + "\n    </a>";
}
export function preload(imageURls) {
    var images = [];
    for (var i = 0; i < imageURls.length; i++) {
        images[i] = new Image();
        images[i].src = imageURls[i];
    }
}
export var formatMedia = function (updateTime) { return function (signature) { return function (m) {
    var src = replaceSig(m.url, signature);
    m.url = src;
    m.src = src;
    m.updateTime = updateTime;
    return m;
}; }; };
function replaceSig(uri, sig) {
    if (!uri)
        return uri;
    if (uri.lastIndexOf('?') > -1) {
        return uri.replace(/\?.*/, sig);
    }
    else {
        return "" + uri + sig;
    }
}
export function getCLUPredictionResponseAsLUISResponse(response) {
    var intents = response.prediction.intents;
    var intent = intents.reduce(function (prev, current) {
        return prev.confidenceScore > current.confidenceScore ? prev : current;
    });
    var topIntentObj = {
        score: intent.confidenceScore
    };
    var entities = response.prediction.entities;
    var transformedEntities = entities.reduce(function (acc, curr) {
        var _a;
        return _a = {},
            _a[curr.category] = [[curr.text.toLowerCase()]],
            _a;
    }, {});
    return { topIntentObj: topIntentObj, transformedEntities: transformedEntities };
}
export function rewind(seek, time) {
    return seek(Math.max(0, time - Constants.RewindTimeInSeconds));
}
export var getCLUPredictionResponseAsLUISResponseV2 = function (response) {
    var intents = response.intents;
    var topIntentObj = intents[response.topIntent];
    var transformedEntities = response.entities;
    return { topIntentObj: topIntentObj, transformedEntities: transformedEntities };
};
