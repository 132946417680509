var _a;
import { JbEncouragingGestures } from "./encouraging-gestures";
import { JbFallbackGestures } from "./fallback-gestures";
import { JbInteractionStyles } from "./gestures.types";
import { JbIdleGestures } from "./idle-gestures";
import { JbIndicativeGestures } from "./indicative-gestures";
import { JbNegativeGestures } from "./negative-gestures";
import { JbNeutralGestures } from "./neutral-gestures";
import { JbPositiveGestures } from "./positive-gestures";
export var JbGestures = (_a = {},
    _a[JbInteractionStyles.Positive] = new JbPositiveGestures(),
    _a[JbInteractionStyles.Negative] = new JbNegativeGestures(),
    _a[JbInteractionStyles.Neutral] = new JbNeutralGestures(),
    _a[JbInteractionStyles.Indicative] = new JbIndicativeGestures(),
    _a[JbInteractionStyles.Encouraging] = new JbEncouragingGestures(),
    _a[JbInteractionStyles.Idle] = new JbIdleGestures(),
    _a[JbInteractionStyles.Fallback] = new JbFallbackGestures(),
    _a);
