var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export function getTick(timeStr) {
    var _a = __read(timeStr.split(':').map(function (time) { return parseFloat(time); }), 3), hours = _a[0], minutes = _a[1], seconds = _a[2];
    var milliseconds = (hours * 3600 + minutes * 60 + seconds) * 1000;
    return milliseconds;
}
