var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { GLGlobal, maskThrottle, unmaskThrottle, withRouter } from "gl-commonui";
import { lazyInject, setChildrenRegion, TYPES } from "@app/util";
import { navigateBy } from "../children/children";
import { StudentLocale } from "@app/locales/localeid";
import { Typography } from "antd-min";
import "./student-home.less";
var StudentHomePage = /** @class */ (function (_super) {
    __extends(StudentHomePage, _super);
    function StudentHomePage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { message: "", loading: false };
        return _this;
    }
    StudentHomePage.prototype.componentDidMount = function () {
        var _this = this;
        var studentId = GLGlobal.loginInfo().profile.sub;
        this.setState({ loading: true });
        this.studentService.getStudentInfo({ id: studentId })
            .then(function (child) {
            var subscriptionType = child.subscriptionType, id = child.id, regionId = child.regionId, repMethodologyVersionId = child.repMethodologyVersionId, schoolId = child.schoolId, campusId = child.campusId, schoolClassId = child.schoolClassId;
            setChildrenRegion([{ id: id, regionId: regionId, repMethodologyVersionId: repMethodologyVersionId, schoolId: schoolId, campusId: campusId, schoolClassId: schoolClassId }]);
            return navigateBy(_this.props.history, child, subscriptionType, null, StudentLocale.ChildrenNoneUnit, false);
        })
            .then(function (navResult) {
            if (navResult) {
                _this.setState({ message: navResult });
            }
            _this.setState({ loading: false });
        })
            .catch(function () {
            _this.setState({ loading: false });
        });
    };
    StudentHomePage.prototype.render = function () {
        var _a = this.state, loading = _a.loading, message = _a.message;
        if (loading) {
            maskThrottle();
            return React.createElement(React.Fragment, null);
        }
        unmaskThrottle();
        return (React.createElement("div", { className: "sh__message" },
            React.createElement(Typography.Title, { level: 3 }, message)));
    };
    __decorate([
        lazyInject(TYPES.IStudentAppService)
    ], StudentHomePage.prototype, "studentService", void 0);
    StudentHomePage = __decorate([
        withRouter
    ], StudentHomePage);
    return StudentHomePage;
}(Component));
export { StudentHomePage };
