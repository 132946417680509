import { bindingCollection } from 'gl-commonui';
import { TYPES } from './types';
import { ParentService } from './parent/index';
import { StudentService } from './student/index';
import { DeviceService } from './device/index';
import { ResourceService } from './resource/index';
import { UserService } from './user/index';
import { ParentAppService } from './app/parent';
import { ContentsignatureAppService } from './app/contentsignature';
import { PlaylistlogsAppService } from './app/playlistlogs';
import { UnitsContentsService } from './app/unitcontents';
import { UnitsPlaylistsService } from './app/unitsplaylists';
import { ContentplaylogsAppService } from './app/contentplaylogs';
import { VpcsthumbnailAppService } from './app/vpcsthumbnail';
import { CdDvdsService } from './app/cddvds';
import { AccessunitService } from './app/accessunit';
import { StudentaccessunitService } from './app/studentaccessunit';
import { SettingService } from './app/setting';
import { ContentService, ActiveLearnService, ConversationIntroService } from './app/content';
import { AnswerService } from './app/answer';
import { HeartBeatService } from './app/heartbeat';
import { PlaylistConnectService } from './app/playlistcontent';
import { CaptionService } from './app/caption';
import { PuzzleService } from './puzzle';
import { StudentAppService } from './app/student';
import { ConversationAdminService } from './conversation-admin';
import { ReportService } from './app/report';
import { RegionService } from './regions';
import { RemoteService } from './app/remote';
import { NotificationService } from "./notifications";
bindingCollection.push(function (container) {
    container.bind(TYPES.ICdDvdsService).to(CdDvdsService);
    container.bind(TYPES.IStudentaccessunitService).to(StudentaccessunitService);
    container.bind(TYPES.IAccessunitService).to(AccessunitService);
    container.bind(TYPES.IVpcsthumbnailAppService).to(VpcsthumbnailAppService);
    container.bind(TYPES.ISettingService).to(SettingService);
    container.bind(TYPES.IContentplaylogsAppService).to(ContentplaylogsAppService);
    container.bind(TYPES.IUnitsPlaylistsService).to(UnitsPlaylistsService);
    container.bind(TYPES.IUnitsContentsService).to(UnitsContentsService);
    container.bind(TYPES.IPlaylistlogsAppService).to(PlaylistlogsAppService);
    container.bind(TYPES.IContentsignatureAppService).to(ContentsignatureAppService);
    container.bind(TYPES.IParentAppService).to(ParentAppService);
    container.bind(TYPES.IParentService).to(ParentService);
    container.bind(TYPES.IStudentService).to(StudentService);
    container.bind(TYPES.IDeviceService).to(DeviceService);
    container.bind(TYPES.IResourceService).to(ResourceService);
    container.bind(TYPES.IUserService).to(UserService);
    container.bind(TYPES.IContentService).to(ContentService);
    container.bind(TYPES.IAnswerService).to(AnswerService);
    container.bind(TYPES.IHeartBeatService).to(HeartBeatService);
    container.bind(TYPES.IPlaylistConnectService).to(PlaylistConnectService);
    container.bind(TYPES.ICaptionService).to(CaptionService);
    container.bind(TYPES.IPuzzleService).to(PuzzleService);
    container.bind(TYPES.IStudentAppService).to(StudentAppService);
    container.bind(TYPES.IConversationAdminService).to(ConversationAdminService);
    container.bind(TYPES.IReportService).to(ReportService);
    container.bind(TYPES.IRegionService).to(RegionService);
    container.bind(TYPES.IRemoteService).to(RemoteService);
    container.bind(TYPES.IActiveLearnService).to(ActiveLearnService);
    container.bind(TYPES.IConversationIntroService).to(ConversationIntroService);
    container.bind(TYPES.INotificationService).to(NotificationService);
});
