var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
export function useWijmoGridValidCheck(grid) {
    var _a = __read(useState(false), 2), isValid = _a[0], setIsValid = _a[1];
    useEffect(function () {
        if (grid) {
            grid.cellEditEnded.addHandler(findAndSetError);
            grid.loadedRows.addHandler(findAndSetError);
            grid.pasted.addHandler(findAndSetError);
            grid.deletedRow.addHandler(findAndSetError);
            findAndSetError();
        }
        return function () {
            if (grid) {
                grid.cellEditEnded.removeHandler(findAndSetError);
                grid.loadedRows.removeHandler(findAndSetError);
                grid.pasted.removeHandler(findAndSetError);
                grid.deletedRow.removeHandler(findAndSetError);
            }
        };
    }, [grid]);
    var findAndSetError = function () {
        var isValid = grid.hostElement && !grid.hostElement.querySelectorAll(".wj-state-invalid").length;
        setIsValid(isValid);
        setTimeout(function () {
            var isValid = grid.hostElement && !grid.hostElement.querySelectorAll(".wj-state-invalid").length;
            setIsValid(isValid);
        }, 200);
    };
    var manualTrigger = findAndSetError;
    return { isValid: isValid, manualTrigger: manualTrigger };
}
