var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var FullScreenIcon = /** @class */ (function (_super) {
    __extends(FullScreenIcon, _super);
    function FullScreenIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FullScreenIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 23 18" },
            React.createElement("polygon", { className: "path", points: "6 0 2 0 0 0 0 2 0 6 2 6 2 2 6 2 6 0" }),
            React.createElement("polygon", { className: "path", points: "21 0 17 0 17 2 21 2 21 6 23 6 23 2 23 0 21 0" }),
            React.createElement("polygon", { className: "path", points: "21 12 21 16 17 16 17 18 21 18 23 18 23 16 23 12 21 12" }),
            React.createElement("polygon", { className: "path", points: "2 16 2 12 0 12 0 16 0 18 2 18 6 18 6 16 2 16" }),
            React.createElement("path", { className: "path", d: "M5,4H18V14H5Z" })));
    };
    return FullScreenIcon;
}(Component));
export default FullScreenIcon;
