var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { GLGlobal, GLUtil, withRouter } from "gl-commonui";
import { WijmoGrid } from "@app/components/grid";
import { ConversationListLocale } from "@app/locales/localeid";
import { ConversationService } from "@app/service/conversation";
import { PlayPlayer } from "@app/components/svg-icon";
import { PathConfig } from "@app/config/pathconfig";
import { setParentChildrenCache, getParentConversationData, TYPES } from "@app/util";
import { useService } from "@app/hooks";
import './conversationlist.less';
var Grid = WijmoGrid.Grid, Column = WijmoGrid.Column;
var ConversationList = function (props) {
    var _a = __read(useState([]), 2), conversations = _a[0], setConversations = _a[1];
    var _b = __read(useState(true), 2), loading = _b[0], setLoading = _b[1];
    var parentAppService = useService(TYPES.IParentAppService);
    useEffect(function () {
        var parentChildrenData = JSON.parse(sessionStorage.getItem("parent-children"));
        if (!parentChildrenData) {
            var parentId = GLGlobal.loginInfo().profile.sub;
            parentAppService.getChildren({ parentId: parentId }).then(function (d) {
                setParentChildrenCache(d);
                var dataForFetchingConversation = getParentConversationData(d);
                saveConversationList(dataForFetchingConversation);
            });
        }
        else {
            var dataForFetchingConversation = parentChildrenData.parentConversationdata;
            saveConversationList(dataForFetchingConversation);
        }
    }, []);
    var saveConversationList = function (dataForFetchingConversation) {
        if (dataForFetchingConversation && dataForFetchingConversation.maxunit) {
            ConversationService
                .getConversationList(Number(dataForFetchingConversation.maxunit))
                .then(function (conversations) {
                setConversations(conversations);
                setLoading(false);
            }).catch(function () {
                setLoading(false);
            });
        }
        else {
            setLoading(false);
        }
    };
    var goToTestConversation = function (record) {
        props.history.push({ pathname: GLUtil.pathStringify(PathConfig.TestConversationPlayer, { conversationId: record.conversationId }) });
    };
    return (React.createElement("div", { className: "conversationlist__container" },
        React.createElement("h1", { className: "conversationlist__heading" }, GLGlobal.intl.formatMessage({ id: ConversationListLocale.ConversationListHeadingText })),
        React.createElement("div", { className: "conversationlist__grid" },
            React.createElement(Grid, { itemsSource: conversations, loading: loading, pagination: false },
                React.createElement(Column, { header: GLGlobal.intl.formatMessage({ id: ConversationListLocale.ConversationListGridNameText }), binding: "conversationName", align: "left", width: "2*" }),
                React.createElement(Column, { header: GLGlobal.intl.formatMessage({ id: ConversationListLocale.ConversationListGridUnitText }), binding: "unit", width: "0.5*" }),
                React.createElement(Column, { header: GLGlobal.intl.formatMessage({ id: ConversationListLocale.ConversationListGridPlayText }), render: function (text, record) {
                        return (React.createElement("span", { className: "conversationlist__grid--icon" },
                            React.createElement(PlayPlayer, { className: "conversationlist__grid--playicon", onClick: function () { return goToTestConversation(record); } })));
                    }, align: "center", width: "*" })))));
};
export var ConversationListPage = withRouter(ConversationList);
