var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
import { Progress } from "antd-min";
import { PlayMode, PlayAction } from "./mediaplayer";
import PlayerButton, { PlayModeButton, StepBackwardButton, StepForwardButton } from "./player-button";
import { getPausePlayer, rewind } from "@app/util";
import { allowedProps } from "../subtitle/subtitle";
var SliderControlContainer = /** @class */ (function (_super) {
    __extends(SliderControlContainer, _super);
    function SliderControlContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.onRewind = function (seek, time) {
            return rewind(seek, time);
        };
        _this.state = {
            playMode: PlayMode.order,
            playModes: [PlayMode.order, PlayMode.singleCycle, PlayMode.random]
        };
        return _this;
    }
    SliderControlContainer.prototype.componentWillReceiveProps = function (_a) {
        var mode = _a.mode;
        if (this.props.mode == undefined && this.props.mode != mode) {
            this.setModeWithPropsValue(mode);
        }
    };
    SliderControlContainer.prototype.componentDidMount = function () {
        this.setModeWithPropsValue(this.props.mode);
    };
    SliderControlContainer.prototype.setModeWithPropsValue = function (playMode) {
        var setPlayMode = this.props.setPlayMode;
        if (playMode) {
            var playModes = this.state.playModes;
            for (var index = 0; playModes[0] != playMode; index++) {
                playModes = playModes.concat([playModes.shift()]);
            }
            setPlayMode(playModes[0]);
            this.props.onModeChanged && this.props.onModeChanged(playModes[0]);
            this.setState({ playMode: playMode, playModes: playModes });
        }
    };
    SliderControlContainer.prototype.renderControlButton = function () {
        var _this = this;
        var playModes = this.state.playModes;
        var _a = this.props, paused = _a.paused, isPaused = _a.isPaused, play = _a.play, pause = _a.pause, setPlayMode = _a.setPlayMode, stepBackward = _a.stepBackward, stepForward = _a.stepForward, seek = _a.seek, time = _a.times.time;
        return (React.createElement(React.Fragment, null,
            React.createElement(StepBackwardButton, { mode: playModes[0], onClick: function () { return stepBackward(); } }),
            React.createElement(PlayerButton, { iconType: isPaused ? PlayAction.play : PlayAction.pause, onClick: function () { return paused() ? play() : (pause(), setAudio(getPausePlayer())); } }),
            React.createElement(StepForwardButton, { mode: playModes[0], onClick: function () { return stepForward(); } }),
            React.createElement(PlayModeButton, { mode: playModes[0], onClick: function () {
                    var playModes = _this.state.playModes;
                    var mode = playModes.shift();
                    _this.setState({ playModes: playModes.concat([mode]) });
                    setPlayMode(playModes[0]);
                    _this.props.onModeChanged && _this.props.onModeChanged(playModes[0]);
                } })));
    };
    SliderControlContainer.prototype.render = function () {
        var _a = this.props, showTitle = _a.showTitle, _b = _a.times, time = _b.time, duration = _b.duration, seek = _a.seek, content = _a.content;
        return React.createElement("div", { className: "slider-controlbar screen-container" },
            React.createElement("div", { className: "controlbar mediaplayer-controlbar " + (this.props.isCD ? "cd-patch" : "") },
                this.renderControlButton(),
                React.createElement("div", { className: "cd-progress-panel" },
                    duration ? React.createElement("span", __assign({}, allowedProps, { title: content.name }), content.name) : React.createElement("span", { dangerouslySetInnerHTML: { __html: '&nbsp;' } }),
                    React.createElement(Progress, { percent: duration ? time / duration * 100 : 0, showInfo: false }))),
            React.createElement("audio", { id: 'playlist-audio', style: { display: 'none' }, src: getPausePlayer() }));
    };
    SliderControlContainer.displayName = 'SliderControlContainer';
    return SliderControlContainer;
}(Component));
export default SliderControlContainer;
function setAudio(src) {
    var audio = document.getElementById('playlist-audio');
    audio.src = src;
    audio.play();
}
