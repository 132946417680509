import { HeadersVisibility } from "wijmo/wijmo.grid";
import * as ResizeSensor from "resize-sensor";
import { debounce } from "lodash";
/**
 * The function make the grid responsive with adding horizontal scrollbar on the small screen sizes.
 * The function should be called in initilize function of the grid with the grid obejct.
 * @param grid FlexGrid object
 */
export var wijmoGridResponsive = function (grid) {
    /**
     * Saving the frozenColumns in the variable.
     * To make the frozenColumns as 0 on the small screen.
     */
    var frozenColumns = grid.frozenColumns;
    // Registering the rezise listener to grid.
    grid.addEventListener(window, "resize", function () {
        if (!grid.editRange) {
            wijmoGirdResponsiveHandler(grid, frozenColumns);
        }
    });
    // Calling the wijmoGirdResponsiveHandler while rendering the rows
    grid.loadedRows.addHandler(function (grid) { return wijmoGirdResponsiveHandler(grid, frozenColumns, 500); });
    // Calling the wijmoGirdResponsiveHandler while grid layout gets updated once
    var isResizeDone = false;
    grid.updatedLayout.addHandler(function (grid) {
        if (!isResizeDone) {
            wijmoGirdResponsiveHandler(grid, frozenColumns, 500);
            isResizeDone = true;
        }
    });
    // Calling the wijmoGirdResponsiveHandler when a cell has been edited
    grid.cellEditEnded.addHandler(function (grid, event) {
        var column = grid.columns[event.col];
        if (column && column.minWidth && column.maxWidth) {
            return null;
        }
        wijmoGirdResponsiveHandler(grid, frozenColumns, 200);
    });
    // Registering the rezise listener to grid.
    var currentWidth = getGridFullWidth(grid);
    var debouncedResponsiveHandler = debounce(function () {
        wijmoGirdResponsiveHandler(grid, frozenColumns);
    }, 300);
    new ResizeSensor(grid.hostElement, function () {
        var newWidth = getGridFullWidth(grid);
        if (currentWidth !== newWidth) {
            debouncedResponsiveHandler();
        }
        currentWidth = newWidth;
    });
};
export var wijmoGirdResponsiveHandler = function (grid, frozenColumns, timeout) {
    if (timeout === void 0) { timeout = 200; }
    if (!grid.collectionView) {
        return null;
    }
    setTimeout(function () {
        grid.autoSizeColumns();
        if (grid.autoRowHeights) {
            grid.autoSizeRows();
        }
        var gridFullWidth = getGridFullWidth(grid);
        var columnsWidth = grid.columns.getTotalSize();
        if (gridFullWidth > columnsWidth) {
            var columns = grid.columns.filter(function (column) { return column.visible && !column.maxWidth; });
            var columnCount = columns.length;
            var extraWidthForEachColumn_1 = (gridFullWidth - columnsWidth) / columnCount;
            columns.forEach(function (column) {
                var widthType = column.minWidth ? "minWidth" : "width";
                column[widthType] = column[widthType] + extraWidthForEachColumn_1;
            });
        }
        // Setting the frozenColumns as 0 on small screen
        if (gridFullWidth <= 768) {
            grid.frozenColumns = 0;
        }
        else {
            grid.frozenColumns = frozenColumns;
        }
    }, timeout);
};
// A utility function to get the width of a grid.
var getGridFullWidth = function (grid) {
    if (grid.headersVisibility === HeadersVisibility.All || grid.headersVisibility === HeadersVisibility.Row) {
        return grid.hostElement.offsetWidth - grid.rowHeaders.width;
    }
    return grid.hostElement.offsetWidth;
};
