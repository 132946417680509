import { GLGlobal, GLUtil } from 'gl-commonui';
import { PlayerRole } from '@app/util';
import { PathConfig } from '@app/config/pathconfig';
export function PagePermission(userId, unit) {
    var leftData = GLGlobal.store.getState().theme.leftData;
    var role = leftData.role;
    if (!leftData.isLoaded) {
        return false;
    }
    else if (!unit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (userId != leftData.id) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (unit < 0 && unit < leftData.lsUnit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (unit > 0 && unit > leftData.unit) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    else if (!(role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other || role == PlayerRole.Text)) {
        window.location.href = GLUtil.pathStringify(PathConfig.Home, {});
    }
    // hiden navigation bar on ls mode
    GLGlobal.store.getState().theme.rightChanged = (role == PlayerRole.Digital || role == PlayerRole.Dual || role == PlayerRole.Other) && unit > 0;
    return true;
}
