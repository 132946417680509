var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import React, { Component } from "react";
import { withRouter, connect, maskThrottle, unmaskThrottle } from "gl-commonui";
import { lazyInject, TYPES, PuzzleSizeEnum, PuzzlePieceTypeEnum } from "@app/util";
import Puzzle from "@app/components/puzzle/puzzle";
import { PuzzleAudioManager } from "@app/components/puzzle/puzzle-audios";
var PuzzlePlayerPage = /** @class */ (function (_super) {
    __extends(PuzzlePlayerPage, _super);
    function PuzzlePlayerPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.puzzleConfig = null;
        _this.puzzleAudioManager = null;
        _this.state = {
            puzzleAsset: { assets: [] },
            sas: '',
            puzzleList: null
        };
        _this.puzzleConfig = _this.getPuzzleConfigByUnit(props);
        _this.puzzleAudioManager = new PuzzleAudioManager();
        _this.puzzleAudioManager.preLoad();
        return _this;
    }
    PuzzlePlayerPage.prototype.componentDidMount = function () {
        maskThrottle();
        this.initPuzzlePlayer();
    };
    PuzzlePlayerPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.leftData && (this.props.leftData.unit !== nextProps.leftData.unit)) {
            this.initPuzzlePlayer();
        }
    };
    PuzzlePlayerPage.prototype.initPuzzlePlayer = function () {
        var _this = this;
        var _a = this.props.match.params, unit = _a.unit, round = _a.round, puzzleId = _a.puzzleId, userId = _a.userId;
        Promise.all([
            this.puzzleService.getSAS(),
            this.puzzleService.getPuzzles([puzzleId], userId)
        ]).then(function (_a) {
            var _b = __read(_a, 2), sas = _b[0], puzzleInfo = _b[1];
            _this.preloadBoardImage(puzzleInfo.endPoint, puzzleInfo.puzzle[0], sas)
                .then(function (_) {
                unmaskThrottle();
                _this.setState({ sas: sas, puzzleList: puzzleInfo }, function () {
                    _this.preloadPieceImages(puzzleInfo.endPoint, puzzleInfo.puzzle[0].puzzlePiece, sas);
                });
            });
        });
    };
    PuzzlePlayerPage.prototype.getPuzzleConfigByUnit = function (props) {
        var params = (props || this.props).match.params;
        var unit = 1;
        var getPiceType = function () {
            var e_1, _a;
            var cfgs = [
                { range: [1, 1], type: PuzzleSizeEnum.p2x2 },
                { range: [2, 10], type: PuzzleSizeEnum.p5x2 },
                { range: [11, 20], type: PuzzleSizeEnum.p7x3 },
                { range: [21, 40], type: PuzzleSizeEnum.p10x4 },
            ];
            try {
                for (var cfgs_1 = __values(cfgs), cfgs_1_1 = cfgs_1.next(); !cfgs_1_1.done; cfgs_1_1 = cfgs_1.next()) {
                    var cfg = cfgs_1_1.value;
                    if (unit >= cfg.range[0] && unit <= cfg.range[1]) {
                        return cfg.type;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (cfgs_1_1 && !cfgs_1_1.done && (_a = cfgs_1.return)) _a.call(cfgs_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        };
        return {
            isEyesOpen: unit < 11,
            pieceType: getPiceType()
        };
    };
    PuzzlePlayerPage.prototype.preloadBoardImage = function (endPoint, asset, sas) {
        var image = asset.path;
        var pathLine = asset.puzzlePiece.filter(function (puzzle) { return puzzle.puzzlePieceTypeId === PuzzlePieceTypeEnum.Line && puzzle.size === PuzzleSizeEnum.p2x2; })[0].path;
        return Promise.all([
            this.preloadImage("" + endPoint + image + sas),
            this.preloadImage("" + endPoint + pathLine + sas)
        ]);
    };
    PuzzlePlayerPage.prototype.preloadPieceImages = function (endPoint, assets, sas) {
        var _this = this;
        assets.filter(function (asset) { return asset.puzzlePieceTypeId === PuzzlePieceTypeEnum.Piece && asset.size === PuzzleSizeEnum.p2x2; })
            .sort(function (firstPiece, secondPiece) { return firstPiece.sequence - secondPiece.sequence; })
            .forEach(function (_a) {
            var path = _a.path;
            _this.preloadImage("" + endPoint + path + sas);
        });
    };
    PuzzlePlayerPage.prototype.preloadImage = function (url) {
        return new Promise(function (resolve, reject) {
            var img = document.createElement("img");
            img.style.display = "none";
            img.className = "preload-img";
            img.src = url;
            document.body.appendChild(img);
            img.onload = function () { return resolve(true); };
            img.onerror = function () { return reject(); };
        });
    };
    PuzzlePlayerPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, sas = _a.sas, puzzleList = _a.puzzleList;
        var _b = this.props.match.params, unit = _b.unit, round = _b.round;
        return React.createElement("div", { id: 'puzzle-player', className: "puzzle-player" },
            React.createElement(Puzzle, { round: round, sas: sas, getSAS: function () { return _this.puzzleService.getSAS(); }, config: this.puzzleConfig, playAudio: function (type) { return _this.puzzleAudioManager.play(type); }, puzzleList: puzzleList }));
    };
    __decorate([
        lazyInject(TYPES.IPuzzleService)
    ], PuzzlePlayerPage.prototype, "puzzleService", void 0);
    PuzzlePlayerPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], PuzzlePlayerPage);
    return PuzzlePlayerPage;
}(Component));
export default PuzzlePlayerPage;
