export var ConversationAuthorType;
(function (ConversationAuthorType) {
    ConversationAuthorType[ConversationAuthorType["Machine"] = 0] = "Machine";
    ConversationAuthorType[ConversationAuthorType["User"] = 1] = "User";
})(ConversationAuthorType || (ConversationAuthorType = {}));
export var ConversationStatus;
(function (ConversationStatus) {
    ConversationStatus[ConversationStatus["Active"] = 0] = "Active";
    ConversationStatus[ConversationStatus["Deleted"] = 1] = "Deleted";
})(ConversationStatus || (ConversationStatus = {}));
export var ContainerType;
(function (ContainerType) {
    ContainerType[ContainerType["Video"] = 0] = "Video";
    ContainerType[ContainerType["Audio"] = 1] = "Audio";
    ContainerType[ContainerType["Page"] = 2] = "Page";
    ContainerType[ContainerType["File"] = 3] = "File";
})(ContainerType || (ContainerType = {}));
export var ConversationAPIEvents;
(function (ConversationAPIEvents) {
    ConversationAPIEvents["onComplete"] = "onComplete";
    ConversationAPIEvents["onEnd"] = "onEnd";
    ConversationAPIEvents["onIncomplete"] = "onIncomplete";
    ConversationAPIEvents["onListen"] = "onListen";
    ConversationAPIEvents["onDialogChange"] = "onDialogChange";
    ConversationAPIEvents["onUserResponse"] = "onUserResponse";
    ConversationAPIEvents["onTriggerSendDialogHistory"] = "onTriggerSendDialogHistory";
    ConversationAPIEvents["onListenStart"] = "onListenStart";
    ConversationAPIEvents["onListenStop"] = "onListenStop";
    ConversationAPIEvents["onServerDown"] = "onServerDown";
})(ConversationAPIEvents || (ConversationAPIEvents = {}));
export var ConversationEndedState;
(function (ConversationEndedState) {
    ConversationEndedState["Complete"] = "onComplete";
    ConversationEndedState["Incomplete"] = "onIncomplete";
    ConversationEndedState["OnEnd"] = "onEnd";
    ConversationEndedState["OnServerDown"] = "onServerDown";
})(ConversationEndedState || (ConversationEndedState = {}));
export var PathTypes;
(function (PathTypes) {
    PathTypes[PathTypes["Normal"] = 1] = "Normal";
    PathTypes[PathTypes["Fallback"] = 2] = "Fallback";
    PathTypes[PathTypes["Silent"] = 3] = "Silent";
})(PathTypes || (PathTypes = {}));
export var ConversationTemplateType;
(function (ConversationTemplateType) {
    ConversationTemplateType[ConversationTemplateType["Silent"] = 1] = "Silent";
    ConversationTemplateType[ConversationTemplateType["Regular"] = 2] = "Regular";
    ConversationTemplateType[ConversationTemplateType["Open"] = 3] = "Open";
    ConversationTemplateType[ConversationTemplateType["Close"] = 4] = "Close";
    ConversationTemplateType[ConversationTemplateType["Other"] = 5] = "Other";
    ConversationTemplateType[ConversationTemplateType["MasterDialog"] = 6] = "MasterDialog";
})(ConversationTemplateType || (ConversationTemplateType = {}));
export var ConversationVoiceGenerationStatus;
(function (ConversationVoiceGenerationStatus) {
    ConversationVoiceGenerationStatus[ConversationVoiceGenerationStatus["Queued"] = 1] = "Queued";
    ConversationVoiceGenerationStatus[ConversationVoiceGenerationStatus["Running"] = 2] = "Running";
    ConversationVoiceGenerationStatus[ConversationVoiceGenerationStatus["Success"] = 3] = "Success";
    ConversationVoiceGenerationStatus[ConversationVoiceGenerationStatus["Error"] = 4] = "Error";
    ConversationVoiceGenerationStatus[ConversationVoiceGenerationStatus["NoVoiceError"] = 5] = "NoVoiceError"; // If there are no active voices on voice Generation service.
})(ConversationVoiceGenerationStatus || (ConversationVoiceGenerationStatus = {}));
export var conversationDialogTrackTemplate = {
    conversationDialogId: 0,
    pathId: null,
    pathName: "",
    utterenceId: null,
    utterenceText: null,
    matchingScore: 0,
    isMatched: null,
    attempt: 1,
    topIntentName: "",
    matchedIntentName: "",
    matchedIntentId: null,
    matchedEntityId: null,
    matchedEntityName: null,
    matchedEntityValue: null,
    isHead: false,
    isCompleted: false
};
export var DialogInteractionType;
(function (DialogInteractionType) {
    DialogInteractionType["InteractiveDialog"] = "InteractiveDialog";
    DialogInteractionType["NonInteractiveDialog"] = "NonInteractiveDialog";
})(DialogInteractionType || (DialogInteractionType = {}));
