var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { GLServiceBase } from "gl-commonui";
import { injectable } from "../../../util/di";
import { guidEmpty, newGuid } from "@app/util";
var ContentService = /** @class */ (function (_super) {
    __extends(ContentService, _super);
    function ContentService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: "content/v1/versions", suffix: "{versionId}" };
        return _this;
    }
    ContentService.prototype.getVersionQuestions = function (data) {
        return this.getItemsBy("questions", data)
            .then(function (questions) {
            if (questions && questions.length > 0) {
                return questions.map(function (question) {
                    if (question.questionId !== guidEmpty()
                        && questions.some(function (q) { return q.id === question.questionId; })) {
                        question.id = newGuid();
                    }
                    else {
                        question.id = question.questionId;
                    }
                    return question;
                });
            }
            return questions;
        });
    };
    ContentService = __decorate([
        injectable()
    ], ContentService);
    return ContentService;
}(GLServiceBase));
export { ContentService };
var ActiveLearnService = /** @class */ (function (_super) {
    __extends(ActiveLearnService, _super);
    function ActiveLearnService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: "content/v1/activelearn" };
        return _this;
    }
    ActiveLearnService.prototype.getSuggestionContentForFailedObjectives = function (unitId, versionGroupId, objectiveRequest) {
        return this.create("objective/contents/{unitId}/{versionGroupId}", objectiveRequest, { unitId: unitId, versionGroupId: versionGroupId });
    };
    ActiveLearnService = __decorate([
        injectable()
    ], ActiveLearnService);
    return ActiveLearnService;
}(GLServiceBase));
export { ActiveLearnService };
var ConversationIntroService = /** @class */ (function (_super) {
    __extends(ConversationIntroService, _super);
    function ConversationIntroService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: "content/v1/versions" };
        return _this;
    }
    ConversationIntroService.prototype.getConversationIntroVideo = function () {
        return this.get("conversationintrovideo");
    };
    ConversationIntroService = __decorate([
        injectable()
    ], ConversationIntroService);
    return ConversationIntroService;
}(GLServiceBase));
export { ConversationIntroService };
