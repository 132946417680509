var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbNeutralGestures = /** @class */ (function (_super) {
    __extends(JbNeutralGestures, _super);
    function JbNeutralGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            // {
            //     id: "NeutralGesture#1",
            //     styleId: JbInteractionStyles.Neutral,
            //     animations: [
            //         new JbAnimations.Happy(200),
            //         new JbAnimations.Happy(200),
            //         new JbAnimations.Neutral(100),
            //         new JbAnimations.EarFlick(),
            //         new JbAnimations.WaveStart(),
            //         new JbAnimations.WaveCycle(),
            //         new JbAnimations.WaveReturn(),
            //         new JbAnimations.Neutral(100),
            //         // new JbAnimations.EarFlick(),
            //         // new JbAnimations.Neutral(300),
            //     ],
            // },
            {
                id: "NeutralGesture#2",
                styleId: JbInteractionStyles.Neutral,
                animations: [
                    new JbAnimations.Neutral(50),
                    new JbAnimations.WaveStart(),
                    new JbAnimations.WaveCycle(),
                    new JbAnimations.WaveReturn(),
                    new JbAnimations.Neutral(50),
                ],
            },
        ];
        return _this;
    }
    return JbNeutralGestures;
}(GestureBase));
export { JbNeutralGestures };
