var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var EXTENDED_SUBSCRIPTION_NOTICE_SHOWN_DATE = "extended-subscription-notice-shown-date";
export default {
    namespace: 'children',
    state: {
        allChildren: [],
        childrenWhoseExtendedSubscription: [],
    },
    reducers: {
        setAllChildren: function (state, action) {
            return __assign({}, state, { allChildren: action.payload, childrenWhoseExtendedSubscription: action.payload.filter(function (child) { return child.isExtendedSubscription; }) });
        }
    }
};
export function setAllChildren(children) {
    return { type: 'children/setAllChildren', payload: children };
}
