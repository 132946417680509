import { JonneyBear } from "@app/components/jonny-bear";
import { JbGestures } from "@app/components/jonny-bear/gestures";
import { JbInteractionStyles } from "@app/components/jonny-bear/gestures/gestures.types";
import { useEffectAfterFirst } from "@app/hooks";
import { useRectBounding } from "@app/hooks/useRectBounding";
import { ConversationAPI } from "@app/util/conversation-api";
import { Icon, Spin } from "antd-min";
import { MatIcon } from "gl-commonui";
import gsap from "gsap";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { fabric } from "fabric";
import { cloneDeep } from "lodash";
import { isSafari } from "@app/util";
import "./conversation-player.less";
export var ConversationPlayer = forwardRef(function (props, ref) {
    var loading = props.loading, dialog = props.dialog, isConversationStarted = props.isConversationStarted, isListening = props.isListening, isBlinking = props.isBlinking, startConversation = props.startConversation, cancelConversation = props.cancelConversation, resetConversationAndShowIntroVideo = props.resetConversationAndShowIntroVideo, startAndStopListening = props.startAndStopListening;
    var _a = dialog || { data: {} }, imageUrl = _a.imageUrl, metaData = _a.data.imageMetaData;
    var containerRef = useRef(null);
    var jonnyContainerRef = useRef(null);
    var jonnyBearRef = useRef();
    var actionContainerRef = useRef(null);
    var imageContainerRef = useRef(null);
    var containerRect = useRectBounding(containerRef);
    var imageRef = useRef(null);
    var canvasRef = useRef(null);
    useEffect(function () {
        canvasRef.current = new fabric.Canvas("imageCropper");
        return function () {
            canvasRef.current && canvasRef.current.dispose();
        };
    }, []);
    useEffectAfterFirst(function () {
        if (!imageUrl) {
            hideContent().then(function () {
                imageRef.current.src = null;
            });
        }
        else {
            showContent();
            if (!metaData) {
                imageRef.current.src = imageUrl;
                return;
            }
            canvasRef.current.clear();
            try {
                fabric.Image.fromURL(imageUrl, function (img) {
                    img.selectable = false;
                    var imageMetaData = cloneDeep(metaData);
                    // rotate image
                    if (imageMetaData.rotate) {
                        img.rotate(imageMetaData.rotate);
                    }
                    var imageHeight = img.height;
                    var imageWidth = img.width;
                    // flip image.
                    if (imageMetaData.scaleX === -1) {
                        img.flipX = true;
                    }
                    if (imageMetaData.scaleY === -1) {
                        img.flipY = true;
                    }
                    // set canvas width/height and image left according to rotation
                    // update image imageMetaData width and height if anyone is zero.
                    if (Math.abs(imageMetaData.rotate) === 90 ||
                        Math.abs(imageMetaData.rotate) === 270) {
                        canvasRef.current.setWidth(imageHeight);
                        canvasRef.current.setHeight(imageWidth);
                        if (imageMetaData.width === 0 || imageMetaData.height === 0) {
                            imageMetaData.width = imageHeight;
                            imageMetaData.height = imageWidth;
                        }
                        img.left = imageHeight;
                    }
                    else {
                        canvasRef.current.setWidth(imageWidth);
                        canvasRef.current.setHeight(imageHeight);
                        if (imageMetaData.width === 0 || imageMetaData.height === 0) {
                            imageMetaData.width = imageWidth;
                            imageMetaData.height = imageHeight;
                        }
                        img.left = imageWidth;
                    }
                    // add image to canvas and render it.
                    canvasRef.current.add(img);
                    canvasRef.current.centerObject(img);
                    canvasRef.current.renderAll();
                    // get cropped image.
                    var pngURL = canvasRef.current.toDataURL({
                        top: imageMetaData.y,
                        left: imageMetaData.x,
                        width: imageMetaData.width,
                        height: imageMetaData.height,
                    });
                    // set image src to cropped image.
                    imageRef.current.src = pngURL;
                }, {
                    crossOrigin: "anonymous"
                });
            }
            catch (e) { } // fail silently.
        }
    }, [imageUrl]);
    useEffect(function () {
        if (!containerRect || !jonnyContainerRef.current) {
            return;
        }
        imageContainerRef.current.style.width =
            containerRect.width -
                jonnyContainerRef.current.clientWidth -
                40 +
                "px";
        imageContainerRef.current.style.height =
            containerRect.height - 180 + "px";
        imageContainerRef.current.style.left =
            jonnyContainerRef.current.clientWidth - 80 + "px";
        imageContainerRef.current.style.transform = "scale(0) translateY(-50%)";
        playJonnyGesture();
    }, [containerRect]);
    useImperativeHandle(ref, function () { return jonnyBearRef.current; });
    var showContent = function () {
        var timeline = gsap.timeline();
        // Making the jonny bear left aligned
        timeline.to(jonnyContainerRef.current, { left: "20px" });
        var isAlternateOrSilentDialog = typeof dialog.isAlternativeOrSilent == "boolean" ? dialog.isAlternativeOrSilent : false;
        // show image
        timeline.fromTo(imageContainerRef.current, { scale: isAlternateOrSilentDialog ? 1 : 0, translateY: "-50%" }, { scale: 1, translateY: "-50%" });
    };
    var hideContent = function () {
        return new Promise(function (resolve) {
            var timeline = gsap.timeline({ onComplete: resolve });
            // hide image
            timeline.to(imageContainerRef.current, { scale: 0 });
            // Making the jonny bear center aligned
            timeline.to(jonnyContainerRef.current, {
                left: (containerRect.width -
                    jonnyContainerRef.current.clientWidth) /
                    2
            });
        });
    };
    var start = function () {
        stopJonnyGesture();
        gsap.to(jonnyContainerRef.current, {
            left: (containerRef.current.clientWidth -
                jonnyContainerRef.current.clientWidth) /
                2
        });
        gsap.to(actionContainerRef.current, {
            bottom: "auto",
            top: 20,
            right: 20,
            width: "auto",
            transform: "none"
        });
        if (isSafari()) {
            var audioElement_1 = new Audio();
            var audioContext_1 = ConversationAPI.createAudioContext();
            // Use the audio Context once here, else microphone permission will not work, on safari.
            audioElement_1.src = "data:audio/wav;base64,UklGRi4EAABXQVZFZm10IBAAAAABAAEA6AMAAOgDAAABAAgATElTVBoAAABJTkZPSVNGVA4AAABMYXZmNTkuMjIuMTAwAGRhdGHoAwAAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgA==";
            audioElement_1.play().then(function () { return startConversation(audioElement_1, audioContext_1); });
        }
        else {
            startConversation();
        }
    };
    var playJonnyGesture = function () {
        JbGestures[JbInteractionStyles.Idle].playRandom(jonnyBearRef.current.executor);
    };
    var stopJonnyGesture = function () {
        jonnyBearRef.current.clear();
    };
    var renderLoader = function () {
        return (loading && (React.createElement(Spin, { className: "playlist-conv__loader", spinning: true, size: "large" })));
    };
    var renderImage = function () {
        return (React.createElement("div", { className: "playlist-conv__content", ref: imageContainerRef },
            React.createElement("img", { ref: imageRef, src: "" })));
    };
    var renderJonny = function () {
        return (React.createElement("div", { className: "pc-jonny", ref: jonnyContainerRef },
            React.createElement(JonneyBear, { ref: jonnyBearRef, playbackRate: props.playbackRate })));
    };
    var playIntroVideo = function () {
        resetConversationAndShowIntroVideo();
    };
    var renderActions = function () {
        return (React.createElement("div", { className: "pc-action", ref: actionContainerRef },
            !isConversationStarted && (React.createElement("a", { className: "pc-action__item", onClick: start },
                React.createElement(MatIcon, { type: "done" }))),
            React.createElement("a", { className: "pc-action__item pc-action--green", onClick: playIntroVideo },
                React.createElement(Icon, { type: "question" })),
            React.createElement("a", { className: "pc-action__item pc-action--red", onClick: cancelConversation },
                React.createElement(MatIcon, { type: "close" }))));
    };
    var renderHiddenCanvas = function () {
        return React.createElement("div", { style: { display: "none", position: "absolute", zIndex: -99 } },
            React.createElement("canvas", { id: "imageCropper" }));
    };
    var renderListener = function () {
        return (isConversationStarted ? isListening ? React.createElement("div", { className: "mic-enabled " + (isBlinking ? "blink-microphone always-blink" : ""), onClick: function () { return startAndStopListening(); } }) : React.createElement("div", { className: "mic-disabled" }) : React.createElement(React.Fragment, null));
    };
    return (React.createElement("div", { className: "playlist-conv", ref: containerRef },
        renderLoader(),
        renderJonny(),
        renderActions(),
        renderImage(),
        renderHiddenCanvas(),
        renderListener()));
});
ConversationPlayer.displayName = "ConversationPlayer";
