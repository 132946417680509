var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConversationServiceClass } from "@app/service/conversation";
var defaultState = {
    unitPendingConversations: []
};
export default {
    namespace: "conversation",
    state: defaultState,
    reducers: {
        updateUnitPendingConversations: function (state, action) {
            var data = action.payload.data;
            return __assign({}, state, { unitPendingConversations: data });
        }
    },
    effects: {
        fetchUnitPendingConversation: function (action, _a, _b) {
            var _c, unit, studentId, repMethodologyVersionId, unitPendingConversations;
            var call = _a.call, put = _a.put;
            var conversationService = _b.conversationService;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _c = action.payload, unit = _c.unit, studentId = _c.studentId, repMethodologyVersionId = _c.repMethodologyVersionId;
                        return [4 /*yield*/, call(conversationService.getUnitPendingConversation, unit, studentId, repMethodologyVersionId)];
                    case 1:
                        unitPendingConversations = _d.sent();
                        return [4 /*yield*/, put(updateUnitPendingConversations(studentId, unitPendingConversations))];
                    case 2:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        }
    },
    services: {
        conversationService: ConversationServiceClass,
    },
};
var updateUnitPendingConversations = function (studentId, data) {
    return {
        type: "conversation/updateUnitPendingConversations",
        payload: { studentId: studentId, data: data },
    };
};
export var fetchUnitPendingConversationAction = function (_a) {
    var unit = _a.unit, studentId = _a.studentId, repMethodologyVersionId = _a.repMethodologyVersionId;
    return {
        type: "conversation/fetchUnitPendingConversation",
        payload: { unit: unit, studentId: studentId, repMethodologyVersionId: repMethodologyVersionId },
    };
};
