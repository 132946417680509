var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var OpenEyeIcon = /** @class */ (function (_super) {
    __extends(OpenEyeIcon, _super);
    function OpenEyeIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OpenEyeIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 45 32" },
            React.createElement("circle", { className: "path", cx: "23", cy: "16", r: "16" }),
            React.createElement("g", { id: "open-eye", transform: "matrix(1 0 0 1 7 1)", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("path", { d: "M16.0000146,12.8571538 L16.0000145,12.8571538 C14.2642616,12.8571538 12.8571537,14.2642617 12.8571537,16.0000146 C12.8571537,16.0000146 12.8571537,16.0000146 12.8571537,16.0000146 L12.8571537,16.0000151 C12.8571537,17.735768 14.2642616,19.1428759 16.0000145,19.1428759 L16.0000144,19.1428759 C17.7357673,19.1428759 19.1428752,17.7357705 19.1428752,16.0000151 L19.1428752,16.0000151 C19.1428752,14.2642622 17.7357673,12.8571538 16.0000144,12.8571538 M16.0000144,21.2381083 L16.0000143,21.2381083 C13.1070846,21.2381083 10.7619211,18.892935 10.7619211,16.0000151 C10.7619211,16.0000149 10.7619211,16.0000146 10.7619211,16.0000144 L10.7619211,16.0000136 C10.7619211,13.1070839 13.1070968,10.7619205 16.0000143,10.7619205 L16.0000146,10.7619205 C18.8929443,10.7619205 21.2381077,13.1070962 21.2381077,16.0000136 L21.2381077,16.0000133 C21.2381077,18.892943 18.8929345,21.2381083 16.0000146,21.2381083 M16.0000143,8.14286133 C10.7619211,8.14286133 6.2885744,11.4009522 4.47619953,16.0000133 C6.2885744,20.5990745 10.7619211,23.8571653 16.0000143,23.8571653 C21.2381074,23.8571653 25.7114541,20.5990745 27.523829,16.0000133 C25.7114541,11.4009522 21.2381074,8.14286133 16.0000143,8.14286133 Z", id: "openeye", fill: "white", fillRule: "nonzero" }))));
    };
    return OpenEyeIcon;
}(Component));
export default OpenEyeIcon;
