var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useService } from "@app/hooks";
import { StudentLocale } from "@app/locales/localeid";
import { fmtMsg, TYPES } from "@app/util";
import { Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./conversation-intro-player.less";
export var ConversationIntroPlayer = function (_a) {
    var onConversationIntroClose = _a.onConversationIntroClose, playbackRate = _a.playbackRate;
    var conversationIntroService = useService(TYPES.IConversationIntroService);
    var _b = __read(useState(null), 2), introVideoUrl = _b[0], setIntroVideoUrl = _b[1];
    var videoRef = useRef(null);
    useEffect(function () {
        conversationIntroService.getConversationIntroVideo().then(function (url) {
            setIntroVideoUrl(url);
            videoRef.current.disablePictureInPicture = true;
            videoRef.current.load();
            videoRef.current.playbackRate = playbackRate;
            videoRef.current.addEventListener("ended", onVideoEnded, false);
        });
        return function () {
            videoRef.current.removeEventListener("ended", onVideoEnded, false);
        };
    }, []);
    var onVideoEnded = function (e) {
        onConversationIntroClose();
    };
    return (React.createElement(Modal, { title: fmtMsg({ id: StudentLocale.ConversationIntroVideoTitle }), visible: true, footer: null, onCancel: onConversationIntroClose, maskClosable: false, className: "conv-intro-player", centered: true, destroyOnClose: true },
        React.createElement("div", { className: "video-section" },
            React.createElement("video", { ref: videoRef, autoPlay: true, controls: true, controlsList: "nodownload noplaybackrate" },
                React.createElement("source", { src: introVideoUrl, type: "video/mp4" })))));
};
