var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { JbAnimations } from "@app/components/jonny-bear";
import { JbGestures } from "@app/components/jonny-bear/gestures";
import { JbInteractionStyles } from "@app/components/jonny-bear/gestures/gestures.types";
import { cloneDeep, includes, isEqual, isFunction, isNull, isUndefined, uniq } from "lodash";
import { AudioConfig, ResultReason } from "microsoft-cognitiveservices-speech-sdk";
import { Constants } from "../constant";
import { getCLUPredictionResponseAsLUISResponse, isIOS, isOldConversationImage, isSafari } from "..";
import { AzureLuisCognitiveService } from "./services/azure-luis-cognitive-service";
import { AzureSpeechRecognizerCognitiveService, SpeechRecognizerResultReason } from "./services/azure-speech-recognizer-cognitive-service";
import { ConversationAPIEvents, ConversationAuthorType, ConversationEndedState, ConversationStatus, ConversationTemplateType, PathTypes, conversationDialogTrackTemplate, DialogInteractionType } from "./utils/conversation-api.model";
import { MicAudioSource } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/MicAudioSource";
import { AudioStreamFormatImpl } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/sdk/Audio/AudioStreamFormat";
import { PcmRecorder } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/PCMRecorder";
import { AudioConfigImpl } from "microsoft-cognitiveservices-speech-sdk/distrib/lib/src/sdk/Audio/AudioConfig";
import { OFFLINE_AUDIO } from "@app/style/audios/offline-audio.js";
import { ConversationService } from "@app/service/conversation";
import { getConversationNextDialogId } from "gs-common-functions";
import { NonePathValue, ZeroScore } from ".";
var speechsdk = isSafari() ? require('microsoft-cognitiveservices-speech-sdk') : null;
var offlineDialogObj = {
    body: "",
    voiceUrl: OFFLINE_AUDIO,
    imageUrl: "",
    author: 1,
    isConclusion: true,
    isAlternativeOrSilent: false,
    data: {
        id: 0,
        imageUrl: "",
        voiceUrl: OFFLINE_AUDIO,
        order: 0,
        retryCount: 0,
        currentRetryCount: 0,
        currentSilentRetryCount: 0,
        currentAlternateRetryCount: 0,
        isHead: true,
        isOptional: false,
        value: "",
        statusId: 1,
        nextDialogId: 0,
        name: "",
        path: [],
        alternateDialog: [],
        isConclusion: true,
        interactionStyleId: JbInteractionStyles.Negative,
        imageMetaData: null
    }
};
var ConversationAPI = /** @class */ (function () {
    function ConversationAPI(conversationInfo, imageSignature, audioSignature, options, testMode, isServerDown) {
        var _this = this;
        this.conversationInfo = conversationInfo;
        this.imageSignature = imageSignature;
        this.audioSignature = audioSignature;
        this.options = {};
        this.conversationItems = [];
        this.retryDialodId = -1;
        this.noneNextDialogId = 0;
        this.recognizing = false;
        this.recognizingStopped = false;
        this.dialogAudio = new Audio();
        this.isConversationEnded = false;
        this.audioContext = null;
        this.testMode = false;
        this.isServerDown = false;
        this.disposed = false;
        this.azureSpeechRecognizerCognitiveService = null;
        this.conversationScores = [];
        this.RecognizedText = '';
        this.getActiveDialogById = function (id) {
            return _this.conversationInfo.conversationDialog.find(function (c) { return c.id === id && c.statusId === ConversationStatus.Active; });
        };
        this.addRecognizerListeners = function () {
            if (_this.recognizer) {
                _this.recognizer.sessionStarted = function () {
                    _this.emitAnEvent(ConversationAPIEvents.onListenStart);
                };
                _this.recognizer.recognized = function () {
                    _this.emitAnEvent(ConversationAPIEvents.onListenStop);
                };
            }
        };
        this.convertScoreToString = function (score) {
            return (score * 100).toFixed(2).toString();
        };
        this.addConversationScore = function (queryText, score) {
            var newConversationScore = {
                body: queryText,
                score: score + "%"
            };
            _this.conversationScores = __spread(_this.conversationScores, [
                newConversationScore
            ]);
        };
        this.addNoneScore = function (queryText, topIntent, score) {
            if (topIntent === NonePathValue) {
                _this.addConversationScore(queryText, ZeroScore.toString());
            }
            else {
                _this.addConversationScore(queryText, _this.convertScoreToString(score));
            }
        };
        this.getRetryOrFallbackId = function (currentDialog) {
            if (_this.canRetry(currentDialog)) {
                return _this.retryDialodId;
            }
            else {
                return _this.getFallbackPathId(currentDialog);
            }
        };
        this.emitConversationEndEvent = function () {
            if (_this.disposed)
                return;
            if (_this.conversationEndedState === ConversationEndedState.OnEnd) {
                _this.playGesture(JbInteractionStyles.Neutral, 0).then(function () {
                    _this.emitAnEvent(_this.conversationEndedState);
                });
            }
            else {
                _this.emitAnEvent(_this.conversationEndedState);
            }
        };
        if (!isServerDown) {
            var _a = this.conversationInfo.speechConfig, speechKey = _a.speechKey, speechRegion = _a.speechRegion;
            this.azureSpeechRecognizerCognitiveService = new AzureSpeechRecognizerCognitiveService(speechKey, speechRegion);
            if (conversationInfo.luisAppId) {
                this.setAzureServices();
            }
            this.setImageSasUrl();
            this.setAudioSasUrl();
        }
        if (options) {
            this.options = options;
        }
        if (testMode) {
            this.testMode = testMode;
        }
        if (isServerDown) {
            this.isServerDown = isServerDown;
        }
        /**
         * in case of safari, recognizer is created, when user starts the conversation.
         * check {@link setAudioContext}
         */
        !isSafari() && this.setAudioRecognizer();
    }
    ConversationAPI.createAudioContext = function () {
        return AudioStreamFormatImpl.getAudioContext(new AudioStreamFormatImpl().samplesPerSec);
    };
    ConversationAPI.prototype.setImageSasUrl = function () {
        var signatureUrl = new URL(this.imageSignature.url);
        this.imageSasUrl = signatureUrl.origin + "/" + this.imageSignature.name + "/{path}" + signatureUrl.search;
    };
    ConversationAPI.prototype.setAudioSasUrl = function () {
        var signatureUrl = new URL(this.audioSignature.url);
        this.audioSasUrl = signatureUrl.origin + "/" + this.audioSignature.name + "/{path}" + signatureUrl.search;
    };
    ConversationAPI.prototype.getImageUrl = function (path) {
        if (path) {
            if (isOldConversationImage(path)) {
                // old image for conversation was stored in same container as voice URLs
                return this.audioSasUrl.replace("{path}", path);
            }
            return this.imageSasUrl.replace("{path}", path);
        }
        return null;
    };
    ConversationAPI.prototype.getAudioUrl = function (path) {
        if (path) {
            return this.audioSasUrl.replace("{path}", path);
        }
        return null;
    };
    ConversationAPI.prototype.getTargetDialogs = function (nextDialogId, currentDialogId, projectedConversations) {
        var nextId = nextDialogId;
        var currentId = currentDialogId;
        if (!isUndefined(nextId) && !isNull(nextId)) {
            var nextConversation = this.getActiveDialogById(nextId);
            if (!isUndefined(nextConversation) && !isNull(nextConversation)) {
                projectedConversations.push({
                    body: nextConversation.value,
                    voiceUrl: nextConversation.voiceUrl,
                    author: ConversationAuthorType.Machine,
                    imageUrl: this.getImageUrl(nextConversation.imageUrl),
                    isConclusion: nextConversation.isConclusion,
                    data: nextConversation,
                });
                currentId = nextConversation.id;
                nextId = nextConversation.nextDialogId;
                this.currentDialogId = currentId;
                if (isNull(nextId) && nextConversation.path.length === 0) {
                    // projectedConversations.push({
                    //     body: "The conversation is now finished.",
                    //     voiceUrl: null,
                    //     author: ConversationAuthorType.Machine,
                    //     imageUrl: null,
                    // });
                    this.endConversation();
                    // Event of conversation end will be emitted after the dialog is spoken.
                }
                this.getTargetDialogs(nextId, currentId, projectedConversations);
            }
        }
    };
    ConversationAPI.prototype.setAudioRecognizer = function (force) {
        if (force === void 0) { force = false; }
        if (isSafari()) {
            if (!this.recognizer || force) {
                if (this.privRecorder) {
                    this.privRecorder.releaseMediaResources(this.audioContext);
                }
                // create recorder and audioConfig
                this.privRecorder = new PcmRecorder(true);
                ;
                MicAudioSource.prototype.destroyAudioContext = function () { };
                var micSource = new MicAudioSource(this.privRecorder, this.options.microphoneDeviceId);
                // set micSource audio context
                micSource.privContext = this.audioContext;
                if (!this.isServerDown) {
                    var audioConfig = new AudioConfigImpl(micSource);
                    this.recognizer = new speechsdk.SpeechRecognizer(this.azureSpeechRecognizerCognitiveService.getSpeechConfig(), audioConfig);
                    this.addRecognizerListeners();
                }
            }
        }
        else {
            if (!this.isServerDown) {
                var audioConfig = AudioConfig.fromMicrophoneInput(this.options.microphoneDeviceId);
                this.recognizer = this.azureSpeechRecognizerCognitiveService.getSpeechRecognizer(audioConfig);
                this.addRecognizerListeners();
            }
        }
    };
    ConversationAPI.prototype.setAzureServices = function () {
        this.azureLuisCognitiveService = new AzureLuisCognitiveService(this.conversationInfo.predictionKey, this.conversationInfo.predictionEndpoint);
    };
    ConversationAPI.prototype.getHeadDialog = function () {
        return this.conversationInfo.conversationDialog.find(function (c) { return c.isHead && c.statusId === ConversationStatus.Active; });
    };
    ConversationAPI.prototype.queryToLuis = function (speechText) {
        return __awaiter(this, void 0, void 0, function () {
            var deploymentName, projectName, endPoint, key, requestData, data, _a, topIntentObj, transformedEntities, intents, topIntent, entities, _b, nextDialogId, _c, dialogTrack, projectedPath, doesConvEndsWithPath, nextDialogs, err_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!speechText) {
                            return [2 /*return*/];
                        }
                        deploymentName = this.conversationInfo.deploymentName || "";
                        projectName = this.conversationInfo.projectName || "";
                        endPoint = this.conversationInfo.endPoint || "";
                        key = this.conversationInfo.key || "";
                        requestData = {
                            deploymentName: deploymentName,
                            projectName: projectName,
                            endPoint: endPoint,
                            key: key,
                            queryText: speechText
                        };
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 5]);
                        return [4 /*yield*/, ConversationService.queryCLU(requestData)];
                    case 2:
                        data = _d.sent();
                        _a = getCLUPredictionResponseAsLUISResponse(data), topIntentObj = _a.topIntentObj, transformedEntities = _a.transformedEntities;
                        intents = data.prediction.intents;
                        topIntent = data.prediction.topIntent;
                        entities = data.prediction.entities;
                        _b = __read(this.getNextDialog(topIntent, topIntentObj, speechText, entities), 2), nextDialogId = _b[0], _c = _b[1], dialogTrack = _c.dialogTrack, projectedPath = _c.projectedPath;
                        if (dialogTrack) {
                            doesConvEndsWithPath = nextDialogId === this.noneNextDialogId && !!projectedPath;
                            // If need to retry or no next dialog exists, set match to false
                            if (!doesConvEndsWithPath && (nextDialogId === this.retryDialodId || nextDialogId === this.noneNextDialogId)) {
                                dialogTrack.isMatched = false;
                            }
                            this.emitAnEvent(ConversationAPIEvents.onUserResponse, dialogTrack);
                        }
                        if (projectedPath) {
                            //await this.playGesture(projectedPath.interactionStyleId);
                        }
                        nextDialogs = getConversationNextDialogId(topIntent, topIntentObj, speechText, transformedEntities, this.getActiveDialogById, this.currentDialogId, this.conversationInfo, getCLUPredictionResponseAsLUISResponse, this.getRetryOrFallbackId, data, this.noneNextDialogId, this.getUtteranceValues, {
                            addNoneScore: this.addNoneScore,
                            addConversationScore: this.addConversationScore,
                            utteranceUseEntity: false
                        });
                        this.moveToNext(nextDialogs[0], ConversationTemplateType.Regular);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _d.sent();
                        console.log('queryToLuis error: ', err_1);
                        this.isServerDown = true;
                        return [4 /*yield*/, this.speak(offlineDialogObj)];
                    case 4:
                        _d.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ConversationAPI.prototype.moveToNext = function (nextDialogId, conversationTemplateType, endMsg) {
        if (nextDialogId === this.noneNextDialogId) {
            // nextDialogId is set to noneNextDialogId, only in case the retry counts are exahusted and no fallback is present
            // In test mode keep the screen freezed.
            if (!this.testMode) {
                this.endConversation("Retry exhausted.");
                this.emitConversationEndEvent();
            }
            return;
        }
        if (nextDialogId !== this.noneNextDialogId && nextDialogId !== this.retryDialodId) {
            var projectedDialog = this.getActiveDialogById(nextDialogId);
            if (!isUndefined(projectedDialog)) {
                var projectedConversations = [];
                projectedConversations.push({
                    body: projectedDialog.value,
                    voiceUrl: projectedDialog.voiceUrl,
                    author: ConversationAuthorType.Machine,
                    imageUrl: this.getImageUrl(projectedDialog.imageUrl),
                    isConclusion: projectedDialog.isConclusion,
                    data: projectedDialog,
                });
                var currentDialogId = projectedDialog.id;
                this.currentDialogId = currentDialogId;
                if (!projectedDialog.nextDialogId && projectedDialog.path.length === 0) {
                    // projectedConversations.push({
                    // 	body: fmtMsg(this.locale.ConversationEndMessage),
                    // 	author: ConversationAuthorType.Machine,
                    // 	imageUrl: ""
                    // });
                    this.endConversation();
                    // End conversation event will be emitted after the dialog is spoken.
                }
                else if (projectedDialog.nextDialogId) {
                    var nextId = projectedDialog.nextDialogId;
                    this.getTargetDialogs(nextId, currentDialogId, projectedConversations);
                }
                this.speakAndListen(projectedConversations);
                // this.setIsLoadingMachineMessage(false);
                // this.conversations = [...this.conversations, ...projectedConversations];
                // this.scrollChatArea();
            }
            else {
                // this.setIsLoadingMachineMessage(false);
                this.endConversation("No active projected dialog.");
                this.emitConversationEndEvent();
                // this.scrollChatArea();
            }
        }
        else if (nextDialogId === this.retryDialodId) {
            var currentRetryCount = this.updateDialogRetryCount(this.currentDialogId, conversationTemplateType);
            var currentDialog = this.getActiveDialogById(this.currentDialogId);
            // this.setIsLoadingMachineMessage(false);
            if (!isUndefined(currentDialog) && !isNull(currentDialog) && currentRetryCount > 0) {
                var alternativeTexts = currentDialog.alternateDialog.filter(function (d) { return d.dialogTypeId === conversationTemplateType && d.statusId === ConversationStatus.Active; });
                if (alternativeTexts.length > 0) {
                    var alternativeTextObject = this.getAlternateDialogByCurrentRetryCount(alternativeTexts, currentRetryCount);
                    if (!isUndefined(alternativeTextObject)) {
                        this.speakAndListen([
                            {
                                body: alternativeTextObject.value,
                                voiceUrl: alternativeTextObject.voiceUrl,
                                author: ConversationAuthorType.Machine,
                                imageUrl: this.getImageUrl(currentDialog.imageUrl),
                                isConclusion: false,
                                isAlternativeOrSilent: true,
                                data: currentDialog,
                            },
                        ]);
                    }
                    else {
                        this.endConversation(endMsg ? endMsg : "Alternative text not present.");
                        this.emitConversationEndEvent();
                    }
                }
                else {
                    this.speakAndListen([
                        {
                            body: currentDialog.value,
                            voiceUrl: currentDialog.voiceUrl,
                            author: ConversationAuthorType.Machine,
                            imageUrl: this.getImageUrl(currentDialog.imageUrl),
                            isConclusion: currentDialog.isConclusion,
                            isAlternativeOrSilent: true,
                            data: currentDialog,
                        },
                    ]);
                }
            }
            else {
                this.endConversation(endMsg ? endMsg : "Retry count issue.");
                this.emitConversationEndEvent();
            }
            // this.scrollChatArea();
        }
        else {
            // this.setIsLoadingMachineMessage(false);
            this.endConversation(endMsg ? endMsg : "No next dialog or retry count exahusted.");
            this.emitConversationEndEvent();
            // this.scrollChatArea();
        }
    };
    ConversationAPI.prototype.updateDialogRetryCount = function (dialogId, conversationTemplateType) {
        var currentRetryCount = 0;
        if (!isUndefined(this.conversationInfo) && !isNull(this.conversationInfo)) {
            var dialogIndex = this.conversationInfo.conversationDialog.findIndex(function (d) { return d.id === dialogId; });
            if (dialogIndex > -1) {
                currentRetryCount = this.conversationInfo.conversationDialog[dialogIndex].currentRetryCount =
                    this.conversationInfo.conversationDialog[dialogIndex].currentRetryCount + 1;
                if (conversationTemplateType === ConversationTemplateType.Silent) {
                    currentRetryCount = this.conversationInfo.conversationDialog[dialogIndex].currentSilentRetryCount =
                        this.conversationInfo.conversationDialog[dialogIndex].currentSilentRetryCount + 1;
                }
                else if (conversationTemplateType === ConversationTemplateType.Regular) {
                    currentRetryCount = this.conversationInfo.conversationDialog[dialogIndex].currentAlternateRetryCount =
                        this.conversationInfo.conversationDialog[dialogIndex].currentAlternateRetryCount + 1;
                }
            }
        }
        return currentRetryCount;
    };
    ConversationAPI.prototype.getAlternateDialogByCurrentRetryCount = function (alternateDialog, currentRetryCount) {
        if (!isUndefined(alternateDialog) && !isNull(alternateDialog) && alternateDialog.length > 0) {
            var condition1 = alternateDialog.find(function (a) { return a.sequence === currentRetryCount; });
            if (!isUndefined(condition1)) {
                return condition1;
            }
            var maxSequenceDialog_1 = alternateDialog.reduce(function (p, c) { return (p.sequence > c.sequence ? p : c); });
            var condition2 = alternateDialog.find(function (a) { return a.sequence === maxSequenceDialog_1.sequence; });
            return condition2;
        }
        else {
            return undefined;
        }
    };
    ConversationAPI.prototype.getNextDialog = function (topIntent, topIntentObj, queryText, entities, isCLUPrediction) {
        if (isCLUPrediction === void 0) { isCLUPrediction = false; }
        var currentDialog = this.getActiveDialogById(this.currentDialogId);
        if (!currentDialog) {
            // this.addNoneScore(queryText, topIntent, topIntentObj.score);
            return [this.noneNextDialogId, null];
        }
        var matchingScore = topIntentObj.score * 100;
        var isMatched = matchingScore >= this.conversationInfo.intentMatchThreshold;
        var dialogTrack = {
            conversationDialogId: currentDialog.id,
            conversationDialogName: currentDialog.name,
            intentName: topIntent,
            pathId: null,
            pathName: null,
            utterenceId: null,
            utterenceText: queryText,
            matchingScore: matchingScore,
            isMatched: isMatched,
            attempt: currentDialog.currentRetryCount + 1,
            topIntentName: topIntent,
            matchedIntentName: null,
            matchedIntentId: null,
            matchedEntityId: null,
            matchedEntityName: null,
            matchedEntityValue: null,
            isHead: currentDialog.isHead,
            isCompleted: currentDialog.isConclusion
        };
        if (!isMatched) {
            // this.addNoneScore(queryText, topIntent, topIntentObj.score);
            return [this.getRetryOrFallbackId(currentDialog), { dialogTrack: dialogTrack }];
        }
        if (currentDialog.nextDialogId) {
            return [currentDialog.nextDialogId, { dialogTrack: null }];
        }
        else {
            var entitiesData_1 = new Map();
            var entityKeys = Object.keys(entities);
            entityKeys.forEach(function (e) {
                if (!entitiesData_1.has(e)) {
                    entitiesData_1.set(e, entities[e]);
                }
            });
            var currentDialogPaths = currentDialog.path.filter(function (p) { return p.pathTypeId === PathTypes.Normal && p.statusId === ConversationStatus.Active; });
            var projectedPath = void 0;
            var projectedPathIntent = void 0;
            var projectedEntity = void 0;
            if (currentDialogPaths.length) {
                currentDialogPaths = currentDialogPaths.sort(function (a, b) { return a.order - b.order; });
                var _loop_1 = function () {
                    // Exiting from loop if the path is already found in previous iteration
                    if (projectedPath) {
                        return "break";
                    }
                    var iteratedPath = currentDialogPaths[i];
                    var projectedIntent = iteratedPath.intent.find(function (i) { return i.statusId === ConversationStatus.Active && i.name.toLocaleLowerCase() === topIntent.toLocaleLowerCase(); });
                    if (projectedIntent) {
                        // Entities used by intent.
                        var intentEntityIds_1 = uniq(projectedIntent.utterence.filter(function (u) { return u.entityId; }).map(function (u) { return u.entityId; }));
                        // Relevant pathEntities as per matched intent.
                        var pathEntities_1 = iteratedPath.pathEntityValue.filter(function (pev) { return includes(intentEntityIds_1, pev.entityId); });
                        var intentUtterancesValues = this_1.getUtteranceValues(projectedIntent.utterence, pathEntities_1);
                        var entitiesdataSize = entitiesData_1.size;
                        if (pathEntities_1.length > 0 && entitiesdataSize > 0) {
                            for (var j = 0; j < pathEntities_1.length; j++) {
                                if (entitiesData_1.has(pathEntities_1[j].entityName)) {
                                    var currentEntityValues = entitiesData_1.get(pathEntities_1[j].entityName);
                                    if (currentEntityValues.some(function (v) { return v.includes(pathEntities_1[j].value); })) {
                                        if (includes(intentUtterancesValues, queryText.toLocaleLowerCase())) {
                                            projectedPath = iteratedPath;
                                            projectedPathIntent = projectedIntent;
                                            projectedEntity = pathEntities_1[j];
                                            break;
                                        }
                                        projectedPath = iteratedPath;
                                        projectedPathIntent = projectedIntent;
                                        break;
                                    }
                                }
                            }
                        }
                        else {
                            projectedPath = iteratedPath;
                            projectedPathIntent = projectedIntent;
                            return "break";
                        }
                    }
                };
                var this_1 = this;
                for (var i = 0; i < currentDialogPaths.length; i++) {
                    var state_1 = _loop_1();
                    if (state_1 === "break")
                        break;
                }
            }
            if (projectedPath) {
                dialogTrack.pathId = projectedPath.id;
                dialogTrack.pathName = projectedPath.value;
            }
            if (projectedPathIntent) {
                dialogTrack.matchedIntentName = projectedPathIntent.name;
                dialogTrack.matchedIntentId = projectedPathIntent.id;
            }
            if (projectedEntity) {
                dialogTrack.matchedEntityId = projectedEntity.entityId;
                dialogTrack.matchedEntityName = projectedEntity.entityName;
                dialogTrack.matchedEntityValue = projectedEntity.value;
            }
            if (projectedPath && projectedPath.nextDialogId) {
                // this.addConversationScore(queryText, this.convertScoreToString(topIntentObj.score));
                return [projectedPath.nextDialogId, { dialogTrack: dialogTrack, projectedPath: projectedPath }];
            }
            else {
                // this.addNoneScore(queryText, topIntent, topIntentObj.score);
                return [this.getRetryOrFallbackId(currentDialog), { dialogTrack: dialogTrack }];
            }
        }
    };
    ConversationAPI.prototype.getUtteranceValues = function (utterances, pathEntityValues) {
        var utteranceValues = [];
        var _loop_2 = function () {
            var currentUtterance = utterances[i];
            if (currentUtterance.statusId === ConversationStatus.Active) {
                if (!currentUtterance.isPattern) {
                    utteranceValues.push(currentUtterance.value.toLocaleLowerCase());
                }
                else if (currentUtterance.isPattern && currentUtterance.entityId) {
                    var retStr = currentUtterance.value;
                    var currentEntityValues = pathEntityValues.filter(function (p) { return p.entityId === currentUtterance.entityId; });
                    for (var j = 0; j < currentEntityValues.length; j++) {
                        var currentEntityValue = currentEntityValues[j];
                        utteranceValues.push(retStr.replace(new RegExp("{" + currentEntityValue.entityName + "}", "g"), currentEntityValue.value).toLocaleLowerCase());
                    }
                }
            }
        };
        for (var i = 0; i < utterances.length; i++) {
            _loop_2();
        }
        return utteranceValues;
    };
    ConversationAPI.prototype.getFallbackPathId = function (currentDialog) {
        var fallbackPath = currentDialog.path.find(function (i) { return i.isFallback && i.pathTypeId === PathTypes.Fallback && i.statusId === ConversationStatus.Active; });
        if (!isUndefined(fallbackPath) && fallbackPath.nextDialogId) {
            return fallbackPath.nextDialogId;
        }
        else {
            return this.noneNextDialogId;
        }
    };
    ConversationAPI.prototype.canRetry = function (currentDialog) {
        return currentDialog.retryCount > 0 && currentDialog.retryCount > currentDialog.currentRetryCount;
    };
    ConversationAPI.prototype.speak = function (dialog) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!dialog.voiceUrl) {
                // Emitting event to say - this dialog is being spoken
                _this.emitDialogChangeEvent(dialog);
                reject("Voice URL does not provided.");
                return;
            }
            if (!_this.dialogAudio) {
                reject("Audio player not initialized.");
                return;
            }
            var playBackRate = _this.options && _this.options.playbackRate && Constants.AllowedPlaybackRate.indexOf(_this.options.playbackRate) > -1
                ? _this.options.playbackRate : Constants.DefaultPlaybackRate;
            _this.dialogAudio.src = _this.isServerDown ? OFFLINE_AUDIO : _this.getAudioUrl(dialog.voiceUrl);
            _this.dialogAudio.playbackRate = playBackRate;
            _this.dialogAudio.onended = function () {
                if (_this.isServerDown) {
                    _this.endConversation("LUIS service down.");
                    _this.emitConversationEndEvent();
                }
                resolve();
            };
            _this.dialogAudio.onerror = function () {
                reject("Unable to play audio.");
            };
            // on IOS audio is loaded on calling play.
            if (isIOS()) {
                _this.dialogAudio.play();
            }
            _this.dialogAudio.onloadeddata = function (metadata) {
                // Emitting event to say - this dialog is being spoken
                var voiceDuration = (_this.dialogAudio.duration / playBackRate) * 1000;
                _this.emitDialogChangeEvent(dialog);
                if (dialog.isConclusion) {
                    _this.markConversationAsComplete();
                }
                // Play the voice
                _this.options.jbRef.executor([new JbAnimations.SpeakingCycle(voiceDuration), new JbAnimations.Neutral()]);
                !isIOS() && _this.dialogAudio.play();
            };
            _this.dialogAudio.addEventListener('waiting', function () {
                _this.options.jbRef.clear();
            });
            _this.dialogAudio.addEventListener('playing', function () {
                var voiceDuration = Number(((_this.dialogAudio.duration - _this.dialogAudio.currentTime) / playBackRate * 1000).toFixed(3));
                _this.options.jbRef.clear();
                _this.options.jbRef.executor([new JbAnimations.SpeakingCycle(voiceDuration), new JbAnimations.Neutral()]);
            });
        });
    };
    ConversationAPI.prototype.listen = function () {
        var _this = this;
        // Emitting event to say: I am listening now
        this.playGesture(JbInteractionStyles.Idle);
        this.emitAnEvent(ConversationAPIEvents.onListen, true);
        var recognize = function () {
            new Promise(function (res) {
                var done = false;
                //     const timeoutRef = setTimeout(() => {
                //     if(!done && !navigator.onLine) {
                //         this.options.jbRef.clear();
                //         this.isServerDown = true;
                //         this.speak(offlineDialogObj);
                //     }
                // }, 10000);
                _this.recognizer.startContinuousRecognitionAsync();
                _this.recognizer.recognized = function (s, event) {
                    done = true;
                    if (event.result.text) {
                        _this.RecognizedText = _this.RecognizedText.concat(' ', event.result.text);
                    }
                    if (_this.RecognizedReason != SpeechRecognizerResultReason.RecognizedSpeech) {
                        _this.RecognizedReason = ResultReason[event.result.reason];
                    }
                };
                res();
                // this.recognizer.recognizeOnceAsync(
                //     (event) => {
                //         done = true;
                //         clearTimeout(timeoutRef);
                //         this.options.jbRef.clear();
                //         // If the conversation is being canceled.
                //         if (this.recognizingStopped) {
                //             this.recognizingStopped = false;
                //             return;
                //         }
                //         // Emitting event to say: stopped listening
                //         this.emitAnEvent(ConversationAPIEvents.onListen, false);
                //         this.recognizing = false;
                //         const reason = ResultReason[event.reason];
                //         if (reason === SpeechRecognizerResultReason.RecognizedSpeech) {
                //             this.recognizing = false; // done as event not stopping on firefox
                //             this.queryToLuis(event.text);
                //         }
                //         if (reason === SpeechRecognizerResultReason.NoMatch) {
                //             this.recognizing = false; // done as event not stopping on firefox
                //             const currentDialog = this.getActiveDialogById(this.currentDialogId);
                //             if (!isUndefined(currentDialog) && !isNull(currentDialog)) {
                //                 const partialTrack: Partial<IConversationDialogTrack> = { attempt: currentDialog.currentRetryCount + 1};
                //                 this.triggerSendDialogHistory(currentDialog, DialogInteractionType.InteractiveDialog, partialTrack);
                //                 this.moveToNext(
                //                     this.getRetryOrSilentOrFallbackId(currentDialog),
                //                     ConversationTemplateType.Silent,
                //                     "ConversationNoSpeechMessage"
                //                 );
                //             } else {
                //                 this.endConversation("ConversationNoSpeechMessage");
                //                 this.emitConversationEndEvent();
                //             }
                //         } else {
                //             // Handle for any other reason.
                //         }
                //         res();
                //     },(err) => {
                //         done = true;
                //         clearTimeout(timeoutRef);
                //         // If the conversation is being canceled.
                //         if (this.recognizingStopped) {
                //             this.recognizingStopped = false;
                //             res();
                //             return;
                //         }
                //         // Emitting event to say: I stopped listening
                //         this.emitAnEvent(ConversationAPIEvents.onListen, false);
                //         this.emitAnEvent(ConversationAPIEvents.onListenStop);
                //         this.recognizing = false;
                //         // End conversation, if any error occurrs while recognizing
                //         this.endConversation("Error while calling STT");
                //         this.emitConversationEndEvent();
                //         res();
                //     }
                // this.recognizer.startContinuousRecognitionAsync();       
                // this.recognizer.recognized = function (s, event) {
                //   };
            }).then(function () {
                // to save some resources, suspend audiocontext, if available.
                // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext/suspend
                _this.audioContext && _this.audioContext.suspend();
            });
        };
        // check audioContext state and run recognition.
        if (this.audioContext && this.audioContext.state === 'suspended') {
            this.audioContext.resume().then(recognize);
        }
        else {
            setTimeout(function () {
                if (!navigator.onLine) {
                    _this.options.jbRef.clear();
                    _this.isServerDown = true;
                    _this.speak(offlineDialogObj);
                }
            }, 2000);
            recognize();
        }
    };
    ConversationAPI.prototype.stopListen = function () {
        var _this = this;
        setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var currentDialog, currentDialog, partialTrack;
            return __generator(this, function (_a) {
                if (this.RecognizedReason === SpeechRecognizerResultReason.RecognizedSpeech) {
                    this.recognizing = false; // done as event not stopping on firefox
                    this.queryToLuis(this.RecognizedText);
                    if (!this.RecognizedText) {
                        currentDialog = this.getActiveDialogById(this.currentDialogId);
                        this.moveToNext(this.getRetryOrSilentOrFallbackId(currentDialog), ConversationTemplateType.Silent, "ConversationNoSpeechMessage");
                    }
                    this.RecognizedText = '';
                    this.RecognizedReason = '';
                }
                if (this.RecognizedReason === SpeechRecognizerResultReason.NoMatch) {
                    this.recognizing = false; // done as event not stopping on firefox
                    currentDialog = this.getActiveDialogById(this.currentDialogId);
                    if (!isUndefined(currentDialog) && !isNull(currentDialog)) {
                        partialTrack = { attempt: currentDialog.currentRetryCount + 1 };
                        this.triggerSendDialogHistory(currentDialog, DialogInteractionType.InteractiveDialog, partialTrack);
                        this.moveToNext(this.getRetryOrSilentOrFallbackId(currentDialog), ConversationTemplateType.Silent, "ConversationNoSpeechMessage");
                    }
                    else {
                        this.endConversation("ConversationNoSpeechMessage");
                        this.emitConversationEndEvent();
                    }
                }
                else {
                    // Handle for any other reason.+
                }
                return [2 /*return*/];
            });
        }); }, 500);
        this.recognizer.stopContinuousRecognitionAsync();
    };
    ConversationAPI.prototype.getRetryOrSilentOrFallbackId = function (currentDialog) {
        if (this.canRetry(currentDialog)) {
            return this.retryDialodId;
        }
        else {
            return this.getSilentOrFallbackPathId(currentDialog);
        }
    };
    ConversationAPI.prototype.getSilentOrFallbackPathId = function (currentDialog) {
        var silentPath = currentDialog.path.find(function (i) { return i.pathTypeId === PathTypes.Silent && i.statusId === ConversationStatus.Active; });
        if (!isUndefined(silentPath) && silentPath.nextDialogId) {
            return silentPath.nextDialogId;
        }
        else {
            return this.getFallbackPathId(currentDialog);
        }
    };
    ConversationAPI.prototype.playGesture = function (interactionStyleId, index) {
        if (!index) {
            var jbIntractionStyleId = interactionStyleId || JbInteractionStyles.Positive;
            var jbGesture = JbGestures[jbIntractionStyleId];
            return jbGesture.playRandom(this.options.jbRef.executor);
        }
        else {
            var jbGesture = JbGestures[interactionStyleId];
            return jbGesture.play(this.options.jbRef.executor, index);
        }
    };
    ConversationAPI.prototype.isNonInteractiveDialog = function (dialog) {
        var path = dialog.path, nextDialogId = dialog.nextDialogId;
        // if condition below is true => the dialog is interactive dialog (the dialog "need to interact with student to be considered complete);
        if ((isUndefined(nextDialogId) || isNull(nextDialogId)) && path.length > 0) {
            return false;
        }
        return true;
    };
    ConversationAPI.prototype.triggerSendDialogHistory = function (dialog, type, partialTrack) {
        var dialogTrack = __assign({}, conversationDialogTrackTemplate, { conversationDialogId: dialog.id, isCompleted: dialog.isConclusion, isHead: dialog.isHead });
        if (partialTrack) {
            dialogTrack = __assign({}, dialogTrack, partialTrack);
        }
        if (type === DialogInteractionType.InteractiveDialog) {
            dialogTrack.isMatched = false;
        }
        this.emitAnEvent(ConversationAPIEvents.onTriggerSendDialogHistory, dialogTrack);
    };
    ConversationAPI.prototype.speakAndListen = function (projectedConversations) {
        var _this = this;
        var index = 0;
        var iterateOverDialogs = function () { return __awaiter(_this, void 0, void 0, function () {
            var dialog, preloadImagePromise, promises, _a, imageUrl, _, checkDuplicateImageNode, checkDuplicateDialogNode, i, imageBeforeCurrentNode, imageAfterCurrentNode, i, i, e_1, data, wait;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dialog = projectedConversations[index];
                        if (dialog.data.isHead) {
                            dialog.imageUrl = null;
                        }
                        preloadImagePromise = dialog.imageUrl
                            ? new Promise(function (res) {
                                fetch(dialog.imageUrl)
                                    .then(function (resp) { return resp.blob(); })
                                    .then(function (blob) { return res(URL.createObjectURL(blob)); })
                                    .catch(function () { return res(null); });
                            })
                            : Promise.resolve(null);
                        promises = [preloadImagePromise];
                        // JB animation before speaking
                        if (dialog.isAlternativeOrSilent) {
                            //promises.push(this.playGesture(JbInteractionStyles.Fallback));
                        }
                        else if (index === 0) { // only play gesture for first dialog
                            promises.push(this.playGesture(dialog.data.interactionStyleId));
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), imageUrl = _a[0], _ = _a[1];
                        // set imageURL to object url for current dialog.
                        dialog = cloneDeep(dialog);
                        dialog.imageUrl = imageUrl ? imageUrl : null;
                        checkDuplicateImageNode = function (index) {
                            return (projectedConversations[index] &&
                                projectedConversations[index].imageUrl &&
                                projectedConversations[index].data.imageUrl &&
                                projectedConversations[index].data.imageUrl ==
                                    dialog.data.imageUrl &&
                                isEqual(projectedConversations[index].data.imageMetaData, dialog.data.imageMetaData));
                        };
                        checkDuplicateDialogNode = function (imageBeforeCurrentNode, imageAfterCurrentNode) {
                            return (imageAfterCurrentNode > -1 &&
                                imageBeforeCurrentNode > -1 &&
                                projectedConversations[imageBeforeCurrentNode].data.imageUrl &&
                                projectedConversations[imageAfterCurrentNode].data.imageUrl &&
                                projectedConversations[imageBeforeCurrentNode].data.imageUrl ==
                                    projectedConversations[imageAfterCurrentNode].data.imageUrl &&
                                isEqual(projectedConversations[imageBeforeCurrentNode].data.imageMetaData, projectedConversations[imageAfterCurrentNode].data.imageMetaData));
                        };
                        if (imageUrl) {
                            for (i = index; i > 0; i--) {
                                if (checkDuplicateImageNode(i)) {
                                    dialog.imageUrl = projectedConversations[i].imageUrl;
                                    break;
                                }
                            }
                        }
                        else {
                            imageBeforeCurrentNode = -1;
                            imageAfterCurrentNode = -1;
                            for (i = index; i > 0; i--) {
                                if (projectedConversations[i] &&
                                    projectedConversations[i].imageUrl) {
                                    imageBeforeCurrentNode = i;
                                    break;
                                }
                            }
                            for (i = index; i < projectedConversations.length; i++) {
                                if (projectedConversations[i] &&
                                    projectedConversations[i].imageUrl) {
                                    imageAfterCurrentNode = i;
                                    break;
                                }
                            }
                            if (checkDuplicateDialogNode(imageBeforeCurrentNode, imageAfterCurrentNode)) {
                                dialog.imageUrl =
                                    projectedConversations[imageBeforeCurrentNode].imageUrl;
                            }
                        }
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.speak(dialog)];
                    case 3:
                        _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        this.endConversation("Speak Error");
                        this.emitConversationEndEvent();
                        return [2 /*return*/];
                    case 5:
                        data = dialog.data;
                        if (this.isNonInteractiveDialog(data)) {
                            this.triggerSendDialogHistory(data, DialogInteractionType.NonInteractiveDialog);
                        }
                        // After speaking
                        if (projectedConversations.length - 1 > index) {
                            index++;
                            wait = 0;
                            if (dialog.imageUrl) {
                                wait = 3000;
                            }
                            this.dialogTimeout = setTimeout(function () {
                                iterateOverDialogs();
                                _this.dialogTimeout = null;
                            }, wait);
                        }
                        else {
                            if (!this.isConversationEnded) {
                                // Play dialog style
                                this.emitAnEvent(ConversationAPIEvents.onListenStart);
                                // this.listen();
                            }
                            else {
                                this.stopListening();
                                // conversation is ended.
                                this.emitConversationEndEvent();
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        iterateOverDialogs();
    };
    ConversationAPI.prototype.emitAnEvent = function (event, params) {
        if (isFunction(this.options[event])) {
            this.options[event](params);
        }
    };
    ConversationAPI.prototype.emitDialogChangeEvent = function (dialog) {
        this.emitAnEvent(ConversationAPIEvents.onDialogChange, dialog);
    };
    ConversationAPI.prototype.endConversation = function (err) {
        this.isConversationEnded = true;
        if (err) {
            console.error(err);
            if (this.isServerDown) {
                this.conversationEndedState = ConversationEndedState.OnServerDown;
            }
            else {
                this.conversationEndedState = ConversationEndedState.Incomplete;
            }
        }
        else {
            this.conversationEndedState = ConversationEndedState.OnEnd;
        }
    };
    ConversationAPI.prototype.markConversationAsComplete = function () {
        this.emitAnEvent(ConversationAPIEvents.onComplete);
    };
    ConversationAPI.prototype.stopSpeech = function () {
        this.dialogAudio.pause();
        this.dialogAudio.src = null;
        if (this.dialogTimeout) {
            clearTimeout(this.dialogTimeout);
            this.dialogTimeout = null;
        }
    };
    ConversationAPI.prototype.startListening = function () {
        this.listen();
    };
    ConversationAPI.prototype.stopListening = function () {
        this.recognizingStopped = true;
        this.emitAnEvent(ConversationAPIEvents.onListen, false);
        this.emitAnEvent(ConversationAPIEvents.onListenStop);
        this.stopListen();
    };
    /**
     * This function must be called on safari browser only.
     * @param audioElement
     * @param audioContext
     */
    ConversationAPI.prototype.setAudioContext = function (audioElement, audioContext) {
        this.dialogAudio = audioElement;
        this.audioContext = audioContext;
        this.setAudioRecognizer();
    };
    ConversationAPI.prototype.updateMicrophone = function (microphoneDeviceId) {
        this.options.microphoneDeviceId = microphoneDeviceId;
        this.recognizer.close();
        this.setAudioRecognizer(true);
    };
    ConversationAPI.prototype.updateSpeed = function (speed) {
        this.options.playbackRate = speed;
    };
    ConversationAPI.prototype.dispose = function () {
        this.disposed = true;
        this.stopSpeech();
        this.stopListening();
        this.recognizer && this.recognizer.close();
        this.destroyAudioContext();
        this.dialogAudio = null;
        this.endConversation("Disposed");
    };
    ConversationAPI.prototype.getConversationName = function () {
        return this.conversationInfo.name;
    };
    ConversationAPI.prototype.start = function () {
        this.isConversationEnded = false;
        this.recognizingStopped = false;
        var headDialog = this.getHeadDialog();
        if (isUndefined(headDialog)) {
            this.endConversation("There is something wrong with the conversation.");
            this.emitConversationEndEvent();
            return;
        }
        var projectedConversations = [];
        projectedConversations.push({
            body: headDialog.value,
            voiceUrl: headDialog.voiceUrl,
            author: ConversationAuthorType.Machine,
            imageUrl: "",
            isConclusion: headDialog.isConclusion,
            data: headDialog,
        });
        var currentDialogId = headDialog.id;
        var nextDialogId = headDialog.nextDialogId;
        this.currentDialogId = currentDialogId;
        this.getTargetDialogs(nextDialogId, currentDialogId, projectedConversations);
        this.speakAndListen(projectedConversations);
    };
    ConversationAPI.prototype.destroyAudioContext = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.audioContext) {
                            return [2 /*return*/];
                        }
                        this.privRecorder.releaseMediaResources(this.audioContext);
                        return [4 /*yield*/, this.audioContext.close()];
                    case 1:
                        _a.sent();
                        this.audioContext = null;
                        return [2 /*return*/];
                }
            });
        });
    };
    return ConversationAPI;
}());
export { ConversationAPI };
