import React from "react";
import { Card, notification } from "antd-min";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil, maskThrottle, unmaskThrottle, GLGlobal } from "gl-commonui";
import { changeThemeColor } from "@app/util/func";
import { updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
import { RoleHelper } from "@app/util";
import { NoneFin } from "@app/components/svg-icon";
export default function UnitCard(_a) {
    var history = _a.history, to = _a.to, userId = _a.userId, unitInfo = _a.unitInfo;
    var unitClassName = null;
    if (unitInfo.id > 0) {
        unitClassName = "unit-" + unitInfo.index;
    }
    else {
        unitClassName = "ls-" + unitInfo.index;
    }
    var isGSUnit = unitInfo.id > 0;
    var round = isGSUnit ? unitInfo.round : 0;
    var puzzleBadges = function () { return (React.createElement("div", { className: 'unit-puzzle-badges' },
        React.createElement("div", { className: 'unit-badges' },
            round > 1 ? React.createElement("div", { className: 'student-badge student-badge-round1-reward' }) : React.createElement(NoneFin, null),
            round > 2 ? React.createElement("div", { className: 'student-badge student-badge-round2-reward' }) : React.createElement(NoneFin, null),
            round > 3 ? React.createElement("div", { className: 'student-badge student-badge-round3-reward' }) : React.createElement(NoneFin, null),
            round > 4 ? React.createElement("div", { className: 'student-badge student-badge-round4-reward' }) : React.createElement(NoneFin, null)))); };
    return (React.createElement("div", { className: "unit-base" },
        React.createElement(Card, { hoverable: true, onClick: function () {
                navigateBy(history, to, userId, unitInfo, 'Your account does not have any unit.');
                changeThemeColor(unitInfo.id);
            }, cover: React.createElement("div", { className: "back unit " + unitClassName }) }),
        isGSUnit ? puzzleBadges() : null));
}
function navigateBy(history, to, userId, unitInfo, message) {
    var path = PathConfig.Listen;
    if (unitInfo.id > 0) {
        switch (to) {
            case "library":
                path = PathConfig.Library;
                break;
            case "listen":
                path = PathConfig.Listen;
                break;
            default:
                path = PathConfig.Playlist;
                break;
        }
    }
    else {
        path = PathConfig.Library;
    }
    if (unitInfo.id && unitInfo.versionId) {
        maskThrottle();
        if (GLGlobal.store.getState().theme.leftData.role && !RoleHelper.isStudent(GLGlobal.store.getState().theme.leftData.role)) {
            updateAppSettings(userId, GLGlobal.store.getState().theme.leftData.parentId, GLGlobal.store.getState().theme.leftData.unit, { lastOpenUnit: unitInfo.id });
        }
        unmaskThrottle();
        history.push({ pathname: GLUtil.pathStringify(path, { userId: userId, unit: unitInfo.id, versionId: unitInfo.versionId }) });
    }
    else {
        notification.warn({ message: message });
    }
}
