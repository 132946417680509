import React from "react";
import { Divider, Modal } from "antd";
import { fmtMsg, getFormattedName, DateHelper } from "@app/util";
import { ExtendedSubscriptionNoticeLocale } from "@app/locales/localeid";
import "./extended-subscription-notice-modal.less";
export var ExtendedSubscriptionNoticeType;
(function (ExtendedSubscriptionNoticeType) {
    ExtendedSubscriptionNoticeType[ExtendedSubscriptionNoticeType["SubscriptionExpired"] = 0] = "SubscriptionExpired";
    ExtendedSubscriptionNoticeType[ExtendedSubscriptionNoticeType["ExtendedSubscriptionExpiration"] = 1] = "ExtendedSubscriptionExpiration";
})(ExtendedSubscriptionNoticeType || (ExtendedSubscriptionNoticeType = {}));
export var ExtendedSubscriptionNoticeModal = function (props) {
    var headerLocale, singleChildLocale, multipleChildrenLocale, childDetailLocale;
    if (props.type === ExtendedSubscriptionNoticeType.SubscriptionExpired) {
        headerLocale = ExtendedSubscriptionNoticeLocale.SubscriptionExpiredHeader;
        singleChildLocale = ExtendedSubscriptionNoticeLocale.SubscriptionExpiredSingleChildMessage;
        multipleChildrenLocale = ExtendedSubscriptionNoticeLocale.SubscriptionExpiredMultipleChildrenMessage;
        childDetailLocale = ExtendedSubscriptionNoticeLocale.SubscriptionExpiredMultipleChildrenChildDetail;
    }
    else {
        headerLocale = ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationHeader;
        singleChildLocale = ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationSingleChildMessage;
        multipleChildrenLocale = ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationMultipleChildrenMessage;
        childDetailLocale = ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationMultipleChildrenChildDetail;
    }
    var getChildName = function (child) {
        return getFormattedName(child.englishName, child.name);
    };
    var getEndDate = function (child) {
        return DateHelper.formatDate2Local(child.extendedSubscriptionEndDate);
    };
    var renderChildrenList = function (children) {
        return children.map(function (child) {
            var childName = getChildName(child);
            var endDate = getEndDate(child);
            return React.createElement("div", { key: child.id },
                "- ",
                fmtMsg({ id: childDetailLocale }, { childName: childName, endDate: endDate }));
        });
    };
    var renderMainContent = function () {
        if (props.children.length > 1) {
            return fmtMsg({ id: multipleChildrenLocale });
        }
        else if (props.children.length === 1) {
            var child = props.children[0];
            var childName = getChildName(child);
            var endDate = getEndDate(child);
            return fmtMsg({ id: singleChildLocale }, { childName: childName, endDate: endDate });
        }
        else {
            return null;
        }
    };
    return (React.createElement(Modal, { visible: props.visible, footer: null, className: "extended-subscription-notice-modal", centered: true, destroyOnClose: true, onCancel: props.onClose, width: 660 },
        React.createElement("div", { className: "extended-subscription-container" },
            React.createElement(Divider, { className: "extended-subscription-header" }, fmtMsg({ id: headerLocale })),
            React.createElement("div", { className: "extended-subscription-content" },
                React.createElement("div", null, renderMainContent()),
                props.children.length > 1
                    ? React.createElement("div", { className: "extended-subscription-content-list" }, renderChildrenList(props.children))
                    : null),
            React.createElement("div", { className: "extended-subscription-footer" },
                React.createElement("button", { className: "decline-btn", role: "button", onClick: props.onClose }, fmtMsg({
                    id: ExtendedSubscriptionNoticeLocale.Close
                }))))));
};
