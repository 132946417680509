import { push } from "connected-react-router";
import { GLGlobal } from "gl-commonui";
export var useHistory = function () {
    var pushFn = function (location) {
        GLGlobal.store.dispatch(push(location));
    };
    return {
        push: pushFn,
    };
};
