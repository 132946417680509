var JbTiltRightToNeutral = /** @class */ (function () {
    function JbTiltRightToNeutral() {
        this.frames = [
            107,
            106,
            105,
            104,
            103,
            102,
            101,
            100,
            1
        ];
    }
    return JbTiltRightToNeutral;
}());
export { JbTiltRightToNeutral };
