var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { notification } from "antd-min";
import { GLUtil } from 'gl-commonui';
import DVDControlContainer from '@app/page/listen/components/dvdpalyercontroller';
import CDplayer from '@app/page/listen/cdplayer';
import { PlayAction, PlayMode } from '@app/components/media/playlistmediaplayer';
import { PathConfig } from '@app/config/pathconfig';
export var PlayerControlBar = function (props) {
    var className = props.className, userId = props.userId, versionId = props.versionId, unit = props.unit, cds = props.cds, dvds = props.dvds, unitcontents = props.unitcontents, signature = props.signature, playbackRate = props.playbackRate, pausePlayer = props.pausePlayer, stopPlayer = props.stopPlayer, nextPlayingItem = props.nextPlayingItem, afterPlayeActionChanged = props.afterPlayeActionChanged, afterPlayed = props.afterPlayed;
    var _a = __read(useState({ index: 0, key: null }), 2), selection = _a[0], setSelection = _a[1];
    var _b = __read(useState(null), 2), selectedsequence = _b[0], setSelectedsequence = _b[1];
    var _c = __read(useState(cds || []), 2), currentCDs = _c[0], setCurrentCDs = _c[1];
    var _d = __read(useState(dvds || []), 2), currentDVDs = _d[0], setCurrentDVDs = _d[1];
    var _e = __read(useState(PlayMode.order), 2), cdMode = _e[0], setCDMode = _e[1];
    var _f = __read(useState(GLUtil.queryParse().mode), 2), dvdMode = _f[0], setDVDMode = _f[1];
    var _g = __read(useState(PlayAction.stop), 2), cdPlayAction = _g[0], setCdPlayAction = _g[1];
    useEffect(function () {
        if (pausePlayer) {
            setCdPlayAction(PlayAction.pause);
            setCDItemStatus(null, false, true);
            afterPlayeActionChanged && afterPlayeActionChanged(PlayAction.pause);
        }
    }, [pausePlayer]);
    useEffect(function () {
        if (stopPlayer) {
            setCdPlayAction(PlayAction.stop);
            setCDItemStatus(null, false, true);
            afterPlayeActionChanged && afterPlayeActionChanged(PlayAction.stop);
        }
    }, [stopPlayer]);
    useEffect(function () {
        if (nextPlayingItem) {
            playCD(nextPlayingItem);
            afterPlayed && afterPlayed();
        }
    }, [nextPlayingItem]);
    var onCDItemStarted = function (id) {
        cdPlayAction == PlayAction.play && setCDItemStatus(id, true);
    };
    var onCDItemEnded = function (id) {
        setCDItemStatus(id, false);
    };
    var onCDPlay = function (id) {
        setCdPlayAction(PlayAction.play);
        setCDItemStatus(id, true);
    };
    var setCDItemStatus = function (id, isPlaying, isclean) {
        if (currentCDs) {
            if (isPlaying || isclean) {
                currentCDs.forEach(function (item) { return item.isPlaying = false; });
            }
            var cd = currentCDs.find(function (cd) { return cd.id == id; });
            if (cd) {
                cd.isPlaying = isPlaying;
            }
            setCurrentCDs(currentCDs);
        }
    };
    var onCDModeChanged = function (mode) {
        setCDMode(mode);
    };
    var changeMode = function (mode) {
        setDVDMode(mode);
    };
    var playCD = function (item) {
        if (item && item.id) {
            setSelectedsequence(item.id);
            setCdPlayAction(PlayAction.play);
            window.setTimeout(function () {
                setSelectedsequence(null);
            }, 100);
        }
        else {
            notification.warn({ message: 'There are nothing to paly' });
        }
    };
    var playDVD = function (contents, item) {
        if (contents && contents.length > 0) {
            var ids = contents.map(function (content) { return content.id; });
            props.history.push({ pathname: GLUtil.pathStringify(PathConfig.DVDPlayer, { userId: userId, unit: unit, versionId: versionId, sequence: item.id }), search: GLUtil.queryStringify({ contents: ids, selection: selection.index, mode: dvdMode }) });
        }
        else {
            notification.warn({ message: 'There are nothing to paly' });
        }
    };
    var renderControlBar = function () {
        var show_dvd = selection.key == "dvds";
        if (show_dvd) {
            return (React.createElement("div", { className: className },
                React.createElement("div", { className: "mediaplayer-wrapper dvd_control_panel" },
                    React.createElement(DVDControlContainer, { mode: dvdMode, play: function () { playDVD(currentDVDs, currentDVDs[0]); }, onModeChanged: function (mode) { changeMode(mode); } }))));
        }
        else {
            return (React.createElement("div", { className: className },
                React.createElement("div", { className: "control_panel cd_control_panel" },
                    React.createElement(CDplayer, { userId: userId, versionId: versionId, unit: unit, contents: currentCDs.map(function (cd) { return cd.id; }), unitcontents: unitcontents, signature: signature, sequence: selectedsequence, playMode: cdMode, onModeChanged: onCDModeChanged, playAction: cdPlayAction, onItemStarted: onCDItemStarted, onItemEnded: onCDItemEnded, onPlay: onCDPlay, playbackRate: playbackRate }))));
        }
    };
    return renderControlBar();
};
