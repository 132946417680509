var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { OidcProxy, RoleName, UserAvatar } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { fmtMsg, userHasRole } from ".";
import { Menu } from "antd-min";
import { useActionCreator, useSelector } from "@app/hooks";
import { setSystemCheckPopupVisible } from "@app/states/system-check";
import { SystemCheckLocale } from "@app/locales/localeid";
export var PortalUserAvatar = function () {
    var _a = __read(useState(false), 2), isStudent = _a[0], setIsStudent = _a[1];
    var showSystemCheck = useActionCreator(setSystemCheckPopupVisible);
    var hasConversationAccess = useSelector(function (state) { return state.systemCheck.hasConversationAccess; });
    useEffect(function () {
        OidcProxy.getUser().then(function (user) {
            var hasStudentRole = userHasRole(RoleName.student, user.profile);
            setIsStudent(hasStudentRole);
        });
    }, []);
    var renderExtraMenus = function () {
        if (hasConversationAccess) {
            return (React.createElement(Menu.Item, { className: "ant-dropdown-menu-item", onClick: function () { return showSystemCheck(true); } }, fmtMsg(SystemCheckLocale.MenuText)));
        }
    };
    return (React.createElement(UserAvatar, { hideEditProfile: isStudent, pageAfterSignin: PathConfig.Home, pageAfterSignout: isStudent ? PathConfig.CodeLogin : PathConfig.Home, subMenuRender: renderExtraMenus }));
};
