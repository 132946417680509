var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { PathConfig } from "@app/config/pathconfig";
import { StudentLocale } from "@app/locales/localeid";
import { setIsDrawerVisible } from "@app/states/drawer";
import { fetchConversationAccessPermission } from "@app/states/system-check";
import { Dropdown, Icon, Menu } from "antd-min";
import { connect, GLGlobal, GLUtil, withRouter } from "gl-commonui";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ConversationHeaderIcon } from "../conversation-header-icon/conversation-header-icon";
import { LibraryIcon, PlaylistIcon } from "../svg-icon";
var RightHeader = /** @class */ (function (_super) {
    __extends(RightHeader, _super);
    function RightHeader(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            menuVisible: false,
            userId: null,
            versionId: null,
            unit: null,
        };
        _this.handleClickCloseDrawer = _this.handleClickCloseDrawer.bind(_this);
        return _this;
    }
    RightHeader.prototype.handleClickCloseDrawer = function (e) {
        // key for jonny bear icon is '2'
        if (e.key === '2') {
            this.setState({ menuVisible: true });
        }
        else {
            this.setState({ menuVisible: false });
        }
        this.props.setIsDrawerVisible(false);
    };
    RightHeader.prototype.componentWillReceiveProps = function (nextProps) {
        var _a = GLUtil.pathParse(PathConfig.Unit), userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
        if (nextProps.leftChanged !== this.props.leftChanged) {
            this.props.fetchConversationAccessPermission(userId);
            this.setState({ userId: userId, versionId: versionId, unit: unit });
        }
        this.state.unit !== unit ? this.setState({ unit: unit }) : null;
    };
    RightHeader.prototype.render = function () {
        var _this = this;
        var _a = this.props, rightChanged = _a.rightChanged, children = _a.children, hasConversationAccess = _a.hasConversationAccess;
        var menuVisible = this.state.menuVisible;
        return (React.createElement("div", { className: "header-right" },
            rightChanged && (React.createElement("div", { className: "imgs" },
                React.createElement(Link, { to: getPath(PathConfig.Playlist) },
                    React.createElement(PlaylistIcon, { className: "home-icon", title: GLGlobal.intl.formatMessage({
                            id: StudentLocale.MenuPlaymode,
                        }) })),
                React.createElement(Link, { to: getPath(PathConfig.Library) },
                    React.createElement(LibraryIcon, { className: "library-icon", title: GLGlobal.intl.formatMessage({
                            id: StudentLocale.MenuLibrary,
                        }) })),
                hasConversationAccess && React.createElement(ConversationHeaderIcon, { studentId: this.state.userId, versionId: this.state.versionId, currentUnit: this.state.unit, insideDropdown: false }),
                React.createElement(Dropdown, { visible: menuVisible, overlayClassName: "header-right-icons-menu", trigger: ["click"], onVisibleChange: function (drawerVisible) { return _this.setState({ menuVisible: drawerVisible }); }, overlay: React.createElement(Menu, { onClick: this.handleClickCloseDrawer },
                        React.createElement(Menu.Item, { key: "0" },
                            React.createElement(Link, { to: getPath(PathConfig.Playlist) },
                                React.createElement(PlaylistIcon, { className: "home-icon" }),
                                React.createElement("span", null, GLGlobal.intl.formatMessage({
                                    id: StudentLocale.MenuPlaymode,
                                })))),
                        React.createElement(Menu.Item, { key: "1" },
                            React.createElement(Link, { to: getPath(PathConfig.Library) },
                                React.createElement(LibraryIcon, { className: "library-icon" }),
                                React.createElement("span", null, GLGlobal.intl.formatMessage({ id: StudentLocale.MenuLibrary })))),
                        hasConversationAccess && (React.createElement(Menu.Item, { key: "2" },
                            React.createElement(ConversationHeaderIcon, { studentId: this.state.userId, versionId: this.state.versionId, currentUnit: this.state.unit, insideDropdown: true })))) },
                    React.createElement(Icon, { type: menuVisible ? "menu-unfold" : "menu-fold", onClick: function () { return _this.setState({ menuVisible: !menuVisible }); } })))),
            children));
    };
    RightHeader = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, rightChanged = _b.rightChanged, leftData = _b.leftData, leftChanged = _b.leftChanged, hasConversationAccess = _a.systemCheck.hasConversationAccess;
            return ({
                rightChanged: rightChanged,
                leftChanged: leftChanged,
                leftData: leftData,
                hasConversationAccess: hasConversationAccess,
            });
        }, {
            setIsDrawerVisible: setIsDrawerVisible,
            fetchConversationAccessPermission: fetchConversationAccessPermission,
        })
    ], RightHeader);
    return RightHeader;
}(Component));
export default RightHeader;
function getPath(pathname) {
    var _a = GLUtil.pathParse(PathConfig.Unit), userId = _a.userId, versionId = _a.versionId, unit = _a.unit;
    if (!userId || !versionId || !unit) {
        return PathConfig.Home;
    }
    return {
        pathname: GLUtil.pathStringify(pathname, { userId: userId, unit: unit, versionId: versionId }),
    };
}
