import { JbBlink } from "./blink";
import { JbClapCycle } from "./clap-cycle";
import { JbClapReturn } from "./clap-return";
import { JbClapStart } from "./clap-start";
import { JbDownTiltBlink } from "./down-tilt-blink";
import { JbDownTiltFromNeutral } from "./down-tilt-from-idle";
import { JbDownTiltSpeaking } from "./down-tilt-speaking";
import { JbDownTiltToNeutral } from "./down-tilt-to-idle";
import { JbEarFlick } from "./ear-flick";
import { JbHappy } from "./happy";
import { JbHappyToNeutral } from "./happy-to-neutral";
import { JbHappyToSad } from "./happy-to-sad";
import { JbNeutral } from "./neutral";
import { JbNeutralToHappy } from "./neutral-to-happy";
import { JbNeutralToSad } from "./neutral-to-sad";
import { JbRaiseHandBlink } from "./raise-hand-blink";
import { JbRaiseHandFromNeutral } from "./raise-hand-from-idle";
import { JbRaiseHandSpeaking } from "./raise-hand-speaking";
import { JbRaiseHandToNeutral } from "./raise-hand-to-idle";
import { JbSad } from "./sad";
import { JbSadToHappy } from "./sad-to-happy";
import { JbSadToNeutral } from "./sad-to-neutral";
import { JbSpeakingCycle } from "./speaking-cycle";
import { JbThumbsUp } from "./thumbs-up";
import { JbThumbsUpReturn } from "./thumbs-up-return";
import { JbTiltLeftBlink } from "./tilt-left-blink";
import { JbTiltLeftFromNeutral } from "./tilt-left-from-idle";
import { JbTiltLeftSpeaking } from "./tilt-left-speaking";
import { JbTiltLeftToNeutral } from "./tilt-left-to-idle";
import { JbTiltRightBlink } from "./tilt-right-blink";
import { JbTiltRightFromNeutral } from "./tilt-right-from-idle";
import { JbTiltRightSpeaking } from "./tilt-right-speaking";
import { JbTiltRightToNeutral } from "./tilt-right-to-idle";
import { JbWaveCycle } from "./wave-cycle";
import { JbWaveReturn } from "./wave-return";
import { JbWaveStart } from "./wave-start";
export var JbAnimations = {
    //statics
    Neutral: JbNeutral,
    Happy: JbHappy,
    Sad: JbSad,
    TiltLeftSpeaking: JbTiltLeftSpeaking,
    TiltLeftBlink: JbTiltLeftBlink,
    TiltRightBlink: JbTiltRightBlink,
    TiltRightSpeaking: JbTiltRightSpeaking,
    DownTiltSpeaking: JbDownTiltSpeaking,
    DownTiltBlink: JbDownTiltBlink,
    RaiseHandSpeaking: JbRaiseHandSpeaking,
    RaiseHandBlink: JbRaiseHandBlink,
    //cycleable
    WaveStart: JbWaveStart,
    WaveReturn: JbWaveReturn,
    ClapStart: JbClapStart,
    ClapReturn: JbClapReturn,
    WaveCycle: JbWaveCycle,
    ClapCycle: JbClapCycle,
    SpeakingCycle: JbSpeakingCycle,
    // idles
    ThumbsUp: JbThumbsUp,
    ThumbsUpReturn: JbThumbsUpReturn,
    TiltLeftFromNeutral: JbTiltLeftFromNeutral,
    TiltLeftToNeutral: JbTiltLeftToNeutral,
    TiltRightFromNeutral: JbTiltRightFromNeutral,
    TiltRightToNeutral: JbTiltRightToNeutral,
    DownTiltFromNeutral: JbDownTiltFromNeutral,
    DownTiltToNeutral: JbDownTiltToNeutral,
    RaiseHandFromNeutral: JbRaiseHandFromNeutral,
    RaiseHandToNeutral: JbRaiseHandToNeutral,
    // emotions
    NeutralToSad: JbNeutralToSad,
    SadToNeutral: JbSadToNeutral,
    NeutralToHappy: JbNeutralToHappy,
    HappyToNeutral: JbHappyToNeutral,
    SadToHappy: JbSadToHappy,
    HappyToSad: JbHappyToSad,
    Blink: JbBlink,
    EarFlick: JbEarFlick
};
