var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import React, { Component } from "react";
import { Button } from "antd-min";
import { PlayAction, PlayMode } from "./mediaplayer";
import { PlayOrder, PlayShuffle, PlayLoopSingle } from "../svg-icon";
var PlayerButton = /** @class */ (function (_super) {
    __extends(PlayerButton, _super);
    function PlayerButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayerButton.prototype.render = function () {
        var _a = this.props, iconType = _a.iconType, rest = __rest(_a, ["iconType"]);
        return React.createElement(Button, __assign({ shape: "circle", className: "player-btn " + iconType }, rest));
    };
    return PlayerButton;
}(Component));
export default PlayerButton;
var PlayModeButton = /** @class */ (function (_super) {
    __extends(PlayModeButton, _super);
    function PlayModeButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PlayModeButton.prototype.render = function () {
        var _a = this.props, mode = _a.mode, rest = __rest(_a, ["mode"]);
        var getModeIcon = function (mode) {
            var _a;
            return (_a = {},
                _a[PlayMode.order] = React.createElement(PlayOrder, null),
                _a[PlayMode.random] = React.createElement(PlayShuffle, null),
                _a[PlayMode.singleCycle] = React.createElement(PlayLoopSingle, null),
                _a[PlayMode.single] = React.createElement(PlayLoopSingle, null),
                _a)[mode];
        };
        return React.createElement(Button, __assign({ shape: "circle", className: "player-btn play-mode" }, rest), getModeIcon(mode));
    };
    return PlayModeButton;
}(Component));
export { PlayModeButton };
var StepBackwardButton = /** @class */ (function (_super) {
    __extends(StepBackwardButton, _super);
    function StepBackwardButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StepBackwardButton.prototype.render = function () {
        var _a = this.props, mode = _a.mode, rest = __rest(_a, ["mode"]);
        return React.createElement(Button, __assign({ disabled: isDisableSingle(mode), shape: "circle", className: "player-btn " + disableStepward(mode) + " " + PlayAction.stepbackward }, rest));
    };
    return StepBackwardButton;
}(Component));
export { StepBackwardButton };
var StepForwardButton = /** @class */ (function (_super) {
    __extends(StepForwardButton, _super);
    function StepForwardButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StepForwardButton.prototype.render = function () {
        var _a = this.props, mode = _a.mode, rest = __rest(_a, ["mode"]);
        return React.createElement(Button, __assign({ disabled: isDisableSingle(mode), shape: "circle", className: "player-btn " + disableStepward(mode) + " " + PlayAction.stepforward }, rest));
    };
    return StepForwardButton;
}(Component));
export { StepForwardButton };
var isDisableSingle = function (mode) { return mode === PlayMode.single || mode === PlayMode.singleCycle; };
var disableStepward = function (mode) { return isDisableSingle(mode) ? 'player-btn-disabled' : ''; };
