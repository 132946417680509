var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '../../util/di';
import VerificationServiceBase from '../app/servicebase/verificationservicebase';
var StudentService = /** @class */ (function (_super) {
    __extends(StudentService, _super);
    function StudentService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'admin/v1/students', suffix: '{id}' };
        return _this;
    }
    StudentService.prototype.relateChildToClass = function (data, routeData) {
        return this.request.post(this.url('{id}/schoolClasses/{schoolClassId}'), data, routeData);
    };
    StudentService.prototype.updateChildInfo = function (params) {
        return this.merge('{id}', params);
    };
    StudentService.prototype.getChildInfoById = function (params) {
        return this.get('{id}', params);
    };
    StudentService.prototype.getParentIdByChildId = function (params) {
        return this.get('{id}/parents', params);
    };
    StudentService.prototype.getStudent = function (params) {
        return this.get('GetStudent', params);
    };
    StudentService.prototype.hasConsumedConversationIntroVideo = function (params) {
        return this.get('hasconsumedconversationintrovideo', params);
    };
    StudentService.prototype.consumeConversationIntroVideo = function (routeData) {
        return this.request.post(this.url('{id}/consumeconversationintrovideo'), null, routeData);
    };
    StudentService.prototype.getChildInfoByIdExt = function (data) {
        return this.get("{studentId}/details", data);
    };
    StudentService = __decorate([
        injectable()
    ], StudentService);
    return StudentService;
}(VerificationServiceBase));
export { StudentService };
