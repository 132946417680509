var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbPositiveGestures = /** @class */ (function (_super) {
    __extends(JbPositiveGestures, _super);
    function JbPositiveGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            {
                id: "PositiveGesture#1",
                styleId: JbInteractionStyles.Positive,
                animations: [
                    new JbAnimations.Neutral(200),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightBlink(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(300),
                ],
            },
            {
                id: "PositiveGesture#2",
                styleId: JbInteractionStyles.Positive,
                animations: [new JbAnimations.Neutral(400), new JbAnimations.EarFlick(), new JbAnimations.Blink(), new JbAnimations.Neutral(300)],
            },
            {
                id: "PositiveGesture#3",
                styleId: JbInteractionStyles.Positive,
                animations: [
                    new JbAnimations.Neutral(200),
                    new JbAnimations.TiltRightFromNeutral(),
                    new JbAnimations.TiltRightToNeutral(),
                    new JbAnimations.Neutral(200),
                ],
            },
            {
                id: "PositiveGesture#4",
                styleId: JbInteractionStyles.Positive,
                animations: [new JbAnimations.Neutral(500), new JbAnimations.Blink(), new JbAnimations.Neutral(500)],
            },
            {
                id: "PositiveGesture#5",
                styleId: JbInteractionStyles.Positive,
                animations: [
                    new JbAnimations.Neutral(),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftBlink(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(),
                ],
            },
            {
                id: "PositiveGesture#6",
                styleId: JbInteractionStyles.Positive,
                animations: [new JbAnimations.Neutral(300), new JbAnimations.EarFlick(), new JbAnimations.Neutral(300)],
            },
            {
                id: "PositiveGesture#7",
                styleId: JbInteractionStyles.Positive,
                animations: [
                    new JbAnimations.Neutral(200),
                    new JbAnimations.TiltLeftFromNeutral(),
                    new JbAnimations.TiltLeftToNeutral(),
                    new JbAnimations.Neutral(300),
                ],
            },
            {
                id: "PositiveGesture#8",
                styleId: JbInteractionStyles.Positive,
                animations: [
                    new JbAnimations.Neutral(),
                    new JbAnimations.Blink(),
                    new JbAnimations.Neutral(),
                    new JbAnimations.EarFlick(),
                    new JbAnimations.Neutral(300),
                ],
            },
        ];
        return _this;
    }
    return JbPositiveGestures;
}(GestureBase));
export { JbPositiveGestures };
