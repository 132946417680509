var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import { injectable } from "../../../util/di";
import VerificationServiceBase from "../servicebase/verificationservicebase";
var PlaylistlogsAppService = /** @class */ (function (_super) {
    __extends(PlaylistlogsAppService, _super);
    function PlaylistlogsAppService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: "rep/v1/playlistlogs", suffix: "{studentId, unit}" };
        return _this;
    }
    PlaylistlogsAppService_1 = PlaylistlogsAppService;
    PlaylistlogsAppService.addCall = function (call) {
        PlaylistlogsAppService_1.calls.push(call);
        PlaylistlogsAppService_1.executor();
    };
    PlaylistlogsAppService.prototype.getPlaylistlog = function (data) {
        return this.getItemsBy(data);
    };
    PlaylistlogsAppService.prototype.sendPlaylistlog = function (data) {
        return this.create("?isFromNewApp=true", data);
    };
    PlaylistlogsAppService.prototype.sendPlaylistlogPlaylog = function (data) {
        return this.create("playlogs", data);
    };
    PlaylistlogsAppService.prototype.getUnitRounds = function (routeData) {
        return this.getItemsBy("{userId}/units", null, routeData);
    };
    PlaylistlogsAppService.prototype.getPlaylistlogs = function (data, routeData) {
        return this.getItemsBy("{studentId}/rounds", data, routeData);
    };
    PlaylistlogsAppService.prototype.getProgress = function (data, routeData) {
        return this.getItemsBy("{studentId}/units/{unit}/progress", data, routeData);
    };
    PlaylistlogsAppService.prototype.sendProgress = function (data, routeData) {
        var _this = this;
        // Add the calls to queue.
        // Because, whenever simultanous calls are made, two records are created. 
        // And whenever we fetch the progress, an error is thrown because of that.
        PlaylistlogsAppService_1.addCall(function () { return _this.update("{studentId}/units/{unit}/progress", data, routeData, { needLoader: false }); });
        return Promise.resolve();
    };
    var PlaylistlogsAppService_1;
    PlaylistlogsAppService.calls = [];
    PlaylistlogsAppService.running = false;
    PlaylistlogsAppService.executor = function () { return __awaiter(_this, void 0, void 0, function () {
        var call;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (PlaylistlogsAppService_1.running) {
                        return [2 /*return*/];
                    }
                    PlaylistlogsAppService_1.running = true;
                    call = PlaylistlogsAppService_1.calls.shift();
                    _a.label = 1;
                case 1:
                    if (!call) return [3 /*break*/, 3];
                    return [4 /*yield*/, call()];
                case 2:
                    _a.sent();
                    call = PlaylistlogsAppService_1.calls.shift();
                    return [3 /*break*/, 1];
                case 3:
                    PlaylistlogsAppService_1.running = false;
                    return [2 /*return*/];
            }
        });
    }); };
    PlaylistlogsAppService = PlaylistlogsAppService_1 = __decorate([
        injectable()
    ], PlaylistlogsAppService);
    return PlaylistlogsAppService;
}(VerificationServiceBase));
export { PlaylistlogsAppService };
