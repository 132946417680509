var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { PlayMode, PlayAction } from "@app/components/media/mediaplayer";
import PlayerButton, { PlayModeButton } from "@app/components/media/player-button";
export var PLAYMODES = [PlayMode.order, PlayMode.singleCycle, PlayMode.random];
var DVDControlContainer = /** @class */ (function (_super) {
    __extends(DVDControlContainer, _super);
    function DVDControlContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            playModes: PLAYMODES.concat(),
        };
        return _this;
    }
    DVDControlContainer.prototype.componentDidMount = function () {
        var mode = this.props.mode;
        var playModes = this.state.playModes;
        for (var index = 0; index < mode; index++) {
            playModes = playModes.concat([playModes.shift()]);
        }
        this.setState({ playModes: playModes });
    };
    DVDControlContainer.prototype.render = function () {
        var _this = this;
        var playModes = this.state.playModes;
        var _a = this.props, play = _a.play, onModeChanged = _a.onModeChanged;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "playlist-controlbar mediaplayer-dvd-controlbar" },
                React.createElement(PlayModeButton, { mode: playModes[0], onClick: function () {
                        var mode = playModes.shift();
                        _this.setState({ playModes: playModes.concat([mode]) });
                        onModeChanged && onModeChanged(PLAYMODES.indexOf(playModes[0]));
                    } }),
                React.createElement(PlayerButton, { iconType: PlayAction.play, onClick: function () { return play(); } })));
    };
    return DVDControlContainer;
}(Component));
export default DVDControlContainer;
