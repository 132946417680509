export var FeedbackType;
(function (FeedbackType) {
    FeedbackType[FeedbackType["ComputerSpoken"] = 1] = "ComputerSpoken";
    FeedbackType[FeedbackType["UserSpoken"] = 2] = "UserSpoken";
})(FeedbackType || (FeedbackType = {}));
export var ComputerSpokenFeedbackType;
(function (ComputerSpokenFeedbackType) {
    ComputerSpokenFeedbackType[ComputerSpokenFeedbackType["NotFitTheFlow"] = 1] = "NotFitTheFlow";
    ComputerSpokenFeedbackType[ComputerSpokenFeedbackType["StrangeVoice"] = 2] = "StrangeVoice";
    ComputerSpokenFeedbackType[ComputerSpokenFeedbackType["Mispronunciation"] = 3] = "Mispronunciation";
    ComputerSpokenFeedbackType[ComputerSpokenFeedbackType["StrangeAnimation"] = 4] = "StrangeAnimation";
    ComputerSpokenFeedbackType[ComputerSpokenFeedbackType["Other"] = 5] = "Other";
})(ComputerSpokenFeedbackType || (ComputerSpokenFeedbackType = {}));
export var UserSpokenFeedbackType;
(function (UserSpokenFeedbackType) {
    UserSpokenFeedbackType[UserSpokenFeedbackType["InaccurateSpeechToText"] = 1] = "InaccurateSpeechToText";
    UserSpokenFeedbackType[UserSpokenFeedbackType["IntentionMisunderstood"] = 2] = "IntentionMisunderstood";
    UserSpokenFeedbackType[UserSpokenFeedbackType["TechnicalProblem"] = 3] = "TechnicalProblem";
    UserSpokenFeedbackType[UserSpokenFeedbackType["Other"] = 4] = "Other";
})(UserSpokenFeedbackType || (UserSpokenFeedbackType = {}));
