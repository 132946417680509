import { setIsReaderAction } from "@app/states/playlistplayer";
import { MatIcon } from "gl-commonui";
import React from "react";
import { FocusWordContentCard } from "./focusword-content-card";
import FocusWordList from "./focusword-list";
import "./playlist-reader-focuswords.less";
export var ReaderFocusWords = function (props) {
    var focusWordContents = props.focusWordContents, onGoToNextReaderContent = props.onGoToNextReaderContent, onGoToPreviousReaderContent = props.onGoToPreviousReaderContent, isMovedForward = props.isMovedForward, isFirstContent = props.isFirstContent;
    var goToNextPage = function () {
        onGoToNextReaderContent && onGoToNextReaderContent();
        setIsReaderAction({ isReaderAction: true });
    };
    var goToPreviousPage = function () {
        onGoToPreviousReaderContent && onGoToPreviousReaderContent();
        setIsReaderAction({ isReaderAction: true });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "focuswords" },
            React.createElement("div", { className: "focuswords__btn" },
                React.createElement("span", { className: isFirstContent
                        ? "focuswords__btn--leftdisabled"
                        : "focuswords__btn--left", onClick: goToPreviousPage },
                    React.createElement(MatIcon, { type: "navigate_before" }))),
            React.createElement("div", { className: "focuswords__container", onClick: function () { return setIsReaderAction({ isReaderAction: false }); } },
                React.createElement(FocusWordList, { focusWordContents: focusWordContents }),
                React.createElement(FocusWordContentCard, { focusWordContents: focusWordContents })),
            React.createElement("div", { className: "focuswords__btn" },
                React.createElement("span", { className: "focuswords__btn--right", onClick: goToNextPage },
                    React.createElement(MatIcon, { type: "chevron_right" }))))));
};
