var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ListStatesRadios } from "@app/components/list-states-radios";
import { Action, Actions } from "@app/components/action/action";
import { useEffectAfterFirst } from "@app/hooks/useEffectAfterFirst";
import { useWijmoEditingHandler, useWijmoValidators } from "@app/hooks";
import { deepClone, ListStates } from "@app/util";
import { wijmoGridResponsive } from "@app/util/wijmo";
import { Col, Empty, Input, Pagination, Row } from "@app/antd-min";
import classNames from "classnames";
import { MessageHelper, NotificationType } from "gl-commonui";
import { cloneDeep, get, isFunction, isNumber, merge } from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { CollectionView, SortDescription } from "wijmo/wijmo";
import { AllowResizing, HeadersVisibility, SelectionMode, } from "wijmo/wijmo.grid";
import { FlexGrid } from "wijmo/wijmo.react.grid";
import * as wjGroup from "wijmo/wijmo.react.grid.grouppanel";
import { FlexGridSearch } from "wijmo/wijmo.react.grid.search";
import { HAlign, Workbook, WorkbookCell, WorkbookColumn, WorkbookFont, WorkbookRow, WorkbookStyle, WorkSheet } from "wijmo/wijmo.xlsx";
import { Loading } from "./loading/loading";
import { mergeClasses } from "@app/util";
import "./grid.less";
export var Grid = forwardRef(function (props, ref) {
    var loadingProp = props.loading, children = props.children, pagination = props.pagination, paginationSize = props.paginationSize, paginationShowTotal = props.paginationShowTotal, grouping = props.grouping, groupBarText = props.groupBarText, initialized = props.initialized, onRefreshed = props.onRefreshed, className = props.className, itemsSource = props.itemsSource, serviceFunction = props.serviceFunction, serviceParams = props.serviceParams, serviceFormatData = props.serviceFormatData, defaultSort = props.defaultSort, search = props.search, searchProps = props.searchOptions, actions = props.actions, listStateShow = props.listStateShow, listStateOptions = props.listStateOptions, title = props.title, bordered = props.bordered, allowExport = props.allowExport, exportProps = props.exportOptions, getError = props.getError, collectionViewFilter = props.filter, beginningEdit = props.beginningEdit, cellEditEnding = props.cellEditEnding, cellEditEnded = props.cellEditEnded, validCallback = props.valid, cellDisableHandler = props.cellDisableHandler, newItemCreator = props.newItemCreator, paginationTotal = props.paginationTotal, sortInClient = props.sortInClient, rest = __rest(props, ["loading", "children", "pagination", "paginationSize", "paginationShowTotal", "grouping", "groupBarText", "initialized", "onRefreshed", "className", "itemsSource", "serviceFunction", "serviceParams", "serviceFormatData", "defaultSort", "search", "searchOptions", "actions", "listStateShow", "listStateOptions", "title", "bordered", "allowExport", "exportOptions", "getError", "filter", "beginningEdit", "cellEditEnding", "cellEditEnded", "valid", "cellDisableHandler", "newItemCreator", "paginationTotal", "sortInClient"]);
    var defaultProps = cloneDeep(Grid.defaultProps.exportOptions); // creates a whole new object
    var exportOptions = merge(defaultProps, exportProps); // lodash merge, adds properties of arg2 in arg1
    var searchOptions = merge(defaultProps, searchProps);
    var _a = __read(useState(null), 2), collectionView = _a[0], setCollectionView = _a[1];
    var _b = __read(useState(1), 2), currentPage = _b[0], setCurrentPage = _b[1];
    var _c = __read(useState(null), 2), totalCount = _c[0], setTotalCount = _c[1];
    var _d = __read(useState(defaultSort), 2), currentSort = _d[0], setCurrentSort = _d[1];
    var _e = __read(useState(""), 2), filter = _e[0], setFilter = _e[1];
    var _f = __read(useState({
        value: ListStates.Active,
        allCnt: 0,
        activeCnt: 0,
        inActiveCnt: 0,
        pendingCount: 0,
        futureCount: 0,
    }), 2), listStateCounts = _f[0], setListStateCounts = _f[1];
    var _g = __read(useState(false), 2), loadingState = _g[0], setLoadingState = _g[1];
    var loading = loadingProp || loadingState;
    var noData = (!collectionView || !collectionView.sourceCollection.length) && !loading && !rest.allowAddNew;
    // const noDataMessage = GLGlobal.intl.formatMessage({
    //     id: GSAdminLocale.ChangeLogNoData,
    // });
    var noDataMessage = "No Data"; // Localize the key
    var flexGridRef = useRef();
    var flexGridSearchRef = useRef();
    var grid = flexGridRef.current && flexGridRef.current.control;
    var validation = useWijmoValidators(grid, children);
    var editingDataItem = useWijmoEditingHandler(grid, beginningEdit, cellEditEnding, cellEditEnded, cellDisableHandler);
    useEffect(function () {
        if (serviceFunction) {
            getData();
        }
        setFlexGridOnSearchCallback();
    }, []);
    useEffect(function () {
        if (itemsSource instanceof CollectionView) {
            changeCollectionView(deepClone(itemsSource.items));
        }
        else {
            changeCollectionView(itemsSource);
        }
    }, [itemsSource]);
    useEffectAfterFirst(function () {
        if (serviceFunction) {
            getData();
        }
        else {
            collectionView.moveToPage(currentPage - 1);
        }
    }, [currentPage, filter, listStateCounts.value]);
    useEffectAfterFirst(function () {
        if (serviceFunction && !sortInClient) {
            getData();
        }
    }, [currentSort]);
    useEffect(function () {
        if (validCallback) {
            validCallback(validation.isValid);
        }
    }, [validation.isValid]);
    useEffect(function () {
        if (isNumber(paginationTotal)) {
            setTotalCount(paginationTotal);
        }
    }, [paginationTotal]);
    useImperativeHandle(ref, function () { return ({
        reload: getData,
        grid: grid,
        validation: validation,
    }); });
    var getData = function () {
        setLoadingState(true);
        var params = getParams();
        var serviceFunctionResponse = serviceFunction(params);
        if (serviceFunctionResponse instanceof Promise) {
            serviceFunctionResponse.then(function (data) {
                setDataToCollectionView(data);
                setLoadingState(false);
            })
                .catch(function () { return setLoadingState(false); });
        }
        else {
            setLoadingState(false);
        }
    };
    var getParams = function () {
        var params = __assign({}, serviceParams, { limit: paginationSize, offset: (currentPage - 1) * paginationSize, keyword: filter });
        if (currentSort) {
            params.sortBy = currentSort.column;
            params.isDescending = !currentSort.ascending;
        }
        if (listStateShow) {
            switch (listStateCounts.value) {
                case ListStates.All:
                    params.includePendingInvite = true;
                    break;
                case ListStates.Active:
                    params.disabled = false;
                    break;
                case ListStates.Inactive:
                    params.disabled = true;
                    break;
                case ListStates.Pending:
                    params.includePendingInvite = true;
                    params.includeOnlyPending = true;
                    break;
                case ListStates.Future:
                    // need to work on it
                    break;
            }
        }
        return params;
    };
    var setDataToCollectionView = function (data) {
        // Save data to collection
        var records = serviceFormatData(data);
        changeCollectionView(records);
        // Saving total count to state
        setTotalCountWrapper(data);
        // Saving list stated counts to state
        if (data.extraData) {
            var _a = data.extraData, activeCnt = _a.activeCnt, allCnt = _a.allCnt, futureCount = _a.futureCnt, inActiveCnt = _a.inActiveCnt, pendingCount = _a.pendingCount;
            setListStateCounts(__assign({}, listStateCounts, { activeCnt: activeCnt, allCnt: allCnt, futureCount: futureCount, inActiveCnt: inActiveCnt, pendingCount: pendingCount }));
        }
    };
    var changeCollectionView = function (records) {
        var newCollectionView = new CollectionView(records);
        if (!serviceFunction && pagination) {
            newCollectionView.pageSize = paginationSize;
            newCollectionView.moveToPage(currentPage - 1);
        }
        if (currentSort) {
            newCollectionView.sortDescriptions.push(new SortDescription(currentSort.column, currentSort.ascending));
        }
        if (getError) {
            newCollectionView.getError = getError;
        }
        if (newItemCreator) {
            newCollectionView.newItemCreator = newItemCreator;
        }
        if (isFunction(collectionViewFilter)) {
            newCollectionView.filter = collectionViewFilter;
        }
        setCollectionView(newCollectionView);
    };
    var setTotalCountWrapper = function (data) {
        if (!listStateShow || !data.extraData) {
            return setTotalCount(data.totalCount);
        }
        var count;
        switch (listStateCounts.value) {
            case ListStates.All:
                count = data.extraData.allCnt;
                break;
            case ListStates.Active:
                count = data.extraData.activeCnt;
                break;
            case ListStates.Inactive:
                count = data.extraData.inActiveCnt;
                break;
            case ListStates.Pending:
                count = data.extraData.pendingCount;
                break;
            case ListStates.Future:
                count = data.extraData.futureCnt;
                break;
        }
        setTotalCount(count);
    };
    var onGridInit = function (grid) {
        if (initialized) {
            initialized(grid);
        }
        // Adjust row height
        grid.rows.defaultSize = 40;
        grid.columnHeaders.rows.defaultSize = 40;
        // Make the grid responsive with adding horizontal scrollbar
        wijmoGridResponsive(grid);
        // Listen sorting changes
        grid.sortedColumn.addHandler(function (flex, args) {
            var sortDescription = grid.collectionView.sortDescriptions[0];
            setCurrentSort({
                column: sortDescription.property,
                ascending: sortDescription.ascending,
            });
        });
    };
    var setFlexGridOnSearchCallback = function () {
        if (!searchOptions.onSearch || !flexGridSearchRef.current || !flexGridSearchRef.current.control) {
            return null;
        }
        flexGridSearchRef.current.control.inputElement.oninput = function () {
            searchOptions.onSearch(flexGridSearchRef.current.control.inputElement.value);
        };
    };
    var onGroupRefresh = function () {
        onRefreshed && onRefreshed(grid);
    };
    var onListStateChange = function (value) {
        setCurrentPage(1);
        setListStateCounts(__assign({}, listStateCounts, { value: value }));
    };
    var exportGrid = function () {
        if (serviceFunction) {
            return exportFromServiceData();
        }
        return exportLocalGridData();
    };
    var exportLocalGridData = function () {
        desingExcelAndDownload(grid.collectionView.items);
    };
    var exportFromServiceData = function () {
        var params = getParams();
        params.keyword = "";
        params.offset = 0;
        delete params.limit;
        setLoadingState(true);
        serviceFunction(params)
            .then(function (data) {
            desingExcelAndDownload(data);
            setLoadingState(false);
        })
            .catch(function () { return setLoadingState(false); });
    };
    var desingExcelAndDownload = function (data) {
        var filename = exportOptions.filename, formatData = exportOptions.formatData, includeColumns = exportOptions.includeColumns, excludeColumns = exportOptions.excludeColumns, onSave = exportOptions.onSave, onError = exportOptions.onError, successMessage = exportOptions.successMessage, errorMessage = exportOptions.errorMessage, noDataMessage = exportOptions.noDataMessage;
        var records = formatData(data);
        // Skip of no records are available in data
        if (!records.length) {
            return MessageHelper.Message(NotificationType.Warning, noDataMessage);
        }
        // Beginning of creation the workbook
        var workbook = new Workbook();
        var sheet = new WorkSheet();
        var columns = __spread(grid.columns.filter(function (column) { return !excludeColumns.includes(column.binding) && column.binding; }), includeColumns);
        // Adding header row
        var headerRow = new WorkbookRow();
        headerRow.style = new WorkbookStyle();
        headerRow.style.font = new WorkbookFont();
        headerRow.style.font.bold = true;
        headerRow.style.hAlign = HAlign.Center;
        // Adding columns and header cells
        columns.forEach(function (c, index) {
            // Sheet Column
            var column = new WorkbookColumn();
            column.width = c.width;
            sheet.columns.push(column);
            // Header cell
            var headerCell = new WorkbookCell();
            headerCell.value = c.header;
            headerRow.cells.push(headerCell);
        });
        sheet.rows.push(headerRow);
        // Adding records
        records.forEach(function (record) {
            var row = new WorkbookRow();
            columns.forEach(function (c) {
                var cell = new WorkbookCell();
                cell.value = get(record, c.binding);
                row.cells.push(cell);
            });
            sheet.rows.push(row);
        });
        workbook.sheets.push(sheet);
        // Saving the workbook
        workbook.saveAsync((filename || "Workbook") + ".xlsx", function (base64) {
            if (onSave) {
                onSave(base64);
            }
            if (successMessage) {
                MessageHelper.Message(NotificationType.Success, successMessage);
            }
        }, function (reason) {
            if (onError) {
                onError(reason);
            }
            if (errorMessage) {
                MessageHelper.Message(NotificationType.Success, errorMessage);
            }
        });
    };
    var renderGroupBar = function () {
        return React.createElement(wjGroup.GroupPanel, { className: "group-panel", placeholder: groupBarText, grid: grid, onRefreshed: onGroupRefresh });
    };
    var renderHead = function () {
        if (!search && !listStateShow && !title && !actions.length && !allowExport) {
            return null;
        }
        return (React.createElement(Row, { className: "wgrid__head", type: "flex", align: "bottom", justify: "space-between" },
            React.createElement(Col, { className: "wgrid__head__left-column" },
                search && renderSearchBar(),
                React.createElement(Row, { type: "flex", align: "middle", gutter: 15 },
                    title && (React.createElement(Col, null,
                        React.createElement("h3", { className: "wgrid__title" }, title))),
                    listStateShow && React.createElement(Col, null, renderListStates()))),
            React.createElement(Col, null,
                React.createElement(Actions, { actions: __spread(actions, getDefaultActions()) }))));
    };
    var getDefaultActions = function () {
        var actions = [];
        if (allowExport) {
            var buttonIcon = exportOptions.buttonIcon, buttonLocaleId = exportOptions.buttonLocaleId;
            actions.push(React.createElement(Action, { key: "u0", materialIcon: buttonIcon, onClick: exportGrid, textLocaleId: buttonLocaleId }));
        }
        return actions;
    };
    var renderSearchBar = function () {
        return (React.createElement("div", { className: "wgrid__search" }, serviceFunction ? (React.createElement(Input.Search, { onSearch: function (value) {
                setCurrentPage(1);
                setFilter(value);
                if (searchOptions.onSearch) {
                    searchOptions.onSearch(value);
                }
            }, placeholder: searchOptions.label })) : (React.createElement(FlexGridSearch, { ref: flexGridSearchRef, grid: grid, placeholder: searchOptions.label }))));
    };
    var renderListStates = function () {
        return (React.createElement(ListStatesRadios, __assign({}, listStateCounts, listStateOptions, { onChange: onListStateChange, className: mergeClasses("wgrid__state", listStateOptions && listStateOptions.className) })));
    };
    var renderGrid = function () {
        return (React.createElement("div", { className: "wgrid__wrapper" },
            React.createElement(FlexGrid, __assign({ itemsSource: collectionView }, rest, { ref: flexGridRef, initialized: onGridInit }), children),
            renderLoader()));
    };
    var renderPagination = function () {
        return (React.createElement(Pagination, { className: "wgrid__pagination", current: currentPage, total: totalCount || collectionView.sourceCollection.length, pageSize: paginationSize, onChange: function (page) { return setCurrentPage(page); }, hideOnSinglePage: true, showTotal: paginationShowTotal }));
    };
    var renderLoader = function () {
        return React.createElement(Loading, { visible: loading, className: "wgrid__loader" });
    };
    var renderNoData = function () {
        return React.createElement(Empty, { description: noDataMessage });
    };
    return (React.createElement("div", { className: classNames("wgrid", className, bordered && "wgrid--bordered") },
        renderHead(),
        grouping && renderGroupBar(),
        renderGrid(),
        noData && renderNoData(),
        pagination && collectionView && !noData && renderPagination()));
});
Grid.defaultProps = {
    selectionMode: SelectionMode.ListBox,
    headersVisibility: HeadersVisibility.Column,
    allowResizing: AllowResizing.Columns,
    allowSorting: true,
    stickyHeaders: true,
    pagination: true,
    paginationSize: 10,
    alternatingRowStep: 0,
    serviceFormatData: function (data) { return data; },
    searchOptions: {
        label: "Search",
    },
    actions: [],
    exportOptions: {
        buttonLocaleId: "Export",
        buttonIcon: "save_alt",
        includeColumns: [],
        excludeColumns: [],
        formatData: function (data) { return data; },
    },
};
