var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import "./style/full-screen.less";
import FullScreenIcon from "./full-screen-icon";
import ExitFullScreenIcon from "./exit-full-screen-icon";
import { isiPhone } from "@app/util";
import { resetDimensions } from "@app/components/media/playlistmediaplayer_support";
import { GLUtil } from "gl-commonui";
var FullScreenButton = /** @class */ (function (_super) {
    __extends(FullScreenButton, _super);
    function FullScreenButton(props) {
        var _this = _super.call(this, props) || this;
        _this.goToFullScreen = function () {
            var elem = document.querySelector('.contentplayer');
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
            else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            }
            else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            _this.setState({ isFullscreen: true });
            setTimeout(function () {
                resetDimensions();
            }, 100);
        };
        _this.exitFullscreen = function () {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
            else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
            else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            _this.setState({ isFullscreen: false });
            setTimeout(function () {
                resetDimensions();
            }, 100);
        };
        _this.fullscreen = function () {
            if (_this.isSupportedFullscreen()) {
                if (_this.isFullscreen()) {
                    _this.exitFullscreen();
                }
                else {
                    _this.goToFullScreen();
                }
            }
            else {
                console.log('Fullscreen is not supported on your browser.');
                var elem = document.querySelector('.contentplayer');
                if (_this.state.isFullscreen) {
                    elem.className = elem.className.replace(/ios9-full-screen/g, '');
                    _this.exitFullscreen();
                }
                else {
                    elem.className = elem.className + " ios9-full-screen";
                    _this.goToFullScreen();
                }
            }
        };
        _this.state = {
            isFullscreen: _this.isFullscreen(),
        };
        return _this;
    }
    FullScreenButton.prototype.isSupportedFullscreen = function () {
        return document.fullscreenEnabled ||
            document.webkitFullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled;
    };
    FullScreenButton.prototype.isFullscreen = function () {
        if (this.isSupportedFullscreen()) {
            return !GLUtil.isNullOrUndefined(document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement);
        }
        else {
            return document
                .querySelector('.contentplayer')
                .className
                .includes('ios9-full-screen');
        }
    };
    FullScreenButton.prototype.render = function () {
        if (!isiPhone()) {
            return (React.createElement("div", { className: "flat-button", onClick: this.fullscreen }, this.state.isFullscreen ? React.createElement(ExitFullScreenIcon, null) : React.createElement(FullScreenIcon, null)));
        }
        return (null);
    };
    return FullScreenButton;
}(Component));
export default FullScreenButton;
