import theme from "./theme";
import signature from "./signature";
import vpcbkaudios from "./vpcbkaudios";
import autoplayAlert from "./autoplayalert";
import playlistplayer from "./playlistplayer";
import drawer from "./drawer";
import systemCheck from "./system-check";
import conversation from "./conversation";
import children from "./children";
export default [theme, signature, vpcbkaudios, autoplayAlert, playlistplayer, drawer, systemCheck, conversation, children];
