var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { mergeClasses } from "@app/util";
import React from "react";
import "./checkbox.less";
export var GridCheckbox = function (props) {
    var className = props.className, context = props.context, rest = __rest(props, ["className", "context"]);
    return (React.createElement("input", __assign({}, rest, { className: mergeClasses("grid-checkbox wj-cell-check", className), defaultChecked: context.item[context.col.binding], type: "checkbox" })));
};
export var GridReadOnlyCheckbox = function (props) {
    var className = props.className, context = props.context, rest = __rest(props, ["className", "context"]);
    return (React.createElement("input", __assign({}, rest, { className: mergeClasses("grid-checkbox wj-cell-check", className), checked: context.item[context.col.binding], readOnly: true, type: "checkbox" })));
};
