var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { PieceSelectedStates, PuzzleSizeEnum, PuzzlePieceTypeEnum } from "@app/util";
import { PuzzleBoardDom, PuzzlePieceBoxDom, PieceDimension, PuzzleBoardPieceBoxDom, PuzzleBoardFrameDom } from "./dimension";
import { Popover, Icon } from "antd-min";
import debounce from "lodash/debounce";
import "./style/puzzle.less";
import { PuzzleAudioType } from "./puzzle-audios";
import PuzzleCongrat from "./puzzle-congrat";
import PortalContainer from "../portal-container";
import { chain } from "lodash";
var Puzzle = /** @class */ (function (_super) {
    __extends(Puzzle, _super);
    function Puzzle(props) {
        var _this = _super.call(this, props) || this;
        _this.onResize = null;
        _this.sas = null;
        _this.boardDom = null;
        _this.boardFrameDom = null;
        _this.pieceBoardBoxDom = null;
        _this.pieceBoxDom = null;
        _this.pieceDimension = null;
        _this.resizeThrottle = 0;
        _this.finished = {};
        var config = props.config;
        _this.state = {
            isEyesOpen: config.isEyesOpen,
            pieceType: config.pieceType,
            piecesAsset: null,
            isReady: false,
            selectSource: {},
            breaksVisible: false,
        };
        var resize = debounce(_this.resizePuzzleShortTime.bind(_this), 100);
        _this.onResize = function () {
            _this.resetPuzzleSize();
            resize();
        };
        return _this;
    }
    Puzzle.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.onResize);
    };
    Puzzle.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.onResize);
    };
    Puzzle.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        var sas = nextProps.sas, puzzleList = nextProps.puzzleList;
        if (this.props.puzzleList != puzzleList && puzzleList && !this.boardDom) {
            this.puzzleList = puzzleList;
            this.sas = sas;
            this.setState({ isEyesOpen: this.puzzleList.studentPuzzleUnlockCountInfo[0].unlockCount > 1 ? false : true });
            var asset = this.formatPieceAssets(puzzleList.puzzle);
            this.setState({ piecesAsset: asset }, function () { return _this.initDoms(); });
        }
    };
    Puzzle.prototype.initDoms = function () {
        var _this = this;
        var _a = this.state, pieceType = _a.pieceType, piecesAsset = _a.piecesAsset;
        this.boardDom = new PuzzleBoardDom(".puzzle-playground-board");
        this.boardFrameDom = new PuzzleBoardFrameDom(".puzzle-playground-board-frame");
        this.pieceDimension = PieceDimension.build(piecesAsset[pieceType], this.boardDom);
        this.pieceBoardBoxDom = new PuzzleBoardPieceBoxDom(".puzzle-playground-board-pieces-box", this.pieceDimension);
        this.pieceBoxDom = new PuzzlePieceBoxDom(".puzzle-playground-pieces-box", this.pieceDimension);
        this.boardFrameDom.trackPosition(function (pt) {
            var pieceType = _this.state.pieceType;
            if (!_this.finished[pieceType]) {
                _this.setSourcePieceState(pt, _this.selectTargetCall(pieceType));
            }
        });
    };
    Puzzle.prototype.setSourcePieceState = function (pt, callback) {
        var targetIndex = this.pieceDimension.locator.findTargetPosition(pt);
        this.setState(function (state) {
            var selectIndex = state.selectSource.pieceIndex;
            if (selectIndex) {
                var selectState = selectIndex === targetIndex ? PieceSelectedStates.Selected : PieceSelectedStates.UnSelected;
                state.piecesAsset[state.pieceType].pieces
                    .find(function (e) { return e.pieceIndex === selectIndex; })
                    .selectState = selectState;
                callback(selectIndex);
            }
            return ({ selectSource: {} });
        });
    };
    Puzzle.prototype.selectTargetCall = function (pieceType) {
        var _this = this;
        return function (selectIndex) {
            _this.finished[pieceType] = _this.selectTargetSideEffects(selectIndex);
        };
    };
    Puzzle.prototype.selectTargetSideEffects = function (selectIndex) {
        var _a = this.state, pieceType = _a.pieceType, piecesAsset = _a.piecesAsset;
        var pieces = piecesAsset[pieceType].pieces;
        var isFinishPuzzle = pieces.every(function (e) { return e.selectState === PieceSelectedStates.Selected; });
        var isSelectCorrect = pieces.find(function (e) { return e.pieceIndex === selectIndex; }).selectState === PieceSelectedStates.Selected;
        var audioType = null;
        if (isFinishPuzzle) {
            audioType = PuzzleAudioType.FinishPuzzle;
        }
        else if (isSelectCorrect) {
            var snaps = [
                PuzzleAudioType.SnapCorrect01,
                PuzzleAudioType.SnapCorrect02,
                PuzzleAudioType.SnapCorrect03
            ];
            audioType = snaps[Math.floor(Math.random() * snaps.length)];
        }
        else {
            audioType = PuzzleAudioType["OOPS" + pieceType.replace('pieces', '')];
        }
        this.clickSideEffect(audioType);
        return isFinishPuzzle;
    };
    Puzzle.prototype.formatPieceAssets = function (assets) {
        var groupedBySize = chain(assets[0].puzzlePiece)
            .groupBy("size")
            .map(function (key, val) { return ({ size: val, puzzlePieceInfo: key }); })
            .value();
        var onlyPuzzlePieces = groupedBySize.map(function (p) {
            return p.puzzlePieceInfo.filter(function (currentPiece) { return currentPiece.puzzlePieceTypeId === PuzzlePieceTypeEnum.Piece; });
        });
        var allPathLInes = groupedBySize.map(function (p) {
            return p.puzzlePieceInfo.filter(function (currentPiece) { return currentPiece.puzzlePieceTypeId === PuzzlePieceTypeEnum.Line; });
        });
        var buildAsset = function (asset, type) {
            var typePuzzlesIndex = onlyPuzzlePieces.findIndex(function (grp) { return grp.some(function (g) { return g.size === type; }); });
            var typePuzzles = onlyPuzzlePieces[typePuzzlesIndex];
            var typePuzzlesLineIndex = allPathLInes.findIndex(function (grp) { return grp.some(function (g) { return g.size === type; }); });
            var typePuzzlesLine = allPathLInes[typePuzzlesLineIndex];
            return assets
                .map(function (e) {
                var _a;
                return __assign({}, asset, (_a = { id: e.id, isColor: e.isColor, masterPuzzleId: e.masterPuzzleId, path: e.path, sequence: e.sequence, thumbnailPath: e.thumbnailPath }, _a[type] = {
                    size: [type],
                    pathImage: typePuzzlesLine.map(function (p) {
                        return p.path;
                    }),
                    pieces: typePuzzles
                        .map(function (p) {
                        var splitArr = __spread(p.data.split(",")).map(function (item) { return parseInt(item); });
                        var data = {
                            piece: p.path,
                            pieceIndex: p.sequence,
                            tabTypes: __spread(splitArr.slice(1), [splitArr[0]]),
                            selectState: PieceSelectedStates.UnSelected
                        };
                        return data;
                    })
                        .sort(random),
                }, _a));
            })[0];
        };
        var asset = buildAsset({}, PuzzleSizeEnum.p2x2);
        asset = buildAsset(asset, PuzzleSizeEnum.p5x2);
        asset = buildAsset(asset, PuzzleSizeEnum.p7x3);
        asset = buildAsset(asset, PuzzleSizeEnum.p10x4);
        return asset;
    };
    Puzzle.prototype.changePieceType = function (pieceType) {
        var _this = this;
        this.finished[pieceType] = false;
        var resetPieces = function () {
            var _a = _this.state, prePieceType = _a.pieceType, piecesAsset = _a.piecesAsset;
            piecesAsset[prePieceType].pieces.sort(random).forEach(function (e) { return e.selectState = PieceSelectedStates.UnSelected; });
            _this.setState({ breaksVisible: false, piecesAsset: piecesAsset, selectSource: {} });
        };
        resetPieces();
        this.props.getSAS()
            .then(function (sas) {
            _this.sas = sas;
            _this.setState({ pieceType: pieceType }, function () {
                var piecesAsset = _this.state.piecesAsset;
                _this.pieceDimension = PieceDimension.build(piecesAsset[pieceType], _this.boardDom);
                _this.pieceBoardBoxDom.setPiece(_this.pieceDimension);
                _this.pieceBoxDom.setPiece(_this.pieceDimension);
                _this.resizePuzzleShortTime();
            });
        });
    };
    Puzzle.prototype.clickSideEffect = function (type) {
        this.props.playAudio(type);
    };
    Puzzle.prototype.resizePuzzleShortTime = function () {
        var _this = this;
        var resize = function () {
            if (Date.now() - _this.resizeThrottle <= 1000) {
                _this.resetPuzzleSize();
                requestAnimationFrame(resize);
            }
        };
        this.resizeThrottle = Date.now();
        resize();
    };
    Puzzle.prototype.resetPuzzleSize = function () {
        if (this.pieceBoxDom && this.pieceBoardBoxDom) {
            this.pieceBoardBoxDom.setDimension();
            this.pieceBoxDom.setDimension();
        }
    };
    Puzzle.prototype.renderBoardPieces = function () {
        var _this = this;
        var _a = this.state, pieceType = _a.pieceType, piecesAsset = _a.piecesAsset;
        var getPieceClass = function (selectState) {
            if (selectState === PieceSelectedStates.Selected) {
                return 'puzzle-playground-board-pieces-box-piece-selected';
            }
            else if (selectState === PieceSelectedStates.Hint) {
                return 'puzzle-playground-board-pieces-box-piece-hint';
            }
            return '';
        };
        return piecesAsset[pieceType].pieces.map(function (_a) {
            var piece = _a.piece, pieceIndex = _a.pieceIndex, selectState = _a.selectState;
            return React.createElement("div", { key: pieceIndex, className: "puzzle-playground-board-pieces-box-piece " + getPieceClass(selectState) },
                React.createElement(PuzzleImage, { "data-identity": pieceIndex, alt: '', src: "" + _this.puzzleList.endPoint + piece + _this.sas }));
        });
    };
    Puzzle.prototype.renderBoard = function () {
        var _this = this;
        var _a = this.state, pieceType = _a.pieceType, isEyesOpen = _a.isEyesOpen, piecesAsset = _a.piecesAsset;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "puzzle-playground-board-bkimage " + (isEyesOpen ? '' : 'puzzle-playground-board-bkimage-hidden') },
                React.createElement(PuzzleImage, { src: "" + this.puzzleList.endPoint + piecesAsset.path + this.sas, onLoad: function () {
                        _this.resizePuzzleShortTime();
                        _this.setState({ isReady: true });
                    } })),
            React.createElement("div", { className: 'puzzle-playground-board-pieces-box' }, this.renderBoardPieces()),
            React.createElement("div", { className: 'puzzle-playground-board-frame' },
                React.createElement(PuzzleImage, { src: "" + this.puzzleList.endPoint + piecesAsset[pieceType].pathImage + this.sas }))));
    };
    Puzzle.prototype.renderPieces = function () {
        var _this = this;
        var round = this.props.round;
        var _a = this.state, pieceType = _a.pieceType, selectSource = _a.selectSource, piecesAsset = _a.piecesAsset;
        var getSelectState = function (piece, selectState) {
            if (selectState === PieceSelectedStates.Selected) {
                return 'source-piece-hidden';
            }
            return selectSource.pieceIndex === piece
                && (selectSource.selectState === PieceSelectedStates.Selecting || selectSource.selectState === PieceSelectedStates.Hint)
                ? 'source-piece-selecting'
                : '';
        };
        return piecesAsset[pieceType].pieces.map(function (_a) {
            var piece = _a.piece, pieceIndex = _a.pieceIndex, selectState = _a.selectState;
            return React.createElement("div", { key: pieceIndex, className: "puzzle-playground-pieces-box-piece " + getSelectState(pieceIndex, selectState), onClick: function () {
                    _this.setState({ selectSource: { piece: piece, pieceIndex: pieceIndex, selectState: PieceSelectedStates.Selecting } });
                } },
                React.createElement(PuzzleImage, { "data-identity": pieceIndex, alt: '', src: "" + _this.puzzleList.endPoint + piece + _this.sas }));
        });
    };
    Puzzle.prototype.renderHammers = function () {
        var _this = this;
        var pieceType = this.state.pieceType;
        var getHammerItem = function (pType, pbreak) {
            return React.createElement("div", { className: "hammer-item " + (pieceType === pType ? 'hammer-item-selected' : '') },
                React.createElement("div", { "data-break": pType, className: "tools-break " + pbreak }));
        };
        return (React.createElement("div", { className: 'hammer-box', onClick: function (ev) {
                var pieceType = ev.target.getAttribute('data-break');
                if (pieceType === _this.state.pieceType) {
                    return;
                }
                _this.clickSideEffect(PuzzleAudioType["SelectBrick" + pieceType.replace('pieces', '')]);
                _this.changePieceType(pieceType);
            } },
            getHammerItem(PuzzleSizeEnum.p2x2, 'break_4'),
            getHammerItem(PuzzleSizeEnum.p5x2, 'break_10'),
            getHammerItem(PuzzleSizeEnum.p7x3, 'break_21'),
            getHammerItem(PuzzleSizeEnum.p10x4, 'break_40')));
    };
    Puzzle.prototype.hintPiece = function () {
        var _a = this.state, pieceType = _a.pieceType, selectSource = _a.selectSource, piecesAsset = _a.piecesAsset;
        var pieces = piecesAsset[pieceType].pieces.filter(function (e) { return e.selectState !== PieceSelectedStates.Selected; });
        var pieceData = null;
        var isUnSelected = selectSource.selectState === undefined || selectSource.selectState === PieceSelectedStates.UnSelected;
        if (isUnSelected) {
            var length = pieces.length;
            pieceData = pieces[Math.floor(Math.random() * length)];
        }
        else {
            pieceData = pieces.find(function (e) { return e.pieceIndex == selectSource.pieceIndex; });
        }
        pieceData.selectState = PieceSelectedStates.Selected;
        this.selectTargetCall(pieceType)(pieceData.pieceIndex);
        this.setState({ piecesAsset: piecesAsset, selectSource: {} });
    };
    Puzzle.prototype.render = function () {
        var _this = this;
        var _a = this.state, isEyesOpen = _a.isEyesOpen, pieceType = _a.pieceType, isReady = _a.isReady, breaksVisible = _a.breaksVisible, piecesAsset = _a.piecesAsset;
        if (!piecesAsset) {
            return React.createElement("div", null);
        }
        return (React.createElement("div", { className: "puzzle-layer " + (isReady ? 'puzzle-layer-ready' : '') },
            React.createElement("div", { className: "puzzle-tools" },
                React.createElement(ToolBack, null),
                React.createElement("div", { className: "tools " + (isEyesOpen ? 'tool-eyes_open' : 'tool-eyes_closed') + " " + (this.finished[pieceType] ? 'tools-disabled' : ''), onClick: function () {
                        if (!_this.finished[pieceType]) {
                            _this.setState(function (state) { return ({ isEyesOpen: !state.isEyesOpen }); });
                        }
                    } }),
                React.createElement(Popover, { visible: breaksVisible, onVisibleChange: function (breaksVisible) {
                        if (breaksVisible) {
                            _this.clickSideEffect(PuzzleAudioType.ClickHammer);
                        }
                        _this.setState({ breaksVisible: breaksVisible });
                    }, overlayClassName: "puzzle-tools-break", placement: "right", getPopupContainer: function (node) { return node.parentElement; }, content: this.renderHammers(), trigger: "click" },
                    React.createElement("div", { className: 'tools tool-hammer' })),
                React.createElement("div", { className: "tools tool-hint " + (this.finished[pieceType] ? 'tools-disabled' : ''), onClick: function () {
                        if (!_this.finished[pieceType]) {
                            _this.hintPiece();
                        }
                    } })),
            React.createElement("div", { className: "puzzle-playground" },
                React.createElement("div", { className: "puzzle-playground-board" }, this.renderBoard()),
                React.createElement("div", { className: "puzzle-playground-pieces-box" }, this.renderPieces())),
            React.createElement(PortalContainer, { id: "puzzle-player" },
                React.createElement(PuzzleCongrat, { isFinished: this.finished[pieceType] }))));
    };
    return Puzzle;
}(Component));
export default Puzzle;
function ToolBack() {
    return (React.createElement("div", { className: "tools tool-back", onClick: function (_) { return window.history.go(-1); } },
        React.createElement(Icon, { type: "arrow-left" })));
}
function PuzzleImage(props) {
    return (React.createElement("img", __assign({ onDragStart: function (_) { return false; }, draggable: false }, props)));
}
var random = function (_) { return 0.5 - Math.random(); };
export function getRound(round) {
    var nextRound = Number(round) - 1;
    return nextRound > 4 ? 4 : nextRound;
}
