var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var SettingDropdownIcon = /** @class */ (function (_super) {
    __extends(SettingDropdownIcon, _super);
    function SettingDropdownIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SettingDropdownIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 18" },
            React.createElement("path", { className: "path", d: "M19.2,7.7c-0.1-0.4-0.5-0.8-0.9-0.9L18,6.7c-0.7-0.2-1.4-0.7-1.8-1.4c-0.4-0.7-0.5-1.5-0.3-2.3l0.1-0.3\n\t\t\tc0.1-0.4,0-1-0.4-1.2c0,0-0.3-0.2-1.1-0.7c-0.8-0.5-1.2-0.6-1.2-0.6c-0.4-0.1-1,0-1.3,0.3l-0.2,0.2c-0.6,0.5-1.3,0.8-2.1,0.8\n\t\t\tC8.8,1.4,8,1.1,7.5,0.6L7.3,0.4C7,0.1,6.4-0.1,6,0.1c0,0-0.4,0.1-1.2,0.6C4,1.1,3.7,1.4,3.7,1.4C3.4,1.7,3.2,2.2,3.3,2.6l0.1,0.3\n\t\t\tc0.2,0.7,0.1,1.6-0.3,2.3C2.7,5.9,2,6.4,1.3,6.7L1,6.7C0.5,6.8,0.1,7.2,0.1,7.7C0.1,7.7,0,8,0,9c0,1,0.1,1.3,0.1,1.3\n\t\t\tc0.1,0.4,0.5,0.8,0.9,0.9l0.3,0.1c0.7,0.2,1.4,0.7,1.8,1.4c0.4,0.7,0.5,1.5,0.3,2.3l-0.1,0.3c-0.1,0.4,0,1,0.4,1.2\n\t\t\tc0,0,0.3,0.2,1.1,0.7C5.6,17.8,6,17.9,6,17.9c0.4,0.1,1,0,1.3-0.3l0.2-0.2c0.6-0.5,1.3-0.9,2.1-0.9c0.8,0,1.6,0.3,2.2,0.9h0\n\t\t\tl0.2,0.2c0.3,0.3,0.9,0.5,1.3,0.3c0,0,0.4-0.1,1.2-0.6c0.8-0.5,1.1-0.7,1.1-0.7c0.3-0.3,0.5-0.8,0.4-1.2l-0.1-0.3\n\t\t\tc-0.2-0.7-0.1-1.6,0.3-2.3c0.4-0.7,1.1-1.2,1.8-1.4v0l0.3-0.1c0.4-0.1,0.8-0.5,0.9-0.9c0,0,0.1-0.4,0.1-1.3\n\t\t\tC19.2,8,19.2,7.7,19.2,7.7L19.2,7.7z M9.6,12.8c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8S11.7,12.8,9.6,12.8\n\t\t\tL9.6,12.8z M9.6,12.8" })));
    };
    return SettingDropdownIcon;
}(Component));
export default SettingDropdownIcon;
