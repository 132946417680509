import React from "react";
function PlaylistBadge(_a) {
    var coordinates = _a.coordinates, index = _a.index;
    return (React.createElement("div", { style: {
            position: "absolute",
            top: coordinates.y - 9,
            left: coordinates.x - 7
        }, className: "student-badge-background" },
        React.createElement("div", { className: "student-badge student-badge-round" + (index + 1) + "-reward" })));
}
export default PlaylistBadge;
