import { isFunction } from "lodash";
import { useEffect } from "react";
import { toggleClass } from "wijmo/wijmo";
import { CellRange, CellRangeEventArgs, CellType, _NewRowTemplate } from "wijmo/wijmo.grid";
export function useWijmoEditingHandler(grid, beginningEditCallback, cellEditEndingCallback, cellEditEndedCallback, cellDisableHandler) {
    useEffect(function () {
        if (grid && !grid.isReadOnly) {
            grid.beginningEdit.addHandler(beginningEdit);
            grid.cellEditEnding.addHandler(cellEditEnding);
            grid.cellEditEnded.addHandler(cellEditEnded);
            grid.pastingCell.addHandler(pastingCell);
            grid.pastedCell.addHandler(pastedCell);
            if (isFunction(cellDisableHandler)) {
                grid.formatItem.addHandler(formatItem);
            }
        }
        return function () {
            if (grid && !grid.isReadOnly) {
                grid.beginningEdit.removeHandler(beginningEdit);
                grid.cellEditEnding.removeHandler(cellEditEnding);
                grid.cellEditEnded.removeHandler(cellEditEnded);
                grid.pastingCell.removeHandler(pastingCell);
                grid.pastedCell.removeHandler(pastedCell);
                if (isFunction(cellDisableHandler)) {
                    grid.formatItem.removeHandler(formatItem);
                }
            }
        };
    }, [grid]);
    var beginningEdit = function (grid, event) {
        if (isFunction(beginningEditCallback)) {
            var dataItem = grid.collectionView.items[event.row];
            beginningEditCallback(grid, event, dataItem);
        }
    };
    var cellEditEnding = function (grid, event) {
        if (isFunction(cellEditEndingCallback)) {
            var dataItem = grid.collectionView.items[event.row];
            cellEditEndingCallback(grid, event, dataItem);
        }
    };
    var cellEditEnded = function (grid, event) {
        if (isFunction(cellEditEndedCallback)) {
            var dataItem = grid.collectionView.items[event.row];
            cellEditEndedCallback(grid, event, dataItem);
        }
    };
    var pastingCell = function (grid, event) {
        if (isFunction(beginningEditCallback)) {
            var dataItem = grid.collectionView.items[event.row];
            beginningEditCallback(grid, event, dataItem);
        }
    };
    var pastedCell = function (grid, event) {
        if (isFunction(cellEditEndedCallback)) {
            var dataItem = grid.collectionView.items[event.row];
            cellEditEndedCallback(grid, event, dataItem);
        }
    };
    var formatItem = function (grid, event) {
        var row = grid.rows[event.row];
        if (event.panel.cellType === CellType.Cell && !(row instanceof _NewRowTemplate)) {
            var dataItem = grid.rows[event.row].dataItem;
            var column = grid.columns[event.col];
            var isDisabled = column.isReadOnly;
            if (!isDisabled) {
                var cellEvent = new CellRangeEventArgs(event.panel, new CellRange(event.row, event.col));
                cellDisableHandler(grid, cellEvent, dataItem);
                isDisabled = cellEvent.cancel;
            }
            toggleClass(event.cell, "wj-state-disabled", isDisabled);
        }
    };
    return null;
}
