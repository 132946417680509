var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from "react";
import { Alert, Icon } from "antd-min";
import { connect } from "gl-commonui";
import { fmtMsg, couldAutoPlay } from "@app/util";
import { StudentLocale } from "@app/locales/localeid";
import { showAutoplayAlert, isAutoPlayAlertShow, hideAutoplayAlert } from "@app/states/autoplayalert";
import { pausePlaylistPlayer } from "@app/states/playlistplayer";
var AutoPlayAlert = /** @class */ (function (_super) {
    __extends(AutoPlayAlert, _super);
    function AutoPlayAlert() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AutoPlayAlert.prototype.render = function () {
        var show = this.props.show;
        return show ? React.createElement("div", { className: "playlist-player-alert" },
            React.createElement(Alert, { showIcon: false, message: (React.createElement("a", { href: 'https://help.grapeseed.com', target: "_blank" },
                    React.createElement(Icon, { type: "info-circle", theme: "filled" }),
                    fmtMsg(StudentLocale.MediaplayerMessageAutoplayalert))), banner: true })) : null;
    };
    AutoPlayAlert = __decorate([
        connect(function (_a) {
            var show = _a.autoplayalert.show;
            return ({
                show: show,
            });
        })
    ], AutoPlayAlert);
    return AutoPlayAlert;
}(Component));
export default AutoPlayAlert;
export function checkAutoPlay(playing, paused) {
    return couldAutoPlay()
        .then(function () {
        playing && playing();
        isAutoPlayAlertShow() && hideAutoplayAlert();
    })
        .catch(function (err) {
        console.error(err);
        pausePlaylistPlayer(true);
        paused && paused();
        showAutoplayAlert(true);
    });
}
