var JbClapCycle = /** @class */ (function () {
    function JbClapCycle(
    /**
     * @duration in milliseconds
     */
    duration) {
        this.duration = duration;
        this.frames = [80, 81, 82, 83, 84, 85, 86];
    }
    return JbClapCycle;
}());
export { JbClapCycle };
