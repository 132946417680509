export var StudentAction;
(function (StudentAction) {
    StudentAction["None"] = "GA_HomePage_None";
})(StudentAction || (StudentAction = {}));
export var ContentGroupEnum;
(function (ContentGroupEnum) {
    ContentGroupEnum["Song"] = "Song";
    ContentGroupEnum["Movie"] = "Movie";
    ContentGroupEnum["Story"] = "Story";
    ContentGroupEnum["VPC"] = "VPC";
    ContentGroupEnum["Question"] = "Question";
    ContentGroupEnum["Conversation"] = "Conversation";
    ContentGroupEnum["ConversationIntroVideo"] = "ConversationIntroVideo";
    ContentGroupEnum["None"] = "None";
})(ContentGroupEnum || (ContentGroupEnum = {}));
export var ServerContentGroupEnum;
(function (ServerContentGroupEnum) {
    ServerContentGroupEnum[ServerContentGroupEnum["None"] = -1] = "None";
    ServerContentGroupEnum[ServerContentGroupEnum["Song"] = 0] = "Song";
    ServerContentGroupEnum[ServerContentGroupEnum["Movie"] = 1] = "Movie";
    ServerContentGroupEnum[ServerContentGroupEnum["Story"] = 2] = "Story";
    ServerContentGroupEnum[ServerContentGroupEnum["Reader"] = 3] = "Reader";
    ServerContentGroupEnum[ServerContentGroupEnum["Writer"] = 4] = "Writer";
    ServerContentGroupEnum[ServerContentGroupEnum["VPC"] = 5] = "VPC";
})(ServerContentGroupEnum || (ServerContentGroupEnum = {}));
export var PlayerRole;
(function (PlayerRole) {
    PlayerRole[PlayerRole["Text"] = 0] = "Text";
    PlayerRole[PlayerRole["Digital"] = 1] = "Digital";
    PlayerRole[PlayerRole["Dual"] = 2] = "Dual";
    PlayerRole[PlayerRole["Other"] = 128] = "Other";
})(PlayerRole || (PlayerRole = {}));
export var SubscriptionTypeUsage;
(function (SubscriptionTypeUsage) {
    SubscriptionTypeUsage[SubscriptionTypeUsage["Standard"] = 0] = "Standard";
    SubscriptionTypeUsage[SubscriptionTypeUsage["Dual"] = 1] = "Dual";
    SubscriptionTypeUsage[SubscriptionTypeUsage["Single"] = 2] = "Single";
})(SubscriptionTypeUsage || (SubscriptionTypeUsage = {}));
export var Mode;
(function (Mode) {
    Mode[Mode["Listen"] = 0] = "Listen";
    Mode[Mode["Play"] = 1] = "Play";
    Mode[Mode["Complete"] = 2] = "Complete";
    Mode[Mode["Suggestion"] = 3] = "Suggestion";
})(Mode || (Mode = {}));
export var PuzzlePieceEnum;
(function (PuzzlePieceEnum) {
    PuzzlePieceEnum["p2x2"] = "pieces2x2";
    PuzzlePieceEnum["p5x2"] = "pieces5x2";
    PuzzlePieceEnum["p7x3"] = "pieces7x3";
    PuzzlePieceEnum["p10x4"] = "pieces10x4";
})(PuzzlePieceEnum || (PuzzlePieceEnum = {}));
export var PieceSelectedStates;
(function (PieceSelectedStates) {
    PieceSelectedStates[PieceSelectedStates["UnSelected"] = 0] = "UnSelected";
    PieceSelectedStates[PieceSelectedStates["Selecting"] = 1] = "Selecting";
    PieceSelectedStates[PieceSelectedStates["Selected"] = 2] = "Selected";
    PieceSelectedStates[PieceSelectedStates["Hint"] = 3] = "Hint";
})(PieceSelectedStates || (PieceSelectedStates = {}));
export var PieceTabType;
(function (PieceTabType) {
    PieceTabType[PieceTabType["Inside"] = -1] = "Inside";
    PieceTabType[PieceTabType["None"] = 0] = "None";
    PieceTabType[PieceTabType["Outside"] = 1] = "Outside";
})(PieceTabType || (PieceTabType = {}));
export var MediaSlotEnum;
(function (MediaSlotEnum) {
    MediaSlotEnum[MediaSlotEnum["ViewingContent"] = 1] = "ViewingContent";
    MediaSlotEnum[MediaSlotEnum["AlternateContent"] = 2] = "AlternateContent";
    MediaSlotEnum[MediaSlotEnum["Audio"] = 3] = "Audio";
    MediaSlotEnum[MediaSlotEnum["AudioBackground"] = 4] = "AudioBackground";
    MediaSlotEnum[MediaSlotEnum["StreamingVideo"] = 5] = "StreamingVideo";
    MediaSlotEnum[MediaSlotEnum["OfflineVideo"] = 6] = "OfflineVideo";
    MediaSlotEnum[MediaSlotEnum["TeacherManual"] = 7] = "TeacherManual";
    MediaSlotEnum[MediaSlotEnum["Thumbnail"] = 8] = "Thumbnail";
    MediaSlotEnum[MediaSlotEnum["ExposureAudio"] = 9] = "ExposureAudio";
    MediaSlotEnum[MediaSlotEnum["ExposureVideo"] = 10] = "ExposureVideo";
    MediaSlotEnum[MediaSlotEnum["Image"] = 11] = "Image";
    MediaSlotEnum[MediaSlotEnum["IndividualAudio"] = 12] = "IndividualAudio";
})(MediaSlotEnum || (MediaSlotEnum = {}));
export var ContentPlayMode;
(function (ContentPlayMode) {
    ContentPlayMode[ContentPlayMode["Video"] = 0] = "Video";
    ContentPlayMode[ContentPlayMode["Audio"] = 1] = "Audio";
})(ContentPlayMode || (ContentPlayMode = {}));
export var REPLessonPlanItemType;
(function (REPLessonPlanItemType) {
    REPLessonPlanItemType[REPLessonPlanItemType["Normal"] = 1] = "Normal";
    REPLessonPlanItemType[REPLessonPlanItemType["Question"] = 2] = "Question";
    REPLessonPlanItemType[REPLessonPlanItemType["Conversation"] = 3] = "Conversation";
    REPLessonPlanItemType[REPLessonPlanItemType["Reader"] = 4] = "Reader";
})(REPLessonPlanItemType || (REPLessonPlanItemType = {}));
export var ListStates;
(function (ListStates) {
    ListStates[ListStates["Active"] = 0] = "Active";
    ListStates[ListStates["Inactive"] = 1] = "Inactive";
    ListStates[ListStates["All"] = 2] = "All";
    ListStates[ListStates["Pending"] = 3] = "Pending";
    ListStates[ListStates["Future"] = 4] = "Future";
})(ListStates || (ListStates = {}));
export var PlaylistPlayerContentTypeEnum;
(function (PlaylistPlayerContentTypeEnum) {
    PlaylistPlayerContentTypeEnum["PlaylistContent"] = "PlaylistContent";
    PlaylistPlayerContentTypeEnum["Suggestion"] = "Suggestion";
    PlaylistPlayerContentTypeEnum["ConversationIntroVideo"] = "ConversationIntroVideo";
})(PlaylistPlayerContentTypeEnum || (PlaylistPlayerContentTypeEnum = {}));
export var PuzzlePieceTypeEnum;
(function (PuzzlePieceTypeEnum) {
    PuzzlePieceTypeEnum[PuzzlePieceTypeEnum["Piece"] = 1] = "Piece";
    PuzzlePieceTypeEnum[PuzzlePieceTypeEnum["Line"] = 2] = "Line";
    PuzzlePieceTypeEnum[PuzzlePieceTypeEnum["PuzzlePiece"] = 3] = "PuzzlePiece";
})(PuzzlePieceTypeEnum || (PuzzlePieceTypeEnum = {}));
export var PuzzleSizeEnum;
(function (PuzzleSizeEnum) {
    PuzzleSizeEnum["p2x2"] = "2x2";
    PuzzleSizeEnum["p5x2"] = "5x2";
    PuzzleSizeEnum["p7x3"] = "7x3";
    PuzzleSizeEnum["p10x4"] = "10x4";
})(PuzzleSizeEnum || (PuzzleSizeEnum = {}));
export var ConversationSourceEnum;
(function (ConversationSourceEnum) {
    ConversationSourceEnum[ConversationSourceEnum["playlist"] = 0] = "playlist";
    ConversationSourceEnum[ConversationSourceEnum["header"] = 1] = "header";
    ConversationSourceEnum[ConversationSourceEnum["test"] = 2] = "test";
})(ConversationSourceEnum || (ConversationSourceEnum = {}));
export var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel[NotificationChannel["BulletinBoard"] = 0] = "BulletinBoard";
    NotificationChannel[NotificationChannel["Email"] = 1] = "Email";
    NotificationChannel[NotificationChannel["BulletinBoardAndEmail"] = 2] = "BulletinBoardAndEmail";
})(NotificationChannel || (NotificationChannel = {}));
export var ProgressControlColors;
(function (ProgressControlColors) {
    ProgressControlColors["Red"] = "#e51312";
    ProgressControlColors["Yellow"] = "#f2ca46";
    ProgressControlColors["Green"] = "#52c41a";
})(ProgressControlColors || (ProgressControlColors = {}));
export var SkillType;
(function (SkillType) {
    SkillType[SkillType["Content"] = 1] = "Content";
    SkillType[SkillType["ContentChapter"] = 2] = "ContentChapter";
    SkillType[SkillType["FocusWord"] = 3] = "FocusWord";
})(SkillType || (SkillType = {}));
