var JbNeutralToSad = /** @class */ (function () {
    function JbNeutralToSad() {
        this.frames = [
            1,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24
        ];
    }
    return JbNeutralToSad;
}());
export { JbNeutralToSad };
