var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from "react";
import { mergeClasses } from "@app/util";
import "./grid-column-link.less";
import { useHistory } from "@app/hooks";
export var ColumnLink = function (props) {
    var url = props.url, children = props.children, className = props.className, underline = props.underline, rest = __rest(props, ["url", "children", "className", "underline"]);
    var history = useHistory();
    var onClickLink = function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.ctrlKey) {
            window.open(url, "_blank");
            return null;
        }
        history.push({ pathname: url });
    };
    return (React.createElement("a", __assign({}, rest, { className: mergeClasses("wgrid-link", underline && "wgrid-link--underline", className), href: url, onClick: onClickLink }), children));
};
