var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { useActionCreator, useSelector } from "@app/hooks";
import { SystemCheckLocale } from "@app/locales/localeid";
import { setSystemCheckMicrophoneDeviceId, setSystemCheckPopupVisible } from "@app/states/system-check";
import { fmtMsg } from "@app/util";
import { Divider } from "antd";
import { Button, Col, Modal, Row, Select, Typography } from "antd-min";
import { MatIcon, mergeClasses } from "gl-commonui";
import React, { useEffect, useState } from "react";
import "./microphone-permission-dialog.less";
var interval = null;
var mediaStream = null;
export var MicrophonePermissionDialog = function (props) {
    var visible = useSelector(function (state) { return state.systemCheck.visible; });
    var setVisible = useActionCreator(setSystemCheckPopupVisible);
    var microphoneDeviceId = useSelector(function (state) { return state.systemCheck.microphoneDeviceId; });
    var setMicrophoneDeviceId = useActionCreator(setSystemCheckMicrophoneDeviceId);
    var _a = __read(useState([]), 2), devices = _a[0], setDevices = _a[1];
    var _b = __read(useState(false), 2), isAccessDenied = _b[0], setIsAccessDenied = _b[1];
    var _c = __read(useState(0), 2), microphoneSpeed = _c[0], setMicrophoneSpeed = _c[1];
    var testBlocks = new Array(16).fill(null);
    useEffect(function () {
        if (visible) {
            init();
        }
        else {
            dispose();
        }
    }, [visible]);
    var init = function () {
        fetchMicrophones()
            .then(function (devices) {
            setIsAccessDenied(false);
            if (devices.length) {
                setDevices(devices);
                var isSelectedMicrophoneExist = devices.find(function (device) { return device.id === microphoneDeviceId; });
                if (isSelectedMicrophoneExist) {
                    onMicrophoneChange(microphoneDeviceId);
                }
                else {
                    onMicrophoneChange(devices[0].id);
                }
            }
        })
            .catch(function () {
            setIsAccessDenied(true);
        });
    };
    var dispose = function () {
        stopMicrophoneTesting();
        stopStream(mediaStream);
    };
    var fetchMicrophones = function () {
        return new Promise(function (resolve, reject) {
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function (stream) {
                navigator.mediaDevices
                    .enumerateDevices()
                    .then(function (devices) {
                    var audioDevices = devices
                        .filter(function (d) { return d.kind === "audioinput"; })
                        .map(function (d) { return ({ id: d.deviceId, name: d.label }); });
                    resolve(audioDevices);
                    setTimeout(function () {
                        stopStream(stream);
                    }, 1000);
                })
                    .catch(reject);
            })
                .catch(reject);
        });
    };
    var onMicrophoneChange = function (deviceId) {
        // Stopping the previous audio stream
        stopStream(mediaStream);
        // Setting microphone to localStorage and redux
        setMicrophoneDeviceId(deviceId);
        // Asking for permission for the selected microphone and testing it afterwards
        navigator.mediaDevices
            .getUserMedia({
            audio: {
                deviceId: deviceId,
                echoCancellation: true,
            },
        })
            .then(function (newStream) {
            mediaStream = newStream;
            startTestStream(newStream);
        })
            .catch(function () {
            setIsAccessDenied(true);
        });
    };
    var startTestStream = function (stream) {
        var audioContext = new AudioContext();
        var audioSource = audioContext.createMediaStreamSource(stream);
        var analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        var volumes = new Uint8Array(analyser.frequencyBinCount);
        stopMicrophoneTesting();
        interval = setInterval(function () {
            var e_1, _a;
            analyser.getByteFrequencyData(volumes);
            var volumeSum = 0;
            try {
                for (var volumes_1 = __values(volumes), volumes_1_1 = volumes_1.next(); !volumes_1_1.done; volumes_1_1 = volumes_1.next()) {
                    var volume = volumes_1_1.value;
                    volumeSum += volume;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (volumes_1_1 && !volumes_1_1.done && (_a = volumes_1.return)) _a.call(volumes_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var averageVolume = volumeSum / volumes.length;
            // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
            var percent = (averageVolume * 100) / 127;
            setMicrophoneSpeed(percent);
        }, 100);
    };
    var stopMicrophoneTesting = function () {
        if (interval) {
            clearInterval(interval);
            interval = null;
        }
    };
    var stopStream = function (stream) {
        if (stream) {
            stream.getTracks().forEach(function (track) { return track.stop(); });
        }
    };
    var close = function () {
        setVisible(false);
    };
    var renderFooter = function () {
        return (React.createElement(Button, { type: "primary", onClick: close }, fmtMsg(SystemCheckLocale.PopupButtonText)));
    };
    var renderMicrophoneControl = function () {
        return (React.createElement(Row, { type: "flex", align: "middle", gutter: 10 },
            React.createElement(Col, null,
                React.createElement(Typography.Text, { strong: true },
                    fmtMsg(SystemCheckLocale.PopupMicrophoneLabel),
                    ":")),
            React.createElement(Col, null,
                React.createElement(Select, { style: { width: 250 }, onChange: onMicrophoneChange, value: microphoneDeviceId }, devices.map(function (device) { return (React.createElement(Select.Option, { value: device.id, key: device.id }, device.name)); })))));
    };
    var renderTestBar = function () {
        return (React.createElement("div", { className: "pid" }, testBlocks.map(function (_, index) {
            var activeThreshold = (100 / testBlocks.length) * (index + 1);
            var isActive = microphoneSpeed >= activeThreshold;
            return React.createElement("div", { className: mergeClasses("pid__item", isActive && "pid--active"), key: index });
        })));
    };
    var renderPermissionDeniedBlock = function () {
        return (React.createElement("div", { className: "mpd" },
            React.createElement(MatIcon, { type: "mic_off" }),
            React.createElement(Typography.Title, { level: 4 }, fmtMsg(SystemCheckLocale.PopupDeniedTitle)),
            React.createElement(Typography.Text, null, fmtMsg(SystemCheckLocale.PopupDeniedText))));
    };
    return (React.createElement(Modal, { visible: visible, title: fmtMsg(SystemCheckLocale.PopupTitle), footer: renderFooter(), onCancel: close, maskClosable: false },
        isAccessDenied && renderPermissionDeniedBlock(),
        !isAccessDenied && (React.createElement(React.Fragment, null,
            renderMicrophoneControl(),
            React.createElement(Divider, null),
            renderTestBar()))));
};
