var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { Spin } from "antd-min";
import { guidEmpty, ContentGroupEnum, isMobile, isSafari, EventBus } from "@app/util";
import { checkAutoPlay } from "./autoplayalert";
import { isPlaylistPlayerPaused, pausePlaylistPlayer } from "@app/states/playlistplayer";
import { resetDimensions, controlMediaDom, setMediaInlineForIOS, clearAudioPoster, random } from "./playlistmediaplayer_support";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
export var technologyType;
(function (technologyType) {
    technologyType["azureHtml5JS"] = "azureHtml5JS";
    technologyType["flashSS"] = "flashSS";
    technologyType["html5FairPlayHLS"] = "html5FairPlayHLS";
    technologyType["silverlightSS"] = "silverlightSS";
    technologyType["html5"] = "html5";
})(technologyType || (technologyType = {}));
export var PlayAction;
(function (PlayAction) {
    PlayAction["play"] = "play";
    PlayAction["pause"] = "pause";
    PlayAction["stop"] = "stop";
    PlayAction["fullscreen"] = "fullscreen";
    PlayAction["exitFullscreen"] = "exitFullscreen";
})(PlayAction || (PlayAction = {}));
export var MediaStreamType;
(function (MediaStreamType) {
    MediaStreamType["audio"] = "audio/mp3";
    MediaStreamType["video"] = "video/mp4";
    MediaStreamType["smooth"] = "application/vnd.ms-sstr+xml";
    MediaStreamType["m3u8"] = "application/x-mpegURL";
})(MediaStreamType || (MediaStreamType = {}));
export var MediaType;
(function (MediaType) {
    MediaType["audio"] = "audio";
    MediaType["video"] = "video";
})(MediaType || (MediaType = {}));
export var MediaExtType;
(function (MediaExtType) {
    MediaExtType["PDF"] = "PDF";
    MediaExtType["MP3"] = "MP3";
    MediaExtType["MP4"] = "MP4";
    MediaExtType["PNG"] = "PNG";
})(MediaExtType || (MediaExtType = {}));
export var MediaDomDisplay;
(function (MediaDomDisplay) {
    MediaDomDisplay["hidden"] = "hidden";
    MediaDomDisplay["visible"] = "visible";
    MediaDomDisplay["audioShow"] = "audioShow";
    MediaDomDisplay["videoShow"] = "videoShow";
})(MediaDomDisplay || (MediaDomDisplay = {}));
export var PlayMode;
(function (PlayMode) {
    PlayMode["random"] = "random";
    PlayMode["order"] = "order";
    PlayMode["single"] = "single";
    PlayMode["singleCycle"] = "singlecycle";
})(PlayMode || (PlayMode = {}));
var IntervalEnum;
(function (IntervalEnum) {
    IntervalEnum["pre"] = "pre";
    IntervalEnum["mid"] = "mid";
    IntervalEnum["post"] = "post";
})(IntervalEnum || (IntervalEnum = {}));
export var SubContentPos;
(function (SubContentPos) {
    SubContentPos["pre"] = "pre";
    SubContentPos["start"] = "start";
    SubContentPos["middle"] = "middle";
    SubContentPos["end"] = "end";
})(SubContentPos || (SubContentPos = {}));
var MediaPlayer = /** @class */ (function (_super) {
    __extends(MediaPlayer, _super);
    function MediaPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.sequenceContents = [];
        _this.contents = [];
        _this.mediaContext = {};
        _this.snapshot = {};
        _this.controller = {
            eventBus: new EventBus(),
            addEventListener: function (type, callback) { return _this.controller.eventBus.addEventListener(type, callback); },
            removeEventListener: function (type, callback) { return _this.controller.eventBus.removeEventListener(type, callback); },
            dispatchEvent: function (event) { return _this.controller.eventBus.dispatchEvent(event); },
            sendPlay: function (data) { return _this.controller.dispatchEvent({ type: 'play', data: data }); },
            sendPause: function (data) { return _this.controller.dispatchEvent({ type: 'pause', data: data }); },
            sendEnded: function (data) { return _this.controller.dispatchEvent({ type: 'ended', data: data }); },
            sendCurrentEnded: function (data) { return _this.controller.dispatchEvent({ type: 'currentEnded', data: data }); },
            repeat: function (again) {
                var description = _this.mediaContext.content.description;
                if (description.single) {
                    again ? _this.playCurrent() : _this.playNext(IntervalEnum.pre);
                }
            }
        };
        _this.playAction = PlayAction.play;
        _this.state = {
            controlBarHide: false,
            controlBarShow: props.visibleBar || false,
            autoPlayFailedBarShow: false,
            loadeddata: true,
        };
        return _this;
    }
    MediaPlayer.prototype.componentDidMount = function () {
        var _this = this;
        var myOptions = {
            nativeControlsForTouch: false,
            controls: false,
            autoplay: this.props.autoplay === false ? false : true,
            logo: { enabled: false },
            'webkit-playsinline': true,
            playsinline: true,
        };
        this.videoPlayer = videojs("videojs-video-player", myOptions);
        this.addEventListeners(this.videoPlayer, true);
        this.audioPlayer = videojs("videojs-audio-player", myOptions);
        this.addEventListeners(this.audioPlayer);
        this.audioBackgroundPlayer = videojs("background-audio", myOptions);
        this.repeatBackgroundAudio();
        this.props.getController && this.props.getController(this.controller);
        var timer;
        this.resizeListener = function () {
            if (!timer) {
                timer = setTimeout(function () {
                    clearTimeout(timer);
                    timer = null;
                    resetDimensions();
                }, 50);
            }
        };
        window.addEventListener("resize", this.resizeListener);
        var mask = document.querySelector("#videojs-audio-player .vjs-poster-span");
        var prevent = function (e) {
            e.stopPropagation();
            _this.controlContainerShow(e);
            return false;
        };
    };
    MediaPlayer.prototype.componentWillUnmount = function () {
        clearInterval(this.playMediaTimer);
        clearInterval(this.controlBarShowTimer);
        if (this.videoPlayer) {
            this.videoPlayer.dispose();
        }
        if (this.audioPlayer) {
            this.audioPlayer.dispose();
        }
        if (this.audioBackgroundPlayer) {
            this.audioBackgroundPlayer.dispose();
        }
        window.removeEventListener('resize', this.resizeListener);
    };
    MediaPlayer.prototype.componentDidUpdate = function () {
        var _this = this;
        this.props.playbackRate && Array.from(document.getElementsByTagName("video")).forEach(function (video) { return video.playbackRate = _this.props.playbackRate; });
        this.props.playbackRate && Array.from(document.getElementsByTagName("audio")).forEach(function (audio) { return audio.playbackRate = _this.props.playbackRate; });
    };
    MediaPlayer.prototype.repeatBackgroundAudio = function () {
        var _this = this;
        this.audioBackgroundPlayer.on(amp.eventName.ended, function () {
            _this.audioBackgroundPlayer.currentTime(0);
            _this.audioBackgroundPlayer.play();
        });
    };
    MediaPlayer.prototype.addEventListeners = function (player, isVideo) {
        var _this = this;
        player.on(amp.eventName.loadedmetadata, function () {
            isVideo && _this.setState({ loadeddata: false });
        });
        player.on(amp.eventName.loadeddata, function () {
            isVideo && _this.setState({ loadeddata: true });
        });
        player.on(amp.eventName.play, function () {
            _this.controller.sendPlay();
        });
        player.on(amp.eventName.playing, function () {
            if (isPlaylistPlayerPaused()) {
                _this.controller.pause();
            }
        });
        player.on(amp.eventName.pause, function () {
            if (isMobile() && isSafari() && !isPlaylistPlayerPaused() && _this.mediaContext.content.contentGroup === ContentGroupEnum.Movie) {
                pausePlaylistPlayer(true);
            }
            _this.mediaContext.action !== PlayAction.stop && _this.controller.sendPause();
        });
        player.on(amp.eventName.ended, function () {
            if (_this.mediaContext.action !== PlayAction.stop) {
                var context = {
                    content: _this.mediaContext.content,
                    subContentPos: undefined,
                    sequence: _this.mediaContext.content.sequence,
                };
                var pos = _this.getSubContentPos();
                if (pos) {
                    context.subContentPos = pos;
                    if (pos !== SubContentPos.pre) {
                        context.sequence = _this.snapshot.mediaContext.content.sequence;
                    }
                    if (pos === SubContentPos.end) {
                        _this.restoreSnapshot();
                        _this.setSubContentPos(SubContentPos.end);
                    }
                }
                _this.controller.sendCurrentEnded(context);
                _this.props.onTimeUpdate({ time: -1, duration: -1 });
                pos === SubContentPos.pre && _this.state.controlBarHide && _this.setState({ controlBarHide: false });
                _this.playMedia();
            }
        });
        player.on(amp.eventName.timeupdate, function () {
            var pages = _this.mediaContext.content.pages;
            if ((_this.props.visibleMedia !== false) && pages && pages.length > 1) {
                var time_1 = player.currentTime() * 1000;
                var matchedPager = pages.find(function (page) { return page.pageTiming > time_1; });
                if (matchedPager && player.poster() !== matchedPager.url) {
                    player.poster(matchedPager.url);
                }
            }
            if (_this.props.onTimeUpdate) {
                var time = player.currentTime(), duration = player.duration();
                _this.props.onTimeUpdate({ time: time, duration: duration });
            }
        });
    };
    MediaPlayer.prototype.componentWillReceiveProps = function (_a) {
        var resources = _a.resources, visibleMedia = _a.visibleMedia, startIndex = _a.startIndex, playMode = _a.playMode, playbackRate = _a.playbackRate;
        if (resources.contents && resources.contents.length > 0) {
            if (this.props.resources !== resources) {
                controlMediaDom(this.visibleMedia(MediaDomDisplay.visible, visibleMedia));
                setMediaInlineForIOS();
                this.sequenceContents = __spread((this.contents = resources.contents));
                this.setStartIndexMode(startIndex, playMode);
                return;
            }
            if (this.props.startIndex !== startIndex || this.props.playMode !== playMode) {
                this.setStartIndexMode(startIndex, playMode);
            }
            if (this.props.playbackRate !== playbackRate) {
                playbackRate && Array.from(document.getElementsByTagName("video")).forEach(function (video) { return video.playbackRate = playbackRate; });
                playbackRate && Array.from(document.getElementsByTagName("audio")).forEach(function (audio) { return audio.playbackRate = playbackRate; });
            }
        }
    };
    MediaPlayer.prototype.visibleMedia = function (type, visibleMedia) {
        return (visibleMedia || this.props.visibleMedia) === false ? MediaDomDisplay.hidden : type;
    };
    MediaPlayer.prototype.setController = function (actions) {
        var _this = this;
        Object.getOwnPropertyNames(actions).forEach(function (action) {
            _this.controller[action] = actions[action];
        });
    };
    MediaPlayer.prototype.setStartIndexMode = function (id, mode) {
        this.setSequenceContents(id, mode || this.mediaContext.mode || PlayMode.order);
        this.playNext(IntervalEnum.pre);
    };
    MediaPlayer.prototype.setMedia = function (content) {
        var _this = this;
        var set = function (player, type, domAction, callbacks, isConversation) {
            if (isConversation === void 0) { isConversation = false; }
            // Don't set src & poster for conversation, just set the controller.
            if (!isConversation) {
                var src = content.medias.map(function (m) { return ({ src: m.url, type: content.description.type || type }); });
                content.src = src;
                callbacks && callbacks.setPoster(player);
                player.pausedAction = function (isAction) { return isAction ? _this.playAction === PlayAction.pause : player.paused(); };
            }
            var tempDescription = __assign({}, content.description);
            _this.setController({
                play: function () {
                    _this.playAction = PlayAction.play;
                    player.autoplay(true);
                    player.play();
                    callbacks && callbacks.play();
                },
                pause: function () {
                    _this.playAction = PlayAction.pause;
                    player.pause();
                    callbacks && callbacks.pause();
                },
                paused: player.pausedAction,
                stop: function () {
                    _this.mediaContext.action = PlayAction.stop;
                    _this.sequenceContents = [];
                    player.currentTime(player.duration());
                    callbacks && callbacks.pause();
                },
                seek: function (seconds) { return player.currentTime(seconds); },
                setPlayMode: function (mode) {
                    switch (mode) {
                        case PlayMode.single:
                            _this.mediaContext.mode = PlayMode.single;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: true });
                            break;
                        case PlayMode.singleCycle:
                            _this.mediaContext.mode = PlayMode.singleCycle;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: Number.MAX_VALUE, single: false });
                            break;
                        case PlayMode.random:
                            _this.mediaContext.mode = PlayMode.random;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: false });
                            _this.sequenceContents = _this.randomContents(content);
                            break;
                        case PlayMode.order:
                            _this.mediaContext.mode = PlayMode.order;
                            _this.mediaContext.content.description = __assign({}, tempDescription, { singleCycle: 0, single: false });
                            _this.sequenceContents = _this.contents.slice(_this.contents.findIndex(function (c) { return c.id === content.id; }) + 1);
                            break;
                        default:
                            break;
                    }
                },
                setStartIndex: function (_a) {
                    var id = _a.id, mode = _a.mode;
                    _this.setStartIndexMode(id, mode);
                },
                playSequence: function (contents, fromConversation) {
                    if (!_this.mediaContext.subPlayed) {
                        _this.playAction = PlayAction.play;
                        if (!fromConversation) {
                            _this.mediaContext.subPlayed = true;
                            _this.sequenceContents = (contents ? __spread((_this.contents = contents)) : __spread(_this.contents[0].id ? _this.contents.slice(1) : _this.contents));
                        }
                        _this.playMedia();
                        return true;
                    }
                    return false;
                },
                markCurrentItemAsPlayed: function () {
                    if (_this.sequenceContents.length > 0) {
                        _this.props.markCurrentItemAsPlayed({ subContentPos: _this.getSubContentPos(), sequence: _this.sequenceContents[0].sequence });
                    }
                    else {
                        // Last Item, send logs too.
                        _this.props.markCurrentItemAsPlayed({ subContentPos: _this.getSubContentPos(), sequence: undefined }, true);
                    }
                },
                enterFullscreen: function () { return player.enterFullscreen(); },
                exitFullscreen: function () { return player.exitFullscreen(); },
            });
            controlMediaDom(_this.visibleMedia(domAction), player);
            return player;
        };
        this.playBackgroundAudio(content);
        if (!content.skillTypeId && content.description.mediaType === MediaType.audio) {
            return set(this.audioPlayer, MediaStreamType.audio, MediaDomDisplay.audioShow, {
                setPoster: function (player) { return (_this.props.visibleMedia !== false) && content.pages.length > 0 && clearAudioPoster(function () { return player.poster(content.pages[0].url); }); },
                play: function () { return _this.resumeBackgroundAudio(); },
                pause: function () { return _this.pauseBackgroundAudio(); },
            });
        }
        else if (!content.skillTypeId && content.description.mediaType === MediaType.video) {
            return set(this.videoPlayer, MediaStreamType.video, MediaDomDisplay.videoShow);
        }
        else if (!content.skillTypeId && content.conversationId !== null) {
            return set(this.videoPlayer, MediaStreamType.video, MediaDomDisplay.hidden, {}, true);
        }
    };
    MediaPlayer.prototype.playBackgroundAudio = function (content) {
        var _this = this;
        if (content.description.backgroundAudio) {
            var playBkAudio = function () {
                _this.audioBackgroundPlayer.src([{ src: content.description.backgroundAudio.uri, type: MediaStreamType.audio }]);
                _this.audioBackgroundPlayer.play();
            };
            if (content.description.backgroundAudio.start) {
                playBkAudio();
            }
            else if (content.description.backgroundAudio.middle && this.pausedBackgroundAudio()) {
                this.resumeBackgroundAudio();
            }
            else if (!this.audioBackgroundPlayer.currentSrc()) {
                playBkAudio();
            }
        }
        else {
            this.pauseBackgroundAudio();
        }
    };
    MediaPlayer.prototype.resumeBackgroundAudio = function () {
        this.audioBackgroundPlayer.play();
    };
    MediaPlayer.prototype.pauseBackgroundAudio = function () {
        this.audioBackgroundPlayer.pause();
    };
    MediaPlayer.prototype.pausedBackgroundAudio = function () {
        return this.audioBackgroundPlayer.paused();
    };
    MediaPlayer.prototype.setSequenceContents = function (id, mode) {
        var _this = this;
        var index = undefined;
        if (typeof id === 'number') {
            index = id;
            // const targetContent = this.contents.find(c => c.sequence === id);
            // id = targetContent ? targetContent.id : undefined;
            var targetContent = this.adaptContent(this.contents, id).content;
            id = targetContent ? targetContent.id : undefined;
        }
        var setSingleMode = function () {
            if (mode === PlayMode.single) {
                _this.sequenceContents[0].description.singleCycle = 0;
                _this.sequenceContents[0].description.single = true;
            }
            else if (mode === PlayMode.singleCycle) {
                _this.sequenceContents[0].description.singleCycle = Number.MAX_VALUE;
                _this.sequenceContents[0].description.single = false;
            }
        };
        if (id && mode) {
            this.mediaContext.mode = mode;
            if (mode === PlayMode.random) {
                this.sequenceContents = this.randomContents({ id: id });
                this.sequenceContents.unshift(this.contents.find(function (c) { return c.id === id; }));
            }
            else {
                this.sequenceContents = this.contents.slice(this.contents.findIndex(function (c, i) {
                    return index ? c.id === id && i === index : c.id === id;
                }));
                setSingleMode();
            }
        }
        else if (id) {
            this.sequenceContents = this.contents.slice(this.contents.findIndex(function (c) { return c.id === id; }));
        }
        else if (mode) {
            this.mediaContext.mode = mode;
            if (mode === PlayMode.random) {
                this.sequenceContents = this.contents.sort(random);
            }
            else {
                this.sequenceContents = __spread(this.contents);
                setSingleMode();
            }
        }
    };
    MediaPlayer.prototype.adaptContent = function (contents, id) {
        var index = undefined;
        var content = undefined;
        for (var i = id; i < contents.length; i++) {
            content = contents[i];
            if (content) {
                index = i;
                break;
            }
        }
        if (!content) {
            for (var i = contents.length - 1; i > -1; i--) {
                content = contents[i];
                if (content) {
                    index = i;
                    break;
                }
            }
        }
        return { content: content, index: index };
    };
    MediaPlayer.prototype.randomContents = function (content) {
        return this.contents
            .filter(function (c) { return c.id !== content.id; })
            .sort(random);
    };
    MediaPlayer.prototype.playMedia = function () {
        var _this = this;
        var shiftEmptyContent = function () {
            if (_this.sequenceContents.length > 0 && _this.sequenceContents[0].id === guidEmpty()) {
                _this.sequenceContents.shift();
                shiftEmptyContent();
            }
        };
        shiftEmptyContent();
        if (this.sequenceContents.length === 0) {
            if (this.getSubContentPos() && this.getSubContentPos() !== SubContentPos.end) {
                this.mediaContext.subPlayed = false;
                return;
            }
            this.pauseBackgroundAudio();
            this.controller.sendEnded();
        }
        else {
            var _a = __read(this.playRepeat(), 2), isRepeat = _a[0], interval = _a[1];
            if (!isRepeat) {
                if (interval && interval.post) {
                    setTimeout(function () { return _this.playNext(IntervalEnum.pre); }, interval.post);
                }
                else {
                    this.playNext(IntervalEnum.pre);
                }
            }
        }
    };
    MediaPlayer.prototype.playCurrent = function () {
        var _a = __read(this.currentContext(), 2), play = _a[0], description = _a[1];
        this.playDelay(play, description.interval, IntervalEnum.mid);
    };
    MediaPlayer.prototype.playRepeat = function () {
        var _a = __read(this.currentContext(), 2), play = _a[0], description = _a[1];
        if (description.single)
            return [true];
        if (description.singleCycle && description.singleCycle > 1) {
            description.singleCycle--;
            this.playDelay(play, description.interval, IntervalEnum.mid);
            return [true];
        }
        return [false, description.interval];
    };
    MediaPlayer.prototype.playDelay = function (play, interval, type) {
        if (interval && interval[type]) {
            setTimeout(function () { return play(); }, interval[type]);
        }
        else {
            play();
        }
    };
    MediaPlayer.prototype.playNext = function (type) {
        var _a = __read(this.nextContext(), 2), play = _a[0], interval = _a[1].interval;
        this.playDelay(play, interval, type);
    };
    MediaPlayer.prototype.saveSnapshot = function () {
        this.snapshot = {
            contents: this.contents,
            sequenceContents: this.sequenceContents,
            mediaContext: this.mediaContext,
        };
    };
    MediaPlayer.prototype.restoreSnapshot = function () {
        var _a = this.snapshot, contents = _a.contents, sequenceContents = _a.sequenceContents, mediaContext = _a.mediaContext;
        this.contents = contents;
        this.sequenceContents = sequenceContents;
        this.mediaContext = mediaContext;
    };
    MediaPlayer.prototype.getSubContentPos = function () {
        return this.mediaContext.content.description.subContentPos;
    };
    MediaPlayer.prototype.isSubContent = function () {
        return this.mediaContext.content ? this.mediaContext.content.description.subContentPos : false;
    };
    MediaPlayer.prototype.setSubContentPos = function (pos) {
        return this.mediaContext.content.description.subContentPos = pos;
    };
    MediaPlayer.prototype.setScreenTitle = function (title) {
        var dom = document.querySelector('.screen-title');
        dom && (dom.innerText = title || '');
    };
    MediaPlayer.prototype.checkAutoPaused = function (content, conditionCall) {
        var _this = this;
        if (conditionCall === void 0) { conditionCall = function () { return true; }; }
        if (isMobile() && conditionCall()) {
            var checkPaused_1 = function (check) {
                if (check === void 0) { check = function (p) { return p; }; }
                return check(_this.mediaContext.media.paused()) && content.id === _this.mediaContext.content.id;
            };
            var timeout_1 = 2;
            var timer_1 = setInterval(function () {
                try {
                    if (checkPaused_1()) {
                        if (--timeout_1 <= 0) {
                            clearInterval(timer_1);
                            pausePlaylistPlayer(true);
                            _this.checkAutoPlayClick(function () { return checkPaused_1(function (paused) { return !paused; }); })();
                        }
                    }
                    else {
                        clearInterval(timer_1);
                    }
                }
                catch (error) {
                    clearInterval(timer_1);
                }
            }, 1000);
        }
    };
    MediaPlayer.prototype.nextContext = function () {
        var _this = this;
        var content = this.sequenceContents.shift();
        if (content.description.contentPrefix && content.description.contentPrefix.length > 0) {
            this.sequenceContents.unshift(content);
            content = content.description.contentPrefix.shift();
            this.checkAutoPaused(content);
        }
        if (content.description.subContentPos === SubContentPos.start) {
            if (content.description.subContentPrefix && content.description.subContentPrefix.length > 0) {
                // this.setState({ controlBarHide: true })
                this.sequenceContents.unshift(content);
                content = content.description.subContentPrefix.shift();
                this.checkAutoPaused(content, function () { return isSafari(); });
            }
            else {
                if ((this.mediaContext.content
                    && (this.getSubContentPos() === SubContentPos.pre
                        || this.getSubContentPos() === SubContentPos.start))
                    || !this.mediaContext.content
                    || content.description.subContentPos === SubContentPos.start) {
                    content.description.backgroundAudio && this.audioPlayer.poster(content.description.backgroundAudio.uri);
                    this.mediaContext.content = content;
                }
                this.saveSnapshot();
                this.sequenceContents = __spread((this.contents = content.description.subContents.contents.map(function (c) { return (c.description.exts = content.description.exts, c); })));
                content = this.sequenceContents.shift();
            }
        }
        var media = this.setMedia(content);
        this.mediaContext = __assign({}, this.mediaContext, { content: content,
            media: media });
        var playOnMobile = function () {
            media.play();
            setTimeout(function () {
                if (media.paused()) {
                    pausePlaylistPlayer(true);
                }
            }, 2000);
        };
        return [
            function () {
                if (content.src && content.src.length) {
                    media.src(content.src);
                }
                _this.setScreenTitle();
                var playMedia = function () {
                    if (media && content.conversationId === null) {
                        isMobile()
                            ? playOnMobile()
                            : checkAutoPlay(function () {
                                media.play();
                            }, _this.checkAutoPlayClick(function () { return !media.paused(); }));
                    }
                    _this.setScreenTitle(content.description.title);
                    var getCurrentContext = function () {
                        var pos = _this.getSubContentPos();
                        if (pos === SubContentPos.pre) {
                            return {
                                remainingCount: _this.sequenceContents.length - 1,
                                content: content,
                                subContentPos: pos,
                                sequence: undefined,
                            };
                        }
                        else if (pos) {
                            return {
                                remainingCount: _this.snapshot.sequenceContents.length,
                                content: content,
                                subContentPos: pos,
                                sequence: _this.snapshot.mediaContext.content.sequence,
                            };
                        }
                        return {
                            remainingCount: _this.sequenceContents.length,
                            content: content,
                            subContentPos: undefined,
                            sequence: content.sequence,
                        };
                    };
                    _this.props.onCurrentStarted && _this.props.onCurrentStarted(getCurrentContext());
                };
                if (!content.skillTypeId && content.description.mediaType === MediaType.audio) {
                    _this.playMediaTimer = setInterval(function () {
                        if (content.src.length && media && content.src[0].src === media.currentSrc()) {
                            clearInterval(_this.playMediaTimer);
                            playMedia();
                        }
                    });
                }
                else {
                    playMedia();
                }
            },
            content.description
        ];
    };
    MediaPlayer.prototype.checkAutoPlayClick = function (notPaused) {
        var _this = this;
        return function () {
            _this.setState({ autoPlayFailedBarShow: true }, function () {
                resetDimensions();
                var timer = setInterval(function () {
                    try {
                        if (notPaused()) {
                            clearInterval(timer);
                            clearInterval(_this.controlBarShowTimer);
                            _this.setState({ autoPlayFailedBarShow: false, controlBarShow: false });
                        }
                    }
                    catch (error) {
                        clearInterval(timer);
                    }
                }, 1000);
            });
        };
    };
    MediaPlayer.prototype.currentContext = function () {
        var _a = this.mediaContext, content = _a.content, media = _a.media;
        return [
            function () {
                media.currentTime(0);
                media.play();
            },
            content.description
        ];
    };
    MediaPlayer.prototype.controlContainerShow = function (e) {
        var _this = this;
        var visibleBar = this.props.visibleBar;
        if (!visibleBar && !this.state.controlBarShow) {
            this.setState({ controlBarShow: true }, function () { return resetDimensions(); });
            clearInterval(this.controlBarShowTimer);
            this.controlBarShowTimer = setInterval(function () {
                _this.controlContainerHide();
            }, 3000);
        }
        else {
            this.controlContainerHide();
        }
    };
    MediaPlayer.prototype.controlContainerHide = function () {
        var visibleBar = this.props.visibleBar;
        if ((!visibleBar && this.mediaContext.media && !this.mediaContext.media.pausedAction(this.isSubContent())) ||
            (!visibleBar && this.mediaContext.content.skillTypeId !== null)) {
            clearInterval(this.controlBarShowTimer);
            this.setState({ controlBarShow: false });
        }
    };
    MediaPlayer.prototype.getComponentName = function (c) {
        return (c.type.displayName || c.type.name);
    };
    MediaPlayer.prototype.renderChildren = function () {
        var _this = this;
        var components = React.Children.toArray(this.props.children);
        var controlBar = components.find(function (c) { return _this.getComponentName(c).includes("PlaylistControlContainer"); });
        var subtitles = components.find(function (c) { return _this.getComponentName(c).endsWith("Subtitles"); });
        var subtitle = components.find(function (c) { return _this.getComponentName(c).endsWith("Subtitle"); });
        var others = components.filter(function (c) {
            var name = _this.getComponentName(c);
            return !name.includes("Subtitles") && !name.includes("Subtitle") && !name.includes("PlaylistControlContainer");
        });
        return (React.createElement(React.Fragment, null,
            others.length > 0 && others,
            (this.state.autoPlayFailedBarShow || (this.state.controlBarShow && !this.state.controlBarHide)) && controlBar,
            subtitles,
            subtitle));
    };
    MediaPlayer.prototype.render = function () {
        var _this = this;
        var loadeddata = this.state.loadeddata;
        var visibleMedia = this.props.visibleMedia;
        return React.createElement("div", { className: 'mediaplayer-wrapper', 
            // onMouseEnter={(e) => !this.isSubContent() && this.controlContainerShow(e)} 
            // onMouseLeave={(e) => !this.isSubContent() && this.controlContainerHide()} 
            onMouseEnter: function (e) { return !_this.isSubContent(); }, onMouseLeave: function (e) { return !_this.isSubContent(); }, onClick: function (e) { return _this.controlContainerShow(e); } },
            React.createElement("video", { id: "videojs-video-player", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered mediaplayer", tabIndex: 0 }),
            React.createElement("audio", { id: "videojs-audio-player", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered mediaplayer", tabIndex: 0 }),
            React.createElement("audio", { id: "background-audio", "webkit-playsinline": 'true', playsInline: true, className: "azuremediaplayer amp-default-skin amp-big-play-centered mediaplayer background", tabIndex: 0 }),
            React.createElement("div", { className: 'screen-controller media-screen-dimensions' },
                React.createElement("div", { className: 'screen-title' }),
                React.createElement(Spin, { delay: 500, size: 'large', spinning: visibleMedia !== false && !loadeddata })),
            this.renderChildren());
    };
    return MediaPlayer;
}(Component));
export default MediaPlayer;
