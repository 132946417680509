var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var Blink = /** @class */ (function (_super) {
    __extends(Blink, _super);
    function Blink(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            enable: false
        };
        return _this;
    }
    ;
    Blink.prototype.componentDidMount = function () {
        this.setState({ enable: false });
    };
    Blink.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (nextProps.activeBlink != this.props.activeBlink && nextProps.activeBlink) {
            this.setState({ enable: true }, function () {
                clearTimeout(_this.activeTimer);
                _this.activeTimer = window.setTimeout(function () {
                    _this.setState({ enable: false });
                    _this.props.activedBlinkCallback && _this.props.activedBlinkCallback();
                }, 4500);
            });
        }
    };
    Blink.prototype.componentWillUnmount = function () {
        clearTimeout(this.activeTimer);
    };
    Blink.prototype.render = function () {
        var blinkStyle = this.state.enable ? "always-blinkable always-blink-item" : '';
        return React.createElement("div", { className: "blinkable " + blinkStyle + " " + this.props.className, children: this.props.children });
    };
    ;
    return Blink;
}(Component));
export default Blink;
;
export function AlwaysBlink(props) {
    return React.createElement("div", { className: "always-blinkable always-blink-item " + props.className, style: props.style }, props.children);
}
