var JbSadToHappy = /** @class */ (function () {
    function JbSadToHappy() {
        this.frames = [
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            37,
            38,
            39
        ];
    }
    return JbSadToHappy;
}());
export { JbSadToHappy };
