var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useState } from "react";
export var FocusWordsContext = createContext({
    selectedIndex: 0,
    setSelectedIndex: function (index) { },
    isPlaying: false,
    setIsPlaying: function (isPlaying) { },
    definitionPlaying: false,
    setIsDefinitionPlaying: function (isPlaying) { },
    showImage: false,
    setShowImage: function (showImage) { },
    showDefinition: false,
    setShowDefinition: function (showDefinition) { },
    wordAudio: "",
    setWordAudio: function (wordAudio) { },
    definitionAudio: "",
    setDefinitionAudio: function (definitionAudio) { },
});
export var FocusWordsContextProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(0), 2), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var _c = __read(useState(false), 2), isPlaying = _c[0], setIsPlaying = _c[1];
    var _d = __read(useState(false), 2), definitionPlaying = _d[0], setIsDefinitionPlaying = _d[1];
    var _e = __read(useState(false), 2), showImage = _e[0], setShowImage = _e[1];
    var _f = __read(useState(false), 2), showDefinition = _f[0], setShowDefinition = _f[1];
    var _g = __read(useState(""), 2), wordAudio = _g[0], setWordAudio = _g[1];
    var _h = __read(useState(""), 2), definitionAudio = _h[0], setDefinitionAudio = _h[1];
    return (React.createElement(FocusWordsContext.Provider, { value: {
            selectedIndex: selectedIndex,
            setSelectedIndex: function (index) { return setSelectedIndex(index); },
            isPlaying: isPlaying,
            setIsPlaying: function (isPlaying) { return setIsPlaying(isPlaying); },
            definitionPlaying: definitionPlaying,
            setIsDefinitionPlaying: function (definitionPlaying) { return setIsDefinitionPlaying(definitionPlaying); },
            showImage: showImage,
            setShowImage: function (showImage) { return setShowImage(showImage); },
            showDefinition: showDefinition,
            setShowDefinition: function (showDefinition) {
                return setShowDefinition(showDefinition);
            },
            wordAudio: wordAudio,
            setWordAudio: function (wordAudio) {
                setWordAudio(wordAudio);
            },
            definitionAudio: definitionAudio,
            setDefinitionAudio: function (definitionAudio) { return setDefinitionAudio(definitionAudio); },
        } }, children));
};
