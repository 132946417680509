var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConversationAdminService } from "@app/service/conversation-admin";
var microphoneKey = "microphone";
function getMicrophoneFromLocalStorage() {
    return localStorage.getItem(microphoneKey);
}
function setMicrophoneToLocalStorage(deviceId) {
    localStorage.setItem(microphoneKey, deviceId);
}
export default {
    namespace: "systemCheck",
    state: {
        visible: false,
        microphoneDeviceId: getMicrophoneFromLocalStorage(),
        hasConversationAccess: false,
    },
    reducers: {
        setDevice: function (state, action) {
            setMicrophoneToLocalStorage(action.payload.microphone);
            return __assign({}, state, { microphoneDeviceId: action.payload.microphone });
        },
        setVisible: function (state, action) {
            return __assign({}, state, { visible: action.payload });
        },
        updateConversationAccess: function (state, action) {
            return __assign({}, state, { hasConversationAccess: action.payload });
        },
    },
    effects: {
        fetchConversationAccessPermission: function (action, _a, _b) {
            var studentId, hasConversationAccess;
            var call = _a.call, put = _a.put;
            var conversationAdminService = _b.conversationAdminService;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        studentId = action.payload.studentId;
                        if (!studentId) return [3 /*break*/, 3];
                        return [4 /*yield*/, call(conversationAdminService.checkAccess, studentId)];
                    case 1:
                        hasConversationAccess = _c.sent();
                        return [4 /*yield*/, put(updateConversationAccess(hasConversationAccess))];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, put(updateConversationAccess(false))];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        },
    },
    services: {
        conversationAdminService: ConversationAdminService,
    },
};
export function setSystemCheckPopupVisible(visible) {
    return { type: "systemCheck/setVisible", payload: visible };
}
export function setSystemCheckMicrophoneDeviceId(id) {
    return { type: "systemCheck/setDevice", payload: { microphone: id } };
}
export function updateConversationAccess(hasAccess) {
    return { type: "systemCheck/updateConversationAccess", payload: hasAccess };
}
export function fetchConversationAccessPermission(studentId) {
    return { type: "systemCheck/fetchConversationAccessPermission", payload: { studentId: studentId } };
}
