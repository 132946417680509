import React from "react";
import { Divider, Modal, Spin } from "antd";
import { fmtMsg } from "@app/util";
import { TermOfPolicyLocale } from "@app/locales/localeid";
import "./term-policy.less";
export var TermOfPolicy = function (_a) {
    var policyShown = _a.policyShown, policyContent = _a.policyContent, handlePolicySubmit = _a.handlePolicySubmit, loading = _a.loading;
    return (React.createElement(Modal, { visible: policyShown, footer: null, maskClosable: false, className: "term-policy-modal", centered: true, destroyOnClose: true, closable: false, confirmLoading: true },
        React.createElement(Spin, { spinning: loading },
            React.createElement("div", { className: "term-container" },
                React.createElement(Divider, { className: "term-header" }, fmtMsg({ id: TermOfPolicyLocale.TermOfPolicyHeader })),
                React.createElement("div", { className: "term-content" },
                    React.createElement("div", { className: "text nice-scroll", dangerouslySetInnerHTML: { __html: policyContent } }),
                    React.createElement("div", { className: "shadow" })),
                React.createElement("div", { className: "term-footer" },
                    React.createElement("button", { className: "accept-btn", role: "button", onClick: function () { return handlePolicySubmit(true); } }, fmtMsg({
                        id: TermOfPolicyLocale.TermOfPolicyAgree
                    })),
                    React.createElement("button", { className: "decline-btn", role: "button", onClick: function () { return handlePolicySubmit(false); } }, fmtMsg({
                        id: TermOfPolicyLocale.TermOfPolicyCancel
                    })))))));
};
