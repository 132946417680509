var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Spin } from "antd-min";
import "./loading.less";
import { mergeClasses } from "@app/util";
export var Loading = function (props) {
    var visible = props.visible, text = props.text, cover = props.cover, height = props.height, iconClassName = props.iconClassName, className = props.className, maxHeight = props.maxHeight, spinProps = props.spinProps;
    var classes = mergeClasses("sw-loader", cover && "sw-loader--cover", className);
    return visible ? (React.createElement("div", { className: classes, style: { maxHeight: maxHeight, height: height } },
        React.createElement(Spin, __assign({ spinning: true, className: mergeClasses("sw-loader__icon", iconClassName) }, spinProps)),
        text && React.createElement("p", { className: "sw-loader__text" }, text))) : null;
};
Loading.defaultProps = {
    spinProps: {}
};
