var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var resourcesDefined = { name: 'resources', storeName: 'resources', mode: 'readwrite' };
var GLIndexDBProxy = /** @class */ (function () {
    function GLIndexDBProxy(name, version) {
        this.name = name;
        this.version = version;
    }
    GLIndexDBProxy.isSupport = function () {
        if (!('indexedDB' in window)) {
            console.log('This browser doesn\'t support IndexedDB');
            return false;
        }
        return true;
    };
    GLIndexDBProxy.prototype.open = function (name, version) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var request = indexedDB.open(name || _this.name, version || _this.version);
            request.addEventListener("upgradeneeded", function (e) {
                var db = _this.db || (_this.db = e.target.result);
                if (!db.objectStoreNames.contains("resources")) {
                    db.createObjectStore("resources", { keyPath: "query" });
                }
            });
            request.addEventListener('success', function (e) {
                var db = _this.db || (_this.db = e.target.result);
                resolve(db);
            });
            request.addEventListener('error', function (e) {
                reject(e);
            });
        });
    };
    GLIndexDBProxy.prototype.getObjectStore = function (name, storeName, mode) {
        return this.db.transaction(storeName, mode).objectStore(name);
    };
    GLIndexDBProxy.prototype.get = function (query, defined) {
        return this.processBlob(__assign({ query: query }, (defined || resourcesDefined), { action: 'get', success: function (d) { return d; }, error: function (e) { return console.error(e); } }));
    };
    GLIndexDBProxy.prototype.has = function (query, defined) {
        return this.processBlob(__assign({ query: query }, (defined || resourcesDefined), { action: 'count', success: function (d) { return d > 0; }, error: function (e) { return console.error(e); } }));
    };
    GLIndexDBProxy.prototype.delete = function (query, defined) {
        return this.processBlob(__assign({ query: query }, (defined || resourcesDefined), { action: 'delete', success: function () { return true; }, error: function (e) { return console.error(e); } }));
    };
    GLIndexDBProxy.prototype.put = function (query, defined) {
        return this.processBlob(__assign({ query: query }, (defined || resourcesDefined), { action: 'put', success: function () { return true; }, error: function (e) { return console.error(e); } }));
    };
    GLIndexDBProxy.prototype.add = function (query, defined) {
        return this.processBlob(__assign({ query: query }, (defined || resourcesDefined), { action: 'add', success: function () { return true; }, error: function (e) { return console.error(e); } }));
    };
    GLIndexDBProxy.prototype.processBlob = function (_a) {
        var _this = this;
        var action = _a.action, query = _a.query, name = _a.name, storeName = _a.storeName, mode = _a.mode, success = _a.success, error = _a.error;
        return new Promise(function (resolve, reject) {
            var request = _this.getObjectStore(name, storeName, mode)[action](query);
            request.addEventListener('success', function (e) {
                resolve(success(e.target.result));
            });
            request.addEventListener('error', function (e) {
                reject(error(e));
            });
        });
    };
    return GLIndexDBProxy;
}());
export default GLIndexDBProxy;
