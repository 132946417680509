var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var SpeedIcon = /** @class */ (function (_super) {
    __extends(SpeedIcon, _super);
    function SpeedIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SpeedIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" },
            React.createElement("path", { className: "path", d: "M20.1,9.7c0-0.1,0.1-0.1,0.1-0.2c1-2.2,1.6-4.5,1.8-7c0-0.1,0-0.3-0.1-0.4C21.8,2.1,21.6,2,21.5,2\n\tc-2.5,0.1-4.9,0.7-7,1.7c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.4,0.2-0.9,0.5-1.3,0.7C9,3.5,5.1,4.8,3.2,8\n\tC3.1,8.2,3.2,8.5,3.3,8.6l2.7,2.7c-0.8,1.2-1.1,2.1-1.1,2.2c-0.1,0.2,0,0.4,0.1,0.5l5,5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0\n\tc0.1,0,0.9-0.4,2.1-1.1l2.7,2.7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1c3.2-1.9,4.5-5.8,3.2-9.2c0.3-0.4,0.5-0.8,0.7-1.3\n\tC20,10.1,20.1,9.9,20.1,9.7z M19.6,8.6l-4.2-4.2C17.1,3.7,19,3.2,21,3C20.8,5,20.3,6.9,19.6,8.6z M4.3,8.2c1.6-2.4,4.5-3.4,7.1-2.7\n\tc-1,0.7-1.9,1.5-2.8,2.5c-0.8,0.9-1.5,1.7-2,2.5L4.3,8.2z M10.4,18.1L6,13.6c0.3-0.7,1.4-2.8,3.4-4.9c1.5-1.6,3.2-2.9,5.1-3.8\n\tl4.7,4.7c-0.9,1.9-2.2,3.6-3.8,5.1C13.3,16.6,11.2,17.7,10.4,18.1z M15.8,19.7l-2.3-2.3c0.8-0.5,1.6-1.2,2.5-2\n\tc0.9-0.9,1.7-1.8,2.5-2.8C19.2,15.2,18.2,18.2,15.8,19.7z M3.4,17.2c-0.5,0.5-0.9,1.4-1.1,2.6C2.1,20.7,2,21.4,2,21.5\n\tc0,0.1,0,0.3,0.1,0.4C2.3,21.9,2.4,22,2.5,22h0c0,0,0.8-0.1,1.7-0.3c1.3-0.3,2.1-0.6,2.6-1.1c1-1,1-2.5,0-3.5\n\tC5.9,16.2,4.4,16.2,3.4,17.2z M6.2,19.9c-0.3,0.3-1.1,0.6-2.1,0.8c-0.4,0.1-0.7,0.1-1,0.2c0-0.3,0.1-0.6,0.2-1\n\tc0.2-1,0.5-1.8,0.8-2.1c0.6-0.6,1.5-0.6,2.1,0C6.8,18.4,6.8,19.4,6.2,19.9z M13,8.6c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5\n\ts2.5-1.1,2.5-2.5C15.4,9.7,14.3,8.6,13,8.6z M13,12.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S13.8,12.5,13,12.5z" })));
    };
    return SpeedIcon;
}(Component));
export default SpeedIcon;
