var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, GLGlobal, connect } from "gl-commonui";
import { PathConfig } from '@app/config/pathconfig';
import { lazyInject, TYPES, Mode } from "@app/util";
import ContentPlayer from "@app/components/media/contentplayer";
import CountDownControlContainer from "@app/components/media/countdown-controlcontainer";
import { PlayMode, SequenceMode } from "@app/components/media";
import UploadLogs from "@app/service/logs/uploadlogs";
import { PLAYMODES } from "./components/dvdpalyercontroller";
import { hidenHeaderFooter, showHeaderFooter } from "../components/hidenheaderfooter";
import HeartBeat from "../components/heartbeat";
import ManualSpinKeeper from "../components/manualspinkeeper";
import { getMySetting, updateAppSettings } from "@app/service/logs/uploadplaylistlogs";
import { PagePermission } from "./extra/permission";
var DVDplayerPage = /** @class */ (function (_super) {
    __extends(DVDplayerPage, _super);
    function DVDplayerPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.isLoaded = false;
        _this.onStoped = function () {
            navigateBy(_this.state, _this.props.history);
        };
        _this.onCurrentStarted = function (context) {
            _this.setState({ manualSpinKeeperEnable: false });
            window.setTimeout(function () {
                _this.setState({ sequence: null });
            }, 100);
        };
        _this.onCurrentEnded = function (content) {
            //upload log.
            UploadLogs({ id: _this.state.userId, role: GLGlobal.store.getState().theme.leftData.role }, _this.state.unit, content, Mode.Listen);
            if (PLAYMODES[_this.state.mode] == PlayMode.single) {
                navigateBy(_this.state, _this.props.history);
            }
            _this.setState({ endedContent: content });
        };
        _this.onEnded = function () {
            //upload log.
            navigateBy(_this.state, _this.props.history);
        };
        _this.onDropdownVisibleChange = function (visible) {
            _this.setState({ visibleBar: visible });
        };
        _this.onPlaybackRateChange = function (value) {
            _this.setState({ playbackRate: value });
            updateAppSettings(_this.state.userId, GLGlobal.store.getState().theme.leftData.parentId, GLGlobal.store.getState().theme.leftData.unit, { slowSpeed: value != 1 });
        };
        var _a = _this.props.match.params, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, sequence = _a.sequence;
        var _b = GLUtil.queryParse(), selection = _b.selection, mode = _b.mode;
        _this.state = {
            userId: userId,
            versionId: versionId,
            unit: unit,
            resources: {},
            signature: {},
            sequence: sequence,
            selection: selection,
            mode: mode,
            endedContent: null,
            manualSpinKeeperEnable: true,
            playbackRate: 1,
            visibleBar: false,
        };
        return _this;
    }
    DVDplayerPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contents, _a, userId, versionId, unit, sequence, selection, mode, _b, id, rest, maxUnit;
            var _this = this;
            return __generator(this, function (_c) {
                contents = GLUtil.queryParse().contents;
                _a = this.state, userId = _a.userId, versionId = _a.versionId, unit = _a.unit, sequence = _a.sequence, selection = _a.selection, mode = _a.mode;
                if (!PagePermission(userId, unit)) {
                    return [2 /*return*/];
                }
                this.isLoaded = true;
                // hiden navigation bar on ls mode
                GLGlobal.store.getState().theme.rightChanged = unit > 0;
                _b = GLGlobal.store.getState().theme.leftData, id = _b.id, rest = __rest(_b, ["id"]);
                maxUnit = rest.unit || rest.lsUnit;
                maskThrottle();
                Promise.all([
                    this.contentsignatureappservice.getSignatures(null, { versionId: versionId }),
                    this.unitsContentsService.getUnitsContents({ units: [Math.abs(unit)] }, { versionId: versionId }),
                    getMySetting(this.settingService, id, maxUnit),
                ]).then(function (_a) {
                    var _b = __read(_a, 3), signature = _b[0], unitcontents = _b[1], settings = _b[2];
                    var unitcontent = unitcontents.shift();
                    var playlistscontents = [{ contents: contents.map(function (id) { return ({ id: id }); }) }];
                    var resources = { playlists: playlistscontents, contents: unitcontent.contents };
                    _this.setState({
                        resources: resources, signature: signature, sequence: sequence,
                        playbackRate: settings.mySetting.appSettings.slowSpeed ? 0.7 : 1
                    });
                });
                // unmaskThrottle();
                hidenHeaderFooter();
                return [2 /*return*/];
            });
        });
    };
    DVDplayerPage.prototype.componentWillUnmount = function () {
        unmaskThrottle();
        showHeaderFooter();
    };
    DVDplayerPage.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.leftData && (this.props.leftData.unit !== nextProps.leftData.unit)) {
            this.componentDidMount();
        }
    };
    DVDplayerPage.prototype.render = function () {
        if (!this.isLoaded) {
            return [];
        }
        return React.createElement("div", { className: "playlistplayer media-page" },
            React.createElement(HeartBeat, { userId: this.state.userId },
                React.createElement(ManualSpinKeeper, { enable: this.state.manualSpinKeeperEnable }),
                React.createElement(ContentPlayer, { stream: true, streamPreload: false, onLoaded: function (loaded) { return loaded && unmaskThrottle(); }, onPause: function () { }, onCurrentStarted: this.onCurrentStarted, onCurrentEnded: this.onCurrentEnded, onEnded: this.onEnded, onStop: this.onStoped, playMode: PLAYMODES[this.state.mode], sequenceMode: SequenceMode.singleCycle, resources: this.state.resources, signature: this.state.signature, startIndex: this.state.sequence, playbackRate: this.state.playbackRate, visibleBar: this.state.visibleBar },
                    React.createElement(CountDownControlContainer, { controlOnScreen: true, playbackRate: this.state.playbackRate, onDropdownVisibleChange: this.onDropdownVisibleChange, onPlaybackRateChange: this.onPlaybackRateChange }))));
    };
    __decorate([
        lazyInject(TYPES.IContentsignatureAppService)
    ], DVDplayerPage.prototype, "contentsignatureappservice", void 0);
    __decorate([
        lazyInject(TYPES.IUnitsContentsService)
    ], DVDplayerPage.prototype, "unitsContentsService", void 0);
    __decorate([
        lazyInject(TYPES.ISettingService)
    ], DVDplayerPage.prototype, "settingService", void 0);
    DVDplayerPage = __decorate([
        withRouter,
        connect(function (_a) {
            var _b = _a.theme, leftChanged = _b.leftChanged, leftData = _b.leftData;
            return ({
                leftChanged: leftChanged,
                leftData: leftData
            });
        })
    ], DVDplayerPage);
    return DVDplayerPage;
}(Component));
export default DVDplayerPage;
function navigateBy(state, history) {
    var userId = state.userId, versionId = state.versionId, unit = state.unit, selection = state.selection, mode = state.mode;
    history.push({ pathname: GLUtil.pathStringify(PathConfig.Listen, { userId: userId, unit: unit, versionId: versionId }), search: GLUtil.queryStringify({ selection: selection, mode: mode }) });
}
