var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import { Progress } from "antd-min";
import { getTime, PlayAction, PlayMode } from "./mediaplayer";
import PlayerButton, { StepBackwardButton, StepForwardButton, PlayModeButton } from "./player-button";
import { getPausePlayer, getStopPlayer, IEVersion, polyfillComp, PolyfillCompKey, rewind } from "@app/util";
import FullScreenButton from "@app/page/components/fullscreen/full-screen-button";
import PlaySetting from "@app/page/components/speedcontroller/speed-control-dropdown";
var DefaultPlayModes = [PlayMode.order, PlayMode.singleCycle, PlayMode.random];
var CountDownControlContainer = /** @class */ (function (_super) {
    __extends(CountDownControlContainer, _super);
    function CountDownControlContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.onDropdownVisibleChange = function (visible) {
            _this.props.onDropdownVisibleChange && _this.props.onDropdownVisibleChange(visible);
        };
        _this.onPlaybackRateChange = function (value) {
            _this.props.onPlaybackRateChange && _this.props.onPlaybackRateChange(value);
        };
        _this.onCaptionShowChange = function (visible) {
            _this.props.onCaptionShowChange && _this.props.onCaptionShowChange(visible);
        };
        _this.onRewind = function (seek, time) {
            return rewind(seek, time);
        };
        _this.state = {
            playModes: _this.resetPlayModes(props.mode || PlayMode.order, DefaultPlayModes)
        };
        return _this;
    }
    CountDownControlContainer.prototype.componentDidMount = function () {
        polyfillComp(PolyfillCompKey.BtnCicle | PolyfillCompKey.ScreenControl);
    };
    CountDownControlContainer.prototype.resetPlayModes = function (mode, modes) {
        var playModes = __spread(modes);
        while (mode != playModes[0]) {
            playModes.push(playModes.shift());
        }
        return playModes;
    };
    CountDownControlContainer.prototype.renderControlButton = function () {
        var _a = this.props, paused = _a.paused, isPaused = _a.isPaused, play = _a.play, pause = _a.pause, stop = _a.stop;
        return (React.createElement(React.Fragment, null,
            React.createElement(PlayerButton, { iconType: isPaused ? PlayAction.play : PlayAction.pause, onClick: function () { return paused() ? play() : (pause(), setAudio(getPausePlayer())); } }),
            React.createElement(PlayerButton, { iconType: PlayAction.stop, onClick: function () { return stopAudio(pause, stop); } })));
    };
    CountDownControlContainer.prototype.renderPlayControlButton = function () {
        var _this = this;
        var playModes = this.state.playModes;
        var _a = this.props, onModeChanged = _a.onModeChanged, setPlayMode = _a.setPlayMode, stepBackward = _a.stepBackward, stepForward = _a.stepForward, seek = _a.seek, _b = _a.times, time = _b.time, duration = _b.duration;
        return (React.createElement(React.Fragment, null,
            React.createElement(StepBackwardButton, { mode: playModes[0], onClick: handleClick(function () { return stepBackward(); }) }),
            React.createElement(StepForwardButton, { mode: playModes[0], onClick: handleClick(function () { return stepForward(); }) }),
            React.createElement(PlayModeButton, { mode: playModes[0], onClick: handleClick(function () {
                    var playModes = _this.state.playModes;
                    var mode = playModes.shift();
                    _this.setState({ playModes: playModes.concat([mode]) });
                    setPlayMode(playModes[0]);
                    onModeChanged && onModeChanged(playModes[0]);
                }) })));
    };
    CountDownControlContainer.prototype.render = function () {
        var _a = this.props, _b = _a.times, time = _b.time, duration = _b.duration, controlOnScreen = _a.controlOnScreen;
        return React.createElement("div", { className: "countdown-controlbar media-screen-dimensions" },
            controlOnScreen && React.createElement("div", { className: "screen-control " + (IEVersion() != -1 ? "IE_EDGE_FIX_STYLE" : "") }, this.renderControlButton()),
            React.createElement("div", { className: "controlbar mediaplayer-controlbar" },
                this.renderPlayControlButton(),
                React.createElement(Progress, { percent: percent(time, duration), format: function () { return getTime((duration - time)); } }),
                !controlOnScreen && this.renderControlButton(),
                React.createElement(PlaySetting, { playbackRate: this.props.playbackRate, hasCaption: this.props.hasCaption, captionShow: this.props.captionShow, onDropdownVisibleChange: this.onDropdownVisibleChange, onCaptionShowChange: this.onCaptionShowChange, onPlaybackRateChange: this.onPlaybackRateChange }),
                React.createElement(FullScreenButton, null)),
            React.createElement("audio", { id: 'playlist-audio', style: { display: 'none' }, src: getStopPlayer() }));
    };
    CountDownControlContainer.displayName = 'CountDownControlContainer';
    return CountDownControlContainer;
}(Component));
export default CountDownControlContainer;
function handleClick(callback) {
    return function (ev) {
        ev.stopPropagation();
        callback(ev);
    };
}
function percent(progress, total) {
    if (progress === void 0) { progress = 0; }
    if (total === void 0) { total = 1; }
    progress = progress / 1000;
    total = total / 1000;
    return Math.floor((progress / total) * 100);
}
function setAudio(src) {
    var audio = document.getElementById('playlist-audio');
    audio.src = src;
    audio.play();
}
function stopAudio(pause, stop) {
    pause();
    setAudio(getStopPlayer());
    setTimeout(stop, 500);
}
