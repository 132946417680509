var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from "react";
import _ from "lodash";
import DOMPurify from "dompurify";
import { GLGlobal, alignPop, formatRegionalUrl } from "gl-commonui";
import { Icon, Popover } from "antd";
import { StudentLocale } from "@app/locales/localeid";
import { DateHelper } from "@app/util";
export function NotificationTitle(_a) {
    var title = _a.content.title;
    var parsedTitle = typeof title === 'string' ? formatRegionalUrl(title) : title;
    return React.createElement(InnerHTML, { html: "" + parsedTitle });
}
export function NotificationDescription(_a) {
    var content = _a.content;
    content = content
        .replace(/target="_blank"/g, 'data-target="_blank"')
        .replace(/target='_blank'/g, 'data-target="_blank"');
    content = DOMPurify.sanitize(_.unescape(content));
    content = content.replace(/data-target="_blank"/g, 'target="_blank"');
    content = content.replace(/<p><\/p>/g, "<br/>");
    var parsedContent = typeof content === 'string' ? formatRegionalUrl(content) : content;
    return (React.createElement("div", { className: "header-notification-item-description", dangerouslySetInnerHTML: { __html: parsedContent } }));
}
export function InnerHTML(props) {
    return React.createElement("span", { dangerouslySetInnerHTML: { __html: props.html } });
}
export function ArchivedSvg() {
    return React.createElement("svg", { viewBox: "0 0 1024 1024", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", focusable: "false" },
        React.createElement("defs", null),
        React.createElement("path", { d: "M512 25.161c68.133 0 132.279 12.837 192.366 38.546A495.433 495.433 0 01861.55 169.29 495.433 495.433 0 01967.13 326.473c25.71 60.124 38.583 124.233 38.583 192.366 0 68.169-12.873 132.279-38.583 192.366a495.433 495.433 0 01-105.581 157.22 495.433 495.433 0 01-157.184 105.582c-60.087 25.71-124.233 38.583-192.366 38.583s-132.279-12.873-192.366-38.583A495.433 495.433 0 01162.45 868.425 495.433 495.433 0 0156.87 711.241c-25.71-60.123-38.583-124.27-38.583-192.366 0-68.169 12.873-132.278 38.583-192.365A495.433 495.433 0 01162.45 169.29 495.433 495.433 0 01319.634 63.706C379.721 38.034 443.867 25.161 512 25.161zm0 926.647c59.794 0 115.858-11.41 168.265-34.23a435.968 435.968 0 00136.924-93.039 446.062 446.062 0 0092.562-137.398c22.82-52.407 34.23-108.508 34.23-168.302 0-59.758-11.41-115.858-34.23-168.229a440.1 440.1 0 00-92.526-136.96 440.1 440.1 0 00-136.996-92.562c-52.407-22.82-108.471-34.23-168.266-34.23-59.794 0-115.858 11.41-168.265 34.23a440.1 440.1 0 00-136.923 92.562 440.1 440.1 0 00-92.562 136.924C91.392 402.98 79.945 459.08 79.945 518.839c0 59.794 11.447 115.895 34.268 168.302a446.062 446.062 0 0092.525 137.398 435.968 435.968 0 00137.033 93.038c52.407 22.82 108.471 34.231 168.266 34.231zM531.273 332.8a30.5 30.5 0 0122.199-9.655c8.338 0 15.726 3.218 22.162 9.655l182.272 187.063-182.272 187.063c-3.218 2.56-6.729 4.68-10.605 6.253a29.879 29.879 0 01-11.557 2.414 29.879 29.879 0 01-11.593-2.414 43.959 43.959 0 01-10.606-6.217 32.183 32.183 0 01-8.667-22.199c0-8.374 2.925-15.762 8.667-22.198l113.81-112.823H296.01a29.22 29.22 0 01-22.199-9.143 30.5 30.5 0 01-8.667-21.724c0-8.996 2.926-16.384 8.667-22.162a30.025 30.025 0 0122.2-8.667h347.135L531.273 376.174a29.44 29.44 0 01-8.667-21.687c0-8.668 2.925-15.909 8.667-21.687z" }));
}
export function ArchivedIcon(props) {
    return (React.createElement("a", null,
        "\u00A0",
        React.createElement(Icon, __assign({ component: ArchivedSvg, className: 'icon-archived' }, props))));
}
export function MarkReadIcon(props) {
    var isRead = props.isRead, rest = __rest(props, ["isRead"]);
    return isRead ? (React.createElement("a", { href: "javascript:void(0)" },
        "\u00A0",
        React.createElement(Icon, __assign({ type: "check", style: {
                cursor: "default"
            } }, rest)))) : (React.createElement("a", null,
        "\u00A0",
        React.createElement(Icon, __assign({ type: "check-circle" }, rest, { title: GLGlobal.intl.formatMessage({
                id: StudentLocale.NotificationMarkAsReadText
            }) }))));
}
export var NotificationTitleItem = function (props) {
    var _a = props.item, id = _a.id, title = _a.title, startDate = _a.startDate, description = _a.description, archived = _a.archived, sticky = _a.sticky, seen = _a.seen, hasPopover = props.hasPopover, onMarkOneAsRead = props.onMarkOneAsRead, onClearOne = props.onClearOne;
    var itemTitle = (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                fontWeight: seen ? 500 : 700,
                cursor: seen ? 'default' : 'pointer'
            }, className: "header-notification-item-title", onClick: function () { return hasPopover && onMarkOneAsRead(id); } },
            React.createElement(NotificationTitle, { content: { title: title } }),
            "\u00A0",
            React.createElement("span", null, startDate
                ? "(" + DateHelper.formatDate2Local(startDate) + ")"
                : ""))));
    return (React.createElement("div", null,
        sticky && (React.createElement(Icon, { style: {
                marginRight: "0.125rem"
            }, type: "pushpin-o" })),
        hasPopover ? (React.createElement(Popover, __assign({}, alignPop(), { overlayClassName: "home-notification", content: React.createElement(NotificationDescription, { content: description }), title: React.createElement(NotificationTitle, { content: { title: title } }), trigger: "click" }), itemTitle)) : (itemTitle),
        React.createElement(MarkReadIcon, { isRead: seen, onClick: function () { return !seen && onMarkOneAsRead(id); } }),
        !archived && (React.createElement(ArchivedIcon, { key: "file-done", title: "Clear", onClick: function (e) { return onClearOne(id); } }))));
};
