var JbHappyToSad = /** @class */ (function () {
    function JbHappyToSad() {
        this.frames = [
            39,
            38,
            37,
            31,
            30,
            29,
            28,
            27,
            26,
            25,
            24
        ];
    }
    return JbHappyToSad;
}());
export { JbHappyToSad };
