var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _this = this;
import { useActionCreator, useEffectAfterFirst, useSelector, useService } from "@app/hooks";
import { ConversationService } from "@app/service/conversation";
import { fetchUnitPendingConversationAction } from "@app/states/conversation";
import { Constants, getChildrenRegion, isSafari, preload, TYPES } from "@app/util";
import { ContainerType, ConversationAPI, ConversationStatus, ConversationVoiceGenerationStatus } from "@app/util/conversation-api";
import { mergeClasses } from "gl-commonui";
import { toNumber, uniq } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ConversationPlayer } from "./components/conversation-player/conversation-player";
import "./playlist-conversation.less";
import audioFile from '@app/style/audios/90ef06f9-a47f-431a-9d00-24d4dbb81daf.wav';
import { ConversationIntroPlayer } from "@app/components/conversation-intro-player/conversation-intro-player";
import moment from "moment";
import ConversationPlaySetting from "@app/page/components/speedcontroller/conversation-speed-control-dropdown";
import { getMySetting } from "@app/service/logs/uploadplaylistlogs";
var isUnmounted = false;
var formatSaveDialogModel = function (dialogTrack, studentId, conversationId, playlistId, unit, versionGroupId, conversationName, childInfo, playedDate, lessonNumber, source) {
    var _a = dialogTrack, conversationDialogId = _a.conversationDialogId, pathId = _a.pathId, pathName = _a.pathName, utterenceText = _a.utterenceText, matchingScore = _a.matchingScore, isMatched = _a.isMatched, attempt = _a.attempt, // always = 1
    topIntentName = _a.topIntentName, matchedIntentName = _a.matchedIntentName, matchedIntentId = _a.matchedIntentId, isHead = _a.isHead, isCompleted = _a.isCompleted, conversationDialogName = _a.conversationDialogName;
    var campusId = childInfo.campusId, regionId = childInfo.regionId, repMethodologyVersionId = childInfo.repMethodologyVersionId, schoolId = childInfo.schoolId, schoolClassId = childInfo.schoolClassId;
    return {
        conversationDialogId: conversationDialogId,
        pathId: pathId,
        pathName: pathName,
        utteranceText: utterenceText,
        matchingScore: matchingScore,
        isMatched: isMatched,
        attempt: attempt,
        topIntentName: topIntentName,
        matchedIntentName: matchedIntentName,
        matchedIntentId: matchedIntentId,
        isHead: isHead,
        isCompleted: isCompleted,
        studentId: studentId,
        conversationId: conversationId,
        playlistId: playlistId,
        unit: unit,
        versionGroupId: versionGroupId,
        conversationName: conversationName,
        conversationDialogName: conversationDialogName,
        campusId: campusId,
        regionId: regionId,
        lessonPlanVersionId: repMethodologyVersionId,
        schoolId: schoolId,
        schoolClassId: schoolClassId,
        playedDate: playedDate,
        mode: 0,
        lessonNumber: lessonNumber,
        source: source,
        isAggregated: false
    };
};
export var PlaylistConversation = function (props) {
    var _a = __read(useState(false), 2), visible = _a[0], setVisible = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(false), 2), isConversationStarted = _c[0], setIsConversationStarted = _c[1];
    var _d = __read(useState(false), 2), isListening = _d[0], setIsListening = _d[1];
    var _e = __read(useState(null), 2), dialog = _e[0], setDialog = _e[1];
    var conversationAPI = useRef(null);
    var conclusionNodeReached = useRef(false);
    var conclusionDialog = useRef(null);
    var jonnyBearRef = useRef();
    var _f = __read(useState(false), 2), showIntroVideo = _f[0], setShowIntroVideo = _f[1];
    var _g = __read(useState(false), 2), playToggle = _g[0], setPlayToggle = _g[1];
    var _h = __read(useState(), 2), speed = _h[0], setSpeed = _h[1];
    var _j = __read(useState(false), 2), isBlinking = _j[0], setIsBlinking = _j[1];
    var microphoneDeviceId = useSelector(function (state) { return state.systemCheck.microphoneDeviceId; });
    var conversationAdminService = useService(TYPES.IConversationAdminService);
    var settingService = useService(TYPES.ISettingService);
    var updateUnitPendingConversations = useActionCreator(fetchUnitPendingConversationAction);
    var content = props.content, playSequence = props.playSequence, playlistId = props.playlistId, unit = props.unit, studentId = props.studentId, regionId = props.regionId;
    var conversationId = content.conversationId;
    var isServerDown = false;
    useEffect(function () {
        isUnmounted = false;
        return function () {
            isUnmounted = true;
            reset();
        };
    }, []);
    useEffectAfterFirst(function () {
        setVisible(true);
        loadConversation();
    }, [playToggle]);
    useEffect(function () {
        if (conversationId) {
            // Check for permission again.
            if (microphoneDeviceId) {
                navigator.mediaDevices
                    .getUserMedia({ audio: true })
                    .then(function (stream) {
                    stream.getTracks().forEach(function (track) { return track.stop(); });
                    loadConversation();
                })
                    .catch(function (e) {
                    cancelConversation();
                });
            }
            else {
                cancelConversation();
            }
        }
        else {
            setVisible(false);
        }
    }, [conversationId]);
    useEffectAfterFirst(function () {
        if (conversationAPI.current) {
            conversationAPI.current.updateMicrophone(microphoneDeviceId);
        }
    }, [microphoneDeviceId]);
    useEffectAfterFirst(function () {
        if (conversationAPI.current) {
            var speed_1 = 0;
            if (props.content && props.content.conversationId) {
                if (props.conversationSpeed === 0.7 || props.conversationSpeed === Constants.ConversationSlowModePlaybackRate) {
                    speed_1 = Constants.ConversationSlowModePlaybackRate;
                }
                else {
                    speed_1 = Constants.DefaultPlaybackRate;
                }
            }
            else if (props.content && !props.content.conversationId) {
                if (props.conversationSpeed === 0.7 || props.conversationSpeed === Constants.ConversationSlowModePlaybackRate) {
                    speed_1 = 0.7;
                }
                else {
                    speed_1 = Constants.DefaultPlaybackRate;
                }
            }
            conversationAPI.current.updateSpeed(speed_1);
        }
    }, [conversationId, props.conversationSpeed]);
    var setConversationAPI = function (instance) {
        conversationAPI.current = instance;
    };
    var onConclusionNodeReached = function () {
        if (!conclusionNodeReached.current) {
            // Update the log
            props.markCurrentItemAsPlayed && props.markCurrentItemAsPlayed();
            saveConversationStatus(true);
        }
        conclusionNodeReached.current = true;
    };
    var resetAndMoveToNextItem = function () {
        // Reset the component to its default state
        reset();
        // Play the next item of playlist
        playSequence(undefined, true);
    };
    var onInComplete = function () {
        if (!conclusionNodeReached.current) {
            saveConversationStatus(false);
        }
        resetAndMoveToNextItem();
    };
    var cancelConversation = function (triggerPendingCall) {
        if (triggerPendingCall === void 0) { triggerPendingCall = true; }
        // Marking the conversation as incompleted
        if (triggerPendingCall && !conclusionNodeReached.current) {
            saveConversationStatus(false);
        }
        resetAndMoveToNextItem();
    };
    var resetConversationAndShowIntroVideo = function () {
        reset();
        isUnmounted = false;
        setPlayToggle(!playToggle);
        setShowIntroVideo(true);
    };
    var onListen = function (isListening) {
        if (isUnmounted) {
            return;
        }
        setIsListening(isListening);
    };
    var sendDialogHistory = function (dialogTrack) {
        var versionId = props.versionId, unit = props.unit, playlistNumber = props.playlistNumber, source = props.source;
        var versiongroupId = versionId;
        var conversationUnit = unit;
        var conversationName = conversationAPI.current.getConversationName();
        var allChildren = getChildrenRegion();
        var currentChild = allChildren.filter(function (c) { return c.id == studentId; })[0];
        var playedDate = moment().format("YYYY-MM-DD");
        var lessonNumber = !playlistNumber ? null : playlistNumber;
        var saveDialogHistoryParams = formatSaveDialogModel(dialogTrack, studentId, conversationId, playlistId, Number(conversationUnit), versiongroupId, conversationName, currentChild, playedDate, playlistNumber, source);
        ConversationService.saveDialogHistory(saveDialogHistoryParams);
    };
    var onUserResponse = function (dialogTrack) {
        sendDialogHistory(dialogTrack);
    };
    var checkAccess = function () {
        return new Promise(function (resolve, reject) {
            conversationAdminService
                .checkAccess(studentId)
                .then(function (hasAccess) {
                if (hasAccess) {
                    resolve();
                }
                else {
                    reject();
                }
            })
                .catch(reject);
        });
    };
    var setConclusionDialog = function (conversationInfo) {
        conclusionDialog.current = conversationInfo.conversationDialog.find(function (c) { return c.isConclusion && c.statusId === ConversationStatus.Active; });
    };
    var loadConversation = function () {
        setLoading(true);
        checkAccess()
            .then(function () { return __awaiter(_this, void 0, void 0, function () {
            var voiceGenStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, ConversationService.getVoiceUrlGeneratedStatus(conversationId)];
                    case 1:
                        voiceGenStatus = _a.sent();
                        if (voiceGenStatus !== ConversationVoiceGenerationStatus.Success) {
                            cancelConversation();
                            setLoading(false);
                            return [2 /*return*/];
                        }
                        setVisible(true);
                        Promise.all([ConversationService.getSasURL(),
                            ConversationService.getConversation(conversationId),
                            getMySetting(settingService, studentId, Number(unit))
                        ])
                            .then(function (_a) {
                            var _b = __read(_a, 3), signatures = _b[0], conversationInfo = _b[1], setting = _b[2];
                            loadConversationFunction([signatures, conversationInfo, isServerDown = false, setting]);
                        })
                            .catch(function () {
                            var conversationInfo = {
                                "conversationDialog": [
                                    {
                                        "isHead": true,
                                        "statusId": 0,
                                        "voiceUrl": audioFile,
                                        "path": []
                                    }
                                ]
                            };
                            var signatures = [];
                            loadConversationFunction([signatures, conversationInfo, isServerDown = true, null]);
                            setLoading(false);
                        });
                        return [2 /*return*/];
                }
            });
        }); })
            .catch(function () {
            cancelConversation(false);
            setLoading(false);
        });
    };
    var loadConversationFunction = function (_a) {
        var _b = __read(_a, 4), signatures = _b[0], conversationInfo = _b[1], isServerDown = _b[2], setting = _b[3];
        var speedSetting = setting.mySetting.appSettings.slowSpeed;
        var currentSpeed = speedSetting === true ? Constants.ConversationSlowModePlaybackRate : Constants.DefaultPlaybackRate;
        setSpeed(currentSpeed);
        var imageSignature = signatures.filter(function (f) { return f.type === ContainerType.Page; });
        var audioSignature = signatures.filter(function (f) { return f.type === ContainerType.File; });
        var api = new ConversationAPI(conversationInfo, imageSignature.length ? imageSignature[0] : null, audioSignature.length ? audioSignature[0] : null, {
            microphoneDeviceId: microphoneDeviceId,
            onComplete: onConclusionNodeReached,
            onEnd: onInComplete,
            onIncomplete: onInComplete,
            //onListen,
            onDialogChange: setDialog,
            onUserResponse: onUserResponse,
            jbRef: jonnyBearRef.current,
            playbackRate: currentSpeed,
            onTriggerSendDialogHistory: sendDialogHistory,
            onListenStart: function () { return onListen(true); },
            onListenStop: function () { return onListen(false); },
            onServerDown: onInComplete
        }, undefined, isServerDown);
        setConversationAPI(api);
        // preload dialog images
        var imageUrls = uniq(conversationInfo.conversationDialog.filter(function (cd) { return cd.imageUrl; }).map(function (cd) { return api.getImageUrl(cd.imageUrl); }));
        preload(imageUrls);
        setConclusionDialog(conversationInfo);
        setLoading(false);
    };
    var startConversation = function (audioElement, audioContext) {
        setIsConversationStarted(true);
        isSafari() && conversationAPI.current.setAudioContext(audioElement, audioContext);
        conversationAPI.current.start();
    };
    var reset = function () {
        if (conversationAPI.current) {
            conversationAPI.current.dispose();
        }
        if (isUnmounted) {
            // We can't update state if the component is already unmounted
            return;
        }
        // setVisible(false);
        setIsConversationStarted(false);
        setIsListening(false);
        setDialog(null);
        setConversationAPI(null);
        conclusionNodeReached.current = false;
    };
    var saveConversationStatus = function (isCompleted) {
        ConversationService
            .saveHistory({
            conversationId: conversationId,
            studentId: studentId,
            unit: toNumber(unit),
            playListId: playlistId,
            isCompleted: isCompleted,
        }).then(function () {
            var allChildren = getChildrenRegion();
            var currentChild = allChildren.filter(function (c) { return c.id == studentId; })[0];
            if (unit && studentId && currentChild) {
                updateUnitPendingConversations({ unit: unit, studentId: studentId, repMethodologyVersionId: currentChild.repMethodologyVersionId });
            }
        });
    };
    var onDropdownVisibleChange = function (visible) {
        props.onDropdownVisibleChange && props.onDropdownVisibleChange(visible);
    };
    var onPlaybackRateChange = function (value) {
        props.onPlaybackRateChange && props.onPlaybackRateChange(value);
    };
    var startAndStopListening = function () {
        if (!isBlinking) {
            conversationAPI.current.startListening();
            setTimeout(function () {
                setIsBlinking(true);
            }, 1000);
        }
        else {
            conversationAPI.current.stopListening();
            setTimeout(function () {
                setIsBlinking(false);
            }, 500);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: mergeClasses("playlist-conversation-container media-screen-dimensions", !visible && "playlist-conversation-container--hidden") }, visible && (React.createElement(React.Fragment, null,
            React.createElement(ConversationPlayer, { isConversationStarted: isConversationStarted, isListening: isListening, dialog: dialog, loading: loading, startConversation: startConversation, cancelConversation: cancelConversation, resetConversationAndShowIntroVideo: resetConversationAndShowIntroVideo, ref: jonnyBearRef, playbackRate: props.conversationSpeed ? props.conversationSpeed : speed, startAndStopListening: startAndStopListening, isBlinking: isBlinking }),
            React.createElement(ConversationPlaySetting, { playbackRate: props.conversationSpeed ? props.conversationSpeed : speed, onDropdownVisibleChange: onDropdownVisibleChange, onPlaybackRateChange: onPlaybackRateChange })))),
        showIntroVideo && React.createElement(ConversationIntroPlayer, { playbackRate: props.conversationSpeed ? props.conversationSpeed : speed, onConversationIntroClose: function () { setShowIntroVideo(false); } })));
};
