var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GLServiceBase } from "gl-commonui";
var ConversationServiceClass = /** @class */ (function (_super) {
    __extends(ConversationServiceClass, _super);
    function ConversationServiceClass() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: "content/v1/conversations" };
        return _this;
    }
    ConversationServiceClass.prototype.getSasURL = function () {
        return this.get("getConversationAssetReadSignature/v2");
    };
    ConversationServiceClass.prototype.getConversation = function (id) {
        var params = {
            conversationId: id
        };
        this.serviceRoute = { prefix: "content/v2/conversations" };
        var response = this.get("info", params);
        this.serviceRoute = { prefix: "content/v1/conversations" };
        return response;
    };
    ConversationServiceClass.prototype.saveDialogHistory = function (requestModel) {
        this.serviceRoute = { prefix: "report/v1/conversation" };
        var result = this.createWithoutLoader("dialog-history", requestModel);
        this.serviceRoute = { prefix: "content/v1/conversations" };
        return result;
    };
    ConversationServiceClass.prototype.getVoiceUrlGeneratedStatus = function (id) {
        var params = {
            conversationId: id,
        };
        return this.get("voiceURLGenerated", params);
    };
    ConversationServiceClass.prototype.saveConversationFeedBack = function (requestModel) {
        return this.createWithoutLoader("addConversationFeedBack", requestModel);
    };
    ConversationServiceClass.prototype.saveHistory = function (request) {
        return this.createWithoutLoader("student/save-history", request);
    };
    ConversationServiceClass.prototype.getConversationList = function (maxUnit) {
        return this.get("lessonplans/conversations", { maxUnit: maxUnit });
    };
    ConversationServiceClass.prototype.getUnitPendingConversation = function (currentUnit, studentId, repMethodologyId) {
        var unit = Number(currentUnit);
        return this.get("student/{unit}/{studentId}/{repMethodologyId}/pending", null, { unit: unit, studentId: studentId, repMethodologyId: repMethodologyId });
    };
    ConversationServiceClass.prototype.queryPrediction = function (data) {
        return this.createWithoutLoader("queryprediction", data);
    };
    ConversationServiceClass.prototype.queryCLU = function (data) {
        return this.createWithoutLoader("queryclu", data);
    };
    return ConversationServiceClass;
}(GLServiceBase));
export { ConversationServiceClass };
export var ConversationService = new ConversationServiceClass();
