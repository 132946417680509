var JbRaiseHandToNeutral = /** @class */ (function () {
    function JbRaiseHandToNeutral() {
        this.frames = [
            119,
            118,
            117,
            116,
            115,
            114,
            113,
            112,
            1
        ];
    }
    return JbRaiseHandToNeutral;
}());
export { JbRaiseHandToNeutral };
