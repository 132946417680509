import React from "react";
import { Link } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { GLUtil } from "gl-commonui";
import { UnitBox } from "@app/components/svg-icon";
export default function PuzzleTray(_a) {
    var history = _a.history, to = _a.to, userId = _a.userId, versionId = _a.versionId, puzzleList = _a.puzzleList;
    var leftBox = 0;
    var rightBox = 1;
    var isLeftBoxEmpty = true;
    var isRightBoxEmpty = true;
    // round3 or round4 completed
    if (puzzleList.length === 2) {
        isLeftBoxEmpty = false;
        isRightBoxEmpty = false;
    }
    // round1 or round2 completed
    if (puzzleList.length === 1) {
        isLeftBoxEmpty = false;
    }
    var puzzleThumbnail = function (boxStatus, boxNumber) { return (React.createElement("div", { className: "unit-puzzle-box " + (boxStatus === false ? '' : 'unit-puzzle-box-empty') }, !boxStatus ? React.createElement(React.Fragment, null,
        React.createElement(UnitBox, { className: "unit-box-theme-1" }),
        React.createElement(PuzzleLink, { userId: userId, versionId: versionId, puzzleId: puzzleList[boxNumber].id },
            React.createElement("div", { className: "unit-puzzle-thumbnail" },
                React.createElement("img", { onDragStart: function (_) { return false; }, draggable: false, src: puzzleList[boxNumber].thumbnailPath })))) : null)); };
    var puzzleBadges = function () { return (React.createElement("div", { className: 'unit-puzzle-badges' },
        React.createElement("div", { className: 'unit-puzzle-boxex' },
            puzzleThumbnail(isLeftBoxEmpty, leftBox),
            puzzleThumbnail(isRightBoxEmpty, rightBox)),
        React.createElement("div", { className: 'unit-puzzle-shelf' }))); };
    return (React.createElement("div", { className: "unit-base" }, puzzleList ? puzzleBadges() : null));
}
function PuzzleLink(_a) {
    var userId = _a.userId, versionId = _a.versionId, puzzleId = _a.puzzleId, children = _a.children;
    return React.createElement(Link, { to: { pathname: GLUtil.pathStringify(PathConfig.PuzzlePlayer, { userId: userId, versionId: versionId, puzzleId: puzzleId }) } }, children);
}
