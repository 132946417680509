var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from "react";
import Lottie from 'lottie-react-web';
import { isIOSSafari9 } from "@app/util";
export default function PuzzleCongrat(_a) {
    var isFinished = _a.isFinished;
    var _b = __read(useState(null), 2), animationDate = _b[0], setAnimationDate = _b[1];
    useEffect(function () {
        new Promise(function (resolve, reject) {
            resolve(require('./animations/congrat.json'));
        }).then(function (data) {
            data && setAnimationDate(data);
        }).catch(function (_) { return setAnimationDate(null); });
    }, []);
    var isShow = isFinished && animationDate;
    var getLottie = function () { return React.createElement(Lottie, { options: {
            animationData: animationDate
        }, style: { position: 'absolute', top: 0, left: 0, visibility: isShow ? 'visible' : 'hidden', opacity: isShow ? '1' : '0' } }); };
    if (isIOSSafari9()) {
        return isShow ? getLottie() : null;
    }
    else {
        return getLottie();
    }
}
