var JbSpeakingCycle = /** @class */ (function () {
    function JbSpeakingCycle(
    /**
     * @duration in milliseconds
     */
    duration) {
        this.duration = duration;
        this.frames = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }
    return JbSpeakingCycle;
}());
export { JbSpeakingCycle };
