var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import { Radio } from 'antd-min';
import { ListStates, fmtMsg, mergeClasses } from '../util';
// import { DashboardLocale, SchoolLocale } from '../locales/localeid';
import { GLUtil, OidcProxy } from 'gl-commonui';
import ReactDOM from 'react-dom';
var RadioGroup = Radio.Group;
var ListStatesRadios = /** @class */ (function (_super) {
    __extends(ListStatesRadios, _super);
    function ListStatesRadios(props) {
        return _super.call(this, props) || this;
    }
    ListStatesRadios.prototype.onChange = function (e) {
        this.props.onChange(parseInt(e.target.value));
    };
    ListStatesRadios.prototype.renderState = function (text, cnt) {
        return (React.createElement("span", null,
            React.createElement("span", null, fmtMsg(text)),
            !this.props.hideCnts && React.createElement("span", { className: "cnt" },
                "(",
                cnt || 0,
                ")")));
    };
    ListStatesRadios.prototype.render = function () {
        var _a = this.props, allCnt = _a.allCnt, activeCnt = _a.activeCnt, futureCount = _a.futureCount, inActiveCntProp = _a.inActiveCnt, inActiveTextLocaleIdProp = _a.inActiveTextLocaleId, activeTextLocaleIdProp = _a.activeTextLocaleId;
        var inActiveCnt = typeof inActiveCntProp === "number" ? inActiveCntProp : allCnt - activeCnt - (futureCount || 0);
        var activeTextLocaleId = activeTextLocaleIdProp ? activeTextLocaleIdProp : "Active";
        var inActiveTextLocaleId = inActiveTextLocaleIdProp ? inActiveTextLocaleIdProp : "Inactive";
        return (React.createElement("div", { className: mergeClasses("list-states", this.props.className) },
            React.createElement(RadioGroup, { value: this.props.value, defaultValue: ListStates.Active, onChange: this.onChange.bind(this) },
                React.createElement(Radio, { className: "all", value: ListStates.All }, this.renderState("All", this.props.allCnt)),
                React.createElement(Radio, { className: "active", value: ListStates.Active }, this.renderState(activeTextLocaleId, this.props.activeCnt)),
                this.props.showInActiveRadio && React.createElement(Radio, { className: "inactive", value: ListStates.Inactive }, this.renderState(inActiveTextLocaleId, inActiveCnt))),
            this.props.showGroupingButton &&
                React.createElement("a", { href: "javascript:", style: { marginLeft: 'auto' }, onClick: this.props.openGroupMode }, "Show group panel")));
    };
    ListStatesRadios.defaultProps = {
        showInActiveRadio: true,
    };
    return ListStatesRadios;
}(Component));
export { ListStatesRadios };
var ListStatesStore = /** @class */ (function () {
    function ListStatesStore(storeKey, stateKey, userKey) {
        this.storeKey = storeKey;
        this.stateKey = stateKey;
        this.userKey = userKey;
        var userSession = OidcProxy.getSessionFromStorage();
        this.userKey = userKey || (userSession ? JSON.parse(userSession).profile.sub : storeKey);
    }
    Object.defineProperty(ListStatesStore.prototype, "unionKey", {
        get: function () {
            return this.userKey + "_" + this.storeKey;
        },
        enumerable: true,
        configurable: true
    });
    ListStatesStore.prototype.setStateKey = function (key) {
        if (!GLUtil.isNullOrUndefined(key)) {
            this.stateKey = key;
        }
    };
    ListStatesStore.prototype.getStates = function (id) {
        id = id || this.stateKey;
        var states = JSON.parse(sessionStorage.getItem(this.unionKey) || "{}");
        return { id: id, states: states };
    };
    ListStatesStore.prototype.getState = function (id, defaultState) {
        var _a = this.getStates(id), key = _a.id, states = _a.states;
        return GLUtil.isNullOrUndefined(states[key]) ? (defaultState || ListStates.Active) : states[key];
    };
    ListStatesStore.prototype.setState = function (state) {
        var _a = this.getStates(), id = _a.id, states = _a.states;
        states[id] = state;
        sessionStorage.setItem(this.unionKey, JSON.stringify(states));
    };
    ListStatesStore.prototype.getUrlQuery = function (page) {
        page.disabled = ListStatesStore.toDisabled(this.getState());
        page.future = this.getState() === ListStates.Future ? true : null;
        page.includePendingInvite = this.getState() === ListStates.All || this.getState() === ListStates.Pending ? true : null;
        page.includeOnlyPending = this.getState() === ListStates.Pending ? true : null;
        return page;
    };
    ListStatesStore.getStateCnt = function (total, active, _a, futureCount, pendingCount, inActiveCnt) {
        var state = _a.listState;
        if (futureCount === void 0) { futureCount = 0; }
        if (pendingCount === void 0) { pendingCount = 0; }
        if (inActiveCnt === void 0) { inActiveCnt = null; }
        switch (state) {
            case ListStates.Active:
                return active;
            case ListStates.Inactive:
                return inActiveCnt && typeof inActiveCnt === "number" ? inActiveCnt : total - active;
            case ListStates.Pending:
                return pendingCount;
            case ListStates.Future:
                return futureCount;
            default:
                return total;
        }
    };
    ListStatesStore.toDisabled = function (state) {
        return state === ListStates.All || GLUtil.isNullOrUndefined(state) || /*state === ListStates.Promotion ||*/ state === ListStates.Pending || state === ListStates.Future ? null : !!state;
    };
    return ListStatesStore;
}());
export { ListStatesStore };
var ListStatesPortal = /** @class */ (function (_super) {
    __extends(ListStatesPortal, _super);
    function ListStatesPortal(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            didMount: false
        };
        return _this;
    }
    ListStatesPortal.prototype.componentDidMount = function () {
        var _this = this;
        var lazyload = function () {
            _this.container = document.querySelector(_this.props.selector);
            if (!_this.container) {
                setTimeout(function () { return lazyload(); }, 300);
            }
            else {
                _this.setState({ didMount: true });
            }
        };
        lazyload();
    };
    ListStatesPortal.prototype.render = function () {
        return this.state.didMount ? ReactDOM.createPortal(this.props.children, this.container) : null;
    };
    return ListStatesPortal;
}(Component));
export { ListStatesPortal };
