var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Icon, Progress, Tooltip, Typography } from "@app/antd-min";
import { fmtMsg, mergeClasses, IconFont } from "@app/util";
import { GLGlobal, MatIcon } from "gl-commonui";
import { GLLink } from "@app/components/gl-link";
import "./action.less";
var Text = Typography.Text;
export var Actions = function (props) {
    var actions = props.actions, children = props.children, noHeight = props.noHeight, className = props.className, action = props.action;
    var hasPermission = !action || GLGlobal.isActionValid(action);
    if (!hasPermission) {
        return null;
    }
    return (React.createElement("div", { className: mergeClasses("tw-actions", noHeight && "no-height", className) },
        actions && actions,
        children && children));
};
export var Action = function (props) {
    var materialIcon = props.materialIcon, icon = props.icon, iconFont = props.iconFont, textLocaleId = props.textLocaleId, onClick = props.onClick, tooltip = props.tooltip, tooltipOptions = props.tooltipOptions, disabled = props.disabled, textProps = props.textProps, className = props.className, progress = props.progress, action = props.action, children = props.children, linkProps = props.linkProps;
    var renderActionChildren = function () { return React.createElement(React.Fragment, null,
        materialIcon && React.createElement(MatIcon, { type: materialIcon }),
        icon && React.createElement(Icon, { type: icon }),
        iconFont && React.createElement(IconFont, { type: iconFont }),
        progress && (React.createElement(Progress, { type: "circle", width: 25, strokeWidth: 10, percent: progress, showInfo: progress === 100, status: progress === 100 ? "success" : "active" })),
        textLocaleId && (React.createElement(Text, __assign({ className: "tw-action-text" }, textProps), fmtMsg({ id: textLocaleId }))),
        children && children); };
    var renderAction = function () { return (linkProps
        ? React.createElement(GLLink, __assign({}, linkProps, { className: mergeClasses("tw-action-item", className, disabled && "tw-action-item-disabled", onClick && "cursor-pointer", textLocaleId && "has-text") }), renderActionChildren())
        : React.createElement("div", { className: mergeClasses("tw-action-item", className, disabled && "tw-action-item-disabled", onClick && "cursor-pointer", textLocaleId && "has-text"), onClick: onClick }, renderActionChildren())); };
    var hasPermission = !action || GLGlobal.isActionValid(action);
    if (!hasPermission) {
        return null;
    }
    return tooltip ? (React.createElement(Tooltip, __assign({ title: tooltip }, tooltipOptions), renderAction())) : (renderAction());
};
