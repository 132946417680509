var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var SlowIcon = /** @class */ (function (_super) {
    __extends(SlowIcon, _super);
    function SlowIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SlowIcon.prototype.render = function () {
        return (React.createElement("svg", { className: "icon", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 45 32" },
            React.createElement("path", { className: "path", d: "M43.1,0c0,0,0.4,0,0,1.6c-0.4,1.6-2.7,7.2-2.5,8.6c0,0,0.2,1.2,0.8,1.4l0.6,0.2c0,0,1,0.6,0.6,1.4\n\t\t\t\t\t\tc-0.4,0.8-0.8,1.4-1,1.6L41,15.6c0,0-0.6,7.6-5.8,11.9c0,0-5.2,6-19.5,4.1c0,0-4.6-1.4-9.8-1.2c0,0-9.3-0.6-0.2-3.1\n\t\t\t\t\t\tc0,0,1.6-0.3,3.1-0.8C6.2,23.8,4.6,20.1,4.6,16c0-8.3,6.7-15.1,15.1-15.1c7.9,0,14.4,6.1,15,13.8c1.1-2.1,2.4-5,2.4-5.7\n\t\t\t\t\t\tc0.2-1.2,0-4.5-0.4-6.8c0,0-1-2.1-0.4-2.1c0,0,0.6-0.2,0.8,1s1.2,6,1.9,6.6c0,0,2.1-2.5,3.1-6.4C42,1.5,42-0.2,43.1,0z" }),
            React.createElement("text", { className: "text", transform: "matrix(1 0 0 1 9 21)" }, "0.7x")));
    };
    return SlowIcon;
}(Component));
export default SlowIcon;
