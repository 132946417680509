var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GLGlobal } from "gl-commonui";
var AUTOHIDEINTERVAL = 20000;
export default {
    namespace: 'autoplayalert',
    state: {
        show: false,
    },
    reducers: {
        show: function (state, action) {
            return __assign({}, state, { show: action.payload });
        },
    },
    effects: {
        autoShow: function (_, _a) {
            var call = _a.call, put = _a.put, select = _a.select, delay = _a.delay;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, put(autoplayAlertShow(true))];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, delay(AUTOHIDEINTERVAL)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, put(autoplayAlertShow(false))];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        },
    },
};
export function autoplayAlertShow(state) {
    return { type: 'autoplayalert/show', payload: state };
}
export function autoplayAlertAutoShow(state) {
    return { type: 'autoplayalert/autoShow', payload: state };
}
export function showAutoplayAlert(autoHide) {
    GLGlobal.store.dispatch(autoHide ? autoplayAlertAutoShow() : autoplayAlertShow(true));
}
export function hideAutoplayAlert() {
    GLGlobal.store.dispatch(autoplayAlertShow(false));
}
export function isAutoPlayAlertShow() {
    return GLGlobal.store.getState().autoplayalert.show;
}
