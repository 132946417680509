var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { PieceTabType, polyfillNodeList, PuzzleSizeEnum } from "@app/util";
var PuzzleWidth = 1080;
var PuzzleHeight = 432;
var PieceScale = 1;
var BoxPieceScale = 0.5;
export function getPieceKey(key) {
    return Number(key.split('_').pop().split('.')[0]);
}
var PuzzleImageSize = /** @class */ (function () {
    function PuzzleImageSize(_width, _height) {
        this._width = _width;
        this._height = _height;
    }
    Object.defineProperty(PuzzleImageSize.prototype, "width", {
        get: function () {
            return this._width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleImageSize.prototype, "height", {
        get: function () {
            return this._height;
        },
        enumerable: true,
        configurable: true
    });
    return PuzzleImageSize;
}());
export { PuzzleImageSize };
var PieceSize = /** @class */ (function (_super) {
    __extends(PieceSize, _super);
    function PieceSize(width, height) {
        return _super.call(this, width, height) || this;
    }
    return PieceSize;
}(PuzzleImageSize));
export { PieceSize };
var PuzzleTabSize = /** @class */ (function () {
    function PuzzleTabSize(_top, _right, _bottom, _left) {
        this._top = _top;
        this._right = _right;
        this._bottom = _bottom;
        this._left = _left;
    }
    Object.defineProperty(PuzzleTabSize.prototype, "top", {
        get: function () {
            return this._top;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleTabSize.prototype, "right", {
        get: function () {
            return this._right;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleTabSize.prototype, "bottom", {
        get: function () {
            return this._bottom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleTabSize.prototype, "left", {
        get: function () {
            return this._left;
        },
        enumerable: true,
        configurable: true
    });
    return PuzzleTabSize;
}());
export { PuzzleTabSize };
var PieceTabSize = /** @class */ (function (_super) {
    __extends(PieceTabSize, _super);
    function PieceTabSize(top, right, bottom, left) {
        return _super.call(this, top, right, bottom, left) || this;
    }
    return PieceTabSize;
}(PuzzleTabSize));
export { PieceTabSize };
var PieceTabTypePos = /** @class */ (function () {
    function PieceTabTypePos(_top, _right, _bottom, _left) {
        this._top = _top;
        this._right = _right;
        this._bottom = _bottom;
        this._left = _left;
    }
    PieceTabTypePos.prototype.ignoreInside = function (type) {
        return type === PieceTabType.Inside ? PieceTabType.None : type;
    };
    Object.defineProperty(PieceTabTypePos.prototype, "top", {
        get: function () {
            return this.ignoreInside(this._top);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceTabTypePos.prototype, "right", {
        get: function () {
            return this.ignoreInside(this._right);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceTabTypePos.prototype, "bottom", {
        get: function () {
            return this.ignoreInside(this._bottom);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceTabTypePos.prototype, "left", {
        get: function () {
            return this.ignoreInside(this._left);
        },
        enumerable: true,
        configurable: true
    });
    return PieceTabTypePos;
}());
export { PieceTabTypePos };
var PuzzleDom = /** @class */ (function () {
    function PuzzleDom(selector, piece) {
        if (piece === void 0) { piece = null; }
        this.dom = null;
        this.piece = null;
        this.dom = document.querySelector(selector);
        this.piece = piece;
    }
    Object.defineProperty(PuzzleDom.prototype, "width", {
        get: function () {
            return this.dom.clientWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleDom.prototype, "height", {
        get: function () {
            return this.dom.clientHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleDom.prototype, "widthScale", {
        get: function () {
            return this.width / PuzzleWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleDom.prototype, "heightScale", {
        get: function () {
            return this.height / PuzzleHeight;
        },
        enumerable: true,
        configurable: true
    });
    PuzzleDom.prototype.setPiece = function (piece) {
        this.piece = piece;
    };
    PuzzleDom.prototype.setDimension = function (_a) {
        var _this = this;
        var pieceSelector = _a.pieceSelector, _b = _a.scale, scale = _b === void 0 ? BoxPieceScale : _b, _c = _a.withPos, withPos = _c === void 0 ? false : _c, _d = _a.withMaxSize, withMaxSize = _d === void 0 ? false : _d;
        this.piece.refreshLocator();
        var setMaxSize = null;
        if (withMaxSize) {
            setMaxSize = (function () {
                var pieceMaxSize = _this.piece.getMaxPieceSize(scale);
                var width = pieceMaxSize.width + "px";
                var height = pieceMaxSize.height + "px";
                return function (dom) {
                    dom.style.width = width;
                    dom.style.height = height;
                };
            })();
        }
        polyfillNodeList(this.dom.querySelectorAll(pieceSelector))
            .forEach(function (dom) {
            setMaxSize && setMaxSize(dom);
            var imgDom = dom.querySelector('img');
            var position = Number(imgDom.getAttribute('data-identity'));
            var size = _this.piece.getPieceSize(position, scale);
            imgDom.style.width = size.width + "px";
            imgDom.style.height = size.height + "px";
            if (withPos) {
                var point = _this.piece.getPiecePos(position);
                dom.style.top = point.y + "px";
                dom.style.left = point.x + "px";
            }
        });
    };
    return PuzzleDom;
}());
export { PuzzleDom };
var PuzzleBoardDom = /** @class */ (function (_super) {
    __extends(PuzzleBoardDom, _super);
    function PuzzleBoardDom(selector) {
        return _super.call(this, selector) || this;
    }
    return PuzzleBoardDom;
}(PuzzleDom));
export { PuzzleBoardDom };
var PuzzleBoardFrameDom = /** @class */ (function (_super) {
    __extends(PuzzleBoardFrameDom, _super);
    function PuzzleBoardFrameDom(selector) {
        return _super.call(this, selector) || this;
    }
    PuzzleBoardFrameDom.prototype.trackPosition = function (callback) {
        var track = function (ev) {
            callback(new PuzzlePoint(ev.offsetX, ev.offsetY));
        };
        this.dom.addEventListener('click', track);
    };
    return PuzzleBoardFrameDom;
}(PuzzleDom));
export { PuzzleBoardFrameDom };
var PuzzleBoardPieceBoxDom = /** @class */ (function (_super) {
    __extends(PuzzleBoardPieceBoxDom, _super);
    function PuzzleBoardPieceBoxDom(selector, piece) {
        return _super.call(this, selector, piece) || this;
    }
    PuzzleBoardPieceBoxDom.prototype.setDimension = function () {
        _super.prototype.setDimension.call(this, {
            pieceSelector: '.puzzle-playground-board-pieces-box-piece',
            scale: PieceScale,
            withPos: true
        });
    };
    return PuzzleBoardPieceBoxDom;
}(PuzzleDom));
export { PuzzleBoardPieceBoxDom };
var PuzzlePieceBoxDom = /** @class */ (function (_super) {
    __extends(PuzzlePieceBoxDom, _super);
    function PuzzlePieceBoxDom(selector, piece) {
        return _super.call(this, selector, piece) || this;
    }
    PuzzlePieceBoxDom.prototype.setDimension = function () {
        _super.prototype.setDimension.call(this, {
            pieceSelector: '.puzzle-playground-pieces-box-piece',
            withMaxSize: true
        });
    };
    return PuzzlePieceBoxDom;
}(PuzzleDom));
export { PuzzlePieceBoxDom };
var PuzzlePoint = /** @class */ (function () {
    function PuzzlePoint(_x, _y) {
        this._x = _x;
        this._y = _y;
    }
    Object.defineProperty(PuzzlePoint.prototype, "x", {
        get: function () {
            return this._x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzlePoint.prototype, "y", {
        get: function () {
            return this._y;
        },
        enumerable: true,
        configurable: true
    });
    return PuzzlePoint;
}());
export { PuzzlePoint };
var PuzzleRect = /** @class */ (function () {
    function PuzzleRect(_leftTopPoint, _rightBottomPoint) {
        this._leftTopPoint = _leftTopPoint;
        this._rightBottomPoint = _rightBottomPoint;
    }
    Object.defineProperty(PuzzleRect.prototype, "leftTopPoint", {
        get: function () {
            return this._leftTopPoint;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleRect.prototype, "rightBottomPoint", {
        get: function () {
            return this._rightBottomPoint;
        },
        enumerable: true,
        configurable: true
    });
    PuzzleRect.prototype.isPointInRect = function (point) {
        return this.leftTopPoint.x <= point.x
            && this.leftTopPoint.y <= point.y
            && this.rightBottomPoint.x > point.x
            && this.rightBottomPoint.y > point.y;
    };
    return PuzzleRect;
}());
export { PuzzleRect };
var PuzzleDomCoordinate = /** @class */ (function () {
    function PuzzleDomCoordinate(_rect, _coordinates, _position) {
        this._rect = _rect;
        this._coordinates = _coordinates;
        this._position = _position;
    }
    Object.defineProperty(PuzzleDomCoordinate.prototype, "rect", {
        get: function () {
            return this._rect;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleDomCoordinate.prototype, "coordinates", {
        get: function () {
            return this._coordinates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PuzzleDomCoordinate.prototype, "position", {
        get: function () {
            return this._position;
        },
        enumerable: true,
        configurable: true
    });
    return PuzzleDomCoordinate;
}());
export { PuzzleDomCoordinate };
var PuzzleBoardTargetLocator = /** @class */ (function () {
    function PuzzleBoardTargetLocator(boardDom, dimension) {
        this.boardDom = null;
        this.dimension = [];
        this._coordinates = [];
        this.boardDom = boardDom;
        this.dimension = dimension;
    }
    Object.defineProperty(PuzzleBoardTargetLocator.prototype, "coordinates", {
        get: function () {
            return this._coordinates;
        },
        enumerable: true,
        configurable: true
    });
    PuzzleBoardTargetLocator.prototype.setCoordinates = function () {
        var _a = __read(this.dimension, 2), wCount = _a[0], hCount = _a[1];
        var width = this.boardDom.width;
        var height = this.boardDom.height;
        var unitRectWidth = width / wCount;
        var unitRectHeight = height / hCount;
        this._coordinates = new Array(wCount * hCount)
            .fill(0)
            .map(function (_, index) {
            var row = Math.trunc(index / wCount);
            var col = index - row * wCount;
            return new PuzzleDomCoordinate(new PuzzleRect(new PuzzlePoint(col * unitRectWidth, row * unitRectHeight), new PuzzlePoint((col + 1) * unitRectWidth, (row + 1) * unitRectHeight)), [row, col], index + 1);
        });
    };
    PuzzleBoardTargetLocator.prototype.findTargetCoordinate = function (targetPos, coordinates) {
        return coordinates.find(function (e) { return e.rect.isPointInRect(targetPos); });
    };
    PuzzleBoardTargetLocator.prototype.findTarget = function (targetPos) {
        return this.findTargetCoordinate(targetPos, this.coordinates);
    };
    PuzzleBoardTargetLocator.prototype.findTargetPosition = function (targetPos) {
        var coordinate = this.findTarget(targetPos);
        return coordinate ? coordinate.position : Number.NaN;
    };
    return PuzzleBoardTargetLocator;
}());
export { PuzzleBoardTargetLocator };
var PieceDimension = /** @class */ (function () {
    function PieceDimension(boardDom, pieceRoundAsset, piecesAsset, grid, locator) {
        this.boardDom = null;
        this.grid = [];
        this.tabSize = null;
        this.maxPieceDimension = null;
        this.tagTypePosConfig = null;
        this.locator = null;
        var _a = PieceDimension.buildConfig(piecesAsset), tabSize = _a.tabSize, maxPieceDimension = _a.maxPieceDimension, tagTypePosConfig = _a.tagTypePosConfig;
        this.boardDom = boardDom;
        this.grid = grid;
        this.tabSize = tabSize;
        this.maxPieceDimension = maxPieceDimension;
        this.tagTypePosConfig = tagTypePosConfig;
        this.locator = locator;
    }
    Object.defineProperty(PieceDimension.prototype, "puzzleWidth", {
        get: function () {
            return this.boardDom.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceDimension.prototype, "puzzleHeight", {
        get: function () {
            return this.boardDom.height;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceDimension.prototype, "pieceBaseSize", {
        get: function () {
            return this.getPieceBaseSize(this.grid[0], this.grid[1]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceDimension.prototype, "pieceTabSize", {
        get: function () {
            var _a = this.tabSize, top = _a.top, right = _a.right, bottom = _a.bottom, left = _a.left;
            var _b = __read(this.puzzleScale, 2), widthScale = _b[0], heightScale = _b[1];
            return new PieceTabSize(top * heightScale, right * widthScale, bottom * heightScale, left * widthScale);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PieceDimension.prototype, "puzzleScale", {
        get: function () {
            return [this.boardDom.widthScale, this.boardDom.heightScale];
        },
        enumerable: true,
        configurable: true
    });
    PieceDimension.prototype.getTabConfig = function (position) {
        var _a = this.pieceTabSize, top = _a.top, right = _a.right, bottom = _a.bottom, left = _a.left;
        var _b = this.tagTypePosConfig[position], posTop = _b.top, posRight = _b.right, posBottom = _b.bottom, posLeft = _b.left;
        var getSize = function (type, size) {
            return type === PieceTabType.Outside ? size : 0;
        };
        var tabSize = new PieceTabSize(getSize(posTop, top), getSize(posRight, right), getSize(posBottom, bottom), getSize(posLeft, left));
        var offset = [tabSize.left, tabSize.top];
        return { tabSize: tabSize, offset: offset };
    };
    PieceDimension.prototype.getPieceBaseSize = function (wCount, hCount) {
        return new PieceSize(this.puzzleWidth / wCount, this.puzzleHeight / hCount);
    };
    PieceDimension.prototype.refreshLocator = function () {
        this.locator.setCoordinates();
    };
    PieceDimension.prototype.getMaxPieceSize = function (scale) {
        if (scale === void 0) { scale = 1; }
        return new PieceSize((this.pieceBaseSize.width + this.pieceTabSize.left * this.maxPieceDimension.left + this.pieceTabSize.right * this.maxPieceDimension.right) * scale, (this.pieceBaseSize.height + this.pieceTabSize.top * this.maxPieceDimension.top + this.pieceTabSize.bottom * this.maxPieceDimension.bottom) * scale);
    };
    PieceDimension.prototype.getPieceSize = function (position, scale) {
        if (scale === void 0) { scale = 1; }
        var tabSize = this.getTabConfig(position).tabSize;
        return new PieceSize((this.pieceBaseSize.width + tabSize.left + tabSize.right) * scale, (this.pieceBaseSize.height + tabSize.top + tabSize.bottom) * scale);
    };
    PieceDimension.prototype.getPiecePos = function (position) {
        var _a = __read(this.getTabConfig(position).offset, 2), offsetX = _a[0], offsetY = _a[1];
        var coordinate = this.locator.coordinates.find(function (r) { return r.position === position; });
        return new PuzzlePoint(coordinate.rect.leftTopPoint.x - offsetX, coordinate.rect.leftTopPoint.y - offsetY);
    };
    PieceDimension.build = function (pieceRoundAsset, boardDom) {
        var pieceGrid = this.getRowAndCol(pieceRoundAsset.size[0]);
        return new PieceDimension(boardDom, null, pieceRoundAsset, pieceGrid, new PuzzleBoardTargetLocator(boardDom, pieceGrid));
    };
    PieceDimension.buildConfig = function (pieceRoundAsset) {
        var heightAndWidth = this.getHeightAndWidthOfTab(pieceRoundAsset.size[0]);
        var width = heightAndWidth[0] * PuzzleWidth;
        var height = heightAndWidth[1] * PuzzleHeight;
        var tabSize = new PieceTabSize(height, width, height, width);
        var tabTypes = [PieceTabType.None, PieceTabType.None, PieceTabType.None, PieceTabType.None];
        var findTabTypes = function (onePos, otherPos, oneIndex, otherIndex) {
            if (onePos === PieceTabType.Outside && otherPos === PieceTabType.Outside) {
                tabTypes[oneIndex] = PieceTabType.Outside;
                tabTypes[otherIndex] = PieceTabType.Outside;
            }
            else if (onePos === PieceTabType.Outside || otherPos === PieceTabType.Outside) {
                tabTypes[oneIndex] = PieceTabType.Outside;
            }
        };
        pieceRoundAsset.pieces.forEach(function (p) {
            var _a = __read(p.tabTypes, 4), top = _a[0], right = _a[1], bottom = _a[2], left = _a[3];
            findTabTypes(top, bottom, 0, 2);
            findTabTypes(right, left, 1, 3);
        });
        var maxPieceDimension = new PieceTabTypePos(tabTypes[0], tabTypes[1], tabTypes[2], tabTypes[3]);
        var tagTypePosConfig = pieceRoundAsset.pieces
            .map(function (p) { return p; })
            .sort(function (pre, next) { return pre.pieceIndex - next.pieceIndex; })
            .reduce(function (tabCfg, p) { return (tabCfg[p.pieceIndex] = new PieceTabTypePos(p.tabTypes[0], p.tabTypes[1], p.tabTypes[2], p.tabTypes[3]),
            tabCfg); }, {});
        return { tabSize: tabSize, maxPieceDimension: maxPieceDimension, tagTypePosConfig: tagTypePosConfig };
    };
    PieceDimension.getRowAndCol = function (size) {
        switch (size) {
            case PuzzleSizeEnum.p2x2: return [2, 2];
            case PuzzleSizeEnum.p5x2: return [5, 2];
            case PuzzleSizeEnum.p7x3: return [7, 3];
            case PuzzleSizeEnum.p10x4: return [10, 4];
        }
    };
    PieceDimension.getHeightAndWidthOfTab = function (size) {
        switch (size) {
            case PuzzleSizeEnum.p2x2: return [0.125, 0.125];
            case PuzzleSizeEnum.p5x2: return [0.05, 0.125];
            case PuzzleSizeEnum.p7x3: return [0.03518518518518519, 0.08333333333333333];
            case PuzzleSizeEnum.p10x4: return [0.025, 0.0625];
        }
    };
    return PieceDimension;
}());
export { PieceDimension };
