var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from "react";
import { withRouter, GLUtil, maskThrottle, unmaskThrottle, GLGlobal, RoleName } from "gl-commonui";
import { lazyInject, TYPES, userHasRole } from "@app/util";
import UnitCard from "./components/unitcard";
import HeartBeat from "../components/heartbeat";
import PuzzleTray from "./components/puzzletray";
import { chain, chunk } from "lodash";
import { SubscriptionExpired, ExtendedSubscriptionExpiration } from "@app/components/extended-subscription-notice";
var UnitsPage = /** @class */ (function (_super) {
    __extends(UnitsPage, _super);
    function UnitsPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        var from = GLUtil.queryParse().from;
        var userId = _this.props.match.params.userId;
        _this.state = {
            from: from,
            userId: userId,
            versionGroupId: null,
            unit: 0,
            lsVersionGroupId: null,
            lsUnit: 0,
            units: [],
            puzzleUnitAssets: [],
            puzzleList: null
        };
        return _this;
    }
    UnitsPage.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userId, isStudent, promises;
            var _this = this;
            return __generator(this, function (_a) {
                maskThrottle();
                userId = this.state.userId;
                isStudent = userHasRole(RoleName.student);
                promises = [
                    this.studentaccessunitService.getAccessUnit({ id: userId }),
                    this.playlistlogsAppService.getUnitRounds({ userId: userId }),
                ];
                if (!isStudent) {
                    promises.push(this.accessunitService.getAccessUnit({ parentId: userId }));
                }
                Promise.all(promises).then(function (_a) {
                    var _b = __read(_a, 3), stuAccessUnit = _b[0], unitRounds = _b[1], parentAccessUnit = _b[2];
                    if ((stuAccessUnit.versionGroupId && stuAccessUnit.unit)
                        ||
                            (stuAccessUnit.lsVersionGroupId && stuAccessUnit.lsUnit)) {
                        _this.setState({
                            versionGroupId: stuAccessUnit.versionGroupId,
                            unit: stuAccessUnit.unit,
                            lsVersionGroupId: stuAccessUnit.lsVersionGroupId,
                            lsUnit: stuAccessUnit.lsUnit,
                        });
                    }
                    else if (GLGlobal.loginInfo().profile.sub === _this.props.match.params.userId) {
                        _this.setState({
                            versionGroupId: parentAccessUnit.versionGroupId,
                            unit: parentAccessUnit.unit,
                            lsVersionGroupId: parentAccessUnit.lsVersionGroupId,
                            lsUnit: parentAccessUnit.lsUnit,
                        });
                    }
                    else {
                        unmaskThrottle();
                        return;
                    }
                    var _c = _this.state, units = _c.units, unit = _c.unit, lsUnit = _c.lsUnit;
                    if (lsUnit && lsUnit < 0) {
                        var lsVersionGroupId = _this.state.lsVersionGroupId;
                        for (var index = -1; index >= lsUnit; index--) {
                            units.push({ id: index, index: Math.abs(index), name: "LittleSEED " + Math.abs(index), versionId: lsVersionGroupId });
                        }
                    }
                    if (unit && unit > 0) {
                        var versionGroupId = _this.state.versionGroupId;
                        var _loop_1 = function (index) {
                            var unitRound = unitRounds ? unitRounds.find(function (log) { return log.unit == index; }) : null;
                            units.push({ id: index, index: index, name: "Unit " + index, versionId: versionGroupId, percentage: 0, round: unitRound ? unitRound.round : 0 });
                        };
                        for (var index = 1; index <= unit; index++) {
                            _loop_1(index);
                        }
                    }
                    var roundUnits = unitRounds && unitRounds.length ? { units: unitRounds.map(function (log) { return log.unit; }).sort(function (left, right) { return left - right; }) } : null;
                    if (roundUnits) {
                        Promise.all([
                            _this.puzzleService.getSAS(),
                            _this.puzzleService.getCompletePuzzleList(userId)
                        ]).then(function (_a) {
                            var _b = __read(_a, 2), sas = _b[0], puzzleList = _b[1];
                            var groupedMasterPuzzles = chain(puzzleList.puzzles)
                                .groupBy("masterPuzzleId")
                                .map(function (key, val) { return ({ masterPuzzleId: val, puzzleInfo: key }); })
                                .value();
                            var finalPuzzles = groupedMasterPuzzles.map(function (puzzle) {
                                return puzzle.puzzleInfo.length >= 2 ? puzzle.puzzleInfo.find(function (puzzle) { return puzzle.isColor; }) : puzzle.puzzleInfo[0];
                            });
                            var sortedFinalPuzzles = finalPuzzles.sort(function (firstPuzzle, secondPuzzle) { return firstPuzzle.sequence - secondPuzzle.sequence; });
                            _this.setState({
                                units: units,
                                puzzleList: sortedFinalPuzzles.map(function (puzzle) {
                                    puzzle.thumbnailPath = "" + puzzleList.endPoint + puzzle.thumbnailPath + sas;
                                    return puzzle;
                                })
                            });
                            unmaskThrottle();
                        });
                    }
                    else {
                        _this.setState({
                            units: units
                        });
                        unmaskThrottle();
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    UnitsPage.prototype.render = function () {
        var _this = this;
        var _a = this.state, from = _a.from, units = _a.units, puzzleList = _a.puzzleList, versionGroupId = _a.versionGroupId;
        var history = this.props.history;
        return React.createElement("div", { className: "units" },
            React.createElement(ExtendedSubscriptionExpiration, null),
            React.createElement(SubscriptionExpired, null),
            React.createElement(HeartBeat, { userId: this.state.userId },
                React.createElement("div", { className: "box" },
                    React.createElement("div", { className: "cards" }, units && units.length > 0 && units.map(function (unit) { return (React.createElement("div", { key: "base_" + unit.id, className: "blinkable" },
                        React.createElement(UnitCard, { key: unit.id, history: history, to: from, userId: _this.state.userId, unitInfo: unit }))); })),
                    puzzleList && puzzleList.length > 0 ?
                        React.createElement("div", { className: "puzzletray" }, chunk(puzzleList, 2).map(function (m, index) {
                            return React.createElement(PuzzleTray, { key: index, history: history, to: from, userId: _this.state.userId, versionId: versionGroupId, puzzleList: m });
                        })) : null)));
    };
    __decorate([
        lazyInject(TYPES.IAccessunitService)
    ], UnitsPage.prototype, "accessunitService", void 0);
    __decorate([
        lazyInject(TYPES.IStudentaccessunitService)
    ], UnitsPage.prototype, "studentaccessunitService", void 0);
    __decorate([
        lazyInject(TYPES.IPuzzleService)
    ], UnitsPage.prototype, "puzzleService", void 0);
    __decorate([
        lazyInject(TYPES.IPlaylistlogsAppService)
    ], UnitsPage.prototype, "playlistlogsAppService", void 0);
    UnitsPage = __decorate([
        withRouter
    ], UnitsPage);
    return UnitsPage;
}(Component));
export default UnitsPage;
