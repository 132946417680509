var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { StudentLocale } from "@app/locales/localeid";
import { formatRegionalUrl, GLGlobal, withRouter } from "gl-commonui";
import { NotificationDescription, NotificationTitleItem } from "./notification-item";
import { isHtml, lazyInject, parseUrlToAnchor, TYPES } from "@app/util";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { NotificationChannel } from "@app/util/enum";
import { Icon, List } from "antd";
var Notifications = /** @class */ (function (_super) {
    __extends(Notifications, _super);
    function Notifications(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.limitation = 5;
        _this.fmt = GLGlobal.intl.formatMessage;
        _this.wrapperRef = React.createRef();
        _this.redirectToNotificationsPage = function () {
            var notificationPageUrl = process.env.portals["parent"] + "/notifications";
            var parsedNotificationPageUrl = formatRegionalUrl(notificationPageUrl);
            window.open(parsedNotificationPageUrl, "_blank");
        };
        _this.state = {
            loading: false,
            notifications: []
        };
        _this.handleClickOutside = _this.handleClickOutside.bind(_this);
        return _this;
    }
    Notifications.prototype.componentDidMount = function () {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.getNotifications();
    };
    Notifications.prototype.componentWillUnmount = function () {
        document.removeEventListener("mousedown", this.handleClickOutside);
    };
    Notifications.prototype.handleClickOutside = function (event) {
        if (this.wrapperRef &&
            event.target.getAttribute("id") !== "notification-icon-header" &&
            !this.wrapperRef.current.contains(event.target)) {
            this.props.onHide();
        }
    };
    Notifications.prototype.getNotifications = function () {
        var _this = this;
        this.setState({ loading: true });
        this.service
            .getItemsBy({
            orderBy: "sticky",
            offset: 0,
            limit: this.limitation,
            hideArchived: true,
            channel: [
                NotificationChannel.BulletinBoard,
                NotificationChannel.BulletinBoardAndEmail
            ]
        })
            .then(function (response) {
            var data = response.data;
            _this.setState({
                notifications: data.map(function (d) {
                    d.title = parseUrlToAnchor(d.title);
                    d.description = isHtml(d.description)
                        ? d.description
                        : parseUrlToAnchor(d.description);
                    return d;
                })
            });
        })
            .finally(function () {
            _this.setState({ loading: false });
        });
    };
    Notifications.prototype.clickMarkAllAsRead = function () {
        var _this = this;
        this.service.markAsReadForAll().then(function () {
            _this.getNotifications();
            _this.props.onGetNumUnread();
        });
    };
    Notifications.prototype.clickClearAll = function () {
        var _this = this;
        this.service.markAsArchived().then(function () {
            _this.getNotifications();
            _this.props.onGetNumUnread();
        });
    };
    Notifications.prototype.markOneAsRead = function (seen, id) {
        var _this = this;
        if (!seen) {
            this.service.markAsReadForOne(id).then(function () {
                _this.getNotifications();
                _this.props.onGetNumUnread();
            });
        }
    };
    Notifications.prototype.clearOne = function (id) {
        var _this = this;
        this.service.createArchived(id).then(function () {
            _this.getNotifications();
            _this.props.onGetNumUnread();
        });
    };
    Notifications.prototype.render = function () {
        var _this = this;
        var ArrowRightIcon = function () {
            return (React.createElement("i", { "aria-label": "icon: arrow-right", className: "anticon anticon-arrow-right" },
                React.createElement("svg", { viewBox: "64 64 896 896", "data-icon": "arrow-right", width: "1em", height: "1em", fill: "currentColor", "aria-hidden": "true", focusable: "false" },
                    React.createElement("path", { d: "M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z" }))));
        };
        return (React.createElement("div", { ref: this.wrapperRef, className: "header-notification-container" },
            React.createElement("div", { className: "header-notification-function" },
                React.createElement("span", null,
                    React.createElement("a", { href: "javascript:void(0)", onClick: function () { return _this.clickClearAll(); } },
                        this.fmt({
                            id: StudentLocale.NotificationClearAll
                        }),
                        " ",
                        React.createElement(ArrowRightIcon, null))),
                React.createElement("span", null,
                    React.createElement("a", { href: "javascript:void(0)", onClick: function () { return _this.clickMarkAllAsRead(); } },
                        this.fmt({
                            id: StudentLocale.NotificationMarkAllAsRead
                        }),
                        " ",
                        React.createElement(Icon, { type: "check" })))),
            React.createElement("div", { className: "header-notification-list" },
                React.createElement(List, { itemLayout: "horizontal", dataSource: this.state.notifications, loading: this.state.loading, locale: {
                        emptyText: this.fmt({
                            id: StudentLocale.NotificationsNoData
                        })
                    }, renderItem: function (_a) {
                        var title = _a.title, description = _a.description, type = _a.type, id = _a.id, startDate = _a.startDate, endDate = _a.endDate, sticky = _a.sticky, archived = _a.archived, seen = _a.seen, isAdvanced = _a.isAdvanced;
                        return (React.createElement(List.Item, null,
                            React.createElement(List.Item.Meta, { title: React.createElement(NotificationTitleItem, { item: {
                                        id: id,
                                        title: title,
                                        startDate: startDate,
                                        description: description,
                                        sticky: sticky,
                                        archived: archived,
                                        seen: seen
                                    }, hasPopover: true, onMarkOneAsRead: function (id) {
                                        _this.markOneAsRead(seen, id);
                                    }, onClearOne: function (id) {
                                        _this.clearOne(id);
                                    } }), description: !isAdvanced && React.createElement("div", { onClick: function () {
                                        return _this.redirectToNotificationsPage();
                                    } },
                                    React.createElement(NotificationDescription, { content: description })) })));
                    } })),
            React.createElement("div", { style: {
                    textAlign: "center"
                } },
                React.createElement("a", { href: "javascript:void(0)", onClick: function () {
                        return _this.redirectToNotificationsPage();
                    } },
                    React.createElement(FormattedMessage, { id: StudentLocale.NotificationsShowMore })))));
    };
    __decorate([
        lazyInject(TYPES.INotificationService)
    ], Notifications.prototype, "service", void 0);
    Notifications = __decorate([
        withRouter
    ], Notifications);
    return Notifications;
}(Component));
export { Notifications };
