import { getPuzzleMedias } from "@app/util";
var PuzzleAudioManager = /** @class */ (function () {
    function PuzzleAudioManager() {
        this.audios = {};
    }
    PuzzleAudioManager.prototype.preLoad = function () {
        var _this = this;
        Object.keys(PuzzleAudioType).filter(function (e) { return Number.isNaN(parseInt(e)); })
            .forEach(function (e) {
            _this.audios[e] = new AudioWrapper(getPuzzleMedias()[e].uri);
        });
    };
    PuzzleAudioManager.prototype.play = function (type) {
        return this.audios[PuzzleAudioType[type]].play();
    };
    return PuzzleAudioManager;
}());
export { PuzzleAudioManager };
var AudioWrapper = /** @class */ (function () {
    function AudioWrapper(url) {
        var _this = this;
        this.element = null;
        this.ready = null;
        this.element = new Audio(url);
        this.element.load();
        this.ready = new Promise(function (resolve, reject) {
            _this.element.addEventListener("canplaythrough", function (ev) {
                resolve(true);
            });
            _this.element.addEventListener('error', function (ev) {
                console.log("puzzle audio: " + ev.message);
                reject(ev);
            });
        });
    }
    AudioWrapper.prototype.play = function () {
        var _this = this;
        return this.ready.then(function (_) {
            return _this.element.play();
        });
    };
    return AudioWrapper;
}());
export { AudioWrapper };
export var PuzzleAudioType;
(function (PuzzleAudioType) {
    PuzzleAudioType[PuzzleAudioType["FinishPuzzle"] = 0] = "FinishPuzzle";
    PuzzleAudioType[PuzzleAudioType["ClickHammer"] = 1] = "ClickHammer";
    PuzzleAudioType[PuzzleAudioType["SelectBrick2x2"] = 2] = "SelectBrick2x2";
    PuzzleAudioType[PuzzleAudioType["SelectBrick5x2"] = 3] = "SelectBrick5x2";
    PuzzleAudioType[PuzzleAudioType["SelectBrick7x3"] = 4] = "SelectBrick7x3";
    PuzzleAudioType[PuzzleAudioType["SelectBrick10x4"] = 5] = "SelectBrick10x4";
    PuzzleAudioType[PuzzleAudioType["SnapCorrect01"] = 6] = "SnapCorrect01";
    PuzzleAudioType[PuzzleAudioType["SnapCorrect02"] = 7] = "SnapCorrect02";
    PuzzleAudioType[PuzzleAudioType["SnapCorrect03"] = 8] = "SnapCorrect03";
    PuzzleAudioType[PuzzleAudioType["OOPS2x2"] = 9] = "OOPS2x2";
    PuzzleAudioType[PuzzleAudioType["OOPS5x2"] = 10] = "OOPS5x2";
    PuzzleAudioType[PuzzleAudioType["OOPS7x3"] = 11] = "OOPS7x3";
    PuzzleAudioType[PuzzleAudioType["OOPS10x4"] = 12] = "OOPS10x4";
})(PuzzleAudioType || (PuzzleAudioType = {}));
