var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from "react";
import { CellRange, CellRangeEventArgs } from "wijmo/wijmo.grid";
import { FlexGridCellTemplate, FlexGridColumn } from "wijmo/wijmo.react.grid";
import { GridCellTemplateType } from "wijmo/wijmo.interop.grid";
import { isFunction } from "lodash";
export var Column = function (props) {
    var render = props.render, binding = props.binding, renderHeader = props.renderHeader, renderEditor = props.renderEditor, validators = props.validators, cellDisableHandler = props.cellDisableHandler, rest = __rest(props, ["render", "binding", "renderHeader", "renderEditor", "validators", "cellDisableHandler"]);
    var renderCell = function (context) {
        var grid = context.row.grid;
        if (grid && cellDisableHandler && isFunction(cellDisableHandler)) {
            var event = new CellRangeEventArgs(context.col.grid.cells, new CellRange(context.row.index, context.col.index));
            cellDisableHandler(context.row.grid, event, context.item);
            context.isDisabled = event.cancel;
        }
        return render(context.item[binding], context.item, context);
    };
    var renderCellEditor = function (context) { return renderEditor(context, context.item[binding], context.item); };
    var renderColumnHeader = function (context) {
        var currentSort = context.col.currentSort;
        return (React.createElement(React.Fragment, null,
            renderHeader(),
            renderSortIcon(currentSort)));
    };
    var renderSortIcon = function (sort) {
        if (sort === "+") {
            return React.createElement("span", { className: "wj-glyph-up" });
        }
        else if (sort === "-") {
            return React.createElement("span", { className: "wj-glyph-down" });
        }
        return null;
    };
    return (React.createElement(FlexGridColumn, __assign({ binding: binding }, rest),
        renderHeader && React.createElement(FlexGridCellTemplate, { cellType: GridCellTemplateType.ColumnHeader, template: renderColumnHeader }),
        render && React.createElement(FlexGridCellTemplate, { cellType: GridCellTemplateType.Cell, template: renderCell }),
        renderEditor && React.createElement(FlexGridCellTemplate, { cellType: GridCellTemplateType.CellEdit, template: renderCellEditor })));
};
Column.defaultProps = {
    isReadOnly: true,
    header: " ",
    binding: null,
};
