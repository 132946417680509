var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { injectable } from '../../util/di';
import VerificationServiceBase from '../app/servicebase/verificationservicebase';
import { getSASCache, setSASCache } from '@app/util';
var PuzzleService = /** @class */ (function (_super) {
    __extends(PuzzleService, _super);
    function PuzzleService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.serviceRoute = { prefix: 'content/v1', suffix: '' };
        return _this;
    }
    PuzzleService.prototype.getPlaylistAssets = function (params) {
        return this.get('puzzles/units/{unit}/playlists/assets/v3', params, params);
    };
    PuzzleService.prototype.getUnitAssets = function (params) {
        return this.get('puzzles/units/assets/v3', params);
    };
    PuzzleService.prototype.getPuzzlePieceAssets = function (params) {
        return this.get('puzzles/units/{unit}/v3', null, params);
    };
    PuzzleService.prototype.getSAS = function () {
        var sas = getSASCache("gl-puzzle-sas");
        if (!!sas) {
            return Promise.resolve(sas);
        }
        return this.get('puzzles/signature/v2')
            .then(function (sas) {
            setSASCache("gl-puzzle-sas", sas);
            return Promise.resolve(sas);
        });
    };
    PuzzleService.prototype.getStudentPuzzlePieceInfo = function (params) {
        return this.get("puzzles/studentpuzzlepiece/{studentId}/{unitId}/{round}", params);
    };
    PuzzleService.prototype.generateStudentPuzzleSequence = function (params) {
        return this.create("puzzles/generate/studentpuzzlepiece", params);
    };
    PuzzleService.prototype.getCompletePuzzleList = function (params) {
        return this.get("puzzles/{studentId}", null, { studentId: params });
    };
    PuzzleService.prototype.getPuzzles = function (params, studentId) {
        return this.create("puzzles?studentId=" + studentId, params);
    };
    PuzzleService.prototype.isStudentPuzzleUnlocked = function (params) {
        return this.get("puzzles/{puzzleId}/student/{studentId}", null, params);
    };
    PuzzleService = __decorate([
        injectable()
    ], PuzzleService);
    return PuzzleService;
}(VerificationServiceBase));
export { PuzzleService };
