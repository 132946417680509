var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { Component } from "react";
import MediaPlayer, { MediaType, MediaExtType, MediaStreamType, SubContentPos } from "./playlistmediaplayer";
import ResourcesCache from "@app/util/resources-cache";
import { lazyInject, TYPES, ContentGroupEnum as ContentGroup, guidEmpty, getCorrectAnswer, getStartPlaylist, isMobileOrTablet, isIE, isFirefox, polyfillComp, PolyfillCompKey, MediaSlotEnum, ContentGroupEnum, REPLessonPlanItemType, SkillType, Constants } from "@app/util";
import { isSignatureChanged } from "@app/states/signature";
import { connect } from "gl-commonui";
import swyk from "@app/style/images/question/swyk.jpg";
import swyk_blank from "@app/style/images/question/swyk_blank_1024x575.jpg";
import playlist_logo from "@app/style/images/playlist_logo_1024x575.png";
import AutoPlayAlert from "./autoplayalert";
import { isPrivateWindow } from "@app/util/isPrivate";
import { random } from "./playlistmediaplayer_support";
import { cloneDeep, entries, groupBy } from "lodash";
var PRELOADTHRESHOLD = 3;
var ContentPlayer = /** @class */ (function (_super) {
    __extends(ContentPlayer, _super);
    function ContentPlayer(props) {
        var _this = _super.call(this, props) || this;
        _this.getReaderContentsGroupByParentId = function (contents) {
            var readerContents = contents.filter(function (content) { return content.skillTypeId && content.parentContentId !== null; });
            var groupedReaderContents = groupBy(readerContents, "parentContentId");
            return entries(groupedReaderContents).map(function (item) { return item; });
        };
        _this.reBuildPlaylistContents = function (contents) {
            if (contents.length) {
                var newContents_1 = cloneDeep(contents);
                newContents_1 = newContents_1.filter(function (content) { return !content.skillTypeId || content.contentGroup === ContentGroupEnum.ConversationIntroVideo; });
                newContents_1.forEach(function (newContent) {
                    newContent.readerContents = null;
                });
                var readerContents = _this.getReaderContentsGroupByParentId(contents);
                readerContents.forEach(function (readerContent) {
                    // find index of the first item in the grouped items and insert at that position
                    var insertionIndex = contents.findIndex(function (content) { return content.id === readerContent[1][0].id; });
                    var readerPlaylistContent = {
                        contentGroup: ContentGroupEnum.None,
                        focusWordDefinition: null,
                        focusWordText: null,
                        id: readerContent[1][0].parentContentId,
                        medias: [],
                        name: readerContent[1][0].parentContentName,
                        pages: [],
                        parentContentId: readerContent[0],
                        sequence: null,
                        skillTypeId: SkillType.Content,
                        thumbnailUrl: readerContent[1][0].thumbnailURL,
                        updateTime: readerContent[1][0].thumbnailURL,
                        readerContents: readerContent[1]
                    };
                    // remove chapter/contentchapter if there are no pages to display.
                    readerPlaylistContent.readerContents = readerPlaylistContent.readerContents
                        .filter(function (c) { return c.skillTypeId === SkillType.FocusWord ||
                        ((c.skillTypeId === SkillType.Content || c.skillTypeId === SkillType.ContentChapter) && c.pages.length); });
                    newContents_1.splice(insertionIndex, 0, readerPlaylistContent);
                });
                return newContents_1;
            }
        };
        _this.state = {
            resources: { contents: [] },
            remainingCount: 0,
            total: 0,
            isPaused: true,
            loaded: false,
            content: {},
            times: { time: 0, duration: 0, last: 0, lastTime: '' },
        };
        return _this;
    }
    ContentPlayer.prototype.componentDidMount = function () {
        polyfillComp(PolyfillCompKey.ContentPlayer | PolyfillCompKey.PlaylistAudio);
        this.resourcesCache = new ResourcesCache();
        var resources = this.props.resources;
        if (resources
            && resources.playlists
            && resources.playlists.length > 0
            && resources.contents
            && resources.contents.length > 0) {
            this.formatResources(this.props);
        }
    };
    ContentPlayer.prototype.componentWillReceiveProps = function (_a) {
        var signature = _a.signature, resources = _a.resources, playlistId = _a.playlistId, onLoaded = _a.onLoaded, stream = _a.stream, streamPreload = _a.streamPreload, startIndex = _a.startIndex, mySetting = _a.mySetting, onPlaybackRateChange = _a.onPlaybackRateChange;
        if (this.props.resources !== resources) {
            this.formatResources({ signature: signature, resources: resources, playlistId: playlistId, onLoaded: onLoaded, stream: stream, streamPreload: streamPreload, startIndex: startIndex, mySetting: mySetting, onPlaybackRateChange: onPlaybackRateChange });
        }
        if (isSignatureChanged(this.props.signature, signature)) {
            this.updateSignature(resources, stream, signature);
        }
    };
    ContentPlayer.prototype.updateSignature = function (resources, stream, signature) {
        var changeSignature = function (signature) { return function (m) { return (m.url = replaceSig(m.url, signature)); }; };
        var changeMediaPageSig = function (content) {
            content.pages && content.pages.forEach(changeSignature(signature.pageContainer));
            content.medias
                && content.medias.filter(function (m) { return m.mediaType === MediaExtType.MP3; }).forEach(changeSignature(signature.audioContainer));
        };
        var needMediaPageSig = function (callback) { return function (content) { return content.description.needSigs && content.description.needSigs.media !== false && content.description.needSigs.page !== false && callback(content); }; };
        resources.contents.forEach(function (content) {
            if (content.description && content.description.mediaType === MediaType.video) {
                content.medias.filter(function (m) { return m.mediaType === MediaExtType.MP4; }).forEach(changeSignature(signature.videoContainer));
            }
            else if (content.contentGroup === ContentGroup.Question) {
                changeMediaPageSig(content);
                content.description.subContentPrefix.forEach(needMediaPageSig(changeMediaPageSig));
                content.description.subContents.contents.forEach(changeMediaPageSig);
                if (content.description.exts) {
                    var contents = content.description.exts.contents;
                    contents.answer.forEach(needMediaPageSig(changeMediaPageSig));
                    contents.candidators.forEach(changeMediaPageSig);
                    contents.question.forEach(changeMediaPageSig);
                    var question = content.description.exts.question;
                    question.questionPreAudioURL = replaceSig(question.questionPreAudioURL, signature.audioContainer);
                    question.questionPostAudioURL = replaceSig(question.questionPostAudioURL, signature.audioContainer);
                    question.answer.audioURL = replaceSig(question.answer.audioURL, signature.audioContainer);
                    question.answer.thumbnailURL = replaceSig(question.answer.thumbnailURL, signature.pageContainer);
                    question.candidator.forEach(function (candidator) {
                        candidator.audioURL = replaceSig(candidator.audioURL, signature.audioContainer);
                        candidator.thumbnailURL = replaceSig(candidator.thumbnailURL, signature.pageContainer);
                    });
                }
            }
            else if (stream) {
                changeMediaPageSig(content);
            }
        });
    };
    ContentPlayer.prototype.formatResources = function (_a) {
        var _this = this;
        var signature = _a.signature, resources = _a.resources, playlistId = _a.playlistId, onLoaded = _a.onLoaded, stream = _a.stream, streamPreload = _a.streamPreload, startIndex = _a.startIndex, mySetting = _a.mySetting, onPlaybackRateChange = _a.onPlaybackRateChange;
        // remove thumbnail type resources
        resources.contents.forEach(function (f) {
            // apply filter only on content which are not readercontent's part.
            if (!f.skillTypeId && f.medias) {
                f.medias = f.medias.filter(function (f) { return f.mediaType !== MediaExtType.PNG; });
            }
        });
        // Find the playlist. If playlistId is provided, then play that, else play first
        var playlist = playlistId ? resources.playlists.find(function (pl) { return pl.id === playlistId; }) : resources.playlists[0];
        var questionSequence = playlist.questions;
        var modifiableGroups = [ContentGroup.Story, ContentGroup.Song, ContentGroup.Movie];
        resources.contents = playlist.contents.reduce(function (contents, playlistContent) {
            var neededContent = resources.contents.filter(function (content) { return content.id === playlistContent.id; });
            if (!neededContent.length) {
                // In case of question, content will not be there
                return contents;
            }
            var contentToModify = cloneDeep(neededContent[0]);
            if (playlistContent.type === REPLessonPlanItemType.Conversation) {
                contentToModify.contentGroup = ContentGroupEnum.Conversation;
                contentToModify.conversationId = playlistContent.intId;
                contents.push(contentToModify);
                return contents;
            }
            if (playlistContent.type === REPLessonPlanItemType.Reader && contentToModify.skillTypeId) {
                var currentContentIds = contents.map(function (c) { return c.id; });
                // TODO: Uncomment if word can be used only once.
                // if (includes(currentContentIds, contentToModify.id)) {
                //     const currentCountOfElement = contents.filter(c => c.id === contentToModify.id).length;
                //     contentToModify = neededContent[currentCountOfElement];
                // }
                if (contentToModify) {
                    contents.push(contentToModify);
                }
                return contents;
            }
            // Remove medias which are not Video or Audio
            contentToModify.medias = contentToModify.medias.filter(function (m) { return m.mediaType === MediaExtType.MP3 || m.mediaType === MediaExtType.MP4 || m.mediaSlotId === MediaSlotEnum.Image; });
            // Sort Pages
            contentToModify.pages = contentToModify.pages.sort(function (pre, cur) { return pre.sequence - cur.sequence; });
            // Adjust 'Story', 'Song', 'Movie', ContentGroup, according to exposure's MediaSlotId.
            // Content Without MediaSlot will not be adjusted.
            if (modifiableGroups.some(function (s) { return s === contentToModify.contentGroup; }) && typeof playlistContent.mediaSlotId === "number") {
                // Update group according to MediaSlot
                if (playlistContent.mediaSlotId === MediaSlotEnum.ExposureAudio) {
                    contentToModify.medias = contentToModify.medias.filter(function (m) { return m.mediaType === MediaExtType.MP3; });
                    contentToModify.contentGroup = ContentGroup.Song;
                }
                else if (playlistContent.mediaSlotId === MediaSlotEnum.ExposureVideo) {
                    contentToModify.medias = contentToModify.medias.filter(function (m) { return m.mediaType === MediaExtType.MP4; });
                    contentToModify.contentGroup = ContentGroup.Movie;
                }
            }
            else if (typeof playlistContent.mediaSlotId !== "number") {
                // keep the data according to group
                var keepMedia_1 = (contentToModify.contentGroup === ContentGroupEnum.Movie || contentToModify.contentGroup === ContentGroupEnum.ConversationIntroVideo) ? MediaExtType.MP4 : MediaExtType.MP3;
                contentToModify.medias = contentToModify.medias.filter(function (m) { return m.mediaType === keepMedia_1; });
            }
            contents.push(contentToModify);
            return contents;
        }, []).map(function (content, index) { return (content.sequence = index, content); });
        // grouping reader items and inserting back into content's list to be played in playlist.
        resources.contents = this.reBuildPlaylistContents(resources.contents);
        var hasQuestions = function () { return resources.questions && resources.questions.length && questionSequence && questionSequence.length; };
        hasQuestions() && this.setQuestions(resources, questionSequence, signature);
        resources.contents = resources.contents.filter(function (c) {
            return c &&
                (c.skillTypeId !== null ||
                    c.contentGroup === ContentGroup.Question ||
                    c.contentGroup === ContentGroup.Conversation ||
                    (Array.isArray(c.medias) && c.medias.length));
        });
        if (resources && resources.contents && Number(startIndex) < resources.contents.length) {
            var isConversation = resources.contents[startIndex].contentGroup === ContentGroupEnum.Conversation ||
                resources.contents[startIndex].contentGroup === ContentGroupEnum.ConversationIntroVideo;
            if (isConversation) {
                var playbackRate = mySetting.appSettings.slowSpeed ? Constants.ConversationSlowModePlaybackRate : 1;
                onPlaybackRateChange(playbackRate);
            }
            else {
                var playbackRate = mySetting.appSettings.slowSpeed ? 0.7 : 1;
                onPlaybackRateChange(playbackRate);
            }
        }
        // Update the content sequence after filtering.
        resources.contents = resources.contents.map(function (m, index) {
            m.sequence = index;
            return m;
        });
        this.setState({ total: resources.contents.length, loaded: false });
        var formatMedia = function (updateTime) { return function (signature) { return function (m) {
            var src = replaceSig(m.url, signature);
            m.url = src;
            m.src = src;
            m.updateTime = updateTime;
            return m;
        }; }; };
        var cacheResources = resources.contents.reduce(function (resources, content) {
            var replaceSignatureUrl = formatMedia(content.updateTime);
            switch (content.contentGroup) {
                case ContentGroup.VPC:
                case ContentGroup.Story:
                case ContentGroup.Song:
                    return resources
                        .concat(content.pages.map(replaceSignatureUrl(signature.pageContainer)))
                        .concat(content.medias.filter(function (f) { return f.mediaType === MediaExtType.MP3; })
                        .map(replaceSignatureUrl(signature.audioContainer)));
                case ContentGroup.Movie:
                    return resources
                        .concat(content.medias.filter(function (f) { return f.mediaType === MediaExtType.MP4; }).map(replaceSignatureUrl(signature.videoContainer)));
                case ContentGroup.Question:
                    if (hasNextContents(content)) {
                        var formatQuestion = function (contents) { return contents.map(function (content) {
                            return {
                                id: content.id,
                                url: content.thumbnailUrl,
                                src: content.thumbnailUrl,
                                updateTime: '2019-07-15 00:00:00',
                                content: content,
                                contentGroup: ContentGroup.Question,
                            };
                        }); };
                        var candidators = formatQuestion(getCandidators(content));
                        var answers = formatQuestion(getAnswer(content));
                        return resources.concat(candidators.concat(answers[answers.length - 1]));
                    }
                    return resources;
                default:
                    return resources;
            }
        }, []);
        var resourcesLoaded = function (promises) { return Promise
            .all(promises)
            .then(function (_) {
            var setResource = function (audios) {
                _this.setVPCBackgroundAudio(resources.contents, audios, _this.props.swyks.map(function (swyk) { return swyk.src; }));
                _this.setSequencePrefix(resources.contents, _this.props.startIndex);
                _this.setState({ resources: resources, loaded: true });
                onLoaded && onLoaded(true);
            };
            var bkAudiosPromises = _this.props.backgroundAudios.map(function (audio) { return _this.resourcesCache.getUrl(audio); });
            Promise.all(bkAudiosPromises).then(function (audios) {
                setResource(audios);
            }).catch(function (_) {
                setResource(_this.props.backgroundAudios.map(function (audio) { return audio.src; }));
            });
        }); };
        isPrivateWindow(function (isPrivate) {
            if (!stream && !isMobileOrTablet() && !isIE() && (isFirefox && !isPrivate) && _this.resourcesCache.isSupport()) {
                cacheResources = cacheResources.filter(function (c) { return c.mediaType !== MediaExtType.MP4; });
                _this.resourcesCache.init().then(function (_) {
                    var replaceCacheUrl = function (m) { return _this.resourcesCache
                        .getCachedUrl(m)
                        .then(function (url) {
                        if (m.contentGroup === ContentGroup.Question) {
                            m.content.thumbnailUrl = url;
                        }
                        m.url = url;
                        return Promise.resolve(true);
                    }); };
                    var caches = function () {
                        var preCached = cacheResources.splice(0, PRELOADTHRESHOLD);
                        return _this.resourcesCache.caches(preCached)
                            .then(function () { return Promise.all(preCached.map(function (media) { return replaceCacheUrl(media); })); })
                            .then(function () { return _this.resourcesCache.restoreVersions(); });
                    };
                    var preLoad = function () {
                        if (cacheResources.length === 0) {
                            return Promise.resolve(true);
                        }
                        return caches().then(preLoad);
                    };
                    var preCaches = caches();
                    var preLoadVideo = _this.preloadVideoUrl(resources.contents);
                    var promises = resources.contents.reduce(function (promises, content) {
                        return promises.concat(_this.formatContent(content, {
                            type: MediaStreamType.m3u8,
                            setAudioUrl: function () { return preCaches; },
                            setVideoUrl: function () { return preLoadVideo; },
                        }));
                    }, []);
                    resourcesLoaded(promises).then(preLoad);
                });
            }
            else {
                var preLoadImages_1 = streamPreload === false ? [] : _this.streamPreloadImage(resources.contents);
                var preLoadVideo_1 = _this.preloadVideoUrl(resources.contents);
                var promises = resources.contents.reduce(function (promises, content) {
                    return promises.concat(_this.formatContent(content, {
                        type: MediaStreamType.m3u8,
                        setAudioUrl: function () { return preLoadImages_1.splice(0, PRELOADTHRESHOLD).map(function (p) { return p(); }); },
                        setVideoUrl: function () { return preLoadVideo_1; },
                    }));
                }, []);
                var preLoad_1 = function () {
                    if (preLoadImages_1.length === 0)
                        return;
                    Promise
                        .all(preLoadImages_1
                        .splice(0, PRELOADTHRESHOLD)
                        .map(function (p) { return p(); }))
                        .then(preLoad_1);
                };
                resourcesLoaded(promises).then(preLoad_1);
            }
        });
    };
    ContentPlayer.prototype.preloadVideoUrl = function (contents) {
        return this.resourceService.getVideoStreams({
            contentIds: contents.filter(function (f) { return f.contentGroup === ContentGroup.Movie; }) // To make sure only Movie's streaming is loaded
                .map(function (content) { return content.id; })
        })
            .then(function (streams) {
            contents.forEach(function (content) {
                var stream = streams.find(function (stream) { return stream.contentId === content.id; });
                if (stream) {
                    content.medias.forEach(function (m) { return m.url = stream.mediaUrl; });
                }
            });
            return Promise.resolve(true);
        });
    };
    ContentPlayer.prototype.setSequencePrefix = function (contents, id) {
        var _this = this;
        if (typeof id === 'number') {
            var targetContent = contents.find(function (c) { return c.sequence === id; });
            id = targetContent ? targetContent.id : undefined;
        }
        var setPrefix = function (content) { return (content.description.contentPrefix = [
            _this.getInitContent({
                subContentPos: null,
                contentPrefixPos: SubContentPos.start,
                mediaUrl: getStartPlaylist(),
                pageUrl: [
                    {
                        url: playlist_logo,
                        pageTiming: 2000,
                    },
                ],
            }),
        ]); };
        id ? setPrefix(contents.find(function (c) { return c.id === id; })) : setPrefix(contents[0]);
    };
    ContentPlayer.prototype.setQuestions = function (resources, questionSequence, signature) {
        this.insertQuestions(resources, questionSequence);
        this.formatQuestions(resources, signature);
        this.setState({ total: resources.contents.length });
    };
    ContentPlayer.prototype.insertQuestions = function (resources, questionSequence) {
        questionSequence && questionSequence.forEach(function (sequence, index) {
            resources.contents.splice(sequence - 1, 0, {
                questionStub: index
            });
        });
    };
    ContentPlayer.prototype.formatQuestions = function (resources, signature) {
        var _this = this;
        var step = 0;
        var temp = __spread(resources.contents);
        temp.forEach(function (content, index) {
            var _a;
            index += step;
            content.sequence = index;
            var questionStub = content.questionStub;
            if (Number.isInteger(questionStub)) {
                var start = questionStub * 3;
                // const questionContents = resources.questions.reverse()
                var questionContents = resources.questions
                    .slice(start, start + 3)
                    .map(function (question, pos) { return (__assign({}, question, { sequence: index + pos })); })
                    .reduce(function (questions, question) {
                    return questions.concat([
                        question.id !== guidEmpty() ?
                            {
                                id: question.id,
                                sequence: question.sequence,
                                contents: _this.getQuestion(question, signature, true),
                                exts: {
                                    question: question,
                                    contents: _this.getQuestionContentGroup(question, signature),
                                },
                            } : {
                            id: question.id,
                            contents: [],
                        }
                    ]);
                }, [])
                    .map(function (question, index) { return ({
                    contentGroup: ContentGroup.Question,
                    id: question.id,
                    sequence: question.sequence,
                    description: __assign({ backgroundAudio: {
                            uri: swyk_blank,
                        }, subContents: {
                            contents: question.contents
                        }, exts: question.exts }, (index === 0 ? ({
                        subContentPos: SubContentPos.start,
                        subContentPrefix: _this.getQuestionPrefix(_this.getQuestionPrefixTitle(resources)),
                    }) : ({
                        subContentPos: SubContentPos.start,
                        subContentPrefix: [],
                    }))),
                }); });
                (_a = resources.contents).splice.apply(_a, __spread([index,
                    1], questionContents));
                step += 2;
            }
        });
        resources.contents = resources.contents
            .filter(function (f) { return typeof f.questionStub !== 'number'; }) // Handle questions which could not be inserted, because of questions' non-Availability
            .map(function (content, index) { return (__assign({}, content, { sequence: index })); });
    };
    ContentPlayer.prototype.getQuestionPrefixTitle = function (resources) {
        if (resources.unit) {
            var unit = Number(resources.unit);
            return unit > 30 ? "Review Words for Unit " + unit : "Unit " + unit;
        }
        return '';
    };
    ContentPlayer.prototype.getQuestionPrefix = function (title) {
        return [
            this.getInitContent({
                mediaUrl: '',
                pageUrl: [
                    {
                        url: swyk,
                        pageTiming: 3950,
                    },
                    {
                        url: swyk_blank,
                        pageTiming: 4000,
                    }
                ],
                subContentPos: SubContentPos.pre,
                title: title,
                needSigs: { media: false, page: false }
            }),
        ];
    };
    ContentPlayer.prototype.getQuestionContentGroup = function (question, signature) {
        return ({
            question: this.getQuestion(question, signature),
            answer: this.getAnswer(question, signature),
            candidators: this.getCandidators(question, signature),
        });
    };
    ContentPlayer.prototype.getAnswer = function (question, signature) {
        var appendSig = function (url, sig) { return url ? replaceSig(url, sig) : url; };
        return [
            this.getInitContent({ mediaUrl: getCorrectAnswer(), bkAudio: { middle: true }, needSigs: { media: false, page: false } }),
            this.getInitContent({ id: question.answer.contentId, mediaUrl: appendSig(question.answer.audioURL, signature.audioContainer), thumbnailUrl: appendSig(question.answer.thumbnailURL, signature.pageContainer), bkAudio: { middle: true } }),
        ];
    };
    ContentPlayer.prototype.getQuestion = function (question, signature, hasBkAudio) {
        var appendSig = function (url, sig) { return url ? replaceSig(url, sig) : url; };
        return [
            this.getInitContent({
                mediaUrl: appendSig(question.questionPreAudioURL, signature.audioContainer),
                // pageUrl: swyk_blank,
                bkAudio: hasBkAudio ? {
                    start: true,
                } : { middle: true },
            }),
            this.getInitContent({ mediaUrl: appendSig(question.answer.audioURL, signature.audioContainer), bkAudio: { middle: true } }),
            this.getInitContent({ mediaUrl: appendSig(question.questionPostAudioURL, signature.audioContainer), bkAudio: { middle: true } }),
        ].filter(function (c) { return c.medias[0].url; });
    };
    ContentPlayer.prototype.getCandidators = function (question, signature) {
        var _this = this;
        var appendSig = function (url, sig) { return url ? replaceSig(url, sig) : url; };
        return question.candidator
            .map(function (c) { return _this.getInitContent({ id: c.contentId, mediaUrl: appendSig(c.audioURL, signature.audioContainer), thumbnailUrl: appendSig(c.thumbnailURL, signature.pageContainer), bkAudio: { middle: true } }); });
    };
    ContentPlayer.prototype.getInitContent = function (_a) {
        var id = _a.id, mediaUrl = _a.mediaUrl, pageUrl = _a.pageUrl, bkAudio = _a.bkAudio, title = _a.title, _b = _a.subContentPos, subContentPos = _b === void 0 ? SubContentPos.middle : _b, contentPrefixPos = _a.contentPrefixPos, thumbnailUrl = _a.thumbnailUrl, needSigs = _a.needSigs;
        return {
            id: id,
            thumbnailUrl: thumbnailUrl,
            description: {
                subContentPos: subContentPos,
                contentPrefixPos: contentPrefixPos,
                mediaType: MediaType.audio,
                backgroundAudio: bkAudio,
                title: title,
                needSigs: needSigs,
            },
            medias: [
                {
                    url: mediaUrl,
                }
            ],
            pages: pageUrl ? typeof pageUrl !== 'object' ? [
                {
                    url: pageUrl,
                    pageTiming: 0,
                }
            ] : pageUrl : []
        };
    };
    ContentPlayer.prototype.streamPreloadImage = function (contents) {
        return contents.reduce(function (promises, content) {
            return promises.concat(content.pages ? content.pages.map(getPreLoadImagePromise) : []);
        }, []);
    };
    ContentPlayer.prototype.streamPreloadQuestionThumbnail = function (contents) {
        return contents.reduce(function (promises, content) {
            if (content.contentGroup === ContentGroup.Question && hasNextContents(content)) {
                var pics = getCandidators(content).concat(getAnswer(content)).map(function (c) { return ({ url: c.thumbnailUrl }); });
                return promises.concat(pics.map(getPreLoadImagePromise));
            }
            return promises;
        }, []);
    };
    ContentPlayer.prototype.formatContent = function (content, _a) {
        var type = _a.type, setVideoUrl = _a.setVideoUrl, setAudioUrl = _a.setAudioUrl;
        var description = content.description = content.contentGroup !== ContentGroup.Question ? ({ mediaType: MediaType.video }) : content.description;
        var promise = [];
        setAudioUrl = setAudioUrl || (function () { return Promise.resolve(true); });
        setVideoUrl = setVideoUrl || (function () { return Promise.resolve(true); });
        switch (content.contentGroup) {
            case ContentGroup.VPC:
                description.mediaType = MediaType.audio;
                description.singleCycle = 2;
                description.interval = { pre: 1300, mid: 1300 };
                return promise.concat(setAudioUrl());
            case ContentGroup.Story:
            case ContentGroup.Song:
                description.mediaType = MediaType.audio;
                return promise.concat(setAudioUrl());
            case ContentGroup.Question:
                return promise.concat(setAudioUrl());
            case ContentGroup.Movie:
                description.type = type;
                return promise.concat(setVideoUrl());
            case ContentGroup.Conversation:
                description.mediaType = null;
                return null;
        }
    };
    ContentPlayer.prototype.setVPCBackgroundAudio = function (contents, backgroundAudios, swyks) {
        backgroundAudios = (backgroundAudios.map(function (bk, index) { return ({
            bk: bk,
            swyk: swyks[index],
        }); }) || []).sort(random);
        var bkLoop = function (audio) {
            backgroundAudios.push(audio);
            return audio;
        };
        var shiftLoop = function () { return bkLoop(backgroundAudios.shift()); };
        var popLoop = function () { return bkLoop(backgroundAudios.pop()); };
        var setSwyk = function (cur, url) { return cur.description.subContentPrefix
            && cur.description.subContentPrefix.length > 0
            && cur.description.subContentPrefix.forEach(function (c) { return c.medias.forEach(function (m) { return m.url = url; }); }); };
        contents.reduce(function (pre, cur) {
            if (cur.id === guidEmpty())
                return cur;
            if (!pre.description && cur.contentGroup === ContentGroup.VPC) {
                cur.description.interval.pre = 1500;
                cur.description.backgroundAudio = {
                    uri: shiftLoop().bk,
                    start: true,
                };
                return cur;
            }
            if (pre.contentGroup === ContentGroup.Question && cur.contentGroup === ContentGroup.VPC) {
                cur.description.interval.pre = 1500;
                cur.description.backgroundAudio = {
                    uri: popLoop().bk,
                    start: pre.id === guidEmpty(),
                    middle: true,
                };
                return cur;
            }
            if (pre.contentGroup !== ContentGroup.VPC && cur.contentGroup === ContentGroup.VPC) {
                cur.description.interval.pre = 1500;
                cur.description.backgroundAudio = {
                    uri: shiftLoop().bk,
                    start: true,
                };
                return cur;
            }
            if (pre.contentGroup === ContentGroup.VPC && cur.contentGroup === ContentGroup.Question) {
                if (cur.description.subContents.contents[0].description.backgroundAudio.start) {
                    var audio = popLoop();
                    cur.description.subContents.contents[0].description.backgroundAudio.uri = audio.bk;
                    cur.description.subContents.contents[0].description.backgroundAudio.start = false;
                    cur.description.subContents.contents[0].description.backgroundAudio.middle = false;
                    setSwyk(cur, audio.swyk);
                }
                return cur;
            }
            if (pre.contentGroup === ContentGroup.Question && cur.contentGroup === ContentGroup.Question) {
                if (cur.description.subContents.contents[0].description.backgroundAudio.start) {
                    var audio = popLoop();
                    cur.description.subContents.contents[0].description.backgroundAudio.uri = audio.bk;
                    cur.description.subContents.contents[0].description.backgroundAudio.start = pre.id === guidEmpty();
                    cur.description.subContents.contents[0].description.backgroundAudio.middle = false;
                    setSwyk(cur, audio.swyk);
                }
                return cur;
            }
            if (pre.contentGroup !== ContentGroup.VPC && cur.contentGroup === ContentGroup.Question) {
                if (cur.description.subContents.contents[0].description.backgroundAudio.start) {
                    var audio = shiftLoop();
                    cur.description.subContents.contents[0].description.backgroundAudio.uri = audio.bk;
                    setSwyk(cur, audio.swyk);
                }
                return cur;
            }
            if (pre.contentGroup === ContentGroup.VPC && cur.contentGroup !== ContentGroup.VPC) {
                pre.description.interval.post = 1500;
                pre.description.backgroundAudio = {
                    uri: popLoop().bk,
                    end: true,
                };
                return cur;
            }
            if (cur.contentGroup === ContentGroup.VPC) {
                cur.description.backgroundAudio = {
                    uri: popLoop().bk,
                    middle: true,
                };
                return cur;
            }
            return cur;
        }, {});
    };
    ContentPlayer.prototype.getController = function (controller) {
        var _this = this;
        this.controller = controller;
        this.controller.addEventListener('play', function () {
            _this.setState({ isPaused: false });
            var onPlay = _this.props.onPlay;
            onPlay && onPlay();
        });
        this.controller.addEventListener('pause', function () {
            _this.setState({ isPaused: true });
            var onPause = _this.props.onPause;
            onPause && onPause();
        });
        this.controller.addEventListener('ended', function () {
            var onEnded = _this.props.onEnded;
            onEnded && onEnded();
        });
        this.controller.addEventListener('currentEnded', function (ev) {
            var onCurrentEnded = _this.props.onCurrentEnded;
            onCurrentEnded && onCurrentEnded(ev.data);
        });
    };
    ContentPlayer.prototype.render = function () {
        var _this = this;
        var _a = this.state, resources = _a.resources, loaded = _a.loaded, remainingCount = _a.remainingCount, total = _a.total, isPaused = _a.isPaused, content = _a.content, times = _a.times;
        var _b = this.props, signature = _b.signature, onStop = _b.onStop, children = _b.children, visibleBar = _b.visibleBar, visibleMedia = _b.visibleMedia, onCurrentStarted = _b.onCurrentStarted, markCurrentItemAsPlayed = _b.markCurrentItemAsPlayed, startIndex = _b.startIndex, playMode = _b.playMode, autoplay = _b.autoplay, rest = __rest(_b, ["signature", "onStop", "children", "visibleBar", "visibleMedia", "onCurrentStarted", "markCurrentItemAsPlayed", "startIndex", "playMode", "autoplay"]);
        var passedProps = __assign({}, rest, { times: times, isPaused: isPaused, remainingCount: remainingCount, total: total, content: content, contentId: content ? content.id : undefined, startIndex: startIndex, playMode: playMode, signature: signature, play: function () { return _this.controller.play(); }, pause: function () { return _this.controller.pause(); }, paused: function (d) { return _this.controller.paused(d); }, stop: function () {
                _this.controller.stop();
                onStop && onStop();
            }, seek: function (seconds) { return _this.controller.seek(seconds); }, repeat: function (again) { return _this.controller.repeat(again); }, setPlayMode: function (mode) { return _this.controller.setPlayMode(mode); }, markCurrentItemAsPlayed: function () { return _this.controller.markCurrentItemAsPlayed(); }, playSequence: function (contents, fromConversation) { return _this.controller.playSequence(contents, fromConversation); }, events: {
                addEventListener: function (type, callback) { return _this.controller.addEventListener(type, callback); },
                removeEventListener: function (type, callback) { return _this.controller.removeEventListener(type, callback); },
            } });
        var components = React.Children.map(this.props.children, function (child) { return React.cloneElement(child, passedProps); });
        return React.createElement("div", { className: "contentplayer" },
            React.createElement(AutoPlayAlert, null),
            React.createElement(MediaPlayer, { autoplay: autoplay, resources: resources, getController: this.getController.bind(this), onCurrentStarted: function (context) {
                    var remainingCount = context.remainingCount, content = context.content;
                    _this.setState({ remainingCount: remainingCount, content: content });
                    onCurrentStarted && onCurrentStarted(__assign({}, context, { total: total }));
                }, markCurrentItemAsPlayed: markCurrentItemAsPlayed, onTimeUpdate: function (times) { return _this.setState({ times: times }); }, visibleBar: visibleBar, visibleMedia: visibleMedia, startIndex: startIndex, playMode: playMode, playbackRate: this.props.playbackRate }, loaded && content.description && components));
    };
    __decorate([
        lazyInject(TYPES.IResourceService)
    ], ContentPlayer.prototype, "resourceService", void 0);
    ContentPlayer = __decorate([
        connect(function (_a) {
            var signature = _a.signature.signature;
            return ({
                signature: signature,
            });
        }, null, function (_a) {
            var nextSignature = _a.state.signature, signature = _a.own.signature;
            return ({
                signature: Object.keys(nextSignature).length > 0 ? nextSignature : signature
            });
        })
    ], ContentPlayer);
    return ContentPlayer;
}(Component));
export default ContentPlayer;
function replaceSig(uri, sig) {
    if (!uri)
        return uri;
    if (uri.lastIndexOf('?') > -1) {
        return uri.replace(/\?.*/, sig);
    }
    else {
        return "" + uri + sig;
    }
}
function getPreLoadImagePromise(pic) {
    return function () { return new Promise(function (resolve, reject) {
        if (pic && pic.url) {
            var img = document.createElement("img");
            img.style.display = "none";
            img.className = "preload-img";
            img.src = pic.url;
            document.body.appendChild(img);
            img.onload = function () { return resolve(true); };
            img.onerror = function () { return resolve(true); };
        }
    }); };
}
function getAnswer(content) {
    var contents = getNextContents(content, 'answer');
    contents[contents.length - 1].description.subContentPos = SubContentPos.end;
    return contents;
}
function getCandidators(content) {
    return getNextContents(content, 'candidators');
}
function getNextContents(content, key) {
    var exts = content.description.exts;
    var next = exts.contents[key].map(function (c) { return (c.description.exts = exts, c); });
    return next;
}
function hasNextContents(content) {
    return !!content.description.exts;
}
