var StudentLocale = /** @class */ (function () {
    function StudentLocale() {
    }
    StudentLocale.ChildrenWelcomeTitle = "children.welcome.title";
    StudentLocale.ChildrenSelectTitle = "children.select.title";
    StudentLocale.ChildrenExtendedSubscription = "children.extended.subscription";
    StudentLocale.ChildrenSchoolTitle = "children.school.title";
    StudentLocale.ChildrenClassTitle = "children.class.title";
    StudentLocale.ChildrenNoneUnit = "children.none.unit";
    StudentLocale.WelcomeBegin = "welcome.begin";
    StudentLocale.ConcurrencyMessageLine_1 = "concurrency.message.line_1";
    StudentLocale.ConcurrencyMessageLine_2 = "concurrency.message.line_2";
    StudentLocale.ConcurrencyStartButton = "concurrency.start_button";
    StudentLocale.MenuPlaymode = "menu.playmode";
    StudentLocale.MenuLibrary = "menu.library";
    StudentLocale.MenuListen = "menu.listen";
    StudentLocale.MenuHelp = "menu.help";
    StudentLocale.MenuDropdownSignout = "menu.dropdown.signout";
    StudentLocale.MenuDropdownEditProfile = "menu.dropdown.editprofile";
    StudentLocale.MediaplayerMessageAutoplayalert = "mediaplayer.message.autoplayalert";
    StudentLocale.CommonSiteTitle = "common.site.title";
    StudentLocale.ConversationIntroVideoTitle = "conversation.introvideo.title";
    StudentLocale.NotificationMarkAllAsRead = "notification.mark.all.as.read";
    StudentLocale.NotificationMarkAsReadText = "notification.view.markAsRead";
    StudentLocale.NotificationClearAll = "notification.clear.all";
    StudentLocale.NotificationsNoData = "notification.no.data";
    StudentLocale.NotificationsShowMore = "notification.show.more";
    StudentLocale.PlayListRegionHasNoRep = "playlist.region.hasnorep";
    return StudentLocale;
}());
export { StudentLocale };
var SystemCheckLocale = /** @class */ (function () {
    function SystemCheckLocale() {
    }
    SystemCheckLocale.MenuText = "systemcheck.menu_text";
    SystemCheckLocale.PopupTitle = "systemcheck.popup_title";
    SystemCheckLocale.PopupMicrophoneLabel = "systemcheck.popup_microphone_label";
    SystemCheckLocale.PopupButtonText = "systemcheck.popup_button_text";
    SystemCheckLocale.PopupDeniedTitle = "systemcheck.popup_denied_title";
    SystemCheckLocale.PopupDeniedText = "systemcheck.popup_denied_text";
    return SystemCheckLocale;
}());
export { SystemCheckLocale };
var ConversationTestLocale = /** @class */ (function () {
    function ConversationTestLocale() {
    }
    ConversationTestLocale.ConversationTestVoiceUrlNotGenerated = "conversation.test.voiceurlmessage";
    ConversationTestLocale.ConversationTestInvalidSpeed = "conversation.test.invalidspeedmessage";
    ConversationTestLocale.ConversationTestPlayBeginningButton = "conversation.test.playbeginningbutton";
    ConversationTestLocale.ConversationTestClearTraceButton = "conversation.test.cleartracebutton";
    ConversationTestLocale.ConversationTestDownloadTraceButton = "conversation.test.downloadtracebutton";
    ConversationTestLocale.ConversationTestTraceHeading = "conversation.test.traceheading";
    ConversationTestLocale.ConversationTestTraceDialogLabel = "conversation.test.tracedialoglabel";
    ConversationTestLocale.ConversationTestTraceIntentLabel = "conversation.test.traceintentlabel";
    ConversationTestLocale.ConversationTestTracePercentageLabel = "conversation.test.tracepercentagelabel";
    ConversationTestLocale.ConversationTestTraceUtteranceLabel = "conversation.test.traceutterancelabel";
    ConversationTestLocale.ConversationTestSuccessMessage = "conversation.test.successmessage";
    ConversationTestLocale.ConversationTestCancelMessage = "conversation.test.cancelmessage";
    ConversationTestLocale.ConversationTestErrorMessage = "conversation.test.errormessage";
    ConversationTestLocale.ConversationTestMicrophoneMessage = "conversation.test.microphonemessage";
    ConversationTestLocale.ConversationTestingText = "conversation.testing.text";
    ConversationTestLocale.ConversationServerDownMessage = "conversation.test.serverdownmessage";
    return ConversationTestLocale;
}());
export { ConversationTestLocale };
var ConversationFeedbackLocale = /** @class */ (function () {
    function ConversationFeedbackLocale() {
    }
    ConversationFeedbackLocale.ConversationFeedbackLinkText = "conversation.feedback.linktext";
    ConversationFeedbackLocale.ConversationFeedbackAnalysisTitle = "conversation.feedback.analysistitle";
    ConversationFeedbackLocale.ConversationFeedbackCommentTitle = "conversation.feedback.commenttitle";
    ConversationFeedbackLocale.ConversationFeedbackCommentsTitle = "conversation.feedback.commentstitle";
    ConversationFeedbackLocale.ConversationFeedbackSubmitAllBtnText = "conversation.feedback.submitbtntext";
    ConversationFeedbackLocale.ConversationFeedbackGeneralNotesLabel = "conversation.feedback.gennoteslabel";
    ConversationFeedbackLocale.ConversationFeedbackNotesLabel = "conversation.feedback.noteslabel";
    ConversationFeedbackLocale.ConversationFeedbackSaveBtnText = "conversation.feedback.savebtntext";
    ConversationFeedbackLocale.ConversationFeedbackDeleteBtnText = "conversation.feedback.deletebtntext";
    ConversationFeedbackLocale.ConversationFeedbackPathLabelText = "conversation.feedback.pathlabel";
    ConversationFeedbackLocale.ConversationFeedbackIntentLabelText = "conversation.feedback.intentlabel";
    ConversationFeedbackLocale.ConversationFeedbackEntityLabelText = "conversation.feedback.entitylable";
    ConversationFeedbackLocale.ConversationFeedbackComputerSpokenNotWithFlowText = "conversation.feedback.computerspoken.notwithflowtext";
    ConversationFeedbackLocale.ConversationFeedbackComputerSpokenStrangeVoiceText = "conversation.feedback.computerspoken.strangevoicetext";
    ConversationFeedbackLocale.ConversationFeedbackComputerSpokenMispronunciationText = "conversation.feedback.computerspoken.mispronunciationtext";
    ConversationFeedbackLocale.ConversationFeedbackComputerSpokenStrangeAnimationText = "conversation.feedback.computerspoken.strangeanimationtext";
    ConversationFeedbackLocale.ConversationFeedbackComputerSpokenOtherText = "conversation.feedback.computerspoken.othertext";
    ConversationFeedbackLocale.ConversationFeedbackUserSpokenInaccurateSpeechText = "conversation.feedback.userspoken.inaccuratespeechtext";
    ConversationFeedbackLocale.ConversationFeedbackUserSpokenIntentionText = "conversation.feedback.userspoken.intentiontext";
    ConversationFeedbackLocale.ConversationFeedbackUserSpokenTechnicalText = "conversation.feedback.userspoken.technicaltext";
    ConversationFeedbackLocale.ConversationFeedbackUserSpokenOtherText = "conversation.feedback.userspoken.othertext";
    ConversationFeedbackLocale.ConversationFeedbackSavedText = "conversation.feedback.savedtext";
    ConversationFeedbackLocale.ConversationFeedbackUnSavedText = "conversation.feedback.unsavedtext";
    ConversationFeedbackLocale.ConversationFeedbackUnsavedWarningMessage = "conversation.feedback.unsavedwarningmsg";
    ConversationFeedbackLocale.ConversationFeedbackNoDisputeWarningMessage = "conversation.feedback.nodisputewarningmsg";
    ConversationFeedbackLocale.ConversationFeedbackConversationHistoryTitle = "conversation.feedback.conversationhistorytitle";
    ConversationFeedbackLocale.ConversationFeedbackConversationFeedbackTitle = "conversation.feedback.conversationfeedbacktitle";
    return ConversationFeedbackLocale;
}());
export { ConversationFeedbackLocale };
var ConversationListLocale = /** @class */ (function () {
    function ConversationListLocale() {
    }
    ConversationListLocale.ConversationListHeadingText = "convesationlist.heading.text";
    ConversationListLocale.ConversationListGridNameText = "convesationlist.grid.name.text";
    ConversationListLocale.ConversationListGridUnitText = "convesationlist.grid.unit.text";
    ConversationListLocale.ConversationListGridPlayText = "convesationlist.grid.play.text";
    return ConversationListLocale;
}());
export { ConversationListLocale };
var TermOfPolicyLocale = /** @class */ (function () {
    function TermOfPolicyLocale() {
    }
    TermOfPolicyLocale.TermOfPolicyHeader = "termofpolicy.header";
    TermOfPolicyLocale.TermOfPolicyAgree = "termofpolicy.agree";
    TermOfPolicyLocale.TermOfPolicyCancel = "termofpolicy.cancel";
    return TermOfPolicyLocale;
}());
export { TermOfPolicyLocale };
var ExtendedSubscriptionNoticeLocale = /** @class */ (function () {
    function ExtendedSubscriptionNoticeLocale() {
    }
    ExtendedSubscriptionNoticeLocale.SubscriptionExpiredHeader = "extendedsubscriptionnotice.subscriptionexpired.header";
    ExtendedSubscriptionNoticeLocale.SubscriptionExpiredSingleChildMessage = "extendedsubscriptionnotice.subscriptionexpired.singlechild.message";
    ExtendedSubscriptionNoticeLocale.SubscriptionExpiredMultipleChildrenMessage = "extendedsubscriptionnotice.subscriptionexpired.multiplechildren.message";
    ExtendedSubscriptionNoticeLocale.SubscriptionExpiredMultipleChildrenChildDetail = "extendedsubscriptionnotice.subscriptionexpired.multiplechildren.childdetail";
    ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationHeader = "extendedsubscriptionnotice.extendedsubscriptionexpiration.header";
    ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationSingleChildMessage = "extendedsubscriptionnotice.extendedsubscriptionexpiration.singlechild.message";
    ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationMultipleChildrenMessage = "extendedsubscriptionnotice.extendedsubscriptionexpiration.multiplechildren.message";
    ExtendedSubscriptionNoticeLocale.ExtendedSubscriptionExpirationMultipleChildrenChildDetail = "extendedsubscriptionnotice.extendedsubscriptionexpiration.multiplechildren.childdetail";
    ExtendedSubscriptionNoticeLocale.Close = "extendedsubscriptionnotice.close";
    return ExtendedSubscriptionNoticeLocale;
}());
export { ExtendedSubscriptionNoticeLocale };
var CommonUI = /** @class */ (function () {
    function CommonUI() {
    }
    CommonUI.helpMenuDocumentLabel = "helpMenu.document.label";
    return CommonUI;
}());
export { CommonUI };
