var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JbAnimations } from "..";
import { GestureBase } from "./gestures.base";
import { JbInteractionStyles } from "./gestures.types";
var JbIndicativeGestures = /** @class */ (function (_super) {
    __extends(JbIndicativeGestures, _super);
    function JbIndicativeGestures() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.animations = [
            {
                id: "IndicativeGesture#1",
                styleId: JbInteractionStyles.Indicative,
                animations: [
                    new JbAnimations.Neutral(300),
                    new JbAnimations.RaiseHandFromNeutral(),
                    new JbAnimations.RaiseHandBlink(),
                    new JbAnimations.RaiseHandToNeutral(),
                    new JbAnimations.Neutral(200),
                ],
            },
            {
                id: "IndicativeGesture#2",
                styleId: JbInteractionStyles.Indicative,
                animations: [
                    new JbAnimations.Neutral(300),
                    new JbAnimations.RaiseHandFromNeutral(),
                    new JbAnimations.RaiseHandToNeutral(),
                    new JbAnimations.Neutral(400),
                ],
            },
        ];
        return _this;
    }
    return JbIndicativeGestures;
}(GestureBase));
export { JbIndicativeGestures };
