var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { JonneyBear } from "@app/components/jonny-bear";
import { JbGestures } from "@app/components/jonny-bear/gestures";
import { JbInteractionStyles } from "@app/components/jonny-bear/gestures/gestures.types";
import { Divider, Switch } from "antd";
import { Constants } from "@app/util";
import { Button, Col, Row } from "antd-min";
import { isNumber, keys } from "lodash";
import React, { useRef, useState } from "react";
export var Jonney = function () {
    var _a;
    var jonneyRef = useRef();
    var _b = __read(useState(Constants.DefaultPlaybackRate), 2), playbackRate = _b[0], setPlayBackRate = _b[1];
    var _c = __read(useState(true), 2), isChecked = _c[0], setChecked = _c[1];
    var startAnimaton = function (index, style) {
        if (style === void 0) { style = JbInteractionStyles.Positive; }
        var gesture;
        if (isNumber(index)) {
            gesture = JbGestures[style].animations[index].animations;
        }
        else {
            gesture = JbGestures[style].pickRandom().gesture.animations;
        }
        jonneyRef.current.executor(gesture);
    };
    var gesturesKeys = keys(JbGestures);
    var metadata = (_a = {},
        _a[JbInteractionStyles.Positive] = { title: "Positive Gestures" },
        _a[JbInteractionStyles.Negative] = { title: "Negative Gestures" },
        _a[JbInteractionStyles.Neutral] = { title: "Neutral Gestures" },
        _a[JbInteractionStyles.Indicative] = { title: "Indicative Gestures" },
        _a[JbInteractionStyles.Encouraging] = { title: "Encouraging Gestures" },
        _a[JbInteractionStyles.Idle] = { title: "Idle Gestures" },
        _a[JbInteractionStyles.Fallback] = { title: "Fallback Gestures" },
        _a);
    var toggleJBSpeed = function (e) {
        if (!e) {
            setPlayBackRate(Constants.ConversationSlowModePlaybackRate);
            setChecked(false);
        }
        else {
            setPlayBackRate(Constants.DefaultPlaybackRate);
            setChecked(true);
        }
    };
    return (React.createElement("div", { style: {
            maxWidth: 1500,
            margin: "50px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        } },
        React.createElement(Row, { type: "flex", gutter: 30 },
            React.createElement(Col, { style: { minWidth: 400 } },
                React.createElement(JonneyBear, { ref: jonneyRef, playbackRate: playbackRate })),
            React.createElement(Col, null,
                React.createElement(Divider, { type: "vertical", style: { height: "100%" } })),
            React.createElement(Col, { style: { flex: "auto" } },
                React.createElement(Row, { type: "flex" },
                    React.createElement(Col, { style: { display: "flex", alignItems: "center" } },
                        React.createElement("span", { style: { paddingRight: "10px" } }, "0.8x"),
                        React.createElement(Switch, { checked: isChecked, onChange: function (e) { return toggleJBSpeed(e); } }),
                        React.createElement("span", { style: { paddingLeft: "10px" } }, "1.0x"))),
                gesturesKeys.map(function (key) {
                    var animations = JbGestures[key].animations;
                    return (React.createElement(Row, { key: key, type: "flex", gutter: 8, style: { marginTop: 20 }, align: "middle" },
                        React.createElement(Col, { style: { width: 180 } },
                            React.createElement("b", null,
                                metadata[key].title,
                                ": ")),
                        React.createElement(Col, null,
                            React.createElement(Button, { onClick: function () { return startAnimaton(undefined, key); } }, "Random")),
                        animations.map(function (_, index) {
                            return (React.createElement(Col, { key: index.toString() },
                                React.createElement(Button, { onClick: function () { return startAnimaton(index, key); } },
                                    "#",
                                    index + 1)));
                        })));
                })))));
};
