var MobileDetect = require('mobile-detect');
export function showHeaderFooter() {
    if (isMobileOrTablet()) {
        var headers = document.getElementsByClassName("gl-header");
        if (headers.length > 0) {
            var header = headers[0];
            header.style.display = "flex";
        }
        var footers = document.getElementsByClassName("gl-footer");
        if (footers.length > 0) {
            var footer = footers[0];
            footer.style.removeProperty("display");
        }
    }
}
export function hidenHeaderFooter() {
    if (isMobileOrTablet()) {
        var headers = document.getElementsByClassName("gl-header");
        if (headers.length > 0) {
            var header = headers[0];
            header.style.display = "none";
        }
        var footers = document.getElementsByClassName("gl-footer");
        if (footers.length > 0) {
            var footer = footers[0];
            footer.style.display = "none";
        }
    }
}
function isMobileOrTablet() {
    var md = new MobileDetect(window.navigator.userAgent);
    return md.mobile() || md.tablet();
}
