var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GLGlobal } from "gl-commonui";
export default {
    namespace: "playlistplayer",
    state: {
        paused: false,
        completedPlaylist: undefined,
        studentPuzzle: undefined,
        isReaderAction: false
    },
    reducers: {
        paused: function (state, action) {
            return __assign({}, state, { paused: action.payload });
        },
        setCompletedPlaylist: function (state, action) {
            return __assign({}, state, { completedPlaylist: action.payload });
        },
        setStudentPuzzle: function (state, action) {
            return __assign({}, state, { studentPuzzle: action.payload });
        },
        setIsReaderAction: function (state, action) {
            return __assign({}, state, { isReaderAction: action.payload });
        }
    }
};
export function setIsReaderAction(payload) {
    return GLGlobal.store.dispatch({
        type: "playlistplayer/setIsReaderAction",
        payload: payload
    });
}
export function setCompletedPlaylist(payload) {
    return GLGlobal.store.dispatch({
        type: "playlistplayer/setCompletedPlaylist",
        payload: payload
    });
}
export function setStudentPuzzle(payload) {
    return GLGlobal.store.dispatch({
        type: "playlistplayer/setStudentPuzzle",
        payload: payload
    });
}
export function getCompletedPlaylist() {
    return GLGlobal.store.getState().playlistplayer.completedPlaylist;
}
export function getStudentPuzzle() {
    return GLGlobal.store.getState().playlistplayer.studentPuzzle;
}
export function playlistplayerPaused(state) {
    return { type: "playlistplayer/paused", payload: state };
}
export function pausePlaylistPlayer(paused) {
    GLGlobal.store.dispatch(playlistplayerPaused(paused));
}
export function isPlaylistPlayerPaused() {
    return GLGlobal.store.getState().playlistplayer.paused;
}
